import type {DefinitionObject, RefInfo, Builder} from '@wix/document-services-types'
import {mapValues, pickBy} from 'lodash'
import {getStyleTypeSchema, type Schema} from './styleTypeSchemas'

export interface BuilderCompDetails {
    definition: Partial<DefinitionObject> & {innerElementsMapType: string}
    schemas: Record<string, Record<string, Schema>>
    identifier: string
    styleProps: StylePropsForBuilderComp
    refs: Record<string, Record<string, RefInfo[]>>
}

export interface StylePropsForBuilderComp {
    root: string[]
    innerElements: Record<string, string[]>
}

export function deriveFromManifest(identifier: string, manifest: Builder.Manifest): BuilderCompDetails {
    const innerElementsMapType = `${identifier}.InnerElementsMap`
    const styleType = `${identifier}.style`
    return {
        identifier,
        styleProps: extractStyleProps(manifest),
        definition: {
            type: 'Component',
            innerElementsMapType: `${identifier}.InnerElementsMap`,
            dataTypes: ['']
        },
        schemas: {
            innerElements: {
                [innerElementsMapType]: generateInnerElementsMapSchema(identifier, manifest)
            },
            style: {
                [styleType]: generateStyleSchema(manifest.definitions?.style ?? {})
            }
        },
        refs: {
            innerElements: {
                [innerElementsMapType]: Object.keys(manifest.elements ?? {}).map(key => ({
                    jsonPointer: `/${key}`,
                    isList: false,
                    referencedMap: 'innerElements',
                    isRefOwner: true,
                    shouldCollect: true,
                    shouldValidate: true,
                    refTypes: ['InnerElement'],
                    path: [key]
                }))
            }
        }
    }
}

function extractStyleProps(manifest: Builder.Manifest): StylePropsForBuilderComp {
    const root = Object.keys(manifest.definitions?.style ?? {})
    const innerElements = pickBy(
        mapValues(manifest.elements ?? {}, ({definitions}) => Object.keys(definitions?.style ?? {})),
        v => v.length > 0
    )
    return {
        root,
        innerElements
    }
}

function generateStyleSchema(style: Builder.Style): Record<string, Schema> {
    return {
        type: 'object',
        additionalProperties: false,
        properties: {
            ...topLevelDataNodeProperties(),
            properties: {
                type: 'object',
                // Accept any additional properties until a decision is made about styles
                additionalProperties: true,
                properties: mapValues(style, ({shapeOf}) => getStyleTypeSchema(shapeOf))
            }
        }
    }
}

function generateInnerElementsMapSchema(identifier: string, manifest: any) {
    const dataNodeProperties = topLevelDataNodeProperties()
    const ownProperties = mapValues(manifest.elements ?? {}, () => ({
        type: 'string',
        pseudoType: 'ref',
        refTypes: ['InnerElement'],
        referencedMap: 'innerElements',
        isRefOwner: true,
        shouldCollect: true,
        shouldValidate: true
    }))
    return {
        type: 'object',
        additionalProperties: false,
        properties: {
            ...ownProperties,
            ...dataNodeProperties
        }
    }
}

export function topLevelDataNodeProperties(): Record<string, Schema> {
    return {
        type: {
            type: 'string'
        },
        id: {
            type: 'string'
        },
        metaData: {
            type: 'object',
            properties: {
                pageId: {
                    type: 'string'
                },
                isPreset: {
                    type: 'boolean'
                },
                schemaVersion: {
                    type: 'string'
                },
                isHidden: {
                    type: 'boolean'
                },
                logicalName: {
                    type: 'string'
                },
                description: {
                    type: 'string',
                    maxLength: 4000
                },
                sig: {
                    type: 'string'
                }
            }
        }
    }
}
