import type {CreateExtArgs, DmApis, Extension, ExtensionAPI, KeyValPredicate} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import type {VariantsExtensionAPI} from '../variants/variants'

interface StylesApi extends ExtensionAPI {
    getComponentsWithStylePredicate(predicate: KeyValPredicate, pageId?: string): Pointer[]
}

export interface StylesExtensionApi extends ExtensionAPI {
    style: StylesApi
}

const createExtension = (): Extension => {
    const createExtensionAPI = ({extensionAPI, pointers}: CreateExtArgs): StylesExtensionApi => {
        const {variants} = extensionAPI as VariantsExtensionAPI

        const getComponentsWithStylePredicate = (
            predicate: KeyValPredicate,
            pageId: string = 'masterPage'
        ): Pointer[] => {
            const styles = pointers.data.getStyleItemsWithPredicate(
                (value, key) => value.type === 'ComponentStyle' && predicate(value, key),
                pageId
            )
            return styles.map(variants.getOwningComponentPointerWithContext).filter(x => !!x) as Pointer[]
        }

        return {
            style: {
                getComponentsWithStylePredicate
            }
        }
    }

    const createPublicAPI = ({extensionAPI}: DmApis) => {
        const {style} = extensionAPI as StylesExtensionApi

        return {
            style: {
                getComponentsWithStylePredicate: style.getComponentsWithStylePredicate
            }
        }
    }
    return {
        name: 'style',
        createExtensionAPI,
        createPublicAPI
    }
}

export {createExtension}
