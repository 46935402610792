import {addIdToMap, getCustomId} from '../utils'
import type {DalItem} from '@wix/document-manager-core'
import {addNamespaceToStructure} from '../componentDeserialization'
import {DATA_TYPES} from '../../../../constants/constants'
import type {DeserializeComponentParams} from '../types'

export const updateComponentAnchorStructure = (deserializationParams: DeserializeComponentParams) => {
    const {compStructure, mappers, customId} = deserializationParams
    const {oldToNewIdMap} = mappers ?? {oldToNewIdMap: {}}
    deserializationParams.customId = getCustomId(oldToNewIdMap, compStructure.anchor, customId)
    const anchorQuery = addNamespaceToStructure(
        deserializationParams,
        compStructure.anchor as DalItem,
        DATA_TYPES.anchors,
        oldToNewIdMap
    )
    if (anchorQuery) {
        compStructure.anchorQuery = anchorQuery
        addIdToMap(compStructure.anchor, anchorQuery, oldToNewIdMap)
    }
}
