import type {ComponentToChildrenOrderMap} from './types'
import _ from 'lodash'
import type {ComponentData, ComponentToChildrenGroupMap} from '../../types'
import {VIRTUAL_TAG} from '../grouping/constants'

const sortChildren = (componentToChildrenOrderMap: ComponentToChildrenOrderMap, comp: ComponentData) => {
    comp.components = comp.components?.sort((a, b) => {
        return (
            componentToChildrenOrderMap[a].measurements!.y - componentToChildrenOrderMap[b].measurements!.y ||
            componentToChildrenOrderMap[a].measurements!.x - componentToChildrenOrderMap[b].measurements!.x
        )
    })
}

export const removeVirtualGroup = (
    componentToChildrenOrderMap: ComponentToChildrenOrderMap,
    comp: ComponentData,
    realChildren: string[] = []
) => {
    if (!comp?.components) {
        return
    }

    for (const childId of comp.components) {
        const child = componentToChildrenOrderMap[childId]
        if (child.componentType === VIRTUAL_TAG) {
            removeVirtualGroup(componentToChildrenOrderMap, child, realChildren)
        } else {
            realChildren.push(child.id)
        }
    }
}

export const orderingGroupMap = (componentToChildrenGroupMap: ComponentToChildrenGroupMap) => {
    const componentToChildrenOrderMap: ComponentToChildrenOrderMap = _.cloneDeep(componentToChildrenGroupMap)
    _.forEach(componentToChildrenOrderMap, comp => {
        sortChildren(componentToChildrenOrderMap, comp)
    })
    return componentToChildrenOrderMap
}
