import type {DocumentManager} from '@wix/document-manager-core'
import type {CompStructure, Pointer, PS, RoutersDefinition} from '@wix/document-services-types'
import {HooksExtensionApi, hooks as extensionHooks} from '@wix/document-manager-extensions'
type DsHooks = typeof import('@wix/document-services-implementation').hooks

const notifySiteSaved = (ps: PS, isFirstSave: boolean) => {
    if (ps.events.CSAVE) {
        ps.events.emitter.emit(ps.events.CSAVE.SITE_SAVED, isFirstSave)
    }
}

const notifyBeforeAddHookToExtensions = (
    ps: PS,
    componentToAddRef: Pointer,
    containerReference: Pointer,
    componentDefinition: CompStructure,
    optionalCustomId?: string,
    optionalIndex?: number
) => {
    ps.events.emitter.emit(
        ps.events.COMPONENTS.BEFORE_ADD_ROOT,
        componentToAddRef,
        containerReference,
        componentDefinition,
        optionalCustomId,
        optionalIndex
    )
}

const notifyExtensionsAfterAdd = (
    ps: PS,
    compToAddPointer: Pointer,
    componentDefinition: CompStructure,
    optionalCustomId: string | undefined,
    mappers: Record<string, any>,
    containerPointer: Pointer
) => {
    const {hooks} = ps.extensionAPI as HooksExtensionApi
    hooks.executeHook(
        extensionHooks.COMPONENTS_HOOKS.ADD_COMPONENT.AFTER.createEvent({
            componentType: componentDefinition.componentType,
            compToAddPointer,
            componentDefinition,
            optionalCustomId,
            mappers,
            containerPointer
        }),
        componentDefinition.componentType
    )
}
export function bridgeDMEventsToHooks(ps: PS, documentManager: DocumentManager, dsHooks: DsHooks) {
    const {EVENTS, eventEmitter, experimentInstance} = documentManager
    const undoRedoEventsToTriggerAutoSave = [
        EVENTS.UNDO_REDO.TAKE_SNAPSHOT,
        EVENTS.UNDO_REDO.UNDO,
        EVENTS.UNDO_REDO.REDO
    ]

    const undoRedoEventsToTriggerAfterApplySnapShot = [EVENTS.UNDO_REDO.UNDO, EVENTS.UNDO_REDO.REDO]

    const notifyAutosave = () => dsHooks.executeHook(dsHooks.HOOKS.AUTOSAVE.ACTION, null!, [ps])

    const notifyAfterApplySnapshot = () =>
        dsHooks.executeHook(dsHooks.HOOKS.UNDO_REDO.AFTER_APPLY_SNAPSHOT, null!, [ps])

    dsHooks.registerHook(dsHooks.HOOKS.SAVE.SITE_SAVED, notifySiteSaved)
    if (EVENTS.CSAVE) {
        eventEmitter.on(EVENTS.CSAVE.NON_RECOVERABLE_ERROR, () => {
            dsHooks.executeHook(dsHooks.HOOKS.AUTOSAVE.CSAVE_NON_RECOVERABLE_ERROR, null!, [ps])
        })
    }

    eventEmitter.on(EVENTS.ROUTERS.BEFORE_UPDATE, (routerPtr: Pointer, routerData: RoutersDefinition) => {
        dsHooks.executeHook(dsHooks.HOOKS.ROUTER.BEFORE_UPDATE, 'router', [ps, routerPtr, routerData])
    })
    eventEmitter.on(EVENTS.ROUTERS.AFTER_ADD, (routerPtr: Pointer, routerData: RoutersDefinition) => {
        dsHooks.executeHook(dsHooks.HOOKS.ROUTER.AFTER_ADD, 'router', [ps, routerPtr, routerData])
    })

    eventEmitter.on(EVENTS.ROUTERS.BEFORE_REMOVE, (routerPtr: Pointer) => {
        dsHooks.executeHook(dsHooks.HOOKS.ROUTER.BEFORE_REMOVE, 'router', [ps, routerPtr])
    })
    eventEmitter.on(EVENTS.ROUTERS.ON_DISCONNECT, (routerPtr: Pointer, pageId: string) => {
        dsHooks.executeHook(dsHooks.HOOKS.ROUTER.ON_DISCONNECT, 'router', [ps, routerPtr, pageId])
    })
    eventEmitter.on(EVENTS.ROUTERS.AFTER_ADD, (routerPtr: Pointer, routerData: RoutersDefinition) => {
        dsHooks.executeHook(dsHooks.HOOKS.ROUTER.AFTER_ADD, 'router', [ps, routerPtr, routerData])
    })

    eventEmitter.on(EVENTS.COMPONENTS.AFTER_REMOVE, (compPointer: Pointer, compType: string, ...extraArgs: any[]) => {
        dsHooks.executeHook(dsHooks.HOOKS.REMOVE.AFTER!, compType, [ps, compPointer, ...extraArgs])
    })

    undoRedoEventsToTriggerAutoSave.forEach(ev => eventEmitter.on(ev, notifyAutosave))
    undoRedoEventsToTriggerAfterApplySnapShot.forEach(ev => eventEmitter.on(ev, notifyAfterApplySnapshot))

    if (EVENTS.LOCAL_EDITOR) {
        eventEmitter.on(EVENTS.LOCAL_EDITOR.AFTER_SYNC_FROM_FS, () => {
            dsHooks.executeHook(dsHooks.HOOKS.IMPORT_EXPORT.AFTER_IMPORT, null!, [ps])
        })
    }

    eventEmitter.on(EVENTS.CONNECTIONS.UPDATE_AFTER, (itemToUpdate: any, componentPointer: Pointer) => {
        dsHooks.executeHook(dsHooks.HOOKS.DATA.AFTER_UPDATE_CONNECTIONS, 'updateConnectionsItem', [
            ps,
            itemToUpdate,
            componentPointer
        ])
    })

    dsHooks.registerHook(dsHooks.HOOKS.ADD_ROOT.BEFORE, notifyBeforeAddHookToExtensions)

    if (experimentInstance.isOpen('dm_moveAfterAddComponentHook')) {
        dsHooks.registerHook(dsHooks.HOOKS.ADD.AFTER, notifyExtensionsAfterAdd)
    }
}
