import type {CreateExtArgs} from '@wix/document-manager-core'
import type {ErrorResult, Result} from './types'
import type {LoggerExtAPI} from '../logger'

export const isError = (r: Result): r is ErrorResult => r.success === false

export const sendBreadCrumbOnValidationError = (
    {extensionAPI}: CreateExtArgs,
    validationError: string,
    extras?: any
) => {
    const {logger} = extensionAPI as LoggerExtAPI
    logger.breadcrumb(validationError, {extras})
}
