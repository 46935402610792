import type {CreateExtArgs} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import {DATA_TYPES} from '../../constants/constants'

const TYPE_OF_MAIN_CONTAINER_INSIDE_POPUP = 'wysiwyg.viewer.components.PopupContainer'

const getPopupContainer = ({dal, pointers}: CreateExtArgs, popupPagePointer: Pointer) => {
    const childrenPointers = pointers.structure.getChildren(popupPagePointer)

    if (childrenPointers.length > 1) {
        throw new Error(
            `More than one child of the popup page was found. A popup page must have only single direct child of type ${TYPE_OF_MAIN_CONTAINER_INSIDE_POPUP}`
        )
    }
    if (childrenPointers.length < 1) {
        throw new Error('No direct child of the popup page was found')
    }

    const candidatePointer = childrenPointers[0]
    const candidateType = dal.get(pointers.getInnerPointer(candidatePointer, 'componentType'))

    if (candidateType !== TYPE_OF_MAIN_CONTAINER_INSIDE_POPUP) {
        throw new Error(
            `Direct child of the popup is of type ${candidateType}. Expected type: ${TYPE_OF_MAIN_CONTAINER_INSIDE_POPUP}`
        )
    }

    return candidatePointer
}

export const isPopup = ({pointers, dal}: CreateExtArgs, pageId: string) => {
    if (pageId === 'masterPage') {
        return false
    }

    const pageDataItemPointer = pointers.data.getDataItemFromMaster(pageId)
    const pageData = dal.get(pageDataItemPointer)
    return pageData?.isPopup
}

export const isPopupFullWidth = (createExtArgs: CreateExtArgs, popupPointer: Pointer) => {
    const {dataModel} = createExtArgs.extensionAPI as DataModelExtensionAPI

    const popupContainerPointer = getPopupContainer(createExtArgs, popupPointer)
    const popupContainerProps = dataModel.components.getItem(popupContainerPointer, DATA_TYPES.prop)
    return popupContainerProps.alignmentType === 'fullWidth'
}
