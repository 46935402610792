import type {
    MobileAlgoContext,
    Stage,
    StageHandler,
    StructureStageData,
    MobileConversionDal,
    MobileAlgoConfig
} from '../types'
import type {Pointer} from '@wix/document-services-types'

export const createStructureStage = (): Stage<StructureStageData> => {
    const handlers: StageHandler<StructureStageData>[] = []
    return {
        register: (handler: StageHandler<StructureStageData>) => {
            handlers.push(handler)
        },
        run: async (
            ctx: MobileAlgoContext,
            mobileConversionDal: MobileConversionDal,
            pageId: string,
            variants: Pointer[],
            config: MobileAlgoConfig
        ) => {
            for (const handler of handlers) {
                await handler({globalContext: ctx, mobileConversionDal, pageId, config})
            }
        }
    }
}
