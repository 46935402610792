import type {CreateExtArgs} from '@wix/document-manager-core'
import type {ComponentAfterAddDataEvent} from '../hooks'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {DATA_TYPES, COMP_TYPES} from '../../../constants/constants'
import type {DataModelExtensionAPI} from '../../dataModel/dataModel'
import {deepClone} from '@wix/wix-immutable-proxy'

const getNewHiddenAnchorIds = (oldHiddenAnchorIds: any, oldToNewIdMap: any) => {
    return _.compact(
        _.map(oldHiddenAnchorIds, hiddenAnchorId => oldToNewIdMap[hiddenAnchorId]).concat(
            _.includes(oldHiddenAnchorIds, 'PAGE_TOP_ANCHOR') ? 'PAGE_TOP_ANCHOR' : null
        )
    )
}
const updateAnchorsMenuDataFromDuplicatedPage = (
    {extensionAPI}: CreateExtArgs,
    anchorsMenuPointer: Pointer,
    data: ComponentAfterAddDataEvent
) => {
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const {compToAddPointer, mappers} = data
    const oldToNewIdMap = mappers?.oldToNewIdMap
    const targetPageId = compToAddPointer.id
    const sourcePageId = _.findKey(oldToNewIdMap, val => val === targetPageId)
    const menuDataItem = deepClone(dataModel.components.getItem(anchorsMenuPointer, DATA_TYPES.data))
    let newHiddenAnchorIds
    if (sourcePageId) {
        newHiddenAnchorIds = getNewHiddenAnchorIds(menuDataItem.hiddenAnchorIds[sourcePageId], oldToNewIdMap)
    } else {
        newHiddenAnchorIds = []
    }
    menuDataItem.hiddenAnchorIds[targetPageId] = newHiddenAnchorIds
    dataModel.components.addItem(anchorsMenuPointer, DATA_TYPES.data, menuDataItem)
}

export const mapHiddenAnchorsForAnchorsMenu = (createExtArgs: CreateExtArgs, data: ComponentAfterAddDataEvent) => {
    const {compToAddPointer} = data
    const {pointers} = createExtArgs
    const pageId = compToAddPointer.id
    const anchorsMenus = pointers.structure.getComponentsByTypeForPage(COMP_TYPES.VERTICAL_ANCHORS_MENU, pageId)
    _.forEach(anchorsMenus, function (anchorsMenu) {
        updateAnchorsMenuDataFromDuplicatedPage(createExtArgs, anchorsMenu, data)
    })
}
