import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import constants from '../platform/common/constants'
import hooks from '../hooks/hooks'
import experiment from 'experiment-amd'
import type {PS} from '@wix/document-services-types'
import {ReportableError} from '@wix/document-manager-utils'

/**
 * @param {ps} ps
 * @param {string} controllerId
 * @returns {any | string}
 */
function getState(ps: PS, controllerId: string) {
    if (experiment.isOpen('dm_moveControllerStateToExt')) {
        return ps.extensionAPI.appController.getState(controllerId)
    }
    const appStatePointer = ps.pointers.platform.getAppStatePointer()

    const controllerState = ps.dal.get(ps.pointers.getInnerPointer(appStatePointer, controllerId))

    if (controllerState) {
        return controllerState
    }

    const templateControllerId = santaCoreUtils.displayedOnlyStructureUtil.getReferredCompId(controllerId)

    return templateControllerId
        ? ps.dal.get(ps.pointers.getInnerPointer(appStatePointer, templateControllerId)) ||
              constants.Controller.DEFAULT_STATE
        : constants.Controller.DEFAULT_STATE
}

function setState(ps: PS, stateMap) {
    if (experiment.isOpen('dm_moveControllerStateToExt')) {
        return ps.extensionAPI.appController.setState(stateMap)
    }
    const appStatePointer = ps.pointers.platform.getAppStatePointer()
    _.forEach(stateMap, function (controllerRefs, state) {
        _.forEach(controllerRefs, function (controllerRef) {
            if (controllerRef?.id) {
                ps.dal.full.set(ps.pointers.getInnerPointer(appStatePointer, controllerRef.id), state)
            } else {
                ps.logger.captureError(
                    new ReportableError({
                        errorType: 'SetStateError',
                        message: 'missing controllerRef id',
                        extras: {controllerRef, state}
                    })
                )
            }
        })
    })
    hooks.executeHook(hooks.HOOKS.PLATFORM.AFTER_CONTROLLERS_SET_STATE, 'controller', [stateMap])
}

export default {
    getState,
    setState
}
