import type {
    ComponentConversionData,
    ComponentData,
    MobileAlgoConfig,
    MobileAlgoPluginInitializationArgs,
    MobileConversionDal,
    PluginContext
} from '../../types'
import {createServerRequest} from './request'
import {CTX_SERVER, VIRTUAL_TAG} from './constants'
import type {Rect} from '@wix/document-services-types'
import type {GroupingResult} from './types'

export const groupingAIResult = async (
    mobileConversionDal: MobileConversionDal,
    pageId: string,
    pluginInitArgs: MobileAlgoPluginInitializationArgs,
    contextHepler: PluginContext,
    config: MobileAlgoConfig
): Promise<GroupingResult> => {
    const {readOnlyExtensionAPI, environmentContext} = pluginInitArgs
    const requestData = createServerRequest(mobileConversionDal, pageId, pluginInitArgs, config)
    const sendRequest = contextHepler.get(CTX_SERVER)
    const serverResponse = await sendRequest(readOnlyExtensionAPI, environmentContext, requestData)
    return serverResponse
}

const isContainer = (comp: ComponentConversionData) => {
    return comp?.components && comp?.components.length > 0
}

const calculateRealCuildren = (children: ComponentData[]): string[] =>
    children.flatMap(child => (child.componentType === VIRTUAL_TAG ? child.realChildren : [child.id]))

const calculateMeasurements = (children: ComponentData[]): Rect => {
    const groupMeasurements = {left: Infinity, top: Infinity, right: 0, bottom: 0}

    children.forEach(({measurements}) => {
        groupMeasurements.left = Math.min(groupMeasurements.left, measurements.x)
        groupMeasurements.top = Math.min(groupMeasurements.top, measurements.y)
        groupMeasurements.right = Math.max(groupMeasurements.right, measurements.x + measurements.width)
        groupMeasurements.bottom = Math.max(groupMeasurements.bottom, measurements.y + measurements.height)
    })

    return {
        x: groupMeasurements.left,
        y: groupMeasurements.top,
        width: groupMeasurements.right - groupMeasurements.left,
        height: groupMeasurements.bottom - groupMeasurements.top
    }
}

export const createVirtualGroupMap = (
    serverResponse: GroupingResult,
    mobileConversionDal: MobileConversionDal
): Record<string, Partial<ComponentData>> => {
    const componentToChildrenGroupMap: Record<string, Partial<ComponentData>> = {}

    const scanGroupingResultAndBuildGroupsTree = (gr: GroupingResult, parent: GroupingResult) => {
        if (gr.tag !== 'Group') {
            const compData = mobileConversionDal.getComponent(gr.id)
            componentToChildrenGroupMap[gr.id] = {
                id: gr.id,
                components: compData.components ?? [],
                componentType: compData.componentType,
                measurements: compData.originalMeasurements,
                parentId: parent.id,
                realChildren: compData.components ?? []
            }
            return
        }

        const childContainer = gr.children!.find(child => {
            const compData = mobileConversionDal.getComponent(child.id)
            return isContainer(compData)
        })?.id

        const getSoleSiblingContainerId = () => {
            const groupHasOnlyOneSibiling = parent?.children?.length === 2
            if (groupHasOnlyOneSibiling) {
                const sibling = parent?.children?.find(child => child.id !== gr.id)
                const compData = mobileConversionDal.getComponent(sibling!.id)
                const isSiblingContainer = isContainer(compData)
                if (isSiblingContainer) {
                    return isSiblingContainer
                }
            }
        }

        const soleSiblingContainer = getSoleSiblingContainerId()

        if (childContainer) {
            componentToChildrenGroupMap[gr.id] = {
                id: gr.id,
                components: gr.children?.map(child => child.id) ?? [],
                componentType: 'CONTAINER_GROUP',
                parentId: parent.id
            }
        }
        if (soleSiblingContainer && !childContainer) {
            componentToChildrenGroupMap[gr.id] = {
                id: gr.id,
                components: gr.children?.map(child => child.id) ?? [],
                componentType: 'CHILDREN_GROUP',
                parentId: parent.id
            }
        }

        if (!soleSiblingContainer && !childContainer) {
            componentToChildrenGroupMap[gr.id] = {
                id: gr.id,
                components: gr.children?.map(child => child.id) ?? [],
                componentType: VIRTUAL_TAG,
                parentId: parent.id
            }
        }

        for (const child of gr.children!) {
            scanGroupingResultAndBuildGroupsTree(child, gr)
        }

        if (childContainer) {
            // Remove the container-Group and replace it with the actual container (keep children)
            if (componentToChildrenGroupMap[parent.id]) {
                const index = componentToChildrenGroupMap[parent.id]!.components!.findIndex(child => child === gr.id)
                componentToChildrenGroupMap[parent.id].components!.splice(index, 1, childContainer)
            }

            componentToChildrenGroupMap[childContainer].parentId = parent.id
            componentToChildrenGroupMap[childContainer].components = componentToChildrenGroupMap[
                gr.id
            ].components!.filter(childId => childId !== childContainer)

            componentToChildrenGroupMap[childContainer].components!.forEach(comp => {
                componentToChildrenGroupMap[comp].parentId = childContainer
            })
            delete componentToChildrenGroupMap[gr.id]
            return
        }

        if (soleSiblingContainer) {
            // Remove the child-group and insert the children where the child-group used to be
            const index = componentToChildrenGroupMap[parent.id].components!.findIndex(child => child === gr.id)
            componentToChildrenGroupMap[parent.id].components!.splice(
                index,
                1,
                ...componentToChildrenGroupMap[gr.id].components!
            )

            componentToChildrenGroupMap[gr.id].components?.forEach(comp => {
                componentToChildrenGroupMap[comp].parentId = parent.id
            })

            delete componentToChildrenGroupMap[gr.id]
            return
        }

        if (componentToChildrenGroupMap[gr.id].componentType === VIRTUAL_TAG) {
            const children = componentToChildrenGroupMap[gr.id].components!.map(
                childId => componentToChildrenGroupMap[childId]
            ) as ComponentData[]
            componentToChildrenGroupMap[gr.id].measurements = calculateMeasurements(children)
            componentToChildrenGroupMap[gr.id].realChildren = calculateRealCuildren(children)
            return
        }
    }

    scanGroupingResultAndBuildGroupsTree(serverResponse.children![0], serverResponse)
    return componentToChildrenGroupMap
}
