import _ from 'lodash'
import type {
    Pointer,
    AbsoluteLayout,
    CompStructure,
    MeshItemLayout,
    ItemLayouts,
    FixedItemLayout,
    CompRef,
    Rect,
    DsItem
} from '@wix/document-services-types'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import type {VariantsExtensionAPI} from '../variants/variants'
import type {ComponentsMetadataAPI} from '../componentsMetadata/componentsMetadata'
import {
    StructureToConvertToMesh,
    MeshConversionResult,
    ConversionContext,
    convertToMesh,
    ReverseConversionContext,
    MeshStructureToConvertToAbsolute,
    getAbsoluteLayoutPropsFromFixedItemLayout,
    shouldBeConvertedToMesh,
    Transformation
} from '@wix/document-manager-utils'
import {CreateExtArgs, Extension, ExtensionAPI, pointerUtils} from '@wix/document-manager-core'
import {
    DATA_TYPES,
    VARIANTS,
    VIEW_MODES,
    RELATION_DATA_TYPES,
    REF_ARRAY_DATA_TYPE,
    BASE_PROPS_SCHEMA_TYPE
} from '../../constants/constants'
import {stripHashIfExists} from '../../utils/refArrayUtils'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {FeaturesExtensionAPI} from '../features/features'

const {getPointer} = pointerUtils
const {MOBILE_VARIANT_ID} = VARIANTS
const mobileVariantQuery = `#${MOBILE_VARIANT_ID}`
const mobileVariantPointer = getPointer(MOBILE_VARIANT_ID, DATA_TYPES.variants)
const {DESKTOP, MOBILE} = VIEW_MODES

export interface MeshLayoutApi {
    pageUsesMeshLayout(pageId: string): boolean

    siteQualifiesForMeshLayout(): boolean

    convertToMeshLayout(componentId: string, conversionSettings?: ConversionSettings): void

    convertToStructureLayout(
        compStructures: Record<string, CompStructure>,
        reverseConversionContext?: ReverseConversionContext
    ): void

    getMeasurements(compPointer: CompRef): Rect
}

export type MeshLayoutExtApi = ExtensionAPI & {
    meshLayout: MeshLayoutApi
}

interface ConversionSettings {
    shouldUpdateOnlyMobile?: boolean
    ignoreRoot?: boolean
}

export const createExtension = (): Extension => {
    const createExtensionAPI = ({dal, extensionAPI, pointers, coreConfig}: CreateExtArgs): MeshLayoutExtApi => {
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        const {features} = extensionAPI as FeaturesExtensionAPI
        const {variants} = extensionAPI as VariantsExtensionAPI
        const {schema} = dal

        const getTransformations = (componentPointer: Pointer) => {
            const transformationsRefArray = dataModel.components.getItem(componentPointer, DATA_TYPES.transformations)
            const {values} = transformationsRefArray
            const transformations: Transformation[] = []
            for (const transformationsDataItem of values) {
                if (transformationsDataItem.type === 'VariantRelation') {
                    transformations.push({item: transformationsDataItem.to, variants: transformationsDataItem.variants})
                } else {
                    transformations.push({item: transformationsDataItem})
                }
            }
            return transformations
        }

        const getStructure = (componentId: string): StructureToConvertToMesh => {
            const desktopPointer = getPointer(componentId, DESKTOP)
            const mobilePointer = getPointer(componentId, MOBILE)
            const desktop = dal.get(desktopPointer)
            const mobile = dal.get(mobilePointer)
            const desktopProps = dataModel.components.getItem(desktopPointer, DATA_TYPES.prop)
            const mobileProps = dataModel.components.getItem(mobilePointer, DATA_TYPES.prop)
            const structure = desktop ?? mobile
            const structureToConvertToMesh: StructureToConvertToMesh = {
                componentType: structure.componentType,
                pageId: structure.metaData.pageId,
                componentId,
                type: structure.type,
                isResponsive: !!structure.layoutQuery
            }
            if (desktop) {
                structureToConvertToMesh.desktop = {
                    layout: _.cloneDeep(desktop.layout),
                    props: desktopProps,
                    transformations: desktop.transformationQuery ? getTransformations(desktopPointer) : [],
                    parent: desktop.parent,
                    components: desktop.components,
                    ratio: 0
                }
            }
            if (mobile) {
                structureToConvertToMesh.mobile = {
                    layout: _.cloneDeep(mobile.layout),
                    props: mobileProps,
                    transformations: mobile.transformationQuery ? getTransformations(mobilePointer) : [],
                    parent: mobile.parent,
                    components: mobile.components,
                    ratio: 0
                }
            }
            return structureToConvertToMesh
        }

        const createVariantRelation = (componentId: string, to: DsItem, relationVariants: string[]) => {
            return {
                type: RELATION_DATA_TYPES.VARIANTS,
                from: `#${componentId}`,
                to,
                variants: relationVariants
            }
        }

        const createMobileVariantRelation = (componentId: string, to: DsItem) =>
            createVariantRelation(componentId, to, [mobileVariantQuery])

        const addVariantRelation = (
            namespace: string,
            componentId: string,
            refArrayPointer: Pointer,
            to: DsItem,
            relationVariants: string[]
        ) => {
            const variantRelation = createVariantRelation(componentId, to, relationVariants)
            const pageId = pointers.structure.getPageOfComponent(getPointer(componentId, DESKTOP)).id
            const variantRelationPointer = dataModel.addItem(variantRelation, DATA_TYPES[namespace], pageId)
            const updatedRefArrayValues = [...dal.get(refArrayPointer).values, `#${variantRelationPointer.id}`]
            dal.set({...refArrayPointer, innerPath: ['values']}, updatedRefArrayValues)
        }

        const addMobileVariantRelation = (
            namespace: string,
            componentId: string,
            refArrayPointer: Pointer,
            to: DsItem
        ) => addVariantRelation(namespace, componentId, refArrayPointer, to, [mobileVariantQuery])

        const addMeshLayout = (conversionResult: MeshConversionResult, componentId: string, viewMode: string) => {
            if (!conversionResult) {
                return
            }

            const values = conversionResult.default.layout ? [conversionResult.default.layout] : []
            const refArray: Record<string, any> = {
                type: REF_ARRAY_DATA_TYPE,
                values
            }

            if (conversionResult.mobile.layout) {
                refArray.values.push(createMobileVariantRelation(componentId, conversionResult.mobile.layout))
            }

            const layoutId = dataModel.components.addItem(
                getPointer(componentId, viewMode),
                DATA_TYPES.layout,
                refArray
            ).id

            if (viewMode !== VIEW_MODES.MOBILE) {
                dataModel.components.linkComponentToItemByTypeDesktopAndMobile(
                    getPointer(componentId, VIEW_MODES.MOBILE),
                    layoutId,
                    DATA_TYPES.layout
                )
            }
        }

        const updateMeshLayoutForMobile = (
            conversionResult: MeshConversionResult,
            componentId: string,
            layoutRefArrayPointer: Pointer
        ) => {
            if (!conversionResult.mobile.layout) {
                return
            }

            const layoutToUpdatePointer = variants.getDataPointerConsideringVariantsForRefPointer(
                getPointer(componentId, DESKTOP, {
                    variants: [{type: DATA_TYPES.variants, id: MOBILE_VARIANT_ID}]
                }),
                DATA_TYPES.layout,
                layoutRefArrayPointer
            )

            if (!layoutToUpdatePointer) {
                addMobileVariantRelation(
                    DATA_TYPES.layout,
                    componentId,
                    layoutRefArrayPointer,
                    conversionResult.mobile.layout
                )
            } else {
                const currentLayout = deepClone(dal.get(layoutToUpdatePointer!))
                const layoutToUpdate = {...currentLayout, ...conversionResult!.mobile.layout}
                dal.set(layoutToUpdatePointer!, layoutToUpdate)
            }
        }

        const addLayout = (
            convertedData: MeshConversionResult,
            componentId: string,
            compViewMode: keyof typeof VIEW_MODES
        ) => {
            const layoutId = dal.get(getPointer(componentId, compViewMode))?.layoutQuery
            if (!layoutId) {
                addMeshLayout(convertedData, componentId, compViewMode)
            } else {
                const layoutRefArrayPointer = getPointer(stripHashIfExists(layoutId), DATA_TYPES.layout)
                updateMeshLayoutForMobile(convertedData, componentId, layoutRefArrayPointer)
            }
        }

        const updateProps = (conversionResult: MeshConversionResult, componentId: string) => {
            if (conversionResult.mobile.props) {
                const {componentsMetadata} = extensionAPI as ComponentsMetadataAPI
                const mobilePointer = getPointer(componentId, MOBILE)
                const desktopPointer = getPointer(componentId, DESKTOP)
                const isSplit = componentsMetadata.isMobileComponentPropertiesSplit(mobilePointer)
                if (isSplit) {
                    dataModel.components.addItem(mobilePointer, DATA_TYPES.prop, conversionResult.mobile.props)
                } else {
                    const propId = dataModel.components.addItem(
                        desktopPointer,
                        DATA_TYPES.prop,
                        conversionResult.mobile.props
                    ).id

                    //TODO dataModel should update queries for the 2 view modes and then remove this line
                    dal.set({...mobilePointer, innerPath: ['propertyQuery']}, propId)
                }
            }
        }

        const prepare = (
            componentsIds: string[],
            structureToConvertToMesh: Record<string, StructureToConvertToMesh>
        ) => {
            for (const componentId of componentsIds) {
                const structure = getStructure(componentId)
                if (shouldBeConvertedToMesh(structure)) {
                    structureToConvertToMesh[componentId] = structure
                }
            }
        }

        const addTransformationsFromRotation = (
            convertedData: MeshConversionResult,
            componentId: string,
            viewMode: keyof typeof VIEW_MODES
        ) => {
            if (!convertedData) {
                return
            }

            const refArray = {
                type: REF_ARRAY_DATA_TYPE,
                values: convertedData.default.transformations?.length
                    ? [convertedData.default.transformations[0].item]
                    : []
            }

            if (convertedData.mobile.transformations?.length) {
                refArray.values.push(
                    createMobileVariantRelation(componentId, convertedData.mobile.transformations[0].item)
                )
            }

            const transformationsId = dataModel.components.addItem(
                getPointer(componentId, viewMode),
                DATA_TYPES.transformations,
                refArray
            ).id

            if (viewMode !== VIEW_MODES.MOBILE) {
                dataModel.components.linkComponentToItemByTypeDesktopAndMobile(
                    getPointer(componentId, VIEW_MODES.MOBILE),
                    transformationsId,
                    DATA_TYPES.transformations
                )
            }
        }

        const updateDesktopTransformations = (
            componentId: string,
            viewMode: keyof typeof VIEW_MODES,
            transformations: Transformation[]
        ) => {
            for (const transformation of transformations) {
                const transformationsPointer = variants.getDataPointerConsideringVariants(
                    getPointer(componentId, viewMode, {
                        variants: transformation.variants?.map((variant: string) => ({
                            type: DATA_TYPES.variants,
                            id: variant.replace('#', '')
                        }))
                    }),
                    DATA_TYPES.transformations
                )
                dal.set(transformationsPointer!, transformation.item)
            }
        }

        const writeMobileTransformations = (
            componentId: string,
            mobileTransformations: Transformation[],
            refArrayPointer: Pointer
        ) => {
            for (const transformation of mobileTransformations) {
                const transformationVariantsWithMobile = _.union(transformation.variants, [mobileVariantQuery])
                addVariantRelation(
                    DATA_TYPES.transformations,
                    componentId,
                    refArrayPointer,
                    transformation.item,
                    transformationVariantsWithMobile
                )
            }
        }

        const updateTransformations = (
            convertedData: MeshConversionResult,
            componentId: string,
            refArrayPointer: Pointer
        ) => {
            if (!convertedData) {
                return
            }

            const {transformations} = convertedData.default
            if (transformations?.length) {
                updateDesktopTransformations(componentId, DESKTOP, transformations)
            }

            const {transformations: mobileTransformations} = convertedData.mobile
            if (mobileTransformations?.length) {
                writeMobileTransformations(componentId, mobileTransformations, refArrayPointer)
            }
        }

        const addTransformations = (
            convertedData: MeshConversionResult,
            componentId: string,
            compViewMode: keyof typeof VIEW_MODES
        ) => {
            const transformationsQuery =
                dal.get(getPointer(componentId, VIEW_MODES.DESKTOP))?.transformationQuery ??
                dal.get(getPointer(componentId, VIEW_MODES.MOBILE))?.transformationQuery
            if (!transformationsQuery) {
                addTransformationsFromRotation(convertedData, componentId, compViewMode)
            } else {
                const refArrayPointer = getPointer(stripHashIfExists(transformationsQuery), DATA_TYPES.transformations)
                updateTransformations(convertedData, componentId, refArrayPointer)
            }
        }

        const write = (convertedToMeshStructure: Record<string, MeshConversionResult>) => {
            for (const [componentId, convertedData] of Object.entries(convertedToMeshStructure)) {
                const isMobileOnly = !dal.has(getPointer(componentId, DESKTOP))
                const compViewMode = isMobileOnly ? MOBILE : DESKTOP

                updateProps(convertedData, componentId)
                addLayout(convertedData, componentId, compViewMode)
                if (convertedData.default.transformations?.length || convertedData.mobile.transformations?.length) {
                    addTransformations(convertedData, componentId, compViewMode)
                }
            }
        }

        const createPropertiesItemByType = (type: string) => {
            const propsType = schema.getComponentDefinition(type).propertyTypes?.[0] ?? BASE_PROPS_SCHEMA_TYPE
            return schema.createItemAccordingToSchema(propsType, 'props')
        }

        const getChildren = (rootId: string, viewMode: string): string[] => {
            const compPointer = getPointer(rootId, viewMode)
            return pointers.structure
                .getChildrenRecursivelyRightLeftRootIncludingRoot(compPointer)
                .map(pointer => pointer.id)
        }

        const convertToMeshLayout = (rootId: string, {shouldUpdateOnlyMobile, ignoreRoot}: ConversionSettings = {}) => {
            const desktopComponentsIds = getChildren(rootId, DESKTOP)
            const mobileComponentsIds = getChildren(rootId, MOBILE)
            const uniqueComponentsIds = _.union(desktopComponentsIds, mobileComponentsIds)
            const componentsIds = shouldUpdateOnlyMobile ? mobileComponentsIds : uniqueComponentsIds
            const structureToConvertToMesh: Record<string, StructureToConvertToMesh> = {}
            const convertedToMeshStructure: Record<string, MeshConversionResult> = {}
            const masterPagePointer = pointers.structure.getMasterPage(DESKTOP)
            const masterPageDataPointer = pointers.data.getDataItemFromMaster(masterPagePointer.id)
            const siteWidth = dal.get(pointers.getInnerPointer(masterPageDataPointer, 'renderModifiers.siteWidth'))
            const conversionContext: ConversionContext = {createPropertiesItemByType, siteWidth}

            prepare(componentsIds, structureToConvertToMesh)
            convertToMesh(structureToConvertToMesh, convertedToMeshStructure, conversionContext)

            if (ignoreRoot) {
                delete convertedToMeshStructure[rootId]
            }

            write(convertedToMeshStructure)
        }

        const convertMeshLayoutToAbsoluteLayout = (
            meshLayoutStructureToConvertToAbsolute: Record<string, MeshStructureToConvertToAbsolute>,
            convertedToAbsoluteLayout: Record<string, AbsoluteLayout>
        ) => {
            for (const [id, structure] of Object.entries(meshLayoutStructureToConvertToAbsolute)) {
                if (structure.layout) {
                    if ((structure.layout.itemLayout as ItemLayouts).type === 'MeshItemLayout') {
                        convertedToAbsoluteLayout[id] = {
                            x: (structure.layout.itemLayout as MeshItemLayout).meshData.x,
                            y: (structure.layout.itemLayout as MeshItemLayout).meshData.y,
                            width: (structure.layout.itemLayout as MeshItemLayout).meshData.width,
                            height: (structure.layout.itemLayout as MeshItemLayout).meshData.height,
                            rotationInDegrees:
                                (structure.layout.itemLayout as MeshItemLayout).meshData.rotationInDegrees ?? 0,
                            fixedPosition: false,
                            scale: 1
                        }
                    }
                    if ((structure.layout.itemLayout as ItemLayouts).type === 'FixedItemLayout') {
                        convertedToAbsoluteLayout[id] = {
                            ...getAbsoluteLayoutPropsFromFixedItemLayout(
                                structure.layout.itemLayout as FixedItemLayout,
                                structure.componentType
                            ),
                            x: 0,
                            y: 0,
                            width: 0,
                            height: 0,
                            scale: 1,
                            fixedPosition: true
                        } as AbsoluteLayout
                    }
                }
            }
        }

        const convertToStructureLayout = (
            flatComponents: Record<string, CompStructure>,
            reverseConversionContext: ReverseConversionContext = {}
        ): void => {
            const meshLayoutStructureToConvertToAbsolute = {}
            const absoluteLayouts: Record<string, AbsoluteLayout> = {}

            for (const [componentId, component] of Object.entries(flatComponents)) {
                const {components, propertyQuery, componentType} = component
                const props = propertyQuery
                    ? dal.get(pointers.data.getPropertyItem(stripHashIfExists(propertyQuery)))
                    : undefined

                const layoutPointer =
                    variants.getComponentDataPointerConsideringVariants(
                        pointers.getPointer(
                            componentId,
                            DESKTOP,
                            reverseConversionContext.isMobile ? {variants: [mobileVariantPointer]} : {}
                        ),
                        DATA_TYPES.layout
                    ) ??
                    variants.getComponentDataPointerConsideringVariants(
                        pointers.getPointer(
                            componentId,
                            MOBILE,
                            reverseConversionContext.isMobile ? {variants: [mobileVariantPointer]} : {}
                        ),
                        DATA_TYPES.layout
                    )
                const layout = layoutPointer ? dal.get(layoutPointer) : undefined

                meshLayoutStructureToConvertToAbsolute[componentId] = {
                    componentType,
                    layout,
                    components,
                    props
                }
            }

            convertMeshLayoutToAbsoluteLayout(meshLayoutStructureToConvertToAbsolute, absoluteLayouts)

            for (const [componentId, layout] of Object.entries(absoluteLayouts)) {
                flatComponents[componentId].layout = layout
            }
        }

        const siteQualifiesForMeshLayout = (): boolean =>
            coreConfig.experimentInstance.isOpen('dm_meshLayout') &&
            !!features.component.get(pointers.structure.getMasterPage(DESKTOP), 'pageSections')?.isSectionsEnabled

        const pageUsesMeshLayout = (pageId: string): boolean =>
            !!dataModel.components.getItem(pointers.structure.getPage(pageId, DESKTOP), DATA_TYPES.layout)

        const getMeasurements = (compPointer: CompRef): Rect => {
            const {x, y, width, height} = dal.get(pointers.getInnerPointer(compPointer, 'layout'))
            return {x, y, width, height}
        }

        return {
            meshLayout: {
                siteQualifiesForMeshLayout,
                pageUsesMeshLayout,
                convertToMeshLayout,
                // temporary solution until measurements will be introduced
                convertToStructureLayout,
                getMeasurements
            }
        }
    }

    return {
        name: 'meshLayout',
        dependencies: new Set(['dataModel', 'structure', 'features', 'variants', 'componentsMetaData', 'viewer']),
        createExtensionAPI
    }
}
