import type {AppData, AppDefinitionId, PS, AddAppsOptions} from '@wix/document-services-types'
import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import experiment from 'experiment-amd'
import workerService from './services/workerService'
import appComponents from './appComponents'
import clientSpecMapService from '../tpa/services/clientSpecMapService'
import appStoreService from '../tpa/services/appStoreService'
import tpaAddService from '../tpa/services/tpaAddService'
import constants from './common/constants'
import wixCode from '../wixCode/wixCode'
import dsConstants from '../constants/constants'
import provision from './provision'
import platform from './platform'
import generalInfo from '../siteMetadata/generalInfo'
import documentModeInfo from '../documentMode/documentModeInfo'
import {loadRemoteDataForApp} from './services/loadRemoteWidgetMetaData'
import clientSpecMap from '../siteMetadata/clientSpecMap'
import * as platformEvents from '@wix/platform-editor-sdk/lib/platformEvents.min'
import notificationService from './services/notificationService'
import {ReportableError} from '@wix/document-manager-utils'
import type {BuilderComponentsExtensionAPI} from '@wix/document-manager-extensions/src/extensions/builderComponents/builderComponents'

const errNames = Object.freeze({
    PROVISION_ERR: 'PROVISION_ERR',
    TEMPLATE_ERR: 'TEMPLATE_ERR',
    EMPTY_APP_DEFS_ERR: 'EMPTY_APP_DEFS_ERR',
    WORKER_NOT_INIT_ERR: 'WORKER_NOT_INIT_ERR',
    ADD_ON_MOBILE: 'ADD_ON_MOBILE',
    ADD_APP_ERR: 'ADD_APP_ERR',
    RUN_EDITOR_SCRIPT_ERR: 'RUN_EDITOR_SCRIPT_ERR'
})
const {PLATFORM_INTERACTIONS} = dsConstants

const {getAppComponents, hasCodePackage} = appComponents

const isPlatformType = app => {
    const components = getAppComponents(app)
    return (
        _.get(app, 'appFields.platform') ||
        components.some(comp => comp.type === 'PLATFORM' || comp.compType === 'PLATFORM')
    )
}

const isWidgetType = app => {
    const appWidgets = _.filter(app.widgets, widget => _.isNil(widget.appPage))
    // @ts-expect-error
    return _.find(appWidgets, {default: true}, false)
}

const isSectionType = app => {
    const appPages = _(app.widgets)
        .filter(widget => !_.isNil(widget.appPage))
        .map('appPage')
        .value()
    // @ts-expect-error
    return _.find(appPages, {hidden: false}, false)
}

const isHeadlessInstall = (app, options) => {
    if (options.headlessInstallation) {
        return true
    }
    const components = getAppComponents(app)
    return (
        _.get(app, 'appFields.platform.platformOnly') ||
        _.get(
            components.find(comp => comp.type === 'PLATFORM' || comp.compType === 'PLATFORM'),
            'data.platformOnly'
        )
    )
}

const getAppType = (app, options) => {
    if (isHeadlessInstall(app, options)) {
        return constants.APP.TYPE.PLATFORM_ONLY
    } else if (isSectionType(app)) {
        return constants.APP.TYPE.SECTION
    } else if (isBuilderType(app)) {
        return constants.APP.TYPE.BLOCKS
    } else if (isWidgetType(app)) {
        return constants.APP.TYPE.WIDGET
    } else if (isPlatformType(app)) {
        return constants.APP.TYPE.PLATFORM_ONLY
    }

    return constants.APP.TYPE.WIDGET
}

const isBuilderType = app => {
    const components = getAppComponents(app)
    return components.some(comp => comp.type === 'STUDIO' || comp.compType === 'STUDIO_WIDGET')
}

async function installCodePackageIfNeeded(ps: PS, appData, internalInstallInfo: {sourceApi?: string}, options) {
    if (!hasCodePackage(appData)) {
        return
    }

    ps.extensionAPI.logger.interactionStarted(PLATFORM_INTERACTIONS.INSTALL_CODE_REUSE, {
        tags: {
            appDefinitionId: appData.appDefinitionId,
            sourceApi: internalInstallInfo.sourceApi
        },
        extras: {
            app_id: appData.appDefinitionId,
            firstInstall: appData.firstInstall,
            installedVersion: appData?.appFields?.installedVersion,
            appVersion: options[appData.appDefinitionId]?.appVersion
        }
    })

    await wixCode.codePackages.installCodeReusePkg(
        ps,
        appData.appDefinitionId,
        appData.appFields.installedVersion ?? options[appData.appDefinitionId]?.appVersion
    )

    ps.extensionAPI.logger.interactionEnded(PLATFORM_INTERACTIONS.INSTALL_CODE_REUSE, {
        tags: {
            appDefinitionId: appData.appDefinitionId,
            sourceApi: internalInstallInfo.sourceApi
        },
        extras: {
            app_id: appData.appDefinitionId,
            firstInstall: appData?.firstInstall,
            installedVersion: appData?.appFields?.installedVersion,
            appVersion: options[appData.appDefinitionId]?.appVersion
        }
    })
}

async function addAppByType(
    ps: PS,
    appType: string,
    appData,
    options: any = {},
    internalInstallInfo: {flowId?: string; origin_info?: any; sourceApi?: string} = {}
) {
    switch (appType) {
        case constants.APP.TYPE.BLOCKS:
            return new Promise((resolve, reject) => {
                provision.extendOptionWithInternalOrigin(options, constants.ADD_APP_ORIGINS.ADD_APPS_BLOCKS)
                provision.onPreSaveProvisionSuccess(ps, resolve, reject, options, appData, internalInstallInfo)
            })
        case constants.APP.TYPE.PLATFORM_ONLY:
            return new Promise((resolve, reject) => {
                provision.extendOptionWithInternalOrigin(options, constants.ADD_APP_ORIGINS.ADD_APPS_PLATFORM_ONLY)
                provision.onPreSaveProvisionSuccess(ps, resolve, reject, options, appData, internalInstallInfo)
            })
        case constants.APP.TYPE.WIDGET:
            return new Promise((resolve, reject) => {
                tpaAddService.addWidgetAfterProvision(ps, appData, appData.appDefinitionId, options, resolve, reject, {
                    sourceApi: internalInstallInfo.sourceApi
                })
            })
        case constants.APP.TYPE.SECTION:
            return new Promise((resolve, reject) => {
                tpaAddService.addSectionAfterProvision(ps, appData, appData.appDefinitionId, options, resolve, reject, {
                    sourceApi: internalInstallInfo.sourceApi
                })
            })
    }
}

async function addApp(
    ps: PS,
    appData,
    options: any = {},
    internalInstallInfo: {flowId?: string; origin_info?: any; sourceApi?: string} = {}
) {
    ps.extensionAPI.logger.interactionStarted(PLATFORM_INTERACTIONS.ADD_APP_GET_APP_TYPE, {
        tags: {
            appDefinitionId: appData.appDefinitionId,
            sourceApi: internalInstallInfo.sourceApi
        },
        extras: {app_id: appData.appDefinitionId, firstInstall: appData?.firstInstall, options}
    })
    const appType = getAppType(appData, options)
    ps.extensionAPI.logger.interactionEnded(PLATFORM_INTERACTIONS.ADD_APP_GET_APP_TYPE, {
        tags: {
            appDefinitionId: appData.appDefinitionId,
            sourceApi: internalInstallInfo.sourceApi
        },
        extras: {app_id: appData.appDefinitionId, appType, firstInstall: appData?.firstInstall, options}
    })
    const {builderComponents} = ps.extensionAPI as BuilderComponentsExtensionAPI
    await builderComponents.registerBuilderComponentsForApp(appData)
    return await addAppByType(ps, appType, appData, options, internalInstallInfo)
}

const splitAppsByProvisionType = appDefinitionIds => {
    const wixCodeApps = (appDefinitionId: AppDefinitionId) => appDefinitionId === constants.APPS.WIX_CODE.appDefId
    const monoProvisionApps = _.filter(appDefinitionIds, wixCodeApps)
    const bulkProvisionApps = _.difference(appDefinitionIds, monoProvisionApps)
    return {monoProvisionApps, bulkProvisionApps}
}

const bulkProvision = (ps: PS, appDefinitionIds, options): Promise<void> =>
    new Promise<void>((resolve, reject) =>
        _.isEmpty(appDefinitionIds)
            ? resolve()
            : appStoreService.provision(
                  ps,
                  _.map(appDefinitionIds, appDefinitionId => ({
                      appDefinitionId,
                      version: _.get(options, [appDefinitionId, 'appVersion']),
                      sourceTemplateId: _.get(options, [appDefinitionId, 'sourceTemplateId'])
                  })),
                  resolve,
                  reject
              )
    )

const provisionWixCode = async (ps: PS, monoProvisionApps: AppDefinitionId[]) => {
    const monoProvisionResults = []

    for (const appDefinitionId of monoProvisionApps) {
        if (appDefinitionId !== constants.APPS.WIX_CODE.appDefId) {
            throw new Error('should not get here, old provision with sourceTemplateId flow')
        }
        if (wixCode.isProvisioned(ps)) {
            const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
            monoProvisionResults.push(appData)
        } else {
            const appData = await new Promise((resolve, reject) =>
                wixCode.provision(ps, {
                    onSuccess: provisionResult => {
                        ps.extensionAPI.appsInstallState.setAppInstalled(appDefinitionId, {}, 'wixCode.provision')
                        resolve(provisionResult)
                    },
                    onError: reject
                })
            )
            monoProvisionResults.push(appData)
        }
    }
    return monoProvisionResults
}

const provisionAppsOnMetaSite = async (ps: PS, appDefinitionIds, options) => {
    const appDefinitionIdsToProvision = _.filter(appDefinitionIds, appDefinitionId =>
        platform.shouldProvisionApp(ps, appDefinitionId)
    )
    const appsData = _(appDefinitionIds)
        .difference(appDefinitionIdsToProvision)
        .map(appDefinitionId => clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId))
        .value()
    const {monoProvisionApps, bulkProvisionApps} = splitAppsByProvisionType(appDefinitionIdsToProvision)
    const monoProvisionResults = await provisionWixCode(ps, monoProvisionApps)
    const bulkProvisionResult = await bulkProvision(ps, bulkProvisionApps, options)
    // @ts-expect-error
    const csm = bulkProvisionResult?.clientSpecMap
    _.forEach(bulkProvisionApps, appDefinitionId => appsData.push(_.find(csm, {appDefinitionId})))
    return [...appsData, ...monoProvisionResults]
}

function onAddAppsError(
    ps: PS,
    err,
    {onError}: any,
    errName: string,
    source: string,
    appDefId?: AppDefinitionId[],
    additionalTags: Record<string, any> = {}
) {
    ps.extensionAPI.logger.captureError(
        new ReportableError({
            errorType: errName,
            message: err.message,
            tags: {
                [source]: true,
                appDefinitionId: appDefId,
                ...additionalTags
            },
            extras: {
                requestId: err?.requestId,
                errName,
                originalError: err
            }
        })
    )
    runCallBackIfExist(onError, err, errName, appDefId)
    throw err
}

function runCallBackIfExist(fn: Function, ...args: any[]) {
    if (fn && typeof fn === 'function') {
        fn(...args)
    }
}

const getOriginInfo = (appDefinitionId: AppDefinitionId, installOptions) => {
    if (!appDefinitionId) {
        return null
    }
    if (installOptions[appDefinitionId]?.platformOrigin) {
        const info = installOptions[appDefinitionId]?.platformOrigin?.info
        return info?.type || info?.appDefinitionId
    }
    if (installOptions[appDefinitionId]?.origin) {
        const info = installOptions[appDefinitionId]?.origin?.info
        return info?.type || info?.appDefinitionId
    }
}

const checkValidStateBeforeInstallation = (
    ps: PS,
    appDefinitionIds: string[],
    source: string,
    options: AddAppsOptions = {}
) => {
    const additionalTag = {sourceApi: 'addApps'}
    if (generalInfo.isTemplate(ps)) {
        const err = new Error('cannot add apps on template')
        onAddAppsError(ps, err, options, errNames.TEMPLATE_ERR, source, appDefinitionIds, additionalTag)
    }
    if (documentModeInfo.getViewMode(ps) === dsConstants.VIEW_MODES.MOBILE) {
        const err = new Error('cannot add apps on MOBILE view mode')
        onAddAppsError(ps, err, options, errNames.ADD_ON_MOBILE, source, appDefinitionIds, additionalTag)
    }
    if (_.isEmpty(appDefinitionIds)) {
        const err = new Error('Add Apps should receive at least one app')
        onAddAppsError(ps, err, options, errNames.EMPTY_APP_DEFS_ERR, source, undefined, additionalTag)
    }
}

const validateAppProvisioned = (ps: PS, appData: AppData, source: string, options: AddAppsOptions) => {
    if (!appData || clientSpecMapService.isAppPermissionsIsRevoked(appData)) {
        const err = new Error('cannot add app before provision')
        onAddAppsError(ps, err, options, errNames.RUN_EDITOR_SCRIPT_ERR, source, [appData?.appDefinitionId])
    }
}

const validateWorker = (
    ps: PS,
    appData: AppData,
    source: string,
    options: AddAppsOptions,
    additionalTags?: {sourceApi?: string}
) => {
    const isWixCodeApp = appData.appDefinitionId === constants.APPS.WIX_CODE.appDefId
    const isPlatformOrBlocksApp = [constants.APP.TYPE.PLATFORM_ONLY, constants.APP.TYPE.BLOCKS].includes(
        getAppType(appData, options)
    )

    if (isWixCodeApp || isPlatformOrBlocksApp) {
        if (!workerService.isInitiated()) {
            const err = new Error(
                `${source} failed to install platform app: ${appData.appDefinitionId} because worker is not initiated`
            )
            onAddAppsError(ps, err, options, errNames.WORKER_NOT_INIT_ERR, source, undefined, additionalTags)
        }
    }
}
const triggerSingleAppCallback = (ps: PS, appDefinitionIds: string[], options) => {
    _.forEach(appDefinitionIds, appDefinitionId => {
        const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
        runCallBackIfExist(options.singleAppCallback, appDefinitionId, _.assign(appData, {success: true}))
    })
}
/**
 *
 * multiple application installer function that can receive widget, section, platform, app-builder apps
 * the main phases of the func:
 * 1. distinguish between apps that retrieved on separate requests and apps that retrieved with single request (CSM)
 * 2. receive all apps data + CSM in parallel
 * 3. distinguish the app type and add the app accordingly one-by-one
 *
 * @param ps
 * @param appDefinitionIds
 * @param {Object} options - object with app definition id's as keys and each key holds option object that related to the app all keys should be included on the appDefinitionIds param
 * options.singleAppCallback - will be called after app is added with [appDefId, addApp result]
 * options.onError - will be called with the error in case an error is thrown
 * options.finishAllCallback - will be called with appsData after installation is finished
 * options.skipActiveApps - true by default, determines whether to skip installation of an active app or not
 * options['appDefinitionId'].headlessInstallation - if true, install only the platform part of the application.
 */
const addApps = async (ps: PS, appDefinitionIds: string[], options: AddAppsOptions = {}): Promise<any[]> => {
    const additionalTag = {sourceApi: 'addApps'}
    // @ts-expect-error
    const flowId = santaCoreUtils.guidUtils.getUniqueId()
    const installationOriginInfo = getOriginInfo(_.last(appDefinitionIds), options)
    const addAppsAllProcessEventGuid = _.random(10000, true)

    ps.extensionAPI.logger.interactionStarted(PLATFORM_INTERACTIONS.ADD_APPS_ALL_PROCESS, {
        eventGuid: addAppsAllProcessEventGuid,
        extras: {app_ids: appDefinitionIds, installation_id: flowId, options},
        tags: {origin_info: installationOriginInfo, ...additionalTag}
    })

    const skipActiveApps = options.skipActiveApps || _.isNil(options.skipActiveApps) // TODO: why Nil is true-ish?
    const activeAppDefinitionIds = appDefinitionIds.filter(appDefId => platform.isAppActive(ps, appDefId))
    const appDefinitionIdsToSkip = skipActiveApps ? activeAppDefinitionIds : []
    const appDefinitionIdsToProvision = skipActiveApps
        ? _.without(appDefinitionIds, ...activeAppDefinitionIds)
        : appDefinitionIds
    if (_.isEmpty(appDefinitionIdsToProvision)) {
        triggerSingleAppCallback(ps, appDefinitionIds, options)
        runCallBackIfExist(
            options.finishAllCallback,
            clientSpecMapService.getAppDataListByAppDefinitionIds(
                ps,
                experiment.isOpen('dm_returnAllAppDataInAddApps') ? appDefinitionIdsToSkip : []
            )
        )
        ps.extensionAPI.logger.interactionEnded(PLATFORM_INTERACTIONS.ADD_APPS_ALL_PROCESS, {
            eventGuid: addAppsAllProcessEventGuid,
            extras: {app_ids: appDefinitionIds, installation_id: flowId, options},
            tags: {origin_info: installationOriginInfo, ...additionalTag}
        })
        return []
    }
    if (appDefinitionIdsToSkip) {
        triggerSingleAppCallback(ps, appDefinitionIdsToSkip, options)
    }

    const appsData = await provisionApps(ps, appDefinitionIdsToProvision, options, {
        origin_info: installationOriginInfo,
        flowId,
        ...additionalTag
    })

    await Promise.all(
        appsData.map(async appData => {
            await installCodePackageIfNeeded(ps, appData, additionalTag, options)
            validateWorker(ps, appData, 'addApps', options, additionalTag)
            await addAppToDocument(ps, appData, options, {
                origin_info: installationOriginInfo,
                flowId,
                ...additionalTag
            })
        })
    )

    ps.extensionAPI.logger.interactionEnded(PLATFORM_INTERACTIONS.ADD_APPS_ALL_PROCESS, {
        eventGuid: addAppsAllProcessEventGuid,
        extras: {app_ids: appDefinitionIds, installation_id: flowId, options},
        tags: {origin_info: installationOriginInfo, ...additionalTag}
    })

    _.attempt(ps.dal.commitTransaction)
    if (experiment.isOpen('dm_returnAllAppDataInAddApps')) {
        const skippedAppsAppData = clientSpecMapService.getAppDataListByAppDefinitionIds(ps, appDefinitionIdsToSkip)

        runCallBackIfExist(options.finishAllCallback, [...appsData, ...skippedAppsAppData])
    } else {
        runCallBackIfExist(options.finishAllCallback, appsData)
    }
}

const provisionApps = async (
    ps: PS,
    appDefinitionIds: string[],
    options: AddAppsOptions = {},
    internalInstallInfo: {flowId?: string; origin_info?: any; sourceApi?: string} = {}
): Promise<any[]> => {
    let appsData = []
    try {
        const provisionAppsEventGuid = _.random(10000, true)
        ps.extensionAPI.logger.interactionStarted(PLATFORM_INTERACTIONS.ADD_APPS_PROVISION, {
            eventGuid: provisionAppsEventGuid,
            extras: {app_ids: appDefinitionIds, installation_id: internalInstallInfo.flowId},
            tags: {origin_info: internalInstallInfo.origin_info, sourceApi: internalInstallInfo.sourceApi}
        })
        appsData = await provisionAppsOnMetaSite(ps, appDefinitionIds, options)
        ps.extensionAPI.logger.interactionEnded(PLATFORM_INTERACTIONS.ADD_APPS_PROVISION, {
            eventGuid: provisionAppsEventGuid,
            extras: {app_ids: appDefinitionIds, installation_id: internalInstallInfo.flowId},
            tags: {origin_info: internalInstallInfo.origin_info, sourceApi: internalInstallInfo.sourceApi}
        })
    } catch (e) {
        onAddAppsError(ps, e, options, errNames.PROVISION_ERR, 'provisionApps', appDefinitionIds, {
            sourceApi: internalInstallInfo.sourceApi
        })
    }

    for (const appData of appsData) {
        const existingAppData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appData.appDefinitionId)
        if (!clientSpecMapService.isAppActive(ps, existingAppData)) {
            clientSpecMap.registerAppData(ps, appData)
        }
        await loadRemoteDataForApp(ps, appData, 'addApps')
    }

    return appsData
}

const addAppToDocument = async (
    ps: PS,
    appData,
    options: AddAppsOptions,
    internalInstallInfo: {flowId?: string; origin_info?: any; sourceApi?: string} = {}
) => {
    const {appDefinitionId} = appData
    if (appDefinitionId !== constants.APPS.WIX_CODE.appDefId) {
        const appOptions = _.get(options, appDefinitionId)
        appData.firstInstall = true

        try {
            const addAppToDocumentEventGuid = _.random(10000, true)
            ps.extensionAPI.logger.interactionStarted(PLATFORM_INTERACTIONS.ADD_APP_AFTER_PROVISION, {
                eventGuid: addAppToDocumentEventGuid,
                extras: {
                    app_id: appDefinitionId,
                    installation_id: internalInstallInfo?.flowId,
                    firstInstall: appData?.firstInstall
                },
                tags: {origin_info: internalInstallInfo?.origin_info, sourceApi: internalInstallInfo?.sourceApi}
            })
            const addResponse = await addApp(ps, appData, appOptions, internalInstallInfo)
            ps.extensionAPI.logger.interactionEnded(PLATFORM_INTERACTIONS.ADD_APP_AFTER_PROVISION, {
                eventGuid: addAppToDocumentEventGuid,
                extras: {
                    app_id: appDefinitionId,
                    installation_id: internalInstallInfo?.flowId,
                    firstInstall: appData?.firstInstall
                },
                tags: {origin_info: internalInstallInfo?.origin_info, sourceApi: internalInstallInfo?.sourceApi}
            })

            ps.extensionAPI.appsInstallState.setAppInstalled(
                appDefinitionId,
                {
                    version: options[appDefinitionId]?.appVersion
                },
                'addAppToDocument'
            )
            if (experiment.isOpen('dm_platformAddSubscribeOnEventFunctionality')) {
                const appInstalledEvent = platformEvents.factory.appInstalled({
                    appDefinitionId
                })
                notificationService.notifyApplication(ps, appDefinitionId, appInstalledEvent)
            }

            runCallBackIfExist(options.singleAppCallback, appDefinitionId, addResponse)
        } catch (e) {
            onAddAppsError(ps, e, options, errNames.ADD_APP_ERR, 'addAppToDocument', appDefinitionId, {
                sourceApi: internalInstallInfo?.sourceApi
            })
        }
    }
}
const addToDocumentWithEditorScript = async (ps: PS, appData, options: AddAppsOptions) => {
    const {appDefinitionId} = appData
    const appOptions = _.get(options, appDefinitionId)

    ps.extensionAPI.logger.interactionStarted(PLATFORM_INTERACTIONS.RUN_EDITOR_SCRIPT_AND_ADD_TO_DOC, {
        extras: {
            app_id: appData.appDefinitionId,
            firstInstall: appData?.firstInstall
        }
    })
    try {
        const addResponse = await new Promise((resolve, reject) => {
            provision.onPreSaveProvisionSuccess(ps, resolve, reject, appOptions, appData)
        })

        ps.extensionAPI.appsInstallState.setAppInstalled(
            appDefinitionId,
            {
                version: options[appDefinitionId]?.appVersion
            },
            'addToDocumentWithEditorScript'
        )

        if (experiment.isOpen('dm_platformAddSubscribeOnEventFunctionality')) {
            const appInstalledEvent = platformEvents.factory.appInstalled({
                appDefinitionId
            })
            notificationService.notifyApplication(ps, appDefinitionId, appInstalledEvent)
        }

        runCallBackIfExist(options.singleAppCallback, appDefinitionId, addResponse)
        ps.extensionAPI.logger.interactionEnded(PLATFORM_INTERACTIONS.RUN_EDITOR_SCRIPT_AND_ADD_TO_DOC, {
            extras: {
                app_id: appData.appDefinitionId
            }
        })
    } catch (e) {
        onAddAppsError(ps, e, options, errNames.ADD_APP_ERR, 'addToDocumentWithEditorScript', appDefinitionId)
    }
}

const getAddAppsParams = (ps: PS, appDefinitionIds: string[]) => ({apps_ids: appDefinitionIds.join(',')})

export default {
    getAddAppsParams,
    addApps: async (ps: PS, appDefinitionIds: AppDefinitionId[], options: AddAppsOptions = {}) => {
        checkValidStateBeforeInstallation(ps, appDefinitionIds, 'addApps', options)
        return addApps(ps, appDefinitionIds, options)
    },
    provisionApps: async (ps: PS, appDefinitionIds: AppDefinitionId[], options: AddAppsOptions = {}) => {
        checkValidStateBeforeInstallation(ps, appDefinitionIds, 'provisionApps', options)
        return provisionApps(ps, appDefinitionIds, options)
    },
    addAppToDocument: async (ps: PS, appDefinitionId: AppDefinitionId, options: AddAppsOptions = {}) => {
        checkValidStateBeforeInstallation(ps, [appDefinitionId], 'addAppToDocument', options)
        const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
        validateAppProvisioned(ps, appData, 'addAppToDocument', options)
        validateWorker(ps, appData, 'addAppToDocument', options)
        return addAppToDocument(ps, appData, options)
    },
    addToDocumentWithEditorScript: async (ps: PS, appDefinitionId: AppDefinitionId, options: AddAppsOptions = {}) => {
        checkValidStateBeforeInstallation(ps, [appDefinitionId], 'addToDocumentWithEditorScript', options)
        const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
        validateAppProvisioned(ps, appData, 'addToDocumentWithEditorScript', options)
        validateWorker(ps, appData, 'addToDocumentWithEditorScript', options)
        return addToDocumentWithEditorScript(ps, appData, options)
    }
}
