import _ from 'lodash'
import {pointerUtils, type DAL, ExtensionAPI} from '@wix/document-manager-core'
import type {
    Breakpoint,
    ExternalRefsMap,
    CompRef,
    Pointer,
    SchemaService,
    ComponentFeature,
    DataItemsMap,
    ResolvedDataItem,
    RawComponentExport,
    RawComponentExportStructure,
    AppControllerExport
} from '@wix/document-services-types'
import {type ReferredDataItemsIterateeContext, forEachReferredDataItem} from '@wix/import-export-utils'
import {DATA_TYPES, VIEW_MODES} from '../../../constants/constants'
import type {RelationshipsAPI} from '../../relationships'
import {COMPONENT_FEATURES} from './constants'

type ReusableIdsMap = Record<string, Record<string, boolean>>

const {DESKTOP, MOBILE} = VIEW_MODES

const isReusable = (
    pointer: Pointer,
    reusableIds: ReusableIdsMap,
    relationships: RelationshipsAPI['relationships']
): boolean => {
    const {id, type} = pointer
    const reusableMapValue = reusableIds[type]?.[id]
    if (reusableMapValue !== undefined) {
        return reusableMapValue
    }

    let reusable = false
    const owners = relationships.getOwningReferencesToPointer(pointer).filter(owner => owner.type !== MOBILE)
    if (owners.length > 0) {
        reusable = owners.every(owner => isReusable(owner, reusableIds, relationships))
    }

    reusableIds[type] ??= {}
    reusableIds[type][id] = reusable
    return reusable
}

const canReuseId = (
    id: string,
    type: string,
    reusableIds: ReusableIdsMap,
    dal: DAL,
    extensionAPI: ExtensionAPI
): boolean => {
    const {relationships} = extensionAPI as RelationshipsAPI

    const pointer = pointerUtils.getPointer(id, type)
    return !dal.has(pointer) || isReusable(pointer, reusableIds, relationships)
}

const reuseId = (
    id: string,
    type: string,
    externalRefs: ExternalRefsMap,
    reusableIds: ReusableIdsMap,
    dal: DAL,
    extensionAPI: ExtensionAPI
) => {
    if (id && !externalRefs[id] && canReuseId(id, type, reusableIds, dal, extensionAPI)) {
        externalRefs[id] = id
    }
}

const reuseComponentIds = (
    component: RawComponentExportStructure,
    reusableIds: ReusableIdsMap,
    externalRefs: ExternalRefsMap,
    extensionAPI: ExtensionAPI,
    dal: DAL,
    schemaService: SchemaService
) => {
    const {structure, data, overrides} = component

    reuseId(structure.id, DESKTOP, externalRefs, reusableIds, dal, extensionAPI)

    _.forOwn(COMPONENT_FEATURES, ({namespace, alias}: ComponentFeature) => {
        const dataItem = data[alias]
        if (!dataItem) {
            return
        }

        forEachReferredDataItem(
            schemaService,
            dataItem,
            namespace,
            ({item, namespace: itemNamespace}: ReferredDataItemsIterateeContext) =>
                reuseId(item.id, itemNamespace, externalRefs, reusableIds, dal, extensionAPI)
        )
    })

    _.forOwn(overrides, (overrideItems: DataItemsMap, namespace: string) => {
        _.forOwn(overrideItems, (dataItem: ResolvedDataItem) => {
            forEachReferredDataItem(
                schemaService,
                dataItem,
                namespace,
                ({item, namespace: itemNamespace}: ReferredDataItemsIterateeContext) =>
                    reuseId(item.id, itemNamespace, externalRefs, reusableIds, dal, extensionAPI)
            )
        })
    })
}

export const reuseIds = (
    componentExport: RawComponentExport,
    extensionAPI: ExtensionAPI,
    dal: DAL,
    schemaService: SchemaService,
    componentToReplace?: CompRef
): void => {
    const reusableIds: ReusableIdsMap = {[componentToReplace!.type]: {[componentToReplace!.id]: true}}
    const {components, externalRefs, page, appControllers, globalData} = componentExport
    _.forOwn(components, component => {
        reuseComponentIds(component, reusableIds, externalRefs, extensionAPI, dal, schemaService)
    })

    _.forOwn(page.breakpoints, (breakpoint: Breakpoint, breakpointId: string) => {
        reuseId(breakpointId, DATA_TYPES.variants, externalRefs, reusableIds, dal, extensionAPI)
    })

    _.forOwn(globalData, (globalDataItems: DataItemsMap, namespace: string) => {
        _.forOwn(globalDataItems, (dataItem: ResolvedDataItem) => {
            forEachReferredDataItem(
                schemaService,
                dataItem,
                namespace,
                ({item, namespace: itemNamespace}: ReferredDataItemsIterateeContext) =>
                    reuseId(item.id, itemNamespace, externalRefs, reusableIds, dal, extensionAPI)
            )
        })
    })

    _.forOwn(appControllers, ({externalRef, appController}: AppControllerExport, appControllerDataId: string) => {
        if (!externalRef) {
            reuseId(appControllerDataId, DATA_TYPES.data, externalRefs, reusableIds, dal, extensionAPI)
        }

        reuseComponentIds(appController, reusableIds, externalRefs, extensionAPI, dal, schemaService)
    })
}
