import {PublishSiteRCRequest, RCLabel} from '@wix/ambassador-wix-html-editor-webapp/types'
import type {DalJsStore, ExtensionAPI, SnapshotDal} from '@wix/document-manager-core'
import {
    ActionOperation,
    CreateRevisionRes,
    CSaveApi,
    PublishPipelineExtensionAPI,
    translationUtils
} from '@wix/document-manager-extensions'
import type {UpdateSiteDTO} from '@wix/document-manager-extensions/src/extensions/csave/continuousSave'
import type {SchemaExtensionAPI} from '@wix/document-manager-extensions/src/extensions/schema/schema'
import type {AppsInstallStateAPI} from '@wix/document-manager-extensions/dist/src/extensions/appsInstallState'
import {ReportableError, retryTaskAndReport, saveErrors, serverSaveErrorCodes} from '@wix/document-manager-utils'
import * as documentServicesJsonSchemas from '@wix/document-services-json-schemas'
import type {Callback1, PS} from '@wix/document-services-types'
import {guidUtils} from '@wix/santa-core-utils'
import {deepClone} from '@wix/wix-immutable-proxy'
import experiment from 'experiment-amd'
import _ from 'lodash'
import biErrors from '../../bi/errors'
import biEvents from '../../bi/events'
import constants from '../../constants/constants'
import editorServerFacade from '../../editorServerFacade/editorServerFacade'
import semanticAppVersionsCleaner from '../../metaSiteProvisioner/semanticAppVersionsCleaner'
import appStoreService from '../../tpa/services/appStoreService'
import permissionsUtils from '../../tpa/utils/permissionsUtils'
import {contextAdapter} from '../../utils/contextAdapter'
import filesDAL from '../../wixCode/services/filesDAL'
import {createFromSnapshotDiff} from '../appServiceData'
import cloneWithoutAdditionalProperties from '../cloneWithoutAdditionalProperties'
import type {BICallbacks, SaveOptions} from '../createSaveAPI'
import extractDataDeltaFromSnapshotDiff from '../extractDataDeltaFromSnapshotDiff'
import monitoring from '../monitoring'
import saveDataFixer from '../saveDataFixer/saveDataFixer'
import {ErrorInfo, convertHttpError} from '../saveErrors'
import {revisionPath, revisionPointer, shouldSaveDiff, versionPath, versionPointer} from '../snapshotDalSaveUtils'
import {
    addWixCodeSavedGridAppToResult,
    Change,
    createErrorObject,
    createErrorObjectFromRestException,
    getHistoryAlteringChanges,
    isValidationError,
    SaveResult,
    getErrorType
} from './saveDocumentBase'

const {getTranslationItemKey, getTranslationInfoFromKey} = translationUtils

interface PublishOptions {
    editorOrigin?: string
    viewerName?: string
}

interface PublishSiteRCOptions extends PublishOptions {
    publishRC?: boolean
    label?: RCLabel
    useUniqueLabel?: boolean
}

export interface PublishAllOptions extends PublishSiteRCOptions {
    publishTestSite?: boolean
    specificPages?: string[]

    overrideRevisionInfo?: {
        revision: string
        branchId: string
    }

    deploymentId?: string
    clickId?: string
}

const TASK_NAME = 'saveDocument'
const STRUCTURE_DATA_TYPES = _.values(constants.VIEW_MODES)
const {PAGE_DATA_DATA_TYPES, MULTILINGUAL_TYPES, COMP_DATA_QUERY_KEYS} = constants

const {
    namespaceMapping: {NAMESPACE_MAPPING, OVERRIDE_NAMESPACES}
} = documentServicesJsonSchemas

const pageDataTypeToKey = _.invert(NAMESPACE_MAPPING)

const previousDiffIdPath = ['documentServicesModel', 'autoSaveInfo', 'previousDiffId']

const changedPageTypes = [
    ...STRUCTURE_DATA_TYPES,
    ...Object.keys(PAGE_DATA_DATA_TYPES),
    ...Object.keys(MULTILINGUAL_TYPES)
]

const isPageComponent = (type: string, comp: any) => type === 'DESKTOP' && comp.type === 'Page'

export type SaveMethodName =
    | 'partialSave'
    | 'fullSave'
    | 'publish'
    | 'publishSiteRC'
    | 'publishAll'
    | 'saveAsTemplate'
    | 'autosave'

enum PublishType {
    NONE = 'NONE',
    SITE = 'SITE',
    RC = 'RC'
}

enum PublishPipelineResponseStatus {
    ERROR = 'ERROR',
    IN_PROGRESS = 'IN_PROGRESS'
}

const getChangedPagesFromSnapshotDal = (
    diff: DalJsStore,
    lastSnapshotDal: SnapshotDal,
    currentSnapshotDal: SnapshotDal
) => {
    const updatedPageIdSet = new Set<string>()
    const deletedPageIdsSet = new Set<string>()
    const addedPageIdsSet = new Set<string>()

    for (const type of changedPageTypes) {
        for (const [id, dalItem] of Object.entries(diff[type] || {})) {
            const wasDeleted = dalItem === undefined
            if (wasDeleted) {
                const prevDalItem = lastSnapshotDal.getValue({type, id})
                const {pageId} = prevDalItem.metaData
                if (isPageComponent(type, prevDalItem)) {
                    deletedPageIdsSet.add(pageId)
                } else {
                    updatedPageIdSet.add(pageId)
                }
            } else {
                const wasAdded = !lastSnapshotDal?.exists({type, id})
                const {pageId} = dalItem.metaData
                if (wasAdded && isPageComponent(type, dalItem)) {
                    addedPageIdsSet.add(pageId)
                } else {
                    updatedPageIdSet.add(pageId)
                }
            }
        }
    }

    const updatedPageIds: string[] = [...updatedPageIdSet].filter(pageId => {
        if (pageId && !addedPageIdsSet.has(pageId) && !deletedPageIdsSet.has(pageId)) {
            // Filter out pages that were inserted because of garbage components from deleted pages (for example in the mobileHints namespace)
            return currentSnapshotDal.exists({type: 'DESKTOP', id: pageId})
        }
        return false
    })
    const deletedPageIds: string[] = _.compact([...deletedPageIdsSet])
    const addedPageIds: string[] = _.compact([...addedPageIdsSet])

    return {
        updatedPageIds,
        deletedPageIds,
        addedPageIds
    }
}

const cleanComponentMetaData = (component: any) => {
    const sig = _.get(component, ['metaData', 'sig'])
    if (sig) {
        component.metaData = {sig}
    } else {
        delete component.metaData
    }
}

const getComponentsRecursively = (snapshotDal: SnapshotDal, type: string, id: string) => {
    const component = deepClone(snapshotDal.getValue({type, id}))
    cleanComponentMetaData(component)
    delete component.parent
    const children = component.components
    if (children) {
        component.components = children.map((childId: string) => getComponentsRecursively(snapshotDal, type, childId))
    }
    return component
}

const getPageStructure = (snapshotDal: SnapshotDal, pageId: string) => {
    const rootComponent = getComponentsRecursively(snapshotDal, 'DESKTOP', pageId)
    const mobilePageComp = _.clone(snapshotDal.getValue({type: 'MOBILE', id: pageId}))
    rootComponent.mobileComponents = mobilePageComp
        ? getComponentsRecursively(snapshotDal, 'MOBILE', pageId).components
        : []
    if (mobilePageComp) {
        cleanComponentMetaData(mobilePageComp)
        rootComponent.mobileMetaData = mobilePageComp.metaData
    }
    if (pageId === 'masterPage') {
        rootComponent.children = rootComponent.components
        delete rootComponent.components
    }
    return rootComponent
}

const masterPageProperties = [
    'children',
    'mobileComponents',
    'type',
    'layout',
    'modes',
    'metaData',
    'mobileMetaData',
    'componentType',
    'variantsQuery',
    'id'
].concat(Object.values(COMP_DATA_QUERY_KEYS))

function extractUpdatedPagesSnapshotDal(
    lastSnapshotDal: SnapshotDal,
    currentSnapshotDal: SnapshotDal,
    diff: DalJsStore
) {
    const {updatedPageIds, deletedPageIds, addedPageIds} = getChangedPagesFromSnapshotDal(
        diff,
        lastSnapshotDal,
        currentSnapshotDal
    )
    const changedPageIds: string[] = _.concat(updatedPageIds, addedPageIds)
    const updatedPages = changedPageIds
        .filter(pageId => pageId !== 'masterPage')
        .map(pageId => getPageStructure(currentSnapshotDal, pageId))

    const masterPage = _.includes(changedPageIds, 'masterPage')
        ? _.pick(getPageStructure(currentSnapshotDal, 'masterPage'), masterPageProperties)
        : undefined

    return {
        updatedPages,
        masterPage,
        deletedPageIds
    }
}

function getSiteMetaData(snapshotDal: SnapshotDal) {
    const siteMetaData = deepClone(snapshotDal.getValue({type: 'rendererModel', id: 'siteMetaData'}))
    const customHeadTags = deepClone(snapshotDal.getValue({type: 'documentServicesModel', id: 'customHeadTags'}))
    return (
        _(siteMetaData)
            .omit(['adaptiveMobileOn'])
            // @ts-expect-error
            .merge({headTags: customHeadTags || ''})
            .value()
    )
}

function getSiteMetaDataWithoutAdditionalProperties(snapshot: SnapshotDal) {
    return cloneWithoutAdditionalProperties('siteMetaData', getSiteMetaData(snapshot))
}

function extractSiteMetaDataIfChanged(lastSnapshotDal: SnapshotDal, currentSnapshotDal: SnapshotDal) {
    const oldSiteMetaData = getSiteMetaData(lastSnapshotDal)
    const currentSiteMetaData = getSiteMetaData(currentSnapshotDal)
    if (!_.isEqual(oldSiteMetaData, currentSiteMetaData)) {
        return cloneWithoutAdditionalProperties('siteMetaData', currentSiteMetaData)
    }
}

function isAdaptiveMobileOn(snapshotDal: SnapshotDal) {
    return snapshotDal.getValue({type: 'rendererModel', id: 'siteMetaData', innerPath: 'adaptiveMobileOn'})
}

function getSiteName(snapshotDal: SnapshotDal) {
    return snapshotDal.getValue({type: 'documentServicesModel', id: 'siteName'})
}

function needToAddSiteName(currentSnapshotDal: SnapshotDal) {
    return currentSnapshotDal.getValue({type: 'documentServicesModel', id: 'isDraft'})
}

function getMetaSiteData(snapshotDal: SnapshotDal) {
    const metaSiteData = deepClone(snapshotDal.getValue({type: 'documentServicesModel', id: 'metaSiteData'}))
    metaSiteData.adaptiveMobileOn = isAdaptiveMobileOn(snapshotDal)
    if (needToAddSiteName(snapshotDal)) {
        metaSiteData.siteName = getSiteName(snapshotDal)
    }
    return cloneWithoutAdditionalProperties('metaSiteData', metaSiteData)
}

function extractMetaSiteDataIfChanged(lastSnapshotDal: SnapshotDal, currentSnapshotDal: SnapshotDal, diff: DalJsStore) {
    const {documentServicesModel} = diff
    const metaSiteDataChanged = documentServicesModel?.hasOwnProperty('metaSiteData')
    const isAdaptiveMobileChanged = isAdaptiveMobileOn(lastSnapshotDal) !== isAdaptiveMobileOn(currentSnapshotDal)
    const needToSendSiteName = needToAddSiteName(currentSnapshotDal)
    if (metaSiteDataChanged || isAdaptiveMobileChanged || needToSendSiteName) {
        return getMetaSiteData(currentSnapshotDal)
    }
}

function getProtectedPagesData(lastSnapshotDal: SnapshotDal, diff: DalJsStore) {
    const rendererModelDiff = diff.rendererModel
    const currentPageToHashedPasswordMap =
        rendererModelDiff && deepClone(_.get(rendererModelDiff, ['pageToHashedPassword', 'pages']))
    if (currentPageToHashedPasswordMap) {
        const lastPageToHashedPasswordMap = lastSnapshotDal?.getValue({
            type: 'rendererModel',
            id: 'pageToHashedPassword',
            innerPath: ['pages']
        })
        if (lastPageToHashedPasswordMap) {
            return _.pickBy(
                currentPageToHashedPasswordMap,
                (newHash, pageId) => newHash !== lastPageToHashedPasswordMap[pageId]
            )
        }
        return currentPageToHashedPasswordMap
    }
    return {}
}

// In case no changes - server expected to get undefined
// In case of changes - server expect to get an object
function getRouters(diff: DalJsStore) {
    const rendererModelDiff = diff.rendererModel
    if (rendererModelDiff?.hasOwnProperty('routers')) {
        const {routers} = rendererModelDiff
        if (routers) {
            return cloneWithoutAdditionalProperties('routers', routers)
        }
        // in case of undo/redo or revision history
        return {}
    }
}

function getPlatformApplications(diff: DalJsStore) {
    const platformApplicationsDiff = diff.pagesPlatformApplications
    if (platformApplicationsDiff) {
        return deepClone(platformApplicationsDiff.pagesPlatformApplications)
    }
    return undefined
}

function getPermanentDataNodesToDelete(snapshotDal: SnapshotDal) {
    return deepClone(snapshotDal.getValue({type: 'save', id: 'orphanPermanentDataNodes'})) || []
}

function getSiteId(snapshotDal: SnapshotDal) {
    return snapshotDal.getValue({type: 'rendererModel', id: 'siteInfo', innerPath: 'siteId'})
}

function getRevision(snapshotDal: SnapshotDal) {
    return snapshotDal.getValue(revisionPointer)
}

function getVersion(snapshotDal: SnapshotDal) {
    return snapshotDal.getValue(versionPointer)
}

const getTranslationsDeltaFromDiff = (
    diff: DalJsStore
): {
    translationsDelta?: Record<string, any>
    deletedTranslations: Record<string, string[]>
    changedTranslationPageIds: string[]
} => {
    const translationsDelta: Record<string, any> = {}
    /**
     * ts expression: {[lang: string]: string[]}
     * @example
     * {
     *   "lang-0": [
     *     "item-0",
     *     "item-1"
     *   ],
     *   "lang-1": [
     *     "item-0",
     *     "item-1"
     *   ]
     * }
     */
    const deletedTranslations: Record<string, string[]> = {}
    const changedTranslationPageIds = new Set<string>()
    _.forEach(diff.multilingualTranslations, (value, key) => {
        const [languageCode, id] = getTranslationInfoFromKey(key)

        if (value === undefined) {
            // removed translations
            const translationDataItemsToRemove = _.get(deletedTranslations, [languageCode], []).concat(id)
            _.setWith(deletedTranslations, [languageCode], translationDataItemsToRemove, Object)
            return
        }

        _.setWith(
            translationsDelta,
            [languageCode, 'data', 'document_data', id],
            cloneWithoutAdditionalProperties(id, value),
            Object
        )

        changedTranslationPageIds.add(value.metaData?.pageId)

        // The translation of page is showing in both the masterPage and actual page.
        // Need to make sure the server loads both, otherwise there is a mismatch
        if (value.type === 'Page') {
            changedTranslationPageIds.add(id)
            changedTranslationPageIds.add('masterPage')
        }
    })
    return {
        translationsDelta: _.isEmpty(translationsDelta) ? undefined : translationsDelta,
        deletedTranslations,
        changedTranslationPageIds: Array.from(changedTranslationPageIds)
    }
}

const createBaseDataToSave = (
    lastSnapshotDal: SnapshotDal,
    currentSnapshotDal: SnapshotDal,
    diff: DalJsStore,
    extensionsAPI: any
): UpdateSiteDTO => {
    const dataToSave: UpdateSiteDTO = {
        lastTransactionId: currentSnapshotDal.lastTransactionId,
        protectedPagesData: getProtectedPagesData(lastSnapshotDal, diff),
        dataNodeIdsToDelete: getPermanentDataNodesToDelete(currentSnapshotDal),
        id: getSiteId(currentSnapshotDal),
        revision: getRevision(currentSnapshotDal),
        version: getVersion(currentSnapshotDal),
        routers: getRouters(diff),
        initiator: getInitiator(currentSnapshotDal),
        pagesPlatformApplications: getPlatformApplications(diff),
        branchId: currentSnapshotDal.getValue({type: 'documentServicesModel', id: 'branchId'}),
        signatures: getSignaturesMap(diff)
    }
    if (extensionsAPI.continuousSave.isCEditOpen()) {
        dataToSave.cedit = true
    }
    return dataToSave
}

const getSignaturesMap = (diff: DalJsStore) =>
    _.pickBy({
        'rendererModel.routers': _.get(diff, ['rendererModel', 'routers', 'metaData', 'sig']),
        'rendererModel.siteMetaData': _.get(diff, ['rendererModel', 'siteMetaData', 'metaData', 'sig']),
        'rendererModel.wixCodeModel': _.get(diff, ['rendererModel', 'wixCodeModel', 'metaData', 'sig']),
        'documentServicesModel.metaSiteData': _.get(diff, ['documentServicesModel', 'metaSiteData', 'metaData', 'sig'])
    })

const reportIgnoredDeletions = (isFull: boolean, ignoredDeletions: {namespace: string; id: string}[]) => {
    if (ignoredDeletions.length < 1) {
        return
    }
    const saveName = isFull ? 'fullSave' : 'partialSave'
    const message = `ignoredDeletions from ${saveName}`
    const err = new ReportableError({
        message,
        errorType: 'ignoredDeletions',
        tags: {
            saveName
        },
        extras: {
            ignoredDeletions
        }
    })
    contextAdapter.utils.fedopsLogger.captureError(err)
}

/**
 * @typedef {object} PartialSaveOptions
 * @property {boolean} [settleInServer]
 * @property {string} [viewerName]
 * @property {string} [initiatorOrigin]
 * @property {string} [editorOrigin]
 */

type DataToSave = UpdateSiteDTO & {
    dataDelta: any
    nodeIdsToDelete: any
    deletedPageIds: any
    masterPage: any
    updatedPages: any
    siteMetaData: any
    metaSiteData: any
    initiatorOrigin: string
    translationsDelta: any
    pageIdsWithChangedData: any
    viewerName: string
    metaSiteActions?: any
}

/**
 * @param bi
 * @param {PartialSaveOptions} o
 * @param lastSnapshotDal
 * @param currentSnapshotDal
 * @param extensionsAPI
 * @returns {Promise<{}>}
 */
const createPartialDataToSave = async (
    bi: BICallbacks,
    {settleInServer, viewerName, initiatorOrigin}: ValidateSiteOptions = {
        settleInServer: undefined,
        viewerName: undefined,
        initiatorOrigin: ''
    },
    lastSnapshotDal?: SnapshotDal,
    currentSnapshotDal?: SnapshotDal,
    extensionsAPI?: ExtensionAPI
): Promise<UpdateSiteDTO> => {
    const diff = currentSnapshotDal.diff(lastSnapshotDal)

    const {
        changedData,
        deletedData,
        deletedDataForSave,
        changedDataPageIds,
        deletedDataPageIds,
        deletedDataPageIdsForSave,
        ignoredDeletions
    } = extractDataDeltaFromSnapshotDiff(diff, lastSnapshotDal, currentSnapshotDal, extensionsAPI)
    reportIgnoredDeletions(false, ignoredDeletions)

    const {updatedPages, masterPage, deletedPageIds} = extractUpdatedPagesSnapshotDal(
        lastSnapshotDal,
        currentSnapshotDal,
        diff
    )

    const {deletedTranslations, translationsDelta, changedTranslationPageIds} = getTranslationsDeltaFromDiff(diff)
    const pageIdsWithChangedData = _(changedDataPageIds)
        .concat(deletedDataPageIdsForSave)
        .concat(changedTranslationPageIds)
        .uniq()
        .value()

    const nodeIdsToDelete = _.mapValues(deletedDataForSave, _.keys)
    const base = createBaseDataToSave(lastSnapshotDal, currentSnapshotDal, diff, extensionsAPI)
    const dataToSave: DataToSave = {
        ...base,
        dataDelta: changedData,
        nodeIdsToDelete,
        deletedPageIds,
        masterPage,
        updatedPages,
        siteMetaData: extractSiteMetaDataIfChanged(lastSnapshotDal, currentSnapshotDal),
        metaSiteData: extractMetaSiteDataIfChanged(lastSnapshotDal, currentSnapshotDal, diff),
        initiatorOrigin: initiatorOrigin || '',
        translationsDelta,
        pageIdsWithChangedData,
        viewerName
    }

    if (!_.isEmpty(deletedTranslations)) {
        _.assign(dataToSave, {translationsToDelete: deletedTranslations})
    }

    if (settleInServer) {
        const isMasterPageUpdated =
            _.includes(changedDataPageIds, 'masterPage') || _.includes(deletedDataPageIds, 'masterPage')
        const appStoreServiceData = createFromSnapshotDiff(
            diff,
            lastSnapshotDal,
            currentSnapshotDal,
            changedData.document_data,
            deletedData.document_data,
            isMasterPageUpdated
        )
        const shouldAvoidRevoking = await permissionsUtils.shouldAvoidRevoking({snapshotDal: currentSnapshotDal})

        const actions = appStoreService.getSettleActionsForSave(appStoreServiceData, shouldAvoidRevoking)

        if (!_.isEmpty(actions)) {
            const appsInstallState = extensionsAPI?.appsInstallState as AppsInstallStateAPI
            appsInstallState.reportStateDifferenceByActions(
                appStoreServiceData.clientSpecMap,
                actions as any,
                'createPartialDataToSave'
            )

            dataToSave.metaSiteActions = _.omitBy(
                {
                    actions,
                    maybeBranchId: appStoreServiceData.branchId
                },
                _.isNil
            )
        }
    }

    saveDataFixer.fixData(dataToSave, {lastSnapshotDal, currentSnapshotDal, bi})

    return _.omitBy(dataToSave, value => _.isNil(value)) as UpdateSiteDTO
}

function getInitiator(currentSnapshotDal: SnapshotDal) {
    if (getAutosaveInfo(currentSnapshotDal, 'autoFullSaveFlag')) {
        return 'auto_save'
    } else if (getPublishSaveInitiator(currentSnapshotDal)) {
        return 'publish'
    } else if (getSilentSaveInitiator(currentSnapshotDal)) {
        return 'provision'
    }
    return 'manual'
}

const getAutosaveInfo = (snapshotDal: SnapshotDal, key: string) =>
    snapshotDal.getValue({type: 'documentServicesModel', id: 'autoSaveInfo', innerPath: key})
const getPublishSaveInitiator = (snapshotDal: SnapshotDal) =>
    snapshotDal.getValue({type: 'save', id: 'publishSaveInitiator'})
const getSilentSaveInitiator = (snapshotDal: SnapshotDal) =>
    snapshotDal.getValue({type: 'save', id: 'silentSaveInitiator'})

export type FullDataToSave = UpdateSiteDTO & {
    dataDelta: any
    deletedPageIds: any[]
    masterPage: any
    updatedPages: any
    siteMetaData: any
    metaSiteData: any
    initiatorOrigin: string
    metaSiteActions?: any
    translationsDelta?: any
}

async function createFullDataToSave(
    currentSnapshotDal: SnapshotDal,
    options: SaveOptions = {},
    extensionsAPI?: ExtensionAPI
) {
    const diff = currentSnapshotDal.toJS()
    const {changedData, deletedData, changedDataPageIds, deletedDataPageIds, ignoredDeletions} =
        extractDataDeltaFromSnapshotDiff(diff, null, currentSnapshotDal, extensionsAPI)
    reportIgnoredDeletions(true, ignoredDeletions)
    const {deletedTranslations, translationsDelta} = getTranslationsDeltaFromDiff(diff)
    const {masterPage, updatedPages} = extractUpdatedPagesSnapshotDal(null, currentSnapshotDal, diff)
    const base = createBaseDataToSave(null, currentSnapshotDal, diff, extensionsAPI)
    const dataToSave: FullDataToSave = {
        ...base,
        dataDelta: changedData,
        deletedPageIds: [],
        masterPage,
        updatedPages,
        siteMetaData: getSiteMetaDataWithoutAdditionalProperties(currentSnapshotDal),
        metaSiteData: getMetaSiteData(currentSnapshotDal),
        initiatorOrigin: _.get(options, 'initiatorOrigin', '')
    }
    if (!_.isEmpty(translationsDelta)) {
        _.assign(dataToSave, {translationsDelta})
    }
    if (!_.isEmpty(deletedTranslations)) {
        _.assign(dataToSave, {translationsToDelete: deletedTranslations})
    }

    saveDataFixer.fixData(dataToSave, {lastSnapshotDal: null, currentSnapshotDal})

    if (options.settleInServer) {
        const isMasterPageUpdated =
            _.includes(changedDataPageIds, 'masterPage') || _.includes(deletedDataPageIds, 'masterPage')
        const appStoreServiceData = createFromSnapshotDiff(
            diff,
            null,
            currentSnapshotDal,
            changedData.document_data,
            deletedData.document_data,
            isMasterPageUpdated
        )
        const shouldAvoidRevoking = await permissionsUtils.shouldAvoidRevoking({snapshotDal: currentSnapshotDal})
        dataToSave.metaSiteActions = _.omitBy(
            {
                // @ts-expect-error
                actions: appStoreService.getSettleActionsForFullSave(appStoreServiceData, shouldAvoidRevoking),
                maybeBranchId: appStoreServiceData.branchId
            },
            _.isNil
        )
    }

    return _.omitBy(dataToSave, value => _.isNil(value))
}

const saveEndpoints = [editorServerFacade.ENDPOINTS.OVERRIDE_SAVE, editorServerFacade.ENDPOINTS.PARTIAL_SAVE]

function cleanupData(
    dataDelta: Record<string, Record<string, string>>,
    extensionsAPI: ExtensionAPI,
    conservativeRemoval: boolean
) {
    const remappedDataDelta = _.mapKeys(dataDelta, (v, sns) => _.findKey(NAMESPACE_MAPPING, k => k === sns))

    const dataDeltaAfterRemovals = (extensionsAPI as SchemaExtensionAPI).schemaAPI.removeWhitelistedPropertiesSafely(
        remappedDataDelta,
        conservativeRemoval
    )
    const restrictedDataDelta = _.mapKeys(dataDeltaAfterRemovals, (v, sns) => NAMESPACE_MAPPING[sns])
    return restrictedDataDelta
}

const semverPattern = /.*(\d+\.(\d+)\.\d+)$/

interface LogMarkers {
    readonly sessionStartTime?: number
    readonly sessionLength?: number
    dmVersion?: string | number
    readonly whitelist?: string
}

function generateSaveLogMarkers(useRadicalWhitelistAtMonitoring: boolean): LogMarkers {
    const logsMarkers: LogMarkers = {
        sessionStartTime: window.performance?.timing?.navigationStart,
        sessionLength: window.performance?.now() / 1000,
        whitelist: useRadicalWhitelistAtMonitoring ? 'radical' : 'conservative'
    }
    if (semverPattern.test(window.dmBase)) {
        const minorVersion = window.dmBase?.replace(semverPattern, '$2')
        logsMarkers.dmVersion = _.toNumber(minorVersion) || window.dmBase
    }
    return logsMarkers
}

/**
 * cleans up data delta contents according the whitelist (instead of ajv additional properties removal)
 * use radical model for monitoring and conservative for actual removal
 * @param endpoint
 * @param data
 * @param extensionsAPI
 */
function cleanupDataDeltaContents(endpoint: string, data: UpdateSiteDTO, extensionsAPI: ExtensionAPI) {
    const useRadicalWhitelistAtMonitoring = experiment.isOpen('dm_radicalWhitelistBasedDataDeltaCleanup')
    if (saveEndpoints.includes(endpoint)) {
        const conservativeDataDelta = cleanupData(data.dataDelta, extensionsAPI, true)
        data.dataDelta = conservativeDataDelta

        if (data.translationsDelta) {
            data.translationsDelta = _.forEach(data.translationsDelta, multilingualDataDelta => {
                multilingualDataDelta.data = cleanupData(multilingualDataDelta.data, extensionsAPI, true)
            })
        }

        data.logsMarkers = generateSaveLogMarkers(useRadicalWhitelistAtMonitoring)

        if (useRadicalWhitelistAtMonitoring) {
            const restrictedDataDelta = cleanupData(data.dataDelta, extensionsAPI, false)
            data.restrictedDataDelta = restrictedDataDelta
        }
    }
}

const logAsyncRequestError = (
    method: string,
    endpoint: string,
    errorInfo: ErrorInfo,
    originalError: any,
    responseType: string
) => {
    contextAdapter.utils.fedopsLogger.captureError(
        new ReportableError({
            message: `${method}: ${errorInfo.errorType}`,
            errorType: method,
            tags: {
                endpoint
            },
            extras: {
                originalErrorObject: JSON.stringify(originalError),
                errorMessage: originalError.message || originalError.toString(),
                ...errorInfo,
                responseType
            }
        })
    )
}

const sendRequestAsyncWrapped = async (
    endpoint: string,
    data: UpdateSiteDTO,
    snapshotDal: SnapshotDal,
    editorOrigin: string,
    extensionsAPI: ExtensionAPI
) => {
    let response: SaveServerResponse
    try {
        response = await sendRequestAsync(endpoint, data, snapshotDal, editorOrigin, extensionsAPI)
    } catch (e: any) {
        const error = await convertHttpError(e)
        logAsyncRequestError('sendRequestAsync', endpoint, error, e, 'error')
        throw error
    }
    if (response.success) {
        return response
    }
    const error = createErrorObject(response)
    logAsyncRequestError('sendRequestAsync', endpoint, error, response, 'response.success=false')
    throw error
}

const sendRestRequestAsyncWrapped = async (
    endpoint: string,
    data: any,
    snapshotDal: SnapshotDal,
    editorOrigin: string,
    extensionsAPI: ExtensionAPI
) => {
    try {
        const response = await sendRequestAsync(endpoint, data, snapshotDal, editorOrigin, extensionsAPI)
        if (response?.success === false) {
            const error = createErrorObject(response)
            logAsyncRequestError('sendRestRequestAsync', endpoint, error, response, 'response.success=false')
        }
        return response
    } catch (e) {
        const error = await createErrorObjectFromRestException(e)
        logAsyncRequestError('sendRestRequestAsync', endpoint, error, e, 'error')
        throw error
    }
}

const sendAndReport = async (endpoint: string, data: UpdateSiteDTO, snapshotDal: SnapshotDal, editorOrigin: string) => {
    monitoring.start(monitoring.SERVER_SAVE)
    const result = await editorServerFacade.sendWithSnapshotDalAsync(snapshotDal, endpoint, data, editorOrigin)
    monitoring.end(monitoring.SERVER_SAVE)
    return result
}

async function sendRequestAsync(
    endpoint: string,
    data: UpdateSiteDTO,
    snapshotDal: SnapshotDal,
    editorOrigin: string,
    extensionsAPI: ExtensionAPI
) {
    const editorSessionId = snapshotDal.getValue({type: 'documentServicesModel', id: 'editorSessionId'})
    cleanupDataDeltaContents(endpoint, data, extensionsAPI)

    const ds_recordActionsToCompareWithRC = experiment.isOpen('ds_recordActionsToCompareWithRC')
    const ds_runActionsToCompareWithRC = experiment.isOpen('ds_runActionsToCompareWithRC')
    if (ds_recordActionsToCompareWithRC || ds_runActionsToCompareWithRC) {
        let result
        if (ds_recordActionsToCompareWithRC) {
            try {
                await window.documentServices.debug.trace.upload()
            } catch (e) {
                // ignore
            }
            result = await sendAndReport(endpoint, data, snapshotDal, editorOrigin)
        }
        if (ds_runActionsToCompareWithRC) {
            if (typeof window.autopilotSaves === 'undefined') {
                window.autopilotSaves = []
            }
            const headers = getSaveDocumentHeaders(editorSessionId, editorOrigin)
            window.autopilotSaves.push({endpoint, body: data, headers})
        }
        return result
    }
    return await sendAndReport(endpoint, data, snapshotDal, editorOrigin)
}

function hasAutoSaveInfo(snapshotDal: SnapshotDal) {
    return Boolean(
        snapshotDal.getValue({type: 'documentServicesModel', id: 'autoSaveInfo', innerPath: 'shouldAutoSave'})
    )
}

function onSaveCompleteError(
    snapshotDal: SnapshotDal,
    bi: BICallbacks,
    editorOrigin: string,
    response: SaveServerResponse
) {
    const rejectionInfo = createErrorObject(response)
    const {errorType} = rejectionInfo
    const validationError = isValidationError(response)
    if (validationError) {
        contextAdapter.utils.fedopsLogger.captureError(
            new ReportableError({message: `Save Error: ${errorType}`, errorType: 'saveValidationError'}),
            {
                tags: {
                    errorType,
                    saveError: true
                },
                extras: {
                    origin: editorOrigin,
                    errorCode: _.get(rejectionInfo, 'errorCode'),
                    errorDescription: _.get(rejectionInfo, 'errorDescription'),
                    duplicateComponentId: _.get(response, 'payload.duplicateComponents[0].id') || null,
                    serverPayload: _.get(response, 'payload') as any
                }
            }
        )
    }
    bi.error(biErrors.SAVE_DOCUMENT_FAILED_ON_SERVER, {
        serverErrorCode: rejectionInfo.errorCode,
        errorType,
        origin: editorOrigin
    })
    return rejectionInfo
}

function reportVersionInfo({revision, version}: {revision: string; version: string}) {
    contextAdapter.utils.fedopsLogger.interactionEnded('versionInfoUpdate', {
        tags: {
            revision,
            version,
            source: 'from_save'
        }
    })
}

export interface SaveServerResponse {
    success: boolean
    payload: {
        permissionsInfo: {}
        mediaSiteUploadToken: {}
        autoSaveInfo: {}
        siteHeader: {
            version: string
            revision: string
        }
        publicUrl: string
        metaSiteData: {}
        clientSpecMap: {}
        revision: string
        version: string
        deleted: Record<string, Record<string, string>>
        previewModel: any
        mediaAuthToken: any

        fatalErrorsNamesAndCount?: {name: string; count: number}[]
        dataReferenceMismatches?: string[]
        duplicateComponents?: string[]
        missingContainers?: string[]
        appControllerReferenceMismatches?: string[]
        connectionListReferenceMismatches?: string[]
        styleReferenceMismatches?: string[]
        behaviorReferenceMismatches?: string[]
        propertyReferenceMismatches?: string[]
        designReferenceMismatches?: string[]
    }
    errorCode?: string
    errorDescription?: string
}

function onSaveCompleteSuccess(
    snapshotDal: SnapshotDal,
    bi: BICallbacks,
    settleInServer: boolean,
    response: SaveServerResponse,
    extensionsAPI: ExtensionAPI
) {
    const {revision, version} = response.payload
    const resolveObject: SaveResult = {
        changes: [
            {
                path: revisionPath,
                value: revision
            },
            {
                path: versionPath,
                value: version
            },
            {
                path: ['orphanPermanentDataNodes'],
                value: []
            }
        ]
    }
    reportVersionInfo({revision, version})
    if (hasAutoSaveInfo(snapshotDal)) {
        resolveObject.changes.push({
            path: previousDiffIdPath,
            value: undefined
        })
    }

    if (snapshotDal.getValue({type: 'documentServicesModel', id: 'isDraft'})) {
        resolveObject.changes.push({
            path: ['documentServicesModel', 'isDraft'],
            value: false
        })
    }

    if (settleInServer) {
        const clientSpecMap = snapshotDal.getValue({type: 'rendererModel', id: 'clientSpecMap'})
        const documentType = snapshotDal.getValue({type: 'rendererModel', id: 'siteInfo', innerPath: 'documentType'})
        if (response.payload.clientSpecMap) {
            resolveObject.changes.push(
                {
                    path: ['rendererModel', 'clientSpecMap'],
                    value:
                        documentType === 'Template'
                            ? response.payload.clientSpecMap
                            : _.assign({}, clientSpecMap, response.payload.clientSpecMap)
                },
                {
                    path: ['rendererModel', 'clientSpecMapCacheKiller'],
                    value: {cacheKiller: guidUtils.getGUID()}
                }
            )
            resolveObject.changes.push(semanticAppVersionsCleaner())
            contextAdapter.utils.fedopsLogger.interactionEnded(constants.PLATFORM_INTERACTIONS.SETTLE_ACTIONS)
        }
        resolveObject.changes.push({
            path: ['rendererModel', 'siteInfo', 'documentType'],
            value: documentType
        })
    }

    const {deleted} = response.payload
    const itemsToDelete = addPagesOfItemsToDelete(deleted, snapshotDal)

    if (!(extensionsAPI as CSaveApi).continuousSave.isCEditOpen()) {
        resolveObject.historyAlteringChanges = getHistoryAlteringChanges(itemsToDelete)
    }

    addWixCodeSavedGridAppToResult(extensionsAPI, response.payload, resolveObject)

    return resolveObject
}

function addPagesOfItemsToDelete(itemsToDelete: Record<string, Record<string, string>>, snapshotDal: SnapshotDal) {
    const result: Record<string, Record<string, string[]>> = {}
    _.forEach(itemsToDelete, function (deletedIds, dataType) {
        _.forEach(deletedIds, function (deletedItemId) {
            /**
             * for dataType === 'multilingualTranslations' deletedItemId will be `itemid^langCode`
             */
            const isMultilingualTranslations = dataType === MULTILINGUAL_TYPES.multilingualTranslations
            const actualItemId = isMultilingualTranslations ? deletedItemId.split('^')[0] : deletedItemId
            const dataItem = snapshotDal.getValue({
                type: isMultilingualTranslations ? OVERRIDE_NAMESPACES[dataType] : pageDataTypeToKey[dataType],
                id: actualItemId
            })
            const pageId = _.get(dataItem, ['metaData', 'pageId'])
            if (pageId) {
                const deletesIdsArr = _.get(result, [pageId, dataType], [])
                let idToPush = deletedItemId
                if (isMultilingualTranslations) {
                    const splitted = deletedItemId.split('^')
                    idToPush = getTranslationItemKey(splitted[1], splitted[0])
                }
                deletesIdsArr.push(idToPush)
                _.set(result, [pageId, dataType], deletesIdsArr)
            }
        })
    })
    return result
}

function getSaveDocumentHeaders(sessionId: string, editorOrigin: string) {
    return {
        'X-Wix-Editor-Version': 'new',
        'X-Wix-DS-Origin': editorOrigin,
        'X-Editor-Session-Id': sessionId
    }
}

const fullSaveAsync = (
    lastSnapshotDal: SnapshotDal,
    currentSnapshotDal: SnapshotDal,
    bi: BICallbacks,
    options: SaveOptions,
    extensionsAPI: ExtensionAPI
) =>
    saveWithFullPayloadAsync(currentSnapshotDal, bi, options, editorServerFacade.ENDPOINTS.OVERRIDE_SAVE, extensionsAPI)

const fullSave = (
    lastSnapshot: unknown,
    currentSnapshot: unknown,
    resolve: Callback1<any>,
    reject: Callback1<any>,
    bi: BICallbacks,
    options: SaveOptions,
    lastSnapshotDal: SnapshotDal,
    currentSnapshotDal: SnapshotDal,
    extensionsAPI: ExtensionAPI
) => {
    fullSaveAsync(lastSnapshotDal, currentSnapshotDal, bi, options, extensionsAPI).then(resolve, reject) // eslint-disable-line promise/prefer-await-to-then
}

async function fullPartialSave(
    bi: BICallbacks,
    options: SaveOptions,
    currentSnapshotDal: SnapshotDal,
    extensionsAPI: ExtensionAPI
): Promise<void> {
    setSaving(extensionsAPI, true)
    try {
        await saveWithFullPayloadAsync(
            currentSnapshotDal,
            bi,
            options,
            editorServerFacade.ENDPOINTS.PARTIAL_SAVE,
            extensionsAPI
        )
    } finally {
        setSaving(extensionsAPI, false)
    }
}

const validateSite = (
    last: unknown,
    current: unknown,
    resolve: Callback1<any>,
    reject: Callback1<any>,
    bi: BICallbacks,
    options: SaveOptions,
    lastSnapshotDal: SnapshotDal,
    currentSnapshotDal: SnapshotDal,
    extensionsAPI: ExtensionAPI
) => {
    // eslint-disable-next-line promise/prefer-await-to-then
    validateSiteAsync(last, current, bi, options, lastSnapshotDal, currentSnapshotDal, extensionsAPI).then(
        resolve,
        reject
    )
}

interface ValidateSiteOptions {
    settleInServer?: boolean
    viewerName?: string
    initiatorOrigin?: string
}

const validateSiteAsync = async (
    last: unknown,
    current: unknown,
    bi: BICallbacks,
    options: SaveOptions,
    lastSnapshotDal: SnapshotDal,
    currentSnapshotDal: SnapshotDal,
    extensionsAPI: ExtensionAPI
) => {
    const opts: ValidateSiteOptions = {
        settleInServer: false,
        viewerName: '',
        initiatorOrigin: _.get(options, 'initiatorOrigin', '')
    }
    const dataToValidate = await createPartialDataToSave(bi, opts, lastSnapshotDal, currentSnapshotDal, extensionsAPI) //no settling on validation
    await sendRequestAsyncWrapped(
        editorServerFacade.ENDPOINTS.VALIDATE,
        dataToValidate,
        currentSnapshotDal,
        options.editorOrigin,
        extensionsAPI
    )
}

async function saveWithFullPayloadAsync(
    currentSnapshotDal: SnapshotDal,
    bi: BICallbacks,
    options: SaveOptions,
    endPoint: string,
    extensionsAPI: ExtensionAPI
): Promise<SaveResult> {
    setSaving(extensionsAPI, true)
    const dataToSave = await createFullDataToSave(currentSnapshotDal, options, extensionsAPI)
    let response
    try {
        response = await sendRequestAsync(
            endPoint,
            dataToSave as any,
            currentSnapshotDal,
            options.editorOrigin,
            extensionsAPI
        )
    } catch (e: any) {
        throw await convertHttpError(e)
    } finally {
        setSaving(extensionsAPI, false)
    }
    if (response.success) {
        return onSaveCompleteSuccess(currentSnapshotDal, bi, options.settleInServer, response, extensionsAPI)
    }
    throw onSaveCompleteError(currentSnapshotDal, bi, options.editorOrigin, response)
}

const setSaving = (extensionsAPI: any, isSaving: boolean) => {
    extensionsAPI.continuousSave.setSaving(isSaving)
}

const convertCreateRevisionResponse = ({siteRevision, actions}: CreateRevisionRes) => {
    return {
        revision: siteRevision.revision,
        version: siteRevision.version,
        clientSpecMap: _.find(actions, {id: 'clientSpecMap', namespace: 'rendererModel'})?.value,
        wixCodeModel: {
            appData: {
                codeAppId: _.find(actions, {namespace: 'rendererModel', id: 'wixCodeModel'})?.value.appData?.codeAppId
            }
        },
        deleted: _(actions)
            .filter({op: 'REMOVE' as ActionOperation})
            .groupBy('namespace')
            .mapValues(x => _.map(x, 'id'))
            .value()
    }
}

const csaveCreateRevision = async (
    currentSnapshotDal: SnapshotDal,
    options: SaveOptions,
    updateSiteDto: UpdateSiteDTO,
    extensionsAPI: any
) => {
    // send origin from here
    const initiator = getInitiator(currentSnapshotDal)
    const createRevisionArgs = {
        initiator,
        viewerName: options.viewerName,
        initiatorOrigin: _.get(options, 'initiatorOrigin', ''),
        dsOrigin: options.editorOrigin,
        editorVersion: updateSiteDto.version.toString()
    }
    const result = await (extensionsAPI as CSaveApi).continuousSave.createRevision(createRevisionArgs, updateSiteDto)
    return {
        success: true,
        payload: convertCreateRevisionResponse(result)
    }
}

const partialSave = (
    last: unknown,
    current: unknown,
    resolve: Callback1<any>,
    reject: Callback1<any>,
    bi: BICallbacks,
    options: SaveOptions,
    lastSnapshotDal: SnapshotDal,
    currentSnapshotDal: SnapshotDal,
    extensionsAPI: ExtensionAPI
) => {
    // eslint-disable-next-line promise/prefer-await-to-then
    partialSaveAsync(last, current, bi, options, lastSnapshotDal, currentSnapshotDal, extensionsAPI).then(
        resolve,
        reject
    )
}

async function sendPartialSaveAsync(
    dataToSave: UpdateSiteDTO,
    currentSnapshotDal: SnapshotDal,
    options: SaveOptions,
    extensionsAPI: any
) {
    const task = () =>
        sendRequestAsync(
            editorServerFacade.ENDPOINTS.PARTIAL_SAVE,
            dataToSave,
            currentSnapshotDal,
            options.editorOrigin,
            extensionsAPI
        )
    return await retryTaskAndReport({
        task,
        checkIfShouldRetry: (e: any) => {
            let reason
            const errorCode = String(e?.errorCode)
            if (e?.status === 503) {
                reason = '503'
            } else if (errorCode === serverSaveErrorCodes.IDENTITY_UNKNOWN_RUNTIME_ERROR) {
                reason = serverSaveErrorCodes.IDENTITY_UNKNOWN_RUNTIME_ERROR
            } else if (errorCode === serverSaveErrorCodes.TRANSPORTATION_ERROR) {
                reason = serverSaveErrorCodes.TRANSPORTATION_ERROR
            } else if (getErrorType(e) === saveErrors.CLONE_GRID_APP_FAILED) {
                reason = saveErrors.CLONE_GRID_APP_FAILED
            } else if (getErrorType(e) === saveErrors.IDENTITY_UNKNOWN_RUNTIME_ERROR) {
                reason = saveErrors.IDENTITY_UNKNOWN_RUNTIME_ERROR
            }

            if (reason) {
                return {shouldRetry: true, reason}
            }
            return {shouldRetry: false}
        },
        interactionName: 'partialSave',
        maxRetries: 1,
        logger: contextAdapter.utils.fedopsLogger
    })
}

const sendRequestOrCreateRevision = async (
    options: SaveOptions,
    currentSnapshotDal: SnapshotDal,
    dataToSave: UpdateSiteDTO,
    extensionsAPI: any
) => {
    return extensionsAPI.continuousSave.isCreateRevisionOpen()
        ? await csaveCreateRevision(currentSnapshotDal, options, dataToSave, extensionsAPI)
        : await sendPartialSaveAsync(dataToSave, currentSnapshotDal, options, extensionsAPI)
}

const partialSaveAsync = async (
    last: unknown,
    current: unknown,
    bi: BICallbacks,
    options: SaveOptions,
    lastSnapshotDal: SnapshotDal,
    currentSnapshotDal: SnapshotDal,
    extensionsAPI: any
): Promise<void | SaveResult> => {
    if (options?.fullPayload) {
        return await fullPartialSave(bi, options, currentSnapshotDal, extensionsAPI)
    }
    monitoring.start(monitoring.BUILD_PARTIAL_PAYLOAD)
    setSaving(extensionsAPI, true)
    const dataToSave = await createPartialDataToSave(bi, options, lastSnapshotDal, currentSnapshotDal, extensionsAPI)
    monitoring.end(monitoring.BUILD_PARTIAL_PAYLOAD)
    let response
    try {
        response = await sendRequestOrCreateRevision(options, currentSnapshotDal, dataToSave, extensionsAPI)
    } catch (e: any) {
        throw await convertHttpError(e)
    } finally {
        extensionsAPI.continuousSave.disableSaveDuringRequiredAndPrimary(false)
        setSaving(extensionsAPI, false)
    }
    if (response.success) {
        return onSaveCompleteSuccess(currentSnapshotDal, bi, options.settleInServer, response, extensionsAPI)
    }
    if (isValidationError(response)) {
        bi.event(biEvents.FULL_DOCUMENT_SAVE_ATTEMPTED_AFTER_PARTIAL_FAILURE, {endpoint: 'partial'})
        monitoring.start(monitoring.FULL_PARTIAL_SAVE)
        await fullPartialSave(bi, options, currentSnapshotDal, extensionsAPI)
        monitoring.end(monitoring.FULL_PARTIAL_SAVE)
    } else {
        throw onSaveCompleteError(currentSnapshotDal, bi, options.editorOrigin, response)
    }
}

const shouldRun = (
    ps: PS,
    methodName: SaveMethodName,
    lastSnapshotDal: SnapshotDal,
    currentSnapshotDal: SnapshotDal
) => {
    if (methodName === 'partialSave') {
        const diff = currentSnapshotDal.diff(lastSnapshotDal)
        const didSnapShotsChangeFromLastSave = shouldSaveDiff(diff)
        const shouldCodeAppChange = filesDAL.doesGridAppHaveChanges(ps)
        if (!didSnapShotsChangeFromLastSave && shouldCodeAppChange) {
            ps.extensionAPI.logger.interactionEnded('create-revision-no-document-changes')
        }
        return didSnapShotsChangeFromLastSave || shouldCodeAppChange
    }
    return true
}

export interface ServerErrorDetails {
    applicationError?: {
        code: string
        description: string
    }
}

export interface ServerErrorData {
    message: string
    details: ServerErrorDetails
}

const saveAsTemplateAsync = async (
    lastSnapshot: unknown,
    currentSnapshot: unknown,
    bi: BICallbacks,
    options: SaveOptions,
    lastSnapshotDal: SnapshotDal,
    currentSnapshotDal: SnapshotDal,
    extensionsAPI?: ExtensionAPI
) => {
    await sendRequestAsyncWrapped(
        editorServerFacade.ENDPOINTS.SAVE_AS_TEMPLATE,
        null,
        currentSnapshotDal,
        options.editorOrigin,
        extensionsAPI
    )
    return {
        changes: [{path: ['rendererModel', 'siteInfo', 'documentType'], value: 'Template'}]
    }
}

interface PublishWithOverridesOps {
    editorOrigin?: string
    label?: string
    specificPages?: string[]
}

const sendPublishWithOverridesRequest = async (
    currentSnapshotDal: SnapshotDal,
    options: PublishWithOverridesOps,
    extensionsAPI: any
) => {
    const endpoint = editorServerFacade.ENDPOINTS.PUBLISH_WITH_OVERRIDES
    const branchId = extensionsAPI.siteAPI.getBranchId()
    const revision = extensionsAPI.siteAPI.getSiteRevision()
    const {publishedSiteDetails}: any = await sendRestRequestAsyncWrapped(
        editorServerFacade.ENDPOINTS.PUBLISHED_SITE_DETAILS,
        null,
        currentSnapshotDal,
        options.editorOrigin,
        extensionsAPI
    )
    const {branchId: basedOnBranch, siteRevision: basedOnRevision} = publishedSiteDetails.siteProperties
    return sendRestRequestAsyncWrapped(
        endpoint,
        {
            label: options.label ?? 'publish-specific-pages',
            deployment_attributes: [
                {
                    page_attribute: {
                        page_ids: options.specificPages,
                        editor_revision: {branch_id: branchId, site_revision: revision}
                    }
                }
            ],
            specific_version: {
                branch_id: basedOnBranch,
                site_revision: basedOnRevision
            },
            should_publish: true
        },
        currentSnapshotDal,
        options.editorOrigin,
        extensionsAPI
    )
}

const sendPublishTestSiteRequest = async (
    currentSnapshotDal: SnapshotDal,
    options: PublishAllOptions,
    extensionsAPI: any
) => {
    const {viewerName} = options
    const endpoint = editorServerFacade.ENDPOINTS.PUBLISH_TEST_SITE
    const data: any = {viewerName}
    const branchId = extensionsAPI.siteAPI.getBranchId()
    if (branchId) {
        data.branchId = branchId
    }
    if (options.overrideRevisionInfo) {
        data.overrideRevisionInfo = {
            revision: options.overrideRevisionInfo.revision
        }
        if (options.overrideRevisionInfo.branchId) {
            data.overrideRevisionInfo.branchId = options.overrideRevisionInfo.branchId
        }
    }

    return sendRestRequestAsyncWrapped(endpoint, data, currentSnapshotDal, options.editorOrigin, extensionsAPI)
}

const sendPublishRCRequest = async (
    currentSnapshotDal: SnapshotDal,
    options: PublishSiteRCOptions,
    extensionAPI: any
) => {
    const endpoint = editorServerFacade.ENDPOINTS.PUBLISH_RC
    const data: PublishSiteRCRequest = {
        label: RCLabel[options.label] || RCLabel.UNKNOWN,
        ...(options.useUniqueLabel !== undefined && {useUniqueLabel: options.useUniqueLabel})
    }
    const branchId = extensionAPI.siteAPI.getBranchId()
    if (branchId) {
        data.branchId = branchId
    }
    if (options.useUniqueLabel) {
        data.useUniqueLabel = options.useUniqueLabel
    }
    // Using sendRestRequestAsyncWrapped which doesn't expect {success: true} in the response
    // expected server response is PublishSiteRCResponse {revision: number}
    return sendRestRequestAsyncWrapped(endpoint, data, currentSnapshotDal, options.editorOrigin, extensionAPI)
}

const sendPublishRequest = async (
    currentSnapshotDal: SnapshotDal,
    options: PublishAllOptions,
    extensionsAPI: ExtensionAPI
) => {
    if (options.deploymentId) {
        return sendPublishPipelineForDeploymentRequest(currentSnapshotDal, options, extensionsAPI)
    }
    if (options.publishRC) {
        return experiment.isOpen('dm_publishPipelineRcSite')
            ? sendPublishPipelineRequest(currentSnapshotDal, options, extensionsAPI)
            : sendPublishRCRequest(currentSnapshotDal, options, extensionsAPI)
    }
    if (options.specificPages) {
        return sendPublishWithOverridesRequest(currentSnapshotDal, options, extensionsAPI)
    }
    if (options.publishTestSite) {
        return sendPublishTestSiteRequest(currentSnapshotDal, options, extensionsAPI)
    }
    return sendPublishPipelineRequest(currentSnapshotDal, options, extensionsAPI)
}

const sendPublishPipelineRequest = async (
    currentSnapshotDal: SnapshotDal,
    options: PublishAllOptions,
    extensionsAPI: any
) => {
    const endpoint = editorServerFacade.ENDPOINTS.PUBLISH
    const branchId = extensionsAPI.siteAPI.getBranchId()
    const revision = extensionsAPI.siteAPI.getSiteRevision()
    const data: any = {
        label: 'EDITOR_PUBLISH',
        deployment_attributes: [],
        publish_type: options.publishRC ? PublishType.RC : PublishType.SITE,
        specific_version: {
            branch_id: branchId,
            site_revision: revision
        },
        session_info: {
            esi: currentSnapshotDal.getValue({type: 'documentServicesModel', id: 'editorSessionId'}),
            ds_origin: options.editorOrigin
        }
    }

    if (options.publishRC) {
        data.publish_site_rc_method = {rc_label: options.label}
    }
    const task = () =>
        sendRestRequestAsyncWrapped(endpoint, data, currentSnapshotDal, options.editorOrigin, extensionsAPI)

    return retryTaskAndReport({
        task,
        checkIfShouldRetry: (e: any) => {
            const errorCode = String(e?.errorCode)
            if (errorCode === serverSaveErrorCodes.TRANSPORTATION_ERROR) {
                return {shouldRetry: true, reason: serverSaveErrorCodes.TRANSPORTATION_ERROR}
            }
            if (errorCode === serverSaveErrorCodes.HTTP_REQUEST_ERROR) {
                return {shouldRetry: true, reason: serverSaveErrorCodes.HTTP_REQUEST_ERROR}
            }
            return {shouldRetry: false}
        },
        interactionName: 'publish',
        maxRetries: 1,
        logger: contextAdapter.utils.fedopsLogger
    })
}

const handlePublishPipelineResponse = async (
    response: any,
    extensionsAPI: ExtensionAPI,
    options: PublishAllOptions
) => {
    const {id, deploymentPipelinesStatus} = response?.deployment || {}
    const {deploymentId, clickId} = options
    const isPublishAnyway = !!deploymentId
    monitoring.start(monitoring.PUBLISH_PIPELINE, getPublishPipelineReportTags(id, clickId, isPublishAnyway))

    if (deploymentPipelinesStatus === PublishPipelineResponseStatus.ERROR && !isPublishAnyway) {
        const error = new ReportableError({
            errorType: 'unknownPublishPipelineError',
            message: `Publish pipeline failed for deployment ${id}`,
            extras: {originalResponse: response}
        })
        monitoring.error(error)
        throw error
    }

    if (deploymentPipelinesStatus === PublishPipelineResponseStatus.IN_PROGRESS) {
        const {publishPipeline} = extensionsAPI as PublishPipelineExtensionAPI
        await publishPipeline.subscribeToDeployment(id)
    }

    monitoring.end(monitoring.PUBLISH_PIPELINE, getPublishPipelineReportTags(id, clickId, isPublishAnyway))
}

const getPublishReportTags = (options: PublishAllOptions) => {
    const {publishRC, publishTestSite, specificPages} = options
    return {
        publishPipelineRc: experiment.isOpen('dm_publishPipelineRcSite'),
        publishRC,
        publishTestSite,
        specificPages
    }
}

const getPublishPipelineReportTags = (deploymentId: string, clickId: string, publishAnyway: boolean) => {
    return {
        deploymentId,
        clickId,
        publishAnyway
    }
}

const publishAsync = async (
    currentSnapshotDal: SnapshotDal,
    extensionsAPI: ExtensionAPI,
    bi: BICallbacks,
    options: PublishAllOptions = {}
): Promise<{changes: Change[]}> => {
    let response
    setSaving(extensionsAPI, true)
    try {
        monitoring.start(monitoring.PUBLISH, getPublishReportTags(options))
        response = await sendPublishRequest(currentSnapshotDal, options, extensionsAPI)
        await handlePublishPipelineResponse(response, extensionsAPI, options)
        monitoring.end(monitoring.PUBLISH, getPublishReportTags(options))
    } finally {
        setSaving(extensionsAPI, false)
    }

    const resultsInPublishedSite = !options.publishTestSite && !options.publishRC

    const changes: Change[] = resultsInPublishedSite
        ? [
              {
                  path: ['documentServicesModel', 'isPublished'],
                  value: true
              }
          ]
        : []

    const revision =
        _.get(response, 'deployment.editorRevision.siteRevision') ??
        _.get(response, 'revision') ??
        _.get(response, 'payload.revision') ??
        _.get(response, 'revisionInfo.revision')

    if (revision) {
        changes.push({
            path: revisionPath,
            value: revision
        })
    }

    const version = _.get(response, 'payload.version')
    if (version) {
        changes.push({
            path: versionPath,
            value: version
        })
    }
    return {
        changes
    }
}

const sendPublishPipelineForDeploymentRequest = async (
    currentSnapshotDal: SnapshotDal,
    options: PublishAllOptions,
    extensionsAPI: any
) => {
    const endpoint = editorServerFacade.ENDPOINTS.PUBLISH_DEPLOYMENT
    const {deploymentId} = options
    const data: any = {
        deploymentId,
        skip_pipeline_check: true
    }

    return sendRestRequestAsyncWrapped(endpoint, data, currentSnapshotDal, options.editorOrigin, extensionsAPI)
}

export default {
    /** @private */
    paths: {
        versionPath,
        revisionPath,
        previousDiffId: previousDiffIdPath
    },

    csaveCreateRevision,

    /**
     *
     * @param {object} lastSnapshot - the DAL snapshot, since the last save
     * @param {object} currentSnapshot - the DAL snapshot, as it is right now
     * @param {Function} resolve - resolve this task (success).
     * @param {Function} reject - reject this save (fail). Can be called with errorType, errorMessage
     * @param {{error: Function, event: Function}} bi
     */
    partialSave,

    /**
     * @param {object} last - the DAL snapshot, since the last save
     * @param {object} current - the DAL snapshot, since the last save
     * @param {{error: Function, event: Function}} bi
     * @param {object} options
     * @param {object} lastSnapshot - the DAL snapshot, since the last save
     * @param {object} currentSnapshot - the DAL snapshot, as it is right now
     */
    partialSaveAsync,

    /**
     *
     * @param {object} lastSnapshot - the DAL snapshot, since the last save
     * @param {object} currentSnapshot - the DAL snapshot, as it is right now
     * @param {Function} resolve - resolve this task (success).
     * @param {Function} reject - reject this save (fail). Can be called with errorType, errorMessage
     */
    fullSave,

    /**
     * @param {object} lastSnapshot - the DAL snapshot, since the last save
     * @param {object} currentSnapshot - the DAL snapshot, as it is right now
     * @param {Function} resolve - resolve this task (success).
     * @param {Function} reject - reject this save (fail). Can be called with errorType, errorMessage
     */
    fullSaveAsync,

    /**
     *
     * @param {object} lastSnapshot - the DAL snapshot, since the last save
     * @param {object} currentSnapshot - the DAL snapshot, as it is right now
     * @param {Function} resolve - resolve this task (success).
     * @param {Function} reject - reject this validation (fail). Can be called with errorType, errorMessage
     */
    validateSite,

    saveAsTemplate(
        lastSnapshot: unknown,
        currentSnapshot: unknown,
        resolve: Callback1<any>,
        reject: Callback1<any>,
        bi: BICallbacks,
        options: SaveOptions,
        lastSnapshotDal?: SnapshotDal,
        currentSnapshotDal?: SnapshotDal
    ) {
        // eslint-disable-next-line promise/prefer-await-to-then
        saveAsTemplateAsync(lastSnapshot, currentSnapshot, bi, options, lastSnapshotDal, currentSnapshotDal).then(
            resolve,
            reject
        )
    },

    /**
     * @param currentSnapshotDal
     * @param extensionsAPI
     * @param resolve resolve this task (success).
     * @param reject reject this save (fail). Can be called with errorType, errorMessage
     * @param bi
     * @param options
     */
    publish(
        currentSnapshotDal: SnapshotDal,
        extensionsAPI: ExtensionAPI,
        resolve: Callback1<any>,
        reject: Callback1<any>,
        bi: BICallbacks,
        options: PublishAllOptions = {}
    ) {
        publishAsync(currentSnapshotDal, extensionsAPI, bi, options).then(resolve, reject) // eslint-disable-line promise/prefer-await-to-then
    },

    publishAsync,

    getTaskName() {
        return TASK_NAME
    },

    shouldRun,

    getSnapshotTags() {
        return ['primary']
    },

    cleanupDataDeltaContents
}
