import type {
    ComponentStageData,
    MobileAlgoPlugin,
    MobileAlgoPluginInitializationArgs,
    MobileConversionDal,
    PluginContext,
    PluginHeuristicsRegistry
} from '../../types'
import {fallbackWidthHeuristics, widthHeuristics} from './widthHeuristic'
import type {ComponentToMeasurementsWidth, Heuristic, ScalingApiPlugin} from './types'
import {CTX_WIDTH_SCALING_RESULT} from './constatns'

export const createPlugin = ({stages}: MobileAlgoPluginInitializationArgs): MobileAlgoPlugin => {
    const writeScalingToConversionDal = (
        mobileConversionDal: MobileConversionDal,
        componentId: string,
        scaling: {width: number; x: number}
    ) => {
        mobileConversionDal.setConvertedMeasurements(componentId, {
            width: scaling.width as number,
            x: scaling.x as number
        })
    }

    const writeComponentScaling = ({componentId, mobileConversionDal}: ComponentStageData) => {
        const compType = mobileConversionDal.getComponent(componentId).semanticType
        const widthHeuristic: Heuristic =
            widthHeuristics.find(heuristic => heuristic.predicate(compType)) ?? fallbackWidthHeuristics
        const scalingWidthX = widthHeuristic.run(mobileConversionDal, componentId)
        writeScalingToConversionDal(mobileConversionDal, componentId, scalingWidthX)
    }

    const createApi = (context: PluginContext): ScalingApiPlugin => {
        return {
            widthScalingResult: (): ComponentToMeasurementsWidth => {
                return context.get(CTX_WIDTH_SCALING_RESULT)
            }
        }
    }

    const register = (registry: PluginHeuristicsRegistry) => {
        registry.register(stages.SCALE, writeComponentScaling)
    }

    const name = 'horizontalScaleAndSize'
    return {register, name, dependencies: [], createApi}
}
