/* eslint-disable promise/prefer-await-to-then */
import type {
    Callback1,
    CompRef,
    ControllerConnectionItem,
    IConnectionItem,
    Pointer,
    PS
} from '@wix/document-services-types'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import {
    getWidgetStructureByAppData,
    getAppDescriptor as getAppDescriptorBySiteHeaderUrl
} from '@wix/blocks-widget-services/remoteStructureFetcher'
import _ from 'lodash'
import appControllerData from '../appControllerData/appControllerData'
import appControllerDataItem from '../appControllerData/appControllerDataItem'
import component from '../component/component'
import componentCode from '../component/componentCode'
import componentDetectorAPI from '../componentDetectorAPI/componentDetectorAPI'
import constants from '../constants/constants'
import dataModel from '../dataModel/dataModel'
import documentModeInfo from '../documentMode/documentModeInfo'
import features from '../features/features'
import page from '../page/page'
import platformCommonConstants from '../platform/common/constants'
import livePreview from '../platform/livePreview/livePreview'
import refComponent from '../refComponent/refComponent'
import refComponentUtils from '../refComponent/refComponentUtils'
import clientSpecMapService from '../tpa/services/clientSpecMapService'
import dsUtils from '../utils/utils'
import variants from '../variants/variants'
import appStudioWidgetUtils from './appStudioWidgetUtils'
import {WidgetInstallationTypes, ROLE_PATH, LIVE_PREVIEW_REFRESH_SOURCE} from './constants'
import {refStructureUtils, ScopesExtensionAPI} from '@wix/document-manager-extensions'

const {REF_COMPONENT_TYPE} = constants.REF_COMPONENT
const {APP_WIDGET} = platformCommonConstants.CONTROLLER_TYPES

const {getReferredCompId} = displayedOnlyStructureUtil
const {switchAppWidgetStructure} = appStudioWidgetUtils

const OPTIONAL_OVERRIDE_TYPES_TO_KEEP = {
    data: true,
    style: true,
    design: true,
    connections: true
}

const PERMANENT_OVERRIDE_TYPES_TO_KEEP = {
    connections: true
}

async function changeVariationOpen(ps: PS, widgetRef: CompRef, newComponentRef: CompRef, variationId: string) {
    const {applicationId} = component.data.get(ps, widgetRef)
    const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, applicationId)
    const widgetId = appControllerData.getSettingsIn(ps, widgetRef, 'type')

    const widgetStructure = await getWidgetStructureByAppData(appData, widgetId, variationId)
    switchAppWidgetStructure(ps, widgetRef, newComponentRef, widgetStructure)
    return newComponentRef
}

function changeVariationForAppWidget(
    ps: PS,
    widgetRef: CompRef,
    variationId: string,
    onSuccess: Callback1<any>,
    onError: Callback1<any>
) {
    const newCompRef = refComponent.getComponentToCreateRef(ps, widgetRef)
    if (refComponent.isReferredComponent(ps, widgetRef)) {
        changeVariationClosed(ps, newCompRef as CompRef, variationId, undefined, undefined, onSuccess)
    } else {
        changeVariationOpen(ps, widgetRef, newCompRef, variationId).then(onSuccess, onError)
    }
}

const isComponentRefNotInflatedInternalRef = (ps: PS, componentRef: CompRef) =>
    refComponentUtils.isInternalRef(ps, componentRef) && !refComponentUtils.isRefComponentInflated(ps, componentRef)

const isGhostCompOverride = ({itemType, dataItem}) => itemType === 'props' && !!dataItem.ghost

function changeVariationClosed(
    ps: PS,
    componentRef: CompRef,
    variationId: string,
    customOverrides,
    keepOverrides = true,
    onSuccess?: Callback1<any>
) {
    const overridesWithPrimaryRole = customOverrides || getOverridesWithPrimaryRole(ps, componentRef)
    const filteredOverridesWithPrimaryRole = filterAllowedOverrides(overridesWithPrimaryRole, keepOverrides)

    refComponentUtils.removeConnectionOverride(ps, componentRef)

    if (!customOverrides || !keepOverrides) {
        refComponentUtils.removeOverrides(ps, componentRef, {exclusions: {[constants.DATA_TYPES.connections]: '*'}})
    }

    refComponentUtils.updateVariation(ps, componentRef, variationId)

    ps.setOperationsQueue.waitForChangesApplied(() => {
        const newVariationCompsWithPrimaryRole = getCompsWithPrimaryRole(ps, componentRef)
        copyOverridesByRole(ps, filteredOverridesWithPrimaryRole, componentRef, newVariationCompsWithPrimaryRole)
        onSuccess(componentRef)
    })
}

function filterAllowedOverrides(overrides, keepOverrides: boolean) {
    return _.filter(overrides, override => {
        if (keepOverrides) {
            return OPTIONAL_OVERRIDE_TYPES_TO_KEEP[override.itemType] || isGhostCompOverride(override)
        }
        return PERMANENT_OVERRIDE_TYPES_TO_KEEP[override.itemType]
    })
}

function getComponentUnderAncestor(ps: PS, componentRef: Pointer) {
    if (refComponentUtils.isRefComponentInflated(ps, componentRef as CompRef)) {
        return componentDetectorAPI.getComponentsUnderAncestor(ps, componentRef)
    }
    return refComponent.getComponentsUnderNotInflatedRefComponentWithInflatedIDs(ps, componentRef)
}

const getComponentId = inflatedCompRef => _.defaults({id: _.last(inflatedCompRef.id.split('_r_'))}, inflatedCompRef)

function getCompsWithPrimaryRole(ps: PS, componentRef: Pointer) {
    const comps = getComponentUnderAncestor(ps, componentRef)
    const isNotInflatedInternalRef = isComponentRefNotInflatedInternalRef(ps, componentRef as CompRef)
    return _.map(comps, compRef => {
        const rolePath = createRolePath(ps, compRef, isNotInflatedInternalRef)
        return _.merge({rolePath}, compRef)
    })
}

/**
 * Copy overrides to new comps
 * @param ps
 * @param overrides - overrides to copy, should contain comp's primary role
 * @param refComp - ref compRef for the override creation
 * @param internalComps - ref comp's internal components
 */
function copyOverridesByRole(ps: PS, overrides, refComp: Pointer, internalComps) {
    const pageId = ps.pointers.full.components.getPageOfComponent(refComp).id
    _.forEach(overrides, ({itemType, dataItem, rolePath, isMobile}) => {
        let compId = _.get(_.find(internalComps, {rolePath}), 'id')
        if (compId) {
            compId = getReferredCompId(compId)
            refComponentUtils.createOverrideDataItem(ps, itemType, refComp, compId, pageId, dataItem, isMobile)
        }
    })
}

function changeVariation(
    ps: PS,
    componentRef: CompRef,
    variationId: string,
    onSuccess = _.noop,
    onError = _.noop,
    options: any = {}
) {
    const {customOverrides, keepOverrides} = options
    if (component.getType(ps, componentRef) === APP_WIDGET) {
        changeVariationForAppWidget(ps, componentRef, variationId, onSuccess, onError)
    } else if (component.getType(ps, componentRef) === REF_COMPONENT_TYPE) {
        changeVariationClosed(ps, componentRef, variationId, customOverrides, keepOverrides, onSuccess)
    } else {
        throw new Error('Change variation is not available for this component')
    }
}

// It's a very temporary solution. This will probably be checked by a metadata in the future
function arePresetsSupportedForComp(ps: PS, componentRef: CompRef) {
    const compType = component.getType(ps, componentRef)
    return compType?.startsWith('platform.builder') || compType === REF_COMPONENT_TYPE
}

function changePreset(ps: PS, componentRef: Pointer, stylePresetId: string, layoutPresetId: string) {
    const presetsSupportedForCompType = arePresetsSupportedForComp(ps, componentRef as CompRef)

    if (!presetsSupportedForCompType) {
        throw new Error('Change preset is available only for ref components')
    }

    features.updateFeatureData(ps, componentRef, constants.DATA_TYPES.presets, {
        type: constants.PRESETS.PRESET_DATA_TYPE,
        ...(stylePresetId ? {style: dsUtils.stripHashIfExists(stylePresetId)} : {}),
        ...(layoutPresetId ? {layout: dsUtils.stripHashIfExists(layoutPresetId)} : {})
    })
}

function getPresetOwner(ps: PS, componentRef: CompRef) {
    const componentType = component.getType(ps, componentRef)
    if (componentType === APP_WIDGET) {
        const {scopes} = ps.extensionAPI as ScopesExtensionAPI
        const scopePointer = scopes.extractScopeFromPointer(componentRef)
        let presetOwnerRef = scopes.getScopeOwner(scopePointer, componentRef.type)
        if (componentRef.variants) {
            presetOwnerRef = variants.getPointerWithVariants(ps, presetOwnerRef, componentRef.variants)
        }
        return presetOwnerRef
    }
    return componentRef
}

function getPreset(ps: PS, componentRef: CompRef) {
    const presetOwnerRef = getPresetOwner(ps, componentRef)
    if (arePresetsSupportedForComp(ps, presetOwnerRef)) {
        return features.getFeatureData(ps, presetOwnerRef, constants.DATA_TYPES.presets)
    }

    throw new Error('Get preset is available only for blocks apps - ref components or app widget or builder')
}

function getDefaultWidgetX(ps: PS, width: number) {
    const pageWidth = ps.siteAPI.getSiteWidth()
    return (pageWidth - width) / 2
}

function getWidgetLayout(ps: PS, structureLayout, layoutOverrides: any = {}) {
    const defaultLayout = {
        x: getDefaultWidgetX(ps, layoutOverrides.width || structureLayout.width)
    }
    return _.defaultsDeep(layoutOverrides, defaultLayout)
}

const isResponsiveBlocksVersion = (blocksVersion: string) => Boolean(blocksVersion?.startsWith('2.'))

const validatePresetsOrThrow = (ps: PS, appDefinitionId: string, widgetId: string, presets) => {
    const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
    const widgetData = appData?.components?.find?.(comp => comp.componentId === widgetId)

    if (isResponsiveBlocksVersion(widgetData?.data?.blocksVersion)) {
        const hasPresets = presets?.style && presets?.layout
        if (!hasPresets) {
            throw new Error(`WidgetId ${widgetId} should have style and layout presets`)
        }
    }
}

function buildRefWidgetStructure(ps: PS, appDefinitionId: string, widgetId: string, options) {
    const {
        variationPageId,
        presets,
        scopedPresets,
        layout,
        layouts,
        overriddenData,
        mobileHints,
        mobileStructure,
        props
    } = options
    validatePresetsOrThrow(ps, appDefinitionId, widgetId, presets)

    return refComponent.generateRefComponentStructure(appDefinitionId, widgetId, variationPageId, {
        overriddenData,
        layout,
        layouts,
        presets,
        scopedPresets,
        mobileHints,
        mobileStructure,
        props
    })
}

function addWidgetClosed(
    ps: PS,
    componentToAddRef: CompRef,
    appDefinitionId: string,
    widgetId: string,
    options: any = {}
) {
    const {containerRef = page.getPage(ps, ps.siteAPI.getFocusedRootId()), onSuccess = _.noop} = options
    const widgetRefStructure = buildRefWidgetStructure(ps, appDefinitionId, widgetId, options)
    component.add(ps, componentToAddRef, containerRef, widgetRefStructure)
    onSuccess(componentToAddRef)
}

function addWidgetOpen(ps: PS, componentToAddRef: CompRef, appData, widgetId: string, options: any = {}) {
    const {
        variationPageId,
        layout,
        containerRef = page.getPage(ps, ps.siteAPI.getFocusedRootId()),
        onSuccess = _.noop,
        onError = _.noop
    } = options

    const widgetPageId = _.get(appData, ['widgets', widgetId, 'componentFields', 'appStudioFields', 'id'])

    getWidgetStructureByAppData(appData, widgetPageId, variationPageId)
        .then(widgetStructure => {
            const updatedLayout = getWidgetLayout(ps, widgetStructure.layout, layout)
            const updatedWidgetStructure = _.assign({}, widgetStructure, {layout: updatedLayout})
            component.add(ps, componentToAddRef, containerRef, updatedWidgetStructure)

            onSuccess(componentToAddRef)
        })
        .catch(onError)
}

function getComponentToAddRef(ps: PS, appDefinitionId: string, widgetId: string, options: any = {}) {
    const {containerRef = page.getPage(ps, ps.siteAPI.getFocusedRootId())} = options
    return component.getComponentToAddRef(ps, containerRef)
}

function addWidget(ps: PS, componentToAddRef: CompRef, appDefinitionId: string, widgetId: string, options: any = {}) {
    const {installationType = WidgetInstallationTypes.CLOSED} = options

    const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
    const widgetData = _.get(appData, ['widgets', widgetId])
    if (_.isNil(widgetData)) {
        throw new Error(`WidgetId '${widgetId}' does not exist in app '${appDefinitionId}'`)
    }

    if (installationType === WidgetInstallationTypes.CLOSED) {
        addWidgetClosed(ps, componentToAddRef, appDefinitionId, widgetId, options)
    } else if (installationType === WidgetInstallationTypes.OPEN) {
        addWidgetOpen(ps, componentToAddRef, appData, widgetId, options)
    } else {
        throw new Error('Unsupported installationType')
    }
}

const getCompNickname = (ps: PS, compRef: CompRef) => {
    if (!component.isExist(ps, compRef)) {
        return
    }

    let refComp = refComponent.getRefHostCompPointer(ps, compRef)

    if (component.getType(ps, compRef) === APP_WIDGET) {
        refComp = refComponent.getRefHostCompPointer(ps, refComp)
        if (!refComp) {
            return ROLE_PATH.ROOT
        }
    }

    const ghostCompsRolesMap = _.mapValues(refComponent.getAllGhostRefComponentsPrimaryConnection(ps, refComp), 'role')
    const [upperAppWidgetRef] = ps.pointers.components.getChildren(refComp)
    return componentCode.getNickname(ps, compRef, upperAppWidgetRef) || ghostCompsRolesMap[compRef.id]
}

const getContext = (ps: PS, compRef: Pointer): CompRef => {
    let outerCompRef = refComponent.getRefHostCompPointer(ps, compRef)
    if (component.getType(ps, compRef) === APP_WIDGET) {
        outerCompRef = refComponent.getRefHostCompPointer(ps, outerCompRef)
    }

    const [appWidgetContainer] = ps.pointers.components.getChildren(outerCompRef)

    return appWidgetContainer
}

const getContextNotRenderedRefComponent = (ps: PS, compRef: Pointer): CompRef => {
    let outerCompRef = refComponent.getRefHostCompPointer(ps, compRef)
    const appWidgetId = dataModel.getDataItem(ps, getComponentId(outerCompRef)).rootCompId
    const masterCompPtr = {id: appWidgetId, type: documentModeInfo.getViewMode(ps)}
    const currentComp = getComponentId(compRef)

    if (component.getType(ps, currentComp) === APP_WIDGET) {
        outerCompRef = refComponent.getRefHostCompPointer(ps, outerCompRef)
        if (!outerCompRef) {
            return
        }
    }

    return refComponent.getUniqueRefCompPointer(ps, outerCompRef, masterCompPtr)
}

const createRolePath = (ps: PS, compRef: CompRef, isNotInflatedInternalRef: boolean) => {
    let getCompNicknameFunc = getCompNickname
    let getContextFunc = getContext

    if (isNotInflatedInternalRef) {
        getCompNicknameFunc = getCompNicknameNotRenderedRefComponent
        getContextFunc = getContextNotRenderedRefComponent
    }

    let path: string
    let curNickname: string
    let currentCompRef = compRef
    while (currentCompRef && (curNickname = getCompNicknameFunc(ps, currentCompRef))) {
        const isRepeatedComponent = displayedOnlyStructureUtil.isRepeatedComponent(currentCompRef.id)
        const itemId = isRepeatedComponent ? displayedOnlyStructureUtil.getRepeaterItemId(currentCompRef.id) : ''

        path =
            curNickname +
            (itemId ? ROLE_PATH.REPEATED_COMP_DELIMITER + itemId : '') +
            (path ? ROLE_PATH.DELIMITER + path : '')
        currentCompRef = getContextFunc(ps, currentCompRef)
    }

    return path
}

const isRootAppWidget = (ps: PS, appWidgetRef: Pointer) => {
    const refComp = refComponent.getRefHostCompPointer(ps, appWidgetRef)
    return !refComponent.getRefHostCompPointer(ps, refComp)
}

const getNicknameOfAppWidget = (ps: PS, appWidget: Pointer) => {
    if (isRootAppWidget(ps, appWidget)) {
        return ROLE_PATH.ROOT
    }
    const refComp = refComponent.getRefHostCompPointer(ps, appWidget)
    const refCompOnly = getComponentId(refComp)
    const allOverrides = refComponentUtils.getOverriddenData(ps, refCompOnly)

    const connectionItemOverride = _(allOverrides).find({itemType: 'connections'})

    const item = _.find(connectionItemOverride.dataItem.items, {isPrimary: true})

    return item.role
}

const getCompNicknameNotRenderedRefComponent = (ps: PS, compRef: Pointer) => {
    const compPointer = getComponentId(compRef)

    if (!component.isExist(ps, compPointer)) {
        return
    }

    if (component.getType(ps, compPointer) === APP_WIDGET) {
        return getNicknameOfAppWidget(ps, compRef)
    }

    return componentCode.getNickname(ps, compPointer)
}

const getOverridesWithPrimaryRole = (ps: PS, refComponentPtr: Pointer) => {
    const overriddenData = refComponentUtils.getOverriddenData(ps, refComponentPtr)

    return _(overriddenData)
        .map(override => {
            const masterCompPtr = {id: override.compId, type: documentModeInfo.getViewMode(ps)}
            const inflatedCompRef = refComponent.getUniqueRefCompPointer(ps, refComponentPtr, masterCompPtr)

            const rolePath = createRolePath(
                ps,
                inflatedCompRef,
                isComponentRefNotInflatedInternalRef(ps, refComponentPtr as CompRef)
            )

            return rolePath ? _.merge({rolePath}, override) : undefined
        })
        .compact()
        .value()
}

function groupByVariant(arrOfOverrrides) {
    return _(arrOfOverrrides)
        .flatMap(override => {
            if (override.variants) {
                return override.variants.map((variant: string) => ({key: _.trimStart(variant, '#'), override}))
            }
            return {key: 'default', override}
        })
        .reduce((result, item) => {
            ;(result[item.key] || (result[item.key] = [])).push(item.override)
            return result
        }, {})
}

const getStyleOverridesGroupedByVariant = (ps: PS, refComp: Pointer) => {
    if (component.getType(ps, refComp) !== REF_COMPONENT_TYPE) {
        return []
    }

    const overrides = ps.pointers.referredStructure.getAllOverrides(refComp)
    return _(overrides)
        .filter((pointer: Pointer) => pointer.type === 'style')
        .map((pointer: Pointer) => {
            const overridePointer = ps.pointers.referredStructure.getPointerWithoutFallbacks(pointer)
            const compId = refStructureUtils.extractBaseComponentId(overridePointer)
            const dataItem = dataModel.getDataByPointer(ps, overridePointer.type, overridePointer, true)

            return {
                overridesByVariant: groupByVariant(dataItem.values),
                compRef: componentDetectorAPI.getComponentById(ps, compId)
            }
        })
        .value()
}

/**
 * Get ref primary connection items from overrides
 * @param ps
 * @param compRef - comp of type wysiwyg.viewer.components.RefComponent
 * @return connectionItems
 */
function getPrimaryConnectionItems(ps: PS, compRef: Pointer) {
    if (component.getType(ps, compRef) !== REF_COMPONENT_TYPE) {
        return []
    }
    const [refConnectionItemPointer] = ps.pointers.referredStructure
        .getAllOverrides(compRef)
        .filter(item => item.type === 'connections')
    const connectionPointerNoFallbacks =
        ps.pointers.referredStructure.getPointerWithoutFallbacks(refConnectionItemPointer)
    const refConnectionItems: IConnectionItem[] = _.get(
        dataModel.getDataByPointer(ps, 'connections', connectionPointerNoFallbacks),
        'items',
        []
    )
    return refConnectionItems.filter(
        item => item.type === 'ConnectionItem' && (item as ControllerConnectionItem).isPrimary
    )
}

/**
 * Returns stageData of the ref direct appWidget child
 * @param ps
 * @param compRef - comp ref of type wysiwyg.viewer.components.RefComponent
 * @return stageData from manifest
 */
function getStageData(ps: PS, compRef: Pointer) {
    const [connectionItem] = getPrimaryConnectionItems(ps, compRef)
    const role = _.get(connectionItem, 'role')
    const controllerRef = appControllerData.getControllerRefByConnectionItem(ps, connectionItem, compRef)
    return appControllerData.getControllerRoleStageDataByControllerRefAndRole(ps, controllerRef, role)
}

/**
 * Get the remote structure of an app builder widget
 * @param ps
 * @param appDefinitionId
 * @param widgetId the widget id in dev center
 * @returns A promise which resolves to the widget structure
 */
function getRemoteWidgetStructure(ps: PS, appDefinitionId: string, widgetId: string) {
    const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)

    if (!appData) {
        return Promise.reject(`App with id "${appDefinitionId}" not found`)
    }

    const widgetPageId = _.get(appData, ['widgets', widgetId, 'componentFields', 'appStudioFields', 'id'])

    if (!widgetPageId) {
        return Promise.reject(`widget with id "${widgetId}" not found for app with id "${appDefinitionId}"`)
    }

    return getWidgetStructureByAppData(appData, widgetPageId)
}

/**
 * Get the remote app descriptor of an app builder application
 * @param ps
 * @param {string} appDefinitionId
 * @returns A promise which resolves to the app descriptor
 */
function getAppDescriptor(ps: PS, appDefinitionId: string): Promise<any> {
    const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)

    if (!appData) {
        return Promise.reject(`App with id "${appDefinitionId}" not found`)
    }

    const siteHeaderUrl: string | undefined = _.get(appData, 'appFields.platform.studio.siteHeaderUrl')

    if (!siteHeaderUrl) {
        return Promise.reject(`${appDefinitionId} - not a valid blocks app`)
    }

    return getAppDescriptorBySiteHeaderUrl(siteHeaderUrl)
}

const getWidgetAppDefinitionId = (ps: PS, widgetRef: Pointer) => {
    const {applicationId: appDefinitionId} = appControllerDataItem.getControllerDataItem(ps, widgetRef)
    return appDefinitionId
}

const getProps = (ps: PS, widgetRef: Pointer) => appControllerData.getSettingsIn(ps, widgetRef, 'props') ?? {}

const getPropsToSet = (ps: PS, widgetRef: Pointer, newProps) => {
    if (!_.isNil(newProps)) {
        const oldProps = getProps(ps, widgetRef)
        return {
            ...oldProps,
            ...newProps
        }
    }

    return null
}

const setProps = (ps: PS, widgetRef: Pointer, newProps?, options: {shouldFetchData?: boolean} = {}) => {
    const {shouldFetchData = false} = options

    const newPropsToSet = getPropsToSet(ps, widgetRef, newProps)
    appControllerData.setSettingsIn(ps, widgetRef, 'props', newPropsToSet)

    livePreview.debouncedRefresh(ps, {
        apps: [getWidgetAppDefinitionId(ps, widgetRef)],
        source: LIVE_PREVIEW_REFRESH_SOURCE,
        shouldFetchData
    })
}

function setInitialAppWidgetData(ps: PS, widgetRef: Pointer, controllerType: string) {
    const settings = {
        type: controllerType,
        behaviors: []
    }
    const data = {
        controllerType,
        settings: JSON.stringify(settings)
    }
    component.data.update(ps, widgetRef, data, true)
}

export default {
    getStyleOverridesGroupedByVariant,
    getPrimaryConnectionItems,
    getStageData,
    changePreset,
    getPreset,
    changeVariation,
    addWidget,
    buildRefWidgetStructure,
    getRemoteWidgetStructure,
    getAppDescriptor,
    getOverridesWithPrimaryRole,
    getComponentToAddRef,
    setInitialAppWidgetData,
    props: {
        get: getProps,
        set: setProps
    }
}
