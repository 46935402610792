import type {CreateExtArgs, CreateExtensionArgument, Extension, ExtensionAPI} from '@wix/document-manager-core'
import _ from 'lodash'

import type {DataModelExtensionAPI} from './dataModel/dataModel'
import type {CompRef, ControllerConnectionItem, IConnectionItem} from '@wix/document-services-types'
import {DATA_TYPES} from '../constants/constants'
import {getWixCodeConnectionItem} from '../utils/nicknamesUtils'
import type {NicknameContextExtensionAPI} from './nicknameContext'
import type {NicknamesExtensionAPI} from './nicknames'

export interface ComponentCodeAPI {
    updateConnectionItemsNickname(
        connectionItems: IConnectionItem[],
        compPointer: CompRef,
        pagePointer: CompRef,
        contextInCurrentContainer: CompRef
    ): void
}

export type ComponentCodeExtensionAPI = ExtensionAPI & {
    componentCode: ComponentCodeAPI
}

const createExtension = ({}: CreateExtensionArgument): Extension => {
    const createExtensionAPI = (createExtArgs: CreateExtArgs): ComponentCodeExtensionAPI => {
        const findSerializedConnectionByContext = (
            context: CompRef | null,
            compConnections: IConnectionItem[]
        ): IConnectionItem => {
            const {extensionAPI} = createExtArgs
            const {dataModel} = extensionAPI as DataModelExtensionAPI
            if (context) {
                const {id: controllerId} = dataModel.components.getItem(context, DATA_TYPES.data) || {}
                if (controllerId) {
                    return _.find(compConnections, {controllerId}) as IConnectionItem
                }
            }
            return getWixCodeConnectionItem(compConnections) as IConnectionItem
        }

        const getContextControllerId = (context: CompRef | null) => {
            const {extensionAPI} = createExtArgs
            const {dataModel} = extensionAPI as DataModelExtensionAPI
            if (!context) {
                return
            }
            const dataItem = dataModel.components.getItem(context, DATA_TYPES.data)
            return _.get(dataItem, ['id'])
        }

        const removeConnectionFromSerializedComponentIfInvalidNickname = (
            compPointer: CompRef,
            context: null | CompRef,
            connectionItems: IConnectionItem[],
            pagePointer: CompRef
        ) => {
            const nicknameConnectionItem = findSerializedConnectionByContext(context, connectionItems)
            const {extensionAPI} = createExtArgs
            const {nicknames} = extensionAPI as NicknamesExtensionAPI
            if (
                nicknameConnectionItem &&
                nicknames.hasComponentWithThatNickname(
                    pagePointer,
                    (nicknameConnectionItem as ControllerConnectionItem).role,
                    compPointer
                )
            ) {
                _.remove(connectionItems, nicknameConnectionItem)
            }
        }

        const updateConnectionItemsNickname = (
            connectionItems: IConnectionItem[],
            compPointer: CompRef,
            pagePointer: CompRef,
            contextInCurrentContainer: CompRef
        ) => {
            const {extensionAPI} = createExtArgs
            const {nicknameContext} = extensionAPI as NicknameContextExtensionAPI
            const connectionItem = findSerializedConnectionByContext(contextInCurrentContainer, connectionItems)
            if (connectionItem) {
                const context = nicknameContext.getNicknameContext(pagePointer)
                ;(connectionItem as ControllerConnectionItem).controllerId = getContextControllerId(context)
                removeConnectionFromSerializedComponentIfInvalidNickname(
                    compPointer,
                    context,
                    connectionItems,
                    pagePointer
                )
            }
        }
        return {
            componentCode: {
                updateConnectionItemsNickname
            }
        }
    }
    return {
        name: 'componentCode',
        dependencies: new Set(['dataModel', 'components']),
        createExtensionAPI
    }
}

export {createExtension}
