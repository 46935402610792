import {
    CreateExtArgs,
    CreateExtensionArgument,
    DAL,
    Extension,
    ExtensionAPI,
    pointerUtils
} from '@wix/document-manager-core'
import {schemas} from '@wix/document-services-json-schemas'
import type {
    CompRef,
    CompStructure,
    EditorClientSpecMapEntry,
    ExternalComponentsReadOnlyDriver,
    Pointer,
    UnifiedWidget
} from '@wix/document-services-types'
import _ from 'lodash'
import {
    ALLOWED_MOBILE_COMPONENTS,
    DEAD_MOBILE_COMPONENT_TYPE,
    EXTERNAL_META_DATA_ALLOWED_TYPES,
    METADATA_TYPES,
    VIEW_MODES
} from '../../constants/constants'
import {getComponentIncludingDisplayOnly, getComponentType} from '../../utils/dalUtils'
import {DEFAULTS} from './defaults'
import {MetadataMap, metadataMap as originalMetadataMap} from './metadataMap'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import type {RMApi} from '../rendererModel'
import {
    createExternalCompDriver,
    filterAndFlagExternalMetadata,
    informIllegalMetadataTypes,
    informUnableToRegisterAlreadyRegisteredComponent,
    isExternalMetaData,
    isExternalMetaDataField
} from './externalMetadata'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {HooksExtensionApi} from '../hooks/hooks'
import {METADATA_HOOKS} from './metadataHooks'
import {
    areChildAndParentTypesMatching,
    isComponentContainableRecursively,
    isContainableByStructure
} from './utils/componentUtils'
import {isRefPointer} from '../../utils/refStructureUtils'

const CONTAINER_TYPES = _.keyBy(['Container', 'RefComponent', 'Page'])

export interface ComponentsMetadataExtension extends ExtensionAPI {
    canConnectToCode(componentPointer: Pointer): boolean
    isContainer(componentType: string): boolean
    sticksToBottom(componentType: string): boolean
    getDefaultNickname(componentPointer: Pointer): string
    isMobileOnly(componentPointer: Pointer): boolean
    shouldAutoSetNickname(componentPointer: Pointer): boolean
    isNativeMobileOnlyByPointer(componentPointer: Pointer): boolean
    isMobileComponentPropertiesSplit(componentPointer: Pointer): boolean
    isCompTypeRepeatable(componentType: string): boolean
    isCompRepeatable(componentPointer: Pointer): boolean
    isContainCheckRecursive(componentPointer: Pointer): boolean
    isEnforcingContainerChildLimitationsByWidth(componentPointer: Pointer): boolean
    isEnforcingContainerChildLimitationsByHeight(componentPointer: Pointer): boolean
    getMetadataValue(componentPointer: Pointer, metadataKey: string, ...additionalArgs: any[]): any
    getMetadataValueByType(componentType: string, metadataKey: string, additionalArgs?: any[]): any
    getRawMetadataValueByType(componentType: string, metadataKey: string): any
    hasExternalMetadataByPointer(componentPointer: Pointer): boolean
    hasExternalMetadataByType(componentType: string): boolean
    canContain(componentPointer: Pointer, potentialChildPointer: CompRef, targetedContainerPointer: Pointer): boolean
    isContainerByPointer(componentPointer: Pointer): boolean
    isAlwaysContainRecursively(componentPointer: Pointer): boolean
    isPublicContainer(componentPointer: Pointer): boolean
    isFullWidthByStructure(compStructure: CompStructure): boolean
    isFullWidth(componentPointer: Pointer): boolean
    isParentTypeAllowed(
        compStructureOrPointer: CompStructure | Pointer,
        potentialContainerType: string,
        isByStructure?: boolean
    ): boolean
    isRepeater(componentPointer: Pointer): boolean
    allowedToContainMoreChildren(componentPointer: Pointer): boolean
    isChildTypeAllowed(potentialContainerPointer: Pointer, childComponentType: string): boolean
    isContainableByStructure(
        componentStructure: CompStructure,
        potentialContainerPointer: Pointer,
        isMobileView?: boolean
    ): boolean
    isContainableByStructurePublic(compStructure: CompStructure, potentialContainerPointer: Pointer): boolean
    registrar: {
        registerComponentMetadata(componentType: string, metadata: any): void
        unregisterComponentMetadata(componentType: string): void
    }
}

export interface ComponentsMetadataAPI extends ExtensionAPI {
    componentsMetadata: ComponentsMetadataExtension
}

const getCompOrDefaultMetaData = (metadataMap: MetadataMap, compType: string, metadataKey: string) => {
    const metadataValue = _.get(metadataMap, [compType, metadataKey])
    return _.isUndefined(metadataValue) ? DEFAULTS[metadataKey] : metadataValue
}

const evaluateMetaData = (
    createExtArgs: CreateExtArgs,
    metadataMap: MetadataMap,
    compType: string,
    metaDataKey: string,
    componentPointer?: Pointer,
    additionalArguments: any[] = []
) => {
    let metaDataValue = getCompOrDefaultMetaData(metadataMap, compType, metaDataKey)
    const {hooks} = createExtArgs.extensionAPI as HooksExtensionApi

    if (_.isFunction(metaDataValue)) {
        let metadataApi: CreateExtArgs | ExternalComponentsReadOnlyDriver | null = createExtArgs
        if (isExternalMetaDataField(metadataMap, compType, metaDataKey)) {
            metadataApi = componentPointer ? createExternalCompDriver(createExtArgs, componentPointer) : null
        }
        const argsForMetaData = [metadataApi].concat(additionalArguments)
        metaDataValue = metaDataValue.apply(this, argsForMetaData)
    }

    const metadataHookDefinition = METADATA_HOOKS[metaDataKey]
    if (metadataHookDefinition) {
        return hooks.executeHookAndUpdateValue(
            metadataHookDefinition.createEvent({additionalArguments, componentPointer}),
            metaDataValue,
            compType
        )
    }
    return metaDataValue
}

const createExtension = (createExtensionArgument?: CreateExtensionArgument): Extension => {
    const metadataMap = deepClone(originalMetadataMap)

    const getMetaData = (
        createExtArgs: CreateExtArgs,
        metaDataKey: string,
        componentPointer: Pointer,
        ...additionalArguments: any[]
    ) => {
        const {dal} = createExtArgs
        const compType = getComponentType(dal, componentPointer as CompRef)
        const args = [componentPointer, ...additionalArguments] // remove metaDataKey from arguments, leave additional trailing optional params]

        return evaluateMetaData(createExtArgs, metadataMap, compType, metaDataKey, componentPointer, args)
    }

    const canConnectToCode = (createExtArgs: CreateExtArgs, componentPointer: Pointer): boolean => {
        return getMetaData(createExtArgs, METADATA_TYPES.CAN_CONNECT_TO_CODE, componentPointer, createExtensionArgument)
    }

    const shouldAutoSetNickname = (dal: DAL, componentPointer: Pointer): boolean => {
        const {componentType} = getComponentIncludingDisplayOnly(dal, componentPointer)
        return getCompOrDefaultMetaData(metadataMap, componentType, METADATA_TYPES.SHOULD_AUTO_SET_NICKNAME)
    }

    const isNativeMobileOnlyByPointer = (dal: DAL, componentPointer: Pointer): boolean => {
        const {componentType} = getComponentIncludingDisplayOnly(dal, componentPointer)
        return !!getCompOrDefaultMetaData(metadataMap, componentType, METADATA_TYPES.MOBILE_ONLY)
    }

    const isMobileOnly = (createExtArgs: CreateExtArgs, componentPointer: Pointer) => {
        const {dal, pointers} = createExtArgs
        componentPointer = pointerUtils.getRepeatedItemPointerIfNeeded(componentPointer)
        if (!componentPointer || componentPointer.type !== VIEW_MODES.MOBILE) {
            return false
        }

        const mobileOnlyTypes = _.keys(ALLOWED_MOBILE_COMPONENTS)
        const compType = _.get(dal.get(componentPointer), ['componentType'])
        if (_.includes(mobileOnlyTypes, compType)) {
            return isNativeMobileOnlyByPointer(dal, componentPointer)
        }
        const desktopPointer = pointers.structure.getDesktopPointer(componentPointer)
        const existOnlyInMobile = dal.has(componentPointer) && !dal.has(desktopPointer)
        if (!existOnlyInMobile) {
            return false
        }
        const isPlaceholder = compType === DEAD_MOBILE_COMPONENT_TYPE
        if (isPlaceholder) {
            return false
        }
        return true
    }

    const isContainer = (componentType: string) => {
        return CONTAINER_TYPES.hasOwnProperty(schemas.default.allComponentsDefinitionsMap[componentType]?.type)
    }

    const sticksToBottom = (componentType: string) => componentType === 'responsive.components.FooterSection'

    const getUnifiedComponentNickname = (
        extensionAPI: DataModelExtensionAPI,
        componentPointer: Pointer
    ): string | undefined => {
        const {rendererModel, dataModel} = extensionAPI as RMApi & DataModelExtensionAPI
        const clientSpecMap = rendererModel.getClientSpecMap()
        const {appDefinitionId, widgetId} = dataModel.components.getItem(componentPointer, 'data') ?? {}

        if (appDefinitionId && widgetId) {
            const appData = Object.values(clientSpecMap ?? {}).find(
                app => (app as EditorClientSpecMapEntry).appDefinitionId === appDefinitionId
            ) as EditorClientSpecMapEntry

            if (appData) {
                const compData = appData.components?.find(({componentId}: any) => componentId === widgetId)
                    ?.data as UnifiedWidget

                if (compData) {
                    const unifiedComponentName = compData.base?.name?.replace?.(/[^\w\s]/g, '')
                    return unifiedComponentName
                }
            }
        }
    }

    const getDefaultNickname = (createExtArgs: CreateExtArgs, componentPointer: Pointer) => {
        const {dal, extensionAPI} = createExtArgs
        let nickname = getUnifiedComponentNickname(extensionAPI as DataModelExtensionAPI, componentPointer)

        if (!nickname) {
            const shouldAddPrefix =
                isMobileOnly(createExtArgs, componentPointer) && !isNativeMobileOnlyByPointer(dal, componentPointer)
            const prefix = shouldAddPrefix ? 'mobile ' : ''
            const defaultNickname = getMetaData(createExtArgs, METADATA_TYPES.NICKNAME, componentPointer)
            nickname = `${prefix}${defaultNickname}`
        }

        return _.camelCase(nickname)
    }

    const createExtensionAPI = (createExtArgs: CreateExtArgs): ComponentsMetadataAPI => {
        const {dal, pointers, extensionAPI} = createExtArgs

        const isMobileComponentPropertiesSplit = (compPointer: Pointer) => {
            if (isMobileOnly(createExtArgs, compPointer)) {
                return true
            }

            const {dataModel} = extensionAPI as DataModelExtensionAPI
            const mobileDataItemId = dataModel.components.getComponentDataItemId(
                pointers.structure.getMobilePointer(compPointer),
                'props'
            )
            const desktopDataItemId = dataModel.components.getComponentDataItemId(
                pointers.structure.getDesktopPointer(compPointer),
                'props'
            )

            return mobileDataItemId !== desktopDataItemId
        }

        const isCompTypeRepeatable = (compType: string) =>
            evaluateMetaData(createExtArgs, metadataMap, compType, METADATA_TYPES.IS_REPEATABLE)

        const isCompRepeatable = (compPointer: Pointer) =>
            getMetaData(createExtArgs, METADATA_TYPES.IS_REPEATABLE, compPointer)

        const isContainCheckRecursive = (compPointer: Pointer) =>
            getMetaData(createExtArgs, METADATA_TYPES.IS_CONTAIN_CHECK_RECURSIVE, compPointer)

        const isEnforcingContainerChildLimitationsByWidth = (compPointer: Pointer) =>
            getMetaData(createExtArgs, METADATA_TYPES.ENFORCE_CONTAINER_CHILD_LIMITS_BY_WIDTH, compPointer)

        const isEnforcingContainerChildLimitationsByHeight = (compPointer: Pointer) =>
            getMetaData(createExtArgs, METADATA_TYPES.ENFORCE_CONTAINER_CHILD_LIMITS_BY_HEIGHT, compPointer)

        const canContain = (compPointer: Pointer, potentialChild: CompRef, targetedContainerPointer: Pointer) =>
            getMetaData(
                createExtArgs,
                METADATA_TYPES.CAN_CONTAIN,
                compPointer,
                potentialChild,
                targetedContainerPointer
            )

        const isContainerByPointer = (compPointer: Pointer): boolean =>
            getMetaData(createExtArgs, METADATA_TYPES.CONTAINER, compPointer)

        const isAlwaysContainRecursively = (compPointer: Pointer): boolean =>
            getMetaData(createExtArgs, METADATA_TYPES.ALWAYS_CONTAIN_RECURSIVELY, compPointer)

        const isPublicContainer = (compPointer: Pointer) =>
            getMetaData(createExtArgs, METADATA_TYPES.IS_PUBLIC_CONTAINER, compPointer)

        const isFullWidthByStructure = (compStructure: CompStructure) =>
            evaluateMetaData(
                createExtArgs,
                metadataMap,
                compStructure.componentType,
                METADATA_TYPES.FULL_WIDTH_BY_STRUCTURE,
                undefined,
                [compStructure]
            )

        const isFullWidth = (compPointer: Pointer) => getMetaData(createExtArgs, METADATA_TYPES.FULL_WIDTH, compPointer)

        const isRepeater = (compPointer: Pointer) => getMetaData(createExtArgs, METADATA_TYPES.IS_REPEATER, compPointer)

        const registerComponentMetadata = (componentType: string, metadata: any) => {
            if (metadataMap[componentType]) {
                informUnableToRegisterAlreadyRegisteredComponent(createExtArgs, componentType)
                return
            }

            const allowedExternalMetaDataFieldNames = _.invert(EXTERNAL_META_DATA_ALLOWED_TYPES)
            const illegalKeys = Object.keys(_.pickBy(metadata, (value, key) => !allowedExternalMetaDataFieldNames[key]))
            if (illegalKeys.length > 0) {
                informIllegalMetadataTypes(createExtArgs, componentType, illegalKeys, metadata)
            }

            const filteredAndFlaggedMetaData = filterAndFlagExternalMetadata(metadata)
            metadataMap[componentType] = filteredAndFlaggedMetaData
        }

        const unregisterComponentMetadata = (componentType: string) => {
            delete metadataMap[componentType]
        }

        const hasExternalMetadataByPointer = (componentPointer: Pointer) => {
            const componentType = getComponentType(dal, componentPointer as CompRef)
            return isExternalMetaData(metadataMap, componentType)
        }

        const hasExternalMetadataByType = (componentType: string) => isExternalMetaData(metadataMap, componentType)

        const getRawMetadataValueByType = (componentType: string, metadataKey: string) => {
            return metadataMap[componentType]?.[metadataKey]
        }

        const allowedToContainMoreChildren = (componentPointer: Pointer) => {
            const maximumChildrenNumber = getMetaData(
                createExtArgs,
                METADATA_TYPES.MAXIMUM_CHILDREN_NUMBER,
                componentPointer
            )

            if (maximumChildrenNumber === Number.MAX_VALUE) {
                return true
            }

            const childrenPointers = pointers.structure.getChildren(componentPointer)

            return maximumChildrenNumber > childrenPointers.length
        }

        const isChildTypeAllowed = (potentialContainerPointer: Pointer, childComponentType: string): boolean => {
            const childTypesAllowed = getMetaData(
                createExtArgs,
                METADATA_TYPES.ALLOWED_CHILD_TYPES,
                potentialContainerPointer
            )
            if (!childTypesAllowed) {
                return true
            }
            return _.includes(childTypesAllowed, childComponentType)
        }

        const isParentTypeAllowed = (
            compStructureOrPointer: CompStructure | Pointer,
            potentialContainerType: string,
            isByStructure: boolean = false
        ) => {
            const parentTypesAllowed = isByStructure
                ? evaluateMetaData(
                      createExtArgs,
                      metadataMap,
                      (compStructureOrPointer as CompStructure).componentType,
                      METADATA_TYPES.ALLOWED_PARENT_TYPES
                  )
                : getMetaData(createExtArgs, METADATA_TYPES.ALLOWED_PARENT_TYPES, compStructureOrPointer as Pointer)
            if (!parentTypesAllowed) {
                return true
            }
            return _.includes(parentTypesAllowed, potentialContainerType)
        }

        const isComponentContainableByStructure = (
            componentStructure: CompStructure,
            potentialContainerPointer: Pointer
        ) => {
            return (
                areChildAndParentTypesMatching(createExtArgs, componentStructure, potentialContainerPointer, true) &&
                !isRefPointer(potentialContainerPointer) &&
                isComponentContainableRecursively(
                    createExtArgs,
                    componentStructure,
                    potentialContainerPointer,
                    isContainableByStructure
                )
            )
        }

        const isContainableByStructurePublic = (compStructure: CompStructure, potentialContainerPointer: Pointer) => {
            return (
                !!potentialContainerPointer &&
                isPublicContainer(potentialContainerPointer) &&
                isComponentContainableByStructure(compStructure, potentialContainerPointer)
            )
        }

        return {
            componentsMetadata: {
                canConnectToCode: (componentPointer: Pointer) => canConnectToCode(createExtArgs, componentPointer),
                isContainer,
                shouldAutoSetNickname: (componentPointer: Pointer) => shouldAutoSetNickname(dal, componentPointer),
                sticksToBottom,
                isMobileOnly: (componentPointer: Pointer) => isMobileOnly(createExtArgs, componentPointer),
                isNativeMobileOnlyByPointer: (componentPointer: Pointer) =>
                    isNativeMobileOnlyByPointer(dal, componentPointer),
                getDefaultNickname: (componentPointer: Pointer) => getDefaultNickname(createExtArgs, componentPointer),
                isMobileComponentPropertiesSplit,
                isCompTypeRepeatable,
                isCompRepeatable,
                isContainCheckRecursive,
                isEnforcingContainerChildLimitationsByWidth,
                isEnforcingContainerChildLimitationsByHeight,
                getMetadataValue: (componentPointer: Pointer, metadataKey: string, ...additionalArguments) =>
                    getMetaData(createExtArgs, metadataKey, componentPointer, ...additionalArguments),
                getMetadataValueByType: (componentType: string, metadataKey: string, additionalArgs?: any[]) =>
                    evaluateMetaData(createExtArgs, metadataMap, componentType, metadataKey, undefined, additionalArgs),
                hasExternalMetadataByPointer,
                hasExternalMetadataByType,
                canContain,
                isContainerByPointer,
                isAlwaysContainRecursively,
                isPublicContainer,
                isFullWidthByStructure,
                isFullWidth,
                isRepeater,
                getRawMetadataValueByType,
                allowedToContainMoreChildren,
                isParentTypeAllowed,
                isChildTypeAllowed,
                isContainableByStructure: isComponentContainableByStructure,
                isContainableByStructurePublic,
                registrar: {
                    registerComponentMetadata,
                    unregisterComponentMetadata
                }
            }
        }
    }

    return {
        name: 'componentsMetadata',
        dependencies: new Set('blocks'),
        createExtensionAPI
    }
}

export {createExtension}
