import _ from 'lodash'
import type {DocumentServicesObject} from '@wix/document-services-types'
import type {MobileAlgoApi} from '@wix/document-manager-extensions/src/extensions/mobileAlgo/mobileAlgo'
import type {OverlayManager} from '../overlayManager/overlayManager'
import type {DAL} from '@wix/document-manager-core'
import type {
    GroupApi,
    GroupingResult
} from '@wix/document-manager-extensions/src/extensions/mobileAlgo/plugins/grouping/types'
import type {ComponentToChildrenMap, DebugToolsConfig} from '../types'
import {NEW_ALGO, VIRTUAL_TAG} from '../constants'
import {getSections} from './utils/getSections'
import {getColor} from './utils/color'

const getPluginApi = (mobileAlgo: MobileAlgoApi) => {
    const ctx = mobileAlgo.context.create(['grouping'])
    const apis = mobileAlgo.plugins.getApis(ctx)
    const {grouping} = apis as GroupApi
    return {ctx, grouping}
}
export type IdMapper = (elem: GroupingResult) => string
const friendlyId: IdMapper = (elem: GroupingResult) => {
    if (elem.tag === 'Group') {
        return elem.id
    }
    return `${_.last(elem.component!.componentType!.split('.'))} (${elem.id.slice(-4)})`
}

export const logGroupTree = async (mobileAlgo: MobileAlgoApi, sectionId: string, idMapper: IdMapper = friendlyId) => {
    const {ctx, grouping} = getPluginApi(mobileAlgo)
    await mobileAlgo.runWithContext({id: sectionId, type: 'DESKTOP'}, ctx, [])
    const dfsLogBuilder = (groupTree: GroupingResult, indent: number = 0) => {
        let result = `${'|\t'.repeat(indent)}${idMapper(groupTree)}\n`
        for (const childTree of groupTree.children ?? []) {
            result += dfsLogBuilder(childTree, indent + 1)
        }
        return result
    }

    console.log(dfsLogBuilder(grouping.getAiGroupTree()))
}

const groupIndicationWrapper = (
    groupsConfiguration: ComponentToChildrenMap,
    overlayManager: OverlayManager,
    config: DebugToolsConfig
) => {
    _.forEach(groupsConfiguration, (data, groupId) => {
        const color = getColor(config)
        if (data.componentType === VIRTUAL_TAG) {
            console.log(`%c ${groupId}`, `color:${color};`)
            console.log(data)
            overlayManager.markComponent(data.realChildren ?? [], NEW_ALGO, groupId, color, '', '-23px')
        }
    })
}

const displayGroups = async (
    mobileAlgo: MobileAlgoApi,
    sections: string[],
    overlayManager: OverlayManager,
    config: DebugToolsConfig
) => {
    for (const sectionId of sections) {
        const {ctx, grouping} = getPluginApi(mobileAlgo)
        await mobileAlgo.runWithContext({id: sectionId, type: 'DESKTOP'}, ctx, [])
        const result = grouping.getGroupingResult()
        groupIndicationWrapper(result, overlayManager, config)
    }
}

export const displayGroupsAllSections = async (
    documentServices: DocumentServicesObject,
    mobileAlgo: MobileAlgoApi,
    overlayManager: OverlayManager,
    dal: DAL,
    config: DebugToolsConfig
) => {
    const currentPageSections = getSections(dal, documentServices.pages.getCurrentPageId())
    await displayGroups(mobileAlgo, currentPageSections, overlayManager, config)
}

export const displayGroupsSingleSection = async (
    mobileAlgo: MobileAlgoApi,
    sectionId: string,
    overlayManager: OverlayManager,
    config: DebugToolsConfig
) => {
    await displayGroups(mobileAlgo, [sectionId], overlayManager, config)
}
