import type {
    ComponentConversionData,
    MobileAlgoPlugin,
    MobileAlgoPluginInitializationArgs,
    PluginHeuristicsRegistry,
    StructureStageData
} from '../../types'
import {
    BOTTOM_PADDING,
    DEFAULT_PADDING_BOTTOM,
    LIST_ITEM_PADDING_TOP,
    TOP_BOTTOM_RELATION,
    TOP_PADDING
} from './constants'
import type {OrderApi} from '../order/types'
import {SemanticTypes} from '../../preprocess/conversionDataBuilders'
import {VIRTUAL_TAG} from '../grouping/constants'

export const createPlugin = ({stages, readOnlyExtensionAPI}: MobileAlgoPluginInitializationArgs): MobileAlgoPlugin => {
    const getMarginsBetweenComps = (
        previousComponent: ComponentConversionData,
        currentComponent: ComponentConversionData
    ): number =>
        TOP_BOTTOM_RELATION[currentComponent.semanticType]?.[previousComponent.semanticType] ?? DEFAULT_PADDING_BOTTOM

    const verticalScaleAndSize = ({mobileConversionDal, globalContext}: StructureStageData) => {
        const apis = readOnlyExtensionAPI.getPluginsApi(globalContext)
        const {order} = apis as OrderApi
        const orderingResults = order.getOrderingResult()

        mobileConversionDal.forEachComponentPostOrder((component: ComponentConversionData) => {
            if (component.id === mobileConversionDal.getRootId()) {
                return
            }
            const container = mobileConversionDal.getComponent(component.parent)
            const orderedContainerChildren = container.components!
            const currentCompIndex = orderedContainerChildren.indexOf(component.id)
            const isFirstChild = currentCompIndex === 0
            const isFirstInListItem =
                orderingResults[orderingResults[component.id].parentId].componentType === VIRTUAL_TAG &&
                orderingResults[orderingResults[component.id].parentId].components!.indexOf(component.id) === 0

            const getFitToContentHeight = () => {
                const lastCompChild = mobileConversionDal.getComponent(
                    mobileConversionDal.getComponent(component.id).components!.at(-1)!
                )
                const lastCompChildLayout = lastCompChild.convertedMeasurements

                return lastCompChildLayout.height + lastCompChildLayout.y + BOTTOM_PADDING[component.semanticType]
            }

            const getSectionHeight = () => {
                if (!component.components?.length) {
                    return 200
                }

                return getFitToContentHeight()
            }

            const getBoxHeight = () => {
                if (component.components?.length) {
                    if (component.originalMeasurements.height < 101) {
                        return Math.max(getFitToContentHeight(), 100)
                    }

                    return Math.max(getFitToContentHeight(), 200)
                }

                if (component.originalMeasurements.height < 101) {
                    return 100
                }

                return 200
            }

            const getButtonHeight = () => {
                const {fontSize} = component.style!

                if (fontSize! < 17) {
                    return 48
                }

                if (fontSize! < 22) {
                    return 56
                }

                if (fontSize! < 27) {
                    return 64
                }

                return 72
            }

            const getImageHeight = () => {
                const {width, height} = component.originalMeasurements
                const maxHeight = width < 201 ? 600 : 300

                return Math.max(Math.min(height, maxHeight), 48)
            }

            const getAspectRatioHeight = () => {
                const {width} = component.convertedMeasurements

                return component.originalMeasurements.height * (width / component.originalMeasurements.width)
            }

            const getComponentHeight = () => {
                if (component.semanticType === SemanticTypes.SECTION) {
                    return getSectionHeight()
                }

                if (component.semanticType === SemanticTypes.BOX) {
                    return getBoxHeight()
                }

                if (component.semanticType === SemanticTypes.BUTTON) {
                    return getButtonHeight()
                }

                if (component.semanticType === SemanticTypes.IMAGE) {
                    return getImageHeight()
                }

                if (
                    component.semanticType === SemanticTypes.SVG ||
                    component.semanticType === SemanticTypes.EMBEDDED_MEDIA
                ) {
                    return getAspectRatioHeight()
                }

                if (component.semanticType === SemanticTypes.TEXT) {
                    return 48
                }

                return component.originalMeasurements.height
            }

            const getYValue = () => {
                if (isFirstChild) {
                    return TOP_PADDING[container.semanticType]
                }

                const previousSibling = mobileConversionDal.getComponent(orderedContainerChildren[currentCompIndex - 1])
                const previousSiblingLayout = previousSibling.convertedMeasurements

                if (isFirstInListItem) {
                    return previousSiblingLayout.y + previousSiblingLayout.height + LIST_ITEM_PADDING_TOP
                }

                return (
                    previousSiblingLayout.y +
                    previousSiblingLayout.height +
                    getMarginsBetweenComps(previousSibling, component)
                )
            }

            const y = getYValue()
            const height = getComponentHeight()

            mobileConversionDal.setConvertedMeasurements(component.id, {
                y,
                height
            })
        })
    }

    const register = (registry: PluginHeuristicsRegistry) => {
        registry.register(stages.POSITION, verticalScaleAndSize)
    }

    const name = 'verticalScaleAndSize'
    return {register, name, dependencies: []}
}
