import {createImmutableProxy} from '@wix/wix-immutable-proxy'
import type {
    MobileConversionDal,
    MobileConversionArgs,
    ComponentAction,
    ComponentConversionData,
    ConversionStyleData
} from './types'
import _ from 'lodash'
import type {Rect, SingleLayoutData} from '@wix/document-services-types'

export const createMobileConversionDal = ({componentsMap, rootId}: MobileConversionArgs): MobileConversionDal => {
    function* flatStructureIterator(comp: ComponentConversionData): Generator<ComponentConversionData> {
        yield comp
        for (const child of comp.components || []) {
            yield* flatStructureIterator(componentsMap[child])
        }
    }

    function* postOrderFlatStructureIterator(comp: ComponentConversionData): Generator<ComponentConversionData> {
        for (const child of comp.components || []) {
            yield* postOrderFlatStructureIterator(componentsMap[child])
        }

        yield comp
    }

    const forEachComponentAsync = async (action: ComponentAction) => {
        for (const component of flatStructureIterator(componentsMap[rootId])) {
            await action(createImmutableProxy(component))
        }
    }

    const forEachComponent = (action: ComponentAction) => {
        for (const component of flatStructureIterator(componentsMap[rootId])) {
            action(createImmutableProxy(component))
        }
    }

    const forEachComponentReverse = (action: ComponentAction) => {
        for (const component of Array.from(flatStructureIterator(componentsMap[rootId])).reverse()) {
            action(createImmutableProxy(component))
        }
    }

    const forEachComponentPostOrder = (action: ComponentAction) => {
        for (const component of postOrderFlatStructureIterator(componentsMap[rootId])) {
            action(createImmutableProxy(component))
        }
    }

    const setConvertedLayout = (componentId: string, componentChanges: Partial<SingleLayoutData>) => {
        _.merge(componentsMap[componentId].convertedLayout, componentChanges)
    }

    const setConvertedMeasurements = (componentId: string, componentChanges: Partial<Rect>) => {
        _.merge(componentsMap[componentId].convertedMeasurements, componentChanges)
    }

    const setStyle = (componentId: string, componentChanges: Partial<ConversionStyleData>) => {
        _.merge(componentsMap[componentId].style, componentChanges)
    }

    const setComponents = (componentId: string, components: string[]) => {
        componentsMap[componentId].components = components
    }

    return {
        getRootId: () => rootId,
        getComponent: (componentId: string) => createImmutableProxy(componentsMap[componentId]),
        forEachComponent,
        forEachComponentAsync,
        forEachComponentReverse,
        forEachComponentPostOrder,
        setConvertedLayout,
        setConvertedMeasurements,
        setStyle,
        setComponents
    }
}
