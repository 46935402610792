import _ from 'lodash'
import type {DalItem, DalJsNamespace, DalJsStore, ExtensionAPI, Null, SnapshotDal} from '@wix/document-manager-core'
import type {SnapshotExtApi} from '../../snapshots'
import {LOCAL_EDITOR_SYNC_TAG} from '../constants'

export const commitLocalEditorSyncSnapshot = (extensionAPI: ExtensionAPI): void => {
    const {snapshots} = extensionAPI as SnapshotExtApi
    snapshots.takeSnapshot(LOCAL_EDITOR_SYNC_TAG)
}

const getOldValue = (prev: Null<SnapshotDal>, namespace: string, id: string) => prev?.getValue({type: namespace, id})
const getPageId = (val: any): string | undefined => _.get(val, ['metaData', 'pageId'])

export const getUpdatedPageIds = (extensionAPI: ExtensionAPI): Set<string> => {
    const {snapshots} = extensionAPI as SnapshotExtApi
    const currentSnapshot = snapshots.getCurrentSnapshot()
    const previousSyncSnapshot = snapshots.getLastSnapshotByTagName(LOCAL_EDITOR_SYNC_TAG)
    const changes: DalJsStore = currentSnapshot.diff(previousSyncSnapshot)
    const pageIds: Set<string> = new Set()

    _.forOwn(changes, (items: DalJsNamespace, namespace: string) => {
        _.forOwn(items, (value: DalItem | undefined, id: string) => {
            const oldValue = getOldValue(previousSyncSnapshot, namespace, id)
            const oldPage: string | undefined = getPageId(oldValue)
            const newPage: string | undefined = getPageId(value)

            if (oldPage) pageIds.add(oldPage)
            if (newPage) pageIds.add(newPage)
        })
    })

    return pageIds
}
