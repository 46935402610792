import _ from 'lodash'
import {SERIALIZED_DATA_KEYS, SERIALIZED_SCOPED_DATA_KEYS} from './constants'
import {COMP_DATA_QUERY_KEYS, VARIANTS} from '../../constants/constants'

const removeBreakpointsIfCompHasVariants = (serializedComp: any) => {
    const hasBreakpoints = _.some(serializedComp?.variants, x => x?.type === VARIANTS.VARIANT_TYPES.BREAKPOINTS_DATA)
    if (hasBreakpoints) {
        delete serializedComp?.breakpoints
    }
}

const removeMetadataFromSerializedItem = (item: any) => {
    if (!_.isPlainObject(item)) {
        return
    }
    delete item.metaData
    _.forOwn(item, val => {
        if (_.isArray(val)) {
            _.forEach(val, removeMetadataFromSerializedItem)
        } else {
            removeMetadataFromSerializedItem(val)
        }
    })
}

const removeMetadata = (serializedComp: any) => {
    delete serializedComp.metaData
    _.forEach(SERIALIZED_DATA_KEYS, key => {
        if (serializedComp[key!]) {
            removeMetadataFromSerializedItem(serializedComp[key!])
        }
    })
    _.forEach(SERIALIZED_SCOPED_DATA_KEYS, key => {
        if (serializedComp[key]) {
            removeMetadataFromSerializedItem(serializedComp[key])
        }
    })
}

export const sanitizeSerializedComponent = (serializedComp: any) => {
    delete serializedComp.activeVariants
    delete serializedComp.referredVariants
    delete serializedComp.siteWidth

    _.forEach(COMP_DATA_QUERY_KEYS, compDataQueryKey => {
        delete serializedComp[compDataQueryKey]
    })

    removeMetadata(serializedComp)
    if (serializedComp.mobileStructure) {
        removeMetadata(serializedComp.mobileStructure)
    }
    removeBreakpointsIfCompHasVariants(serializedComp)
}
