import type {DAL, ExtensionAPI} from '@wix/document-manager-core'
import type {CompRef, Pointer, Pointers, RawComponentExport, WMLFiles} from '@wix/document-services-types'
import type {ImportExportExtensionApi} from '../../import-export/importExportExtension'
import {buildPlaceholderStructure} from '../../import-export/json/utils'

export const importPage = async (
    pagePointer: CompRef,
    pageData: WMLFiles | null,
    extensionAPI: ExtensionAPI,
    dal: DAL,
    pointers: Pointers
): Promise<void> => {
    const {importExport} = extensionAPI as ImportExportExtensionApi

    if (pageData === null) {
        const allItemsOfPage: Pointer[] = pointers.page.getAllPointersByPage(pagePointer.id)
        allItemsOfPage.forEach((ptr: Pointer) => dal.remove(ptr))
        return
    }

    const rawComponentExport: RawComponentExport = await importExport.wml.convertWmlToRawComponentExport(pageData)

    if (!dal.has(pagePointer)) {
        dal.set(pagePointer, buildPlaceholderStructure(pagePointer, pagePointer))
    }

    importExport.pages.replace(pagePointer, rawComponentExport, {componentFeatures: 'All'})
}

export const exportPage = (pagePointer: CompRef, extensionAPI: ExtensionAPI, dal: DAL): WMLFiles | null => {
    if (!dal.has(pagePointer)) {
        return null
    }

    const {importExport} = extensionAPI as ImportExportExtensionApi
    return importExport.wml.exportPage(pagePointer, {componentFeatures: 'All'})
}
