import type {MobileConversionDal} from '../../types'
import type {Heuristic, WidthHeuristics} from './types'
import {
    CHARACTERS_2,
    CHARACTERS_3,
    CHARACTERS_4,
    CHARACTERS_5,
    FONT_SIZE_EXTRA_LARGE,
    FONT_SIZE_LARGE,
    FONT_SIZE_MEDIUM,
    FONT_SIZE_SMALL
} from './constatns'
import {SemanticTypes} from '../../preprocess/conversionDataBuilders'

const calculateMeasurements = (calculateWidth: number, xvalueAfterGaping: number, widthAfterGaping: number) => {
    const mobileWidth = getCalculateWidth(calculateWidth, widthAfterGaping)
    return {width: mobileWidth, x: xvalueAfterGaping}
}

const getLabelWidth = (fontSize: number, label: string) => {
    if (fontSize <= FONT_SIZE_SMALL) {
        if (label.length <= CHARACTERS_2) return 160
        if (label.length <= CHARACTERS_3) return 200
        if (label.length <= CHARACTERS_4) return 240
        if (label.length <= CHARACTERS_5) return 342
        return 342
    } else if (fontSize <= FONT_SIZE_MEDIUM) {
        if (label.length <= CHARACTERS_2) return 200
        if (label.length <= CHARACTERS_3) return 240
        if (label.length <= CHARACTERS_4) return 280
        if (label.length <= CHARACTERS_5) return 342
        return 342
    } else if (fontSize <= FONT_SIZE_LARGE) {
        if (label.length <= CHARACTERS_2) return 240
        if (label.length <= CHARACTERS_5) return 342
        return 342
    } else if (fontSize <= FONT_SIZE_EXTRA_LARGE) {
        return 342
    }
    return 342
}

const getWidthWithGapping = (mobileConversionDal: MobileConversionDal, compId: string) => {
    const currentComp = mobileConversionDal.getComponent(compId)
    const parentId = currentComp.parent
    const parentComp = mobileConversionDal.getComponent(parentId)
    const parentWidth = parentComp.originalMeasurements.width
    const parentX = parentComp.originalMeasurements.x

    return {
        widthAfterGaping: (parentWidth as number) - 48,
        xvalueAfterGaping: parentX + 24,
        currentComp
    }
}

function getCalculateWidth(calculateWidth: number, widthAfterGaping: number) {
    return calculateWidth <= widthAfterGaping ? calculateWidth : widthAfterGaping
}

export const widthHeuristics: WidthHeuristics = [
    {
        predicate: (type: string) => type === SemanticTypes.SECTION,
        run: () => {
            return {width: 390, x: 0}
        }
    },
    {
        predicate: (type: string) => type === SemanticTypes.BOX,
        run: (mobileConversionDal: MobileConversionDal, compId: string) => {
            const {widthAfterGaping, xvalueAfterGaping} = getWidthWithGapping(mobileConversionDal, compId)
            const calculateWidth = 390
            const mobileWidth = getCalculateWidth(calculateWidth, widthAfterGaping)
            return {width: mobileWidth, x: xvalueAfterGaping}
        }
    },
    {
        predicate: (type: string) => type === SemanticTypes.IMAGE,
        run: (mobileConversionDal: MobileConversionDal, compId: string) => {
            const {widthAfterGaping, currentComp, xvalueAfterGaping} = getWidthWithGapping(mobileConversionDal, compId)
            let calculateWidth
            if ((currentComp.originalMeasurements.width as number) <= 200) {
                calculateWidth = currentComp.originalMeasurements.width as number
            } else {
                calculateWidth = (currentComp.originalMeasurements.width as number) * 0.7
            }
            const mobileWidth = getCalculateWidth(calculateWidth, widthAfterGaping)
            return {width: mobileWidth, x: xvalueAfterGaping}
        }
    },
    {
        predicate: (type: string) => type === SemanticTypes.SVG,
        run: (mobileConversionDal: MobileConversionDal, compId: string) => {
            const {widthAfterGaping, currentComp, xvalueAfterGaping} = getWidthWithGapping(mobileConversionDal, compId)
            let calculateWidth
            if ((currentComp.originalMeasurements.width as number) <= 200) {
                calculateWidth = currentComp.originalMeasurements.width
            } else {
                calculateWidth = Math.max(currentComp.originalMeasurements.width * 0.6, 40)
            }
            const mobileWidth = getCalculateWidth(calculateWidth, widthAfterGaping)
            return {width: mobileWidth, x: xvalueAfterGaping}
        }
    },
    {
        predicate: (type: string) => type === SemanticTypes.TEXT,
        run: (mobileConversionDal: MobileConversionDal, compId: string) => {
            const {widthAfterGaping, xvalueAfterGaping} = getWidthWithGapping(mobileConversionDal, compId)
            const calculateWidth = 390
            const mobileWidth = getCalculateWidth(calculateWidth, widthAfterGaping)
            return {width: mobileWidth, x: xvalueAfterGaping}
        }
    },
    {
        predicate: (type: string) => type === SemanticTypes.HORIZONTAL_LINE,
        run: (mobileConversionDal: MobileConversionDal, compId: string) => {
            const {widthAfterGaping, currentComp, xvalueAfterGaping} = getWidthWithGapping(mobileConversionDal, compId)
            let calculateWidth
            if ((currentComp.originalMeasurements.width as number) <= 200) {
                calculateWidth = currentComp.originalMeasurements.width
            } else {
                calculateWidth = 390
            }
            const mobileWidth = getCalculateWidth(calculateWidth, widthAfterGaping)
            return {width: mobileWidth, x: xvalueAfterGaping}
        }
    },
    {
        predicate: (type: string) => type === SemanticTypes.BUTTON,
        run: (mobileConversionDal: MobileConversionDal, compId: string) => {
            const {widthAfterGaping, xvalueAfterGaping} = getWidthWithGapping(mobileConversionDal, compId)
            const currentComp = mobileConversionDal.getComponent(compId)
            const {fontSize} = currentComp.style!
            const {label} = currentComp.data

            return calculateMeasurements(getLabelWidth(fontSize!, label), xvalueAfterGaping, widthAfterGaping)
        }
    }
]

export const fallbackWidthHeuristics: Heuristic = {
    predicate: () => true,
    run: (mobileConversionDal: MobileConversionDal, compId: string) => {
        const {widthAfterGaping, xvalueAfterGaping} = getWidthWithGapping(mobileConversionDal, compId)
        const calculateWidth = 390
        const mobileWidth = getCalculateWidth(calculateWidth, widthAfterGaping)
        return {width: mobileWidth, x: xvalueAfterGaping}
    }
}
