import type {
    ComponentStageData,
    MobileAlgoPlugin,
    MobileAlgoPluginInitializationArgs,
    PluginHeuristicsRegistry
} from '../../types'

export const createPlugin = ({stages}: MobileAlgoPluginInitializationArgs): MobileAlgoPlugin => {
    const calculateNewFontSize = (fontSize: number) => {
        if (fontSize < 14) {
            return fontSize
        }

        if (fontSize < 17) {
            return 14
        }

        if (fontSize < 20) {
            return 16
        }

        if (fontSize < 23) {
            return 18
        }

        if (fontSize < 31) {
            return 20
        }

        if (fontSize < 39) {
            return 22
        }

        if (fontSize < 47) {
            return 24
        }

        return 26
    }

    const textScale = ({componentId, mobileConversionDal}: ComponentStageData) => {
        const baseStyleSettings = mobileConversionDal.getComponent(componentId).style

        if (baseStyleSettings?.fontSize) {
            const fontSize = calculateNewFontSize(baseStyleSettings?.fontSize)
            mobileConversionDal.setStyle(componentId, {fontSize})
        }

        if (baseStyleSettings?.collapsibleTextButtonFontSize) {
            const collapsibleTextButtonFontSize = calculateNewFontSize(baseStyleSettings?.collapsibleTextButtonFontSize)
            mobileConversionDal.setStyle(componentId, {collapsibleTextButtonFontSize})
        }
    }

    const register = (registry: PluginHeuristicsRegistry) => {
        registry.register(stages.SCALE, textScale)
    }

    const name = 'textScale'
    return {register, name, dependencies: []}
}
