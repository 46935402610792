import type {CoreConfig, DAL, ExtensionAPI} from '@wix/document-manager-core'
import type {CompRef, ImportExportOptions, Pointers, RawComponentExport} from '@wix/document-services-types'
import {importRawComponent} from './raw/import'
import {addPlaceholderComponent, addPlaceholderPage} from './utils'
import {deepClone} from '@wix/wix-immutable-proxy'
import {reuseIds} from './reuseIds'
import {getRawOptions} from './options'

export const replaceComponent = (
    rawComponentExport: RawComponentExport,
    componentToReplace: CompRef,
    options: ImportExportOptions | undefined,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    {schemaService}: CoreConfig
) => {
    const clonedExport = deepClone(rawComponentExport)
    reuseIds(clonedExport, extensionAPI, dal, schemaService, componentToReplace)

    importRawComponent(
        clonedExport.rootComponent,
        clonedExport,
        componentToReplace,
        getRawOptions(options),
        dal,
        pointers,
        extensionAPI,
        schemaService
    )
}

export const addComponent = (
    rawComponentExport: RawComponentExport,
    containerPointer: CompRef,
    options: ImportExportOptions | undefined,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    coreConfig: CoreConfig
): CompRef => {
    const {rootComponent, externalRefs} = rawComponentExport
    externalRefs[rootComponent] ??= rootComponent
    const componentToReplace = addPlaceholderComponent(externalRefs[rootComponent], containerPointer, dal, pointers)
    replaceComponent(rawComponentExport, componentToReplace, options, dal, pointers, extensionAPI, coreConfig)
    return componentToReplace
}

export const addPage = (
    rawPageExport: RawComponentExport,
    options: ImportExportOptions | undefined,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    coreConfig: CoreConfig
): CompRef => {
    const {rootComponent, components, externalRefs} = rawPageExport
    const page = components[rootComponent]
    externalRefs[rootComponent] ??= rootComponent
    const pageToReplace = addPlaceholderPage(externalRefs[rootComponent], page, pointers, extensionAPI)
    replaceComponent(rawPageExport, pageToReplace, options, dal, pointers, extensionAPI, coreConfig)
    return pageToReplace
}
