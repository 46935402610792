const _ = require('lodash')
const dataUtils = require('../helpers/dataUtils')
const coreUtils = require('@wix/santa-core-utils')
const effectsUtils = require('../helpers/effectsUtils')
const imageXUtils = require('../helpers/imageXMigrationUtils')
const {
    DATA_TYPES,
    NAMESPACE_MAPPING,
    COMP_DATA_QUERY_KEYS
} = require('@wix/document-services-json-schemas/dist/constants.json')
const MOBILE = 'MOBILE'
const DESKTOP = 'DESKTOP'
const TRIGGERS_TYPE = 'Triggers'
const REF_ARRAY_TYPE = 'RefArray'
const REACTIONS_TYPE = 'Reactions'
const EFFECTS_LIST_TYPE = 'EffectsList'
const PRESET_VARIANT_TYPE = 'Preset'
const MOBILE_VARIANT = 'MOBILE-VARIANT'
const BLOCKS_APP_DEF_ID = '46b2ad43-5720-41d2-8436-2058979cb53f'
const IMAGEX_COMPONENT_TYPE = 'wixui.ImageX'

const {
    createItemWithValues,
    createNamedEffectItem,
    createScrubNamedEffectItem,
    createVariantRelation,
    createPlayReactionItem,
    createScrubReactionItem,
    createTogglePlayReactionItem,
    createTimeAnimationItem,
    createScrubAnimationItem,
    createTimeAnimationOptionsItem,
    createScrubAnimationOptionsItem,
    createEmptyScrubAnimationOptionsItem,
    createViewportEnterVariantItem,
    createHoverTriggerVariantItem,
    createViewProgressVariantItem,
    createEmptyTimeAnimationOptionsItem
} = effectsUtils

/**
 * @typedef EffectsMigratorContext
 * @type {object}
 * @property {Function} getId - returns a generated an id for namespace.
 * @property {Function} isExperimentOpen - returns a boolean.
 * @property {Object} migrationSettings - an object represent current behavior migration settings.
 * @property {object} clientSpecMap - an object represent partial clientSpecMap.
 * @property {object} structure - an object represent deep structure of the current migrated page.
 * @property {object} data - an object represent all the data of the current migrated page.
 * @property {object} desktopComponents - a flat map of all desktop components with behaviors.
 * @property {object} mobileComponents - a flat map of all mobile components with behaviors.
 * @property {object} allUniqueCompsWithBehavior - a flat map of all unique components with behaviors.
 * @property {Set} mobileOnlyComponentsIds - a set containing all mobile only component ids.
 */

/** @type {EffectsMigratorContext} */
const context = {
    getId: undefined,
    isExperimentOpen: undefined,
    migrationSettings: undefined,
    clientSpecMap: undefined,
    structure: undefined,
    data: undefined,
    desktopComponents: undefined,
    mobileComponents: undefined,
    allUniqueCompsWithBehavior: undefined,
    mobileOnlyComponentsIds: undefined
}

const updateBehaviorsData = (componentId, compStructure, updatedBehaviorsItems) => {
    const {behaviorQuery} = compStructure

    if (_.isEmpty(updatedBehaviorsItems)) {
        delete context.data.behaviors_data[behaviorQuery]
        removeBehaviorQuery(componentId)
    } else {
        _.set(context.data, ['behaviors_data', behaviorQuery, 'items'], JSON.stringify(updatedBehaviorsItems))
    }
}

const getAllBehaviorItemsFromCompStructure = compStructure => {
    const {behaviorQuery} = compStructure
    const behaviorObject = context.data.behaviors_data[behaviorQuery]

    if (!behaviorObject) {
        return
    }

    return JSON.parse(behaviorObject.items)
}

const getAllBehaviorItemsOrScrollEffectsFromCompStructure = compStructure => {
    if (compStructure.componentType === IMAGEX_COMPONENT_TYPE) {
        return imageXUtils.getImageXScrollEffectsFromCompStructure(compStructure, context.data)
    }
    return getAllBehaviorItemsFromCompStructure(compStructure)
}

const updateBehaviorsDataOrImageXScrollEffects = (componentId, compStructure, updatedBehaviorsItems) => {
    if (compStructure.componentType === IMAGEX_COMPONENT_TYPE) {
        imageXUtils.cleanImageXScrollEffects(compStructure, context.data)
    } else {
        updateBehaviorsData(componentId, compStructure, updatedBehaviorsItems)
    }
}

const behaviorActionToTriggerAndReaction = {
    screenIn: {
        triggerType: 'viewport-enter',
        createTrigger: createViewportEnterVariantItem,
        createReaction: createPlayReactionItem,
        createEffectItem: createNamedEffectItem,
        createAnimationItem: createTimeAnimationItem,
        createAnimationOptionsItem: createTimeAnimationOptionsItem,
        createEmptyAnimationOptionsItem: createEmptyTimeAnimationOptionsItem,
        shouldMigrate: () => context.isExperimentOpen('dm_screenInBehaviorsToEntranceEffectsFixer'),
        shouldIgnore: compStructure => !!compStructure.effectsQuery,
        getAllBehaviorItemsFromCompStructure,
        updateOriginalDataAfterMigrationDone: updateBehaviorsData
    },
    modeIn: {
        triggerType: 'hover',
        createTrigger: createHoverTriggerVariantItem,
        createReaction: createTogglePlayReactionItem,
        createEffectItem: createNamedEffectItem,
        createAnimationItem: createTimeAnimationItem,
        createAnimationOptionsItem: createTimeAnimationOptionsItem,
        createEmptyAnimationOptionsItem: createEmptyTimeAnimationOptionsItem,
        shouldMigrate: () => context.isExperimentOpen('dm_migrateOldHoverBoxToNewFixer'),
        shouldIgnore: () => false,
        getAllBehaviorItemsFromCompStructure,
        updateOriginalDataAfterMigrationDone: updateBehaviorsData
    },
    modeOut: {
        shouldMigrate: () => context.isExperimentOpen('dm_migrateOldHoverBoxToNewFixer'),
        shouldIgnore: () => true,
        getAllBehaviorItemsFromCompStructure,
        updateOriginalDataAfterMigrationDone: updateBehaviorsData
    },
    bgScrub: {
        triggerType: 'view-progress',
        createTrigger: createViewProgressVariantItem,
        createReaction: createScrubReactionItem,
        createEffectItem: createScrubNamedEffectItem,
        createAnimationItem: createScrubAnimationItem,
        createAnimationOptionsItem: createScrubAnimationOptionsItem,
        createEmptyAnimationOptionsItem: createEmptyScrubAnimationOptionsItem,
        shouldMigrate: () => context.isExperimentOpen('dm_bgScrubToMotionFixer'),
        shouldIgnore: () => false,
        getAllBehaviorItemsFromCompStructure: getAllBehaviorItemsOrScrollEffectsFromCompStructure,
        updateOriginalDataAfterMigrationDone: updateBehaviorsDataOrImageXScrollEffects
    }
}

const getComponentsWithBehaviors = (structure, isMobile) => {
    return coreUtils.dataUtils.getAllCompsInStructure(
        structure,
        isMobile,
        comp => comp.behaviorQuery || (!isMobile && comp.componentType === IMAGEX_COMPONENT_TYPE && comp.dataQuery)
    )
}

const getAllComponentsWithBehaviors = structure => {
    const desktopComponents = getComponentsWithBehaviors(structure, false)
    const mobileComponents = getComponentsWithBehaviors(structure, true)
    const desktopCompsIds = _.map(desktopComponents, 'id')
    const mobileCompsIds = _.map(mobileComponents, 'id')
    const allUniqueCompsWithBehavior = {...mobileComponents, ...desktopComponents}
    const mobileOnlyComponentsIds = new Set(_.difference(mobileCompsIds, desktopCompsIds))

    return {
        desktopComponents,
        mobileComponents,
        allUniqueCompsWithBehavior,
        mobileOnlyComponentsIds
    }
}

const addQueriesOnCompStructure = (structure, queriesAndIdsToSet) => {
    if (structure) {
        _.forEach(queriesAndIdsToSet, (key, value) => {
            structure[value] = key
        })
    }
}

const deleteBehaviorQuery = compStructure => {
    if (compStructure) {
        delete compStructure.behaviorQuery
    }
}

const isStudioSite = () => {
    const doesPageHaveResponsiveSection = _.some(
        context.structure.components,
        ({componentType}) => componentType === 'responsive.components.Section'
    )
    const isThereBreakpointDataOrRange = _.some(context.data[NAMESPACE_MAPPING.variants], ({type}) =>
        ['BreakpointsData', 'BreakpointRange'].includes(type)
    )

    return !!context.structure.breakpointVariantsQuery || doesPageHaveResponsiveSection || isThereBreakpointDataOrRange
}

const isBlocksApp = () => {
    return (
        Object.values(context.clientSpecMap).some(({appDefinitionId}) => appDefinitionId === BLOCKS_APP_DEF_ID) &&
        _.some(context.data[NAMESPACE_MAPPING.variants], ({type}) => type === 'Preset')
    )
}

const initContext = (structure, data, clientSpecMap, uniqueIdGenerator, isExperimentOpen) => {
    Object.assign(context, getAllComponentsWithBehaviors(structure), {
        clientSpecMap,
        structure,
        data,
        getId: namespace => uniqueIdGenerator.getUniqueId(namespace, '-'),
        isExperimentOpen
    })
}

const getOrCreateTriggerVariant = (componentId, triggersQuery, behavior) => {
    if (behavior.triggerVariantId && context.data[NAMESPACE_MAPPING.variants][behavior.triggerVariantId]) {
        return {triggerVariantItem: context.data[NAMESPACE_MAPPING.variants][behavior.triggerVariantId]}
    }

    let triggerVariantItem = Object.values(context.data[NAMESPACE_MAPPING.variants]).find(
        ({type, trigger, componentId: compId}) =>
            trigger === context.migrationSettings.triggerType && type === 'Trigger' && compId === componentId
    )

    if (!triggerVariantItem) {
        triggerVariantItem = context.migrationSettings.createTrigger(context.getId(DATA_TYPES.variants), componentId)
        context.data[NAMESPACE_MAPPING.variants][triggerVariantItem.id] = triggerVariantItem
    }

    if (triggersQuery) {
        context.data[NAMESPACE_MAPPING.triggers][triggersQuery] = dataUtils.updateItemWithValues(
            context.data[NAMESPACE_MAPPING.triggers][triggersQuery],
            [triggerVariantItem.id]
        )

        return {triggerVariantItem}
    }

    const triggerItem = effectsUtils.createItemWithValues(context.getId(DATA_TYPES.triggers), TRIGGERS_TYPE, [
        triggerVariantItem.id
    ])
    context.data[NAMESPACE_MAPPING.triggers][triggerItem.id] = triggerItem

    return {triggerVariantItem, triggerItem}
}

const setEffectsList = (animationOptionsId, effectsQuery) => {
    const refArrayItem = createItemWithValues(context.getId(DATA_TYPES.effects), REF_ARRAY_TYPE, [animationOptionsId])
    context.data[NAMESPACE_MAPPING.effects][refArrayItem.id] = refArrayItem

    const animationItem = context.migrationSettings.createAnimationItem(
        context.getId(DATA_TYPES.effects),
        refArrayItem.id
    )
    context.data[NAMESPACE_MAPPING.effects][animationItem.id] = animationItem
    let effectsListItem

    if (!effectsQuery) {
        effectsListItem = createItemWithValues(context.getId(DATA_TYPES.effects), EFFECTS_LIST_TYPE, [animationItem.id])
        context.data[NAMESPACE_MAPPING.effects][effectsListItem.id] = effectsListItem
    } else {
        context.data[NAMESPACE_MAPPING.effects][effectsQuery] = dataUtils.updateItemWithValues(
            context.data[NAMESPACE_MAPPING.effects][effectsQuery],
            [animationItem.id]
        )
    }

    return {animationItem, refArrayItem, effectsListItem}
}

const setNamedEffect = behavior => {
    const namedEffectItem = context.migrationSettings.createEffectItem(context.getId(DATA_TYPES.effects), behavior)
    context.data[NAMESPACE_MAPPING.effects][namedEffectItem.id] = namedEffectItem
    const animationOptionsItem = context.migrationSettings.createAnimationOptionsItem(
        context.getId(DATA_TYPES.effects),
        behavior,
        namedEffectItem.id
    )
    context.data[NAMESPACE_MAPPING.effects][animationOptionsItem.id] = animationOptionsItem

    return animationOptionsItem
}

const setEffectInVariant = (animationItemId, refArrayItem, isNamedEffect, behavior, variantId) => {
    const animationOptionsItem = isNamedEffect
        ? setNamedEffect(behavior)
        : context.migrationSettings.createEmptyAnimationOptionsItem(context.getId(DATA_TYPES.effects))
    context.data[NAMESPACE_MAPPING.effects][animationOptionsItem.id] = animationOptionsItem
    const variantRelationItem = createVariantRelation(
        context.getId(DATA_TYPES.effects),
        [variantId],
        animationOptionsItem.id,
        animationItemId
    )
    context.data[NAMESPACE_MAPPING.effects][variantRelationItem.id] = variantRelationItem
    context.data[NAMESPACE_MAPPING.effects][refArrayItem.id] = dataUtils.updateItemWithValues(
        context.data[NAMESPACE_MAPPING.effects][refArrayItem.id],
        [variantRelationItem.id]
    )
}

const setEffectInMobileVariant = (animationItemId, refArrayItem, isNamedEffect, behavior) =>
    setEffectInVariant(animationItemId, refArrayItem, isNamedEffect, behavior, MOBILE_VARIANT)

const addReactionData = (componentId, effectId, variants, behaviorItem, isDisabledReaction = false) => {
    const values = []

    if (!isDisabledReaction) {
        const reactionItem = context.migrationSettings.createReaction(context.getId(DATA_TYPES.reactions), effectId)
        context.data[NAMESPACE_MAPPING.reactions][reactionItem.id] = reactionItem
        values.push(reactionItem.id)
    }

    const reactionsListItem = createItemWithValues(context.getId(DATA_TYPES.reactions), REACTIONS_TYPE, values)
    context.data[NAMESPACE_MAPPING.reactions][reactionsListItem.id] = reactionsListItem
    const variantRelationItem = createVariantRelation(
        context.getId(DATA_TYPES.reactions),
        variants,
        reactionsListItem.id,
        componentId
    )
    context.data[NAMESPACE_MAPPING.reactions][variantRelationItem.id] = variantRelationItem

    return variantRelationItem.id
}

const addOrUpdateReactionRefArray = (variantRelationsIds, reactionsQuery) => {
    if (reactionsQuery) {
        context.data[NAMESPACE_MAPPING.reactions][reactionsQuery] = dataUtils.updateItemWithValues(
            context.data[NAMESPACE_MAPPING.reactions][reactionsQuery],
            variantRelationsIds
        )

        return reactionsQuery
    }

    const reactionsRefArrayItem = createItemWithValues(
        context.getId(DATA_TYPES.reactions),
        REF_ARRAY_TYPE,
        variantRelationsIds
    )
    context.data[NAMESPACE_MAPPING.reactions][reactionsRefArrayItem.id] = reactionsRefArrayItem

    return reactionsRefArrayItem.id
}

const addNamedInDefaultAndNamedInBreakpoints = (compStructure, behavior, behaviorItemsInBreakpoints) => {
    const componentId = compStructure.id
    const {triggersQuery, reactionsQuery, effectsQuery} = compStructure
    const animationOptionsItem = behavior.name
        ? setNamedEffect(behavior)
        : context.migrationSettings.createEmptyAnimationOptionsItem(context.getId(DATA_TYPES.effects))
    context.data[NAMESPACE_MAPPING.effects][animationOptionsItem.id] = animationOptionsItem
    const {animationItem, refArrayItem, effectsListItem} = setEffectsList(animationOptionsItem.id, effectsQuery)
    const {triggerVariantItem, triggerItem} = getOrCreateTriggerVariant(componentId, triggersQuery, behavior)
    const reactionVariantRelationId = addReactionData(componentId, animationItem.id, [triggerVariantItem.id], behavior)
    const reactionRefArrayId = addOrUpdateReactionRefArray([reactionVariantRelationId], reactionsQuery)

    for (let i = 0; i < behaviorItemsInBreakpoints.length; i++) {
        const {breakpointVariantId, name} = behaviorItemsInBreakpoints[i]
        setEffectInVariant(
            animationItem.id,
            refArrayItem,
            name !== '',
            behaviorItemsInBreakpoints[i],
            breakpointVariantId
        )
    }

    return {
        ...(effectsListItem && {[COMP_DATA_QUERY_KEYS.effects]: effectsListItem.id}),
        ...(triggerItem && {[COMP_DATA_QUERY_KEYS.triggers]: triggerItem.id}),
        ...(reactionRefArrayId && {[COMP_DATA_QUERY_KEYS.reactions]: reactionRefArrayId})
    }
}

const addNamedInDefaultAndInMobile = (compStructure, behavior, mobileBehaviorOverride) => {
    const componentId = compStructure.id
    const {triggersQuery, reactionsQuery, effectsQuery} = compStructure
    const animationOptionsId = setNamedEffect(behavior).id
    const {animationItem, refArrayItem, effectsListItem} = setEffectsList(animationOptionsId, effectsQuery)
    const {triggerVariantItem, triggerItem} = getOrCreateTriggerVariant(componentId, triggersQuery, behavior)
    setEffectInMobileVariant(animationItem.id, refArrayItem, true, mobileBehaviorOverride || behavior)
    const reactionVariantRelationId = addReactionData(componentId, animationItem.id, [triggerVariantItem.id], behavior)
    const mobileReactionVariantRelationId = addReactionData(
        componentId,
        animationItem.id,
        [triggerVariantItem.id, MOBILE_VARIANT],
        behavior
    )
    const reactionRefArrayId = addOrUpdateReactionRefArray(
        [reactionVariantRelationId, mobileReactionVariantRelationId],
        reactionsQuery
    )

    return {
        ...(effectsListItem && {[COMP_DATA_QUERY_KEYS.effects]: effectsListItem.id}),
        ...(triggerItem && {[COMP_DATA_QUERY_KEYS.triggers]: triggerItem.id}),
        ...(reactionRefArrayId && {[COMP_DATA_QUERY_KEYS.reactions]: reactionRefArrayId})
    }
}

const addNamedInDefaultAndEmptyInMobile = (compStructure, behavior) => {
    const componentId = compStructure.id
    const {triggersQuery, reactionsQuery, effectsQuery} = compStructure
    const animationOptionsId = setNamedEffect(behavior).id
    const {animationItem, refArrayItem, effectsListItem} = setEffectsList(animationOptionsId, effectsQuery)
    const {triggerVariantItem, triggerItem} = getOrCreateTriggerVariant(componentId, triggersQuery, behavior)
    setEffectInMobileVariant(animationItem.id, refArrayItem, false)
    const reactionVariantRelationId = addReactionData(componentId, animationItem.id, [triggerVariantItem.id], behavior)
    const disabledReactionVariantRelationId = addReactionData(
        componentId,
        animationItem.id,
        [triggerVariantItem.id, MOBILE_VARIANT],
        behavior,
        true
    )
    const reactionRefArrayId = addOrUpdateReactionRefArray(
        [reactionVariantRelationId, disabledReactionVariantRelationId],
        reactionsQuery
    )

    return {
        ...(effectsListItem && {[COMP_DATA_QUERY_KEYS.effects]: effectsListItem.id}),
        ...(triggerItem && {[COMP_DATA_QUERY_KEYS.triggers]: triggerItem.id}),
        ...(reactionRefArrayId && {[COMP_DATA_QUERY_KEYS.reactions]: reactionRefArrayId})
    }
}

const addEmptyInDefaultAndNamedInVariants = (compStructure, behavior, variantIds) => {
    const componentId = compStructure.id
    const defaultEmptyAnimationOptionsItem = context.migrationSettings.createEmptyAnimationOptionsItem(
        context.getId(DATA_TYPES.effects)
    )
    context.data[NAMESPACE_MAPPING.effects][defaultEmptyAnimationOptionsItem.id] = defaultEmptyAnimationOptionsItem
    const {triggersQuery, reactionsQuery, effectsQuery} = compStructure
    const {animationItem, refArrayItem, effectsListItem} = setEffectsList(
        defaultEmptyAnimationOptionsItem.id,
        effectsQuery
    )
    const {triggerVariantItem, triggerItem} = getOrCreateTriggerVariant(componentId, triggersQuery, behavior)
    let reactionRefArrayId

    for (let i = 0; i < variantIds.length; i++) {
        const variantId = variantIds[i]
        setEffectInVariant(animationItem.id, refArrayItem, true, behavior, variantId)
        const reactionVariantRelationId = addReactionData(
            componentId,
            animationItem.id,
            [triggerVariantItem.id, variantId],
            behavior
        )
        reactionRefArrayId = addOrUpdateReactionRefArray(
            [reactionVariantRelationId],
            reactionsQuery ?? reactionRefArrayId
        )
    }
    return {
        ...(effectsListItem && {[COMP_DATA_QUERY_KEYS.effects]: effectsListItem.id}),
        ...(triggerItem && {[COMP_DATA_QUERY_KEYS.triggers]: triggerItem.id}),
        ...(reactionRefArrayId && {[COMP_DATA_QUERY_KEYS.reactions]: reactionRefArrayId})
    }
}

const addEmptyInDefaultAndNamedInMobile = (compStructure, behavior) =>
    addEmptyInDefaultAndNamedInVariants(compStructure, behavior, [MOBILE_VARIANT])

const collectPagePresetVariantsIds = () =>
    Object.keys(_.get(context.data, [NAMESPACE_MAPPING.variants])).filter(variantId => {
        const {type} = _.get(context.data, [NAMESPACE_MAPPING.variants, variantId])
        return type === PRESET_VARIANT_TYPE
    })

const removeBehaviorQuery = componentId => {
    deleteBehaviorQuery(context.desktopComponents[componentId])
    deleteBehaviorQuery(context.mobileComponents[componentId])
}

const runBlocksMigration = (compStructure, itemsToMigrate) => {
    const behaviorItem = itemsToMigrate.find(({viewMode}) => viewMode === DESKTOP) ?? itemsToMigrate[0]
    const presetVariantsIds = collectPagePresetVariantsIds()
    return addEmptyInDefaultAndNamedInVariants(compStructure, behaviorItem, presetVariantsIds)
}

const runStudioMigration = (compStructure, itemsToMigrate) => {
    const behaviorItem =
        itemsToMigrate.find(({viewMode, breakpointVariantId}) => viewMode === DESKTOP && !breakpointVariantId) ??
        itemsToMigrate[0]
    const behaviorItemsInBreakpoints = itemsToMigrate.filter(
        ({breakpointVariantId}) =>
            !!breakpointVariantId && context.data[NAMESPACE_MAPPING.variants][breakpointVariantId]
    )
    return addNamedInDefaultAndNamedInBreakpoints(compStructure, behaviorItem, behaviorItemsInBreakpoints)
}

const runClassicMigration = (compStructure, itemsToMigrate) => {
    if (itemsToMigrate.length === 1) {
        // means that either it is a mobileOnly comp or it has animation in one viewMode only
        const behavior = itemsToMigrate[0]
        // if comp is mobileOnly then default should be empty effect and namedEffect should be set on the mobile variant
        if (
            behavior.viewMode === MOBILE ||
            (behavior.viewMode === undefined && context.mobileOnlyComponentsIds.has(compStructure.id))
        ) {
            // add empty effect for default and namedEffect for mobile variant
            return addEmptyInDefaultAndNamedInMobile(compStructure, behavior)
        } else if (behavior.viewMode === undefined) {
            return addNamedInDefaultAndInMobile(compStructure, behavior)
        } else if (behavior.viewMode === DESKTOP) {
            return addNamedInDefaultAndEmptyInMobile(compStructure, behavior)
        }
    } else if (itemsToMigrate.length > 1) {
        const desktopBehavior = itemsToMigrate.find(({viewMode}) => viewMode === DESKTOP) ?? itemsToMigrate[0]
        const mobileBehavior = itemsToMigrate.find(({viewMode}) => viewMode === MOBILE) ?? itemsToMigrate[0]
        return addNamedInDefaultAndInMobile(compStructure, desktopBehavior, mobileBehavior)
    }
}

const runMigration = () => {
    const isBlocks = isBlocksApp()
    const isStudio = isStudioSite()
    const behaviorActionTypesToMigrate = Object.keys(
        _.pickBy(behaviorActionToTriggerAndReaction, value => value.shouldMigrate())
    )

    _.forEach(context.allUniqueCompsWithBehavior, (compStructure, compId) => {
        _.forEach(behaviorActionTypesToMigrate, behaviorAction => {
            let queriesToIdsMap
            context.migrationSettings = behaviorActionToTriggerAndReaction[behaviorAction]
            const behaviorItems = context.migrationSettings.getAllBehaviorItemsFromCompStructure(compStructure)

            if (!_.isArray(behaviorItems)) {
                return context.migrationSettings.updateOriginalDataAfterMigrationDone(
                    compId,
                    compStructure,
                    behaviorItems
                )
            }

            const [behaviorItemsToMigrate, remainingBehaviorItems] = _.partition(
                behaviorItems,
                // eslint-disable-next-line lodash/matches-prop-shorthand
                item => item.action === behaviorAction
            )

            if (_.isEmpty(behaviorItemsToMigrate)) {
                return
            }

            if (context.migrationSettings.shouldIgnore(compStructure)) {
                return context.migrationSettings.updateOriginalDataAfterMigrationDone(
                    compId,
                    compStructure,
                    remainingBehaviorItems
                )
            }

            if (isBlocks) {
                queriesToIdsMap = runBlocksMigration(compStructure, behaviorItemsToMigrate)
            } else if (isStudio) {
                queriesToIdsMap = runStudioMigration(compStructure, behaviorItemsToMigrate)
            } else {
                queriesToIdsMap = runClassicMigration(compStructure, behaviorItemsToMigrate)
            }

            context.migrationSettings.updateOriginalDataAfterMigrationDone(
                compId,
                compStructure,
                remainingBehaviorItems
            )
            addQueriesOnCompStructure(context.desktopComponents[compId], queriesToIdsMap)
            addQueriesOnCompStructure(context.mobileComponents[compId], queriesToIdsMap)
        })
    })
}

const migratePage = (structure, data, uniqueIdGenerator, clientSpecMap, isExperimentOpen) => {
    data[NAMESPACE_MAPPING.effects] = data[NAMESPACE_MAPPING.effects] || {}
    data[NAMESPACE_MAPPING.variants] = data[NAMESPACE_MAPPING.variants] || {}
    data[NAMESPACE_MAPPING.triggers] = data[NAMESPACE_MAPPING.triggers] || {}
    data[NAMESPACE_MAPPING.reactions] = data[NAMESPACE_MAPPING.reactions] || {}

    initContext(structure, data, clientSpecMap, uniqueIdGenerator, isExperimentOpen)

    runMigration()
}

module.exports = {
    name: 'migrateScreenInBehaviorsToEntranceEffects',
    version: 0,
    experimentalVersions: [
        {version: 1, experiment: 'dm_screenInBehaviorsToEntranceEffectsFixer'},
        {version: 2, experiment: 'dm_migrateOldHoverBoxToNewFixer'},
        {version: 3, experiment: 'dm_bgScrubToMotionFixer'}
    ],
    exec(pageJson, pageIdsArray, magicObject) {
        const {clientSpecMap, editorConfig} = magicObject
        const {uniqueIdGenerator} = magicObject.dataFixerUtils
        const {structure, data} = pageJson
        const isADI = editorConfig && editorConfig.isADI

        if (isADI || !magicObject.isExperimentOpen('dm_screenInBehaviorsToEntranceEffectsFixer')) {
            return
        }

        if (structure.id === 'masterPage') {
            const masterPageData = data[NAMESPACE_MAPPING.data].masterPage

            if (!masterPageData.usesNewAnimations) {
                masterPageData.usesNewAnimations = true
            }
        }

        migratePage(structure, data, uniqueIdGenerator, clientSpecMap, magicObject.isExperimentOpen)
    },
    fixerRequiresReruns: true
}
