import type {DalValue, DocumentManager} from '@wix/document-manager-core'
import type {Experiment, Pointer} from '@wix/document-services-types'
import type {SiteMigrator} from '../dataMigrationRunner'
import type {FeaturesExtensionAPI} from '../../../features/features'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {TPAExtensionAPI} from '../../../tpa'
import type {PageExtensionAPI} from '../../../page'
import type {VariantsExtensionAPI} from '../../../variants/variants'

const experimentName = 'dm_migrateToNewColorPalette'

const THEME_DATA_ID = 'THEME_DATA'

const TPA_COLORS_MAP = {
    color_11: 'color_36',
    color_15: 'color_37',
    color_12: 'color_38',
    color_39: 'color_13',
    color_14: 'color_40',
    color_18: 'color_41',
    color_45: 'color_37',
    color_46: 'color_40',
    color_47: 'color_37',
    color_48: 'color_41',
    color_49: 'color_41',
    color_50: 'color_36',
    color_51: 'color_41',
    color_52: 'color_41',
    color_53: 'color_36',
    color_54: 'color_13',
    color_55: 'color_13',
    color_56: 'color_36',
    color_57: 'color_36',
    color_58: 'color_41',
    color_59: 'color_41',
    color_60: 'color_36',
    color_61: 'color_41',
    color_62: 'color_41',
    color_63: 'color_36',
    color_64: 'color_13',
    color_65: 'color_13'
}

/*
 * get site colors and font themes
 * */

const getTheme = (dm: DocumentManager) => {
    const pointer = dm.pointers.data.getThemeItem(THEME_DATA_ID, 'masterPage')
    const colorsPointer = dm.pointers.getInnerPointer(pointer, 'color')
    const fontsPointer = dm.pointers.getInnerPointer(pointer, 'font')
    const textThemePointer = dm.pointers.getInnerPointer(pointer, 'textTheme')
    const colors = deepClone(dm.dal.get(colorsPointer))
    const fonts = deepClone(dm.dal.get(fontsPointer))
    const textThemes = deepClone(dm.dal.get(textThemePointer))
    return {colors, fonts, textThemes}
}

const updateTheme = (dm: DocumentManager, colors: DalValue, fonts: DalValue, textThemes: DalValue) => {
    const pointer = dm.pointers.data.getThemeItem(THEME_DATA_ID, 'masterPage')
    const colorsPointer = dm.pointers.getInnerPointer(pointer, 'color')
    const fontsPointer = dm.pointers.getInnerPointer(pointer, 'font')
    const textThemePointer = dm.pointers.getInnerPointer(pointer, 'textTheme')
    dm.dal.set(colorsPointer, colors)
    dm.dal.set(fontsPointer, fonts)
    dm.dal.set(textThemePointer, textThemes)
}

const replaceColorInStylesUtil = (styleData: any) => {
    const serializedStyle = JSON.stringify(styleData)
    const colors = serializedStyle.match(/color_\d\d/g)
    if (colors) {
        const colorsToReplace = colors.filter(color => TPA_COLORS_MAP[color])
        if (colorsToReplace.length > 0) {
            const updatedSerialization = colorsToReplace.reduce(
                (structure, color) =>
                    // @ts-ignore
                    structure.replaceAll(color, TPA_COLORS_MAP[color]),
                serializedStyle
            )
            return JSON.parse(updatedSerialization)
        }
    }
}

const migrateSite = (documentManager: DocumentManager, experimentInstance: Experiment) => {
    const {features, tpa, variants, page} = documentManager.extensionAPI as FeaturesExtensionAPI &
        TPAExtensionAPI &
        VariantsExtensionAPI &
        PageExtensionAPI
    const themeConfig = features.site.get('themeConfig') || {}
    if (!experimentInstance.isOpen(experimentName) || themeConfig?.colorMapping) {
        return
    }
    const usedColors = new Set<string>()

    const matcher = (item: any) => {
        const matched = JSON.stringify(item).match(/color_\d\d/g)
        matched?.forEach(colorId => usedColors.add(colorId))
        return matched
    }

    const replacer = (pointer: Pointer) => {
        const compPointer = variants.getOwningComponentPointerWithContext(pointer)
        if (compPointer) {
            const componentStructure = documentManager.dal.get(compPointer)
            const {componentType} = componentStructure
            if (tpa.isTpaByCompType(componentType)) {
                return
            }
        }
        const styleData = documentManager.dal.get(pointer)
        const updatedStyleData = replaceColorInStylesUtil(styleData)
        if (updatedStyleData) {
            documentManager.dal.set(pointer, updatedStyleData)
        }
    }

    const styleItemWithPointers = page.getAllPagesIds(true).flatMap(pageId => {
        return documentManager.pointers.data.getStyleItemsWithPredicate(matcher, pageId)
    })
    // replace in styleItems
    styleItemWithPointers.forEach(replacer)

    const designItemsPointers = page.getAllPagesIds(true).flatMap(pageId => {
        return documentManager.pointers.data.getDesignItemsWithPredicate(matcher, pageId)
    })
    // replace in desingItems
    designItemsPointers.forEach(replacer)

    const {colors: palette, fonts, textThemes} = getTheme(documentManager)
    for (const i in textThemes) {
        if (!fonts[i]) {
            continue
        }
        const match = textThemes[i].color.match(/color_\d\d/)
        usedColors.add(match[0])
        if (match && TPA_COLORS_MAP[match[0]]) {
            textThemes[i].color = textThemes[i].color.replace(match[0], TPA_COLORS_MAP[match[0]])
            fonts[i] = fonts[i].replace(match[0], TPA_COLORS_MAP[match[0]])
        }
    }
    const colorsToAdd = new Set(Object.values(TPA_COLORS_MAP))
    usedColors.forEach(color => {
        if (!TPA_COLORS_MAP[color]) {
            colorsToAdd.add(color)
        } else {
            const colorIndex = parseInt(color.replace('color_', ''), 10)
            const newIndex = parseInt(TPA_COLORS_MAP[color].replace('color_', ''), 10)
            palette[newIndex] = palette[colorIndex]
        }
    })
    const colorNaming = Object.fromEntries([...colorsToAdd].map((color, idx) => [color, {name: '', order: idx}]))
    features.site.update('themeConfig', {
        ...themeConfig,
        type: 'themeConfig',
        colorMapping: TPA_COLORS_MAP,
        colorNaming
    })
    updateTheme(documentManager, palette, fonts, textThemes)
}

const name = 'migrateToNewColorPalette'
const version = 0
const experimentalVersions = [{version: 1, experiment: experimentName}]

export const migrateToNewColorPalette: SiteMigrator = {
    migrateSite,
    name,
    version,
    experimentalVersions
}
