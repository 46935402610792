import type {CoreConfig, DAL, ExtensionAPI} from '@wix/document-manager-core'
import type {CompRef, ImportExportOptions, Pointers, RawComponentExport} from '@wix/document-services-types'
import {exportRawComponent} from './raw/export'
import {getRawOptions} from './options'

export const exportComponent = (
    rootPointer: CompRef,
    options: ImportExportOptions | undefined,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    {schemaService}: CoreConfig
): RawComponentExport => {
    return exportRawComponent(rootPointer, getRawOptions(options), dal, pointers, extensionAPI, schemaService)
}
