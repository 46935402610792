import {CreateExtArgs, DAL, DalValue, Extension, ExtensionAPI, pointerUtils} from '@wix/document-manager-core'
import {ReportableError} from '@wix/document-manager-utils'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
const {isRepeatedComponent} = displayedOnlyStructureUtil

import type {
    ConnectionList,
    IConnectionItem,
    MobileHints,
    ObsoleteBehaviorsList,
    Pointer,
    Pointers,
    RefInfo,
    StyleRef
} from '@wix/document-services-types'
import {deepClone} from '@wix/wix-immutable-proxy'
import _ from 'lodash'
import {
    COMP_DATA_QUERY_KEYS_WITH_STYLE,
    DATA_TYPES,
    DATA_TYPES_VALUES_WITH_HASH,
    VIEW_MODES
} from '../../constants/constants'
import {
    addDefaultMetaData,
    generateItemIdWithPrefix,
    generateUniqueIdByType,
    shouldMergeDataItems
} from '../../utils/dataUtils'
import {validateCompNamespaceType} from '../../utils/schemaUtils'
import {translateIfNeeded, translateIfNeededWithFallback} from '../page/language'
import type {RelationshipsAPI} from '../relationships'
import {getUniqueDisplayedId, isRepeater} from '../../utils/repeaterUtils'
import type {DataExtensionAPI} from '../data'
import {stripHashIfExists} from '../../utils/refArrayUtils'
import type {HooksExtensionApi} from '../hooks/hooks'
import {DATA_MODEL_HOOKS} from './hooks'
import type {SchemaExtensionAPI} from '../schema/schema'
import type {BaseDataAccessApi, DataAccessExtensionApi} from '../dataAccess/dataAccess'
import {getRepeatedItemQuery} from '../../utils/inflationUtils'
import {removeVariablesConnections} from '../variables/variablesRemoval'

const {getInnerPointer, getPointer, getRepeatedItemPointerIfNeeded} = pointerUtils
export type AddCompItem = (
    compPointer: Pointer,
    namespace: string,
    data: DalValue,
    languageCode?: string,
    options?: AddItemOptions
) => Pointer
export type GetCompItem = (
    compPointer: Pointer,
    namespace: string,
    languageCode?: string,
    useOriginalLanguageFallback?: boolean
) => DalValue | void
export type GenerateItemIdWithPrefix = (prefix: string) => string
export type GenerateUniqueIdByType = (type: string, pageId: string, dal: DAL, pointers: Pointers) => string
export type LinkComponentToItemByTypeDesktopAndMobile = (
    componentPointer: Pointer,
    itemId: string,
    itemType: string,
    itemQuery?: string
) => void

interface AddedItemInfo {
    pointer: Pointer
    id: string
}

interface RemoveItemRecursivelyOptions {
    shouldRemovePermanentDataNodes: boolean
}

interface AddItemOptions {
    skipOriginalNodeMerge?: boolean
}

export interface DataModelAPIBase extends ExtensionAPI {
    addItem(
        item: DalValue,
        itemType: string,
        pageId: string,
        customId?: string,
        languageCode?: string,
        oldToNewIdMap?: {[key: string]: string}
    ): Pointer
    addDeserializedItem(
        item: DalValue,
        itemType: string,
        pageId: string,
        customId?: string,
        languageCode?: string
    ): Pointer
    linkDataToItemByType(ownerPointer: Pointer, itemId: string, namespace: string, itemQuery?: string): void
    addItemWithRefReuse(
        item: DalValue,
        itemType: string,
        pageId: string,
        customId?: string,
        languageCode?: string,
        options?: AddItemOptions
    ): Pointer
    getItem(
        id: string,
        namespace: string,
        pageId: string,
        languageCode?: string,
        useOriginalLanguageFallback?: boolean
    ): DalValue | void
    getDeserializedItem(
        id: string,
        namespace: string,
        pageId: string,
        languageCode?: string,
        useOriginalLanguageFallback?: boolean
    ): DalValue | void
    generateItemIdWithPrefix: GenerateItemIdWithPrefix
    generateUniqueIdByType: GenerateUniqueIdByType
    createDataItemByType<T = Record<string, any>>(dataType: string, overrides?: any): T
    createStyleItemByType(styleType: string): StyleRef
    createBehaviorsItem(behaviors: string): ObsoleteBehaviorsList
    createConnectionsItem(connections: IConnectionItem[]): ConnectionList
    createMobileHintsItem(mobileHints?: MobileHints): MobileHints
    createDesignItemByType(schemaName: string): any
    createPropertiesItemByType(propertiesType: string): any
    components: {
        linkComponentToItemByTypeDesktopAndMobile: LinkComponentToItemByTypeDesktopAndMobile
        addItem: AddCompItem
        getItem: GetCompItem
        getItemPointer(compPointer: Pointer, namespace: string): undefined | Pointer
        removeItemForDesktopAndMobile(compId: string, namespace: string): void
        removeItem(compPointer: Pointer, namespace: string): void
        getAllRepeaterOverridesForComponent(componentPointer: Pointer, langCode?: string): Pointer[]
        getAllRepeaterDataOverridesForComponent(componentPointer: Pointer, langCode?: string): Pointer[]
        getItemsPointers(componentPointer: Pointer, namespace: string): Pointer[]
        getComponentDataItemId(componentPointer: Pointer, namespace: string): string | undefined
    }
    removeItemRecursively(pointer: Pointer): void
    removeItemRecursivelyIncludingPermanentNodes(pointer: Pointer): void
    duplicate(id: string, namespace: string, pageId: string, toPageId?: string): Pointer
}

export interface DataModelAPI extends DataModelAPIBase {
    withSuper: DataModelAPIBase
}

export type DataModelExtensionAPI = ExtensionAPI & {
    dataModel: DataModelAPI
}

const IGNORED_ITEM_NAMESPACES_FOR_RECURSIVE_REMOVAL = VIEW_MODES

const isUpdatingTranslatedItemThatDoesntHaveTranslation = (
    itemInDALPointer: Pointer,
    itemInDALPointerBeforeTranslation: Pointer,
    itemInDAL: Record<string, any>
) => !_.isEqual(itemInDALPointer, itemInDALPointerBeforeTranslation) && !itemInDAL

const createExtensionAPI = ({dal, pointers, extensionAPI}: CreateExtArgs): DataModelExtensionAPI => {
    const {relationships} = extensionAPI as RelationshipsAPI
    const hooksApi = () => (extensionAPI as HooksExtensionApi).hooks
    const schemaAPI = () => (extensionAPI as SchemaExtensionAPI).schemaAPI
    const dataAccessApi = () => (extensionAPI as DataAccessExtensionApi).dataAccess

    const createExtensionApiForLimitedDal = (localDal: BaseDataAccessApi): DataModelAPIBase => {
        const getRefResolver = (
            newItem: DalValue,
            existingItemInDal: DalValue,
            path: readonly string[],
            globalResolver?: Function
        ) => {
            if (globalResolver) {
                return globalResolver
            }
            const newValue = _.get(newItem, path)
            const refs = new Set<string>([])
            if (_.isArray(newValue)) {
                // eslint-disable-next-line lodash/prefer-lodash-chain
                _.get(existingItemInDal, path)?.forEach((refId: string) => {
                    refs.add(refId.replace(/^#/, ''))
                })
            } else if (_.isObject(newValue) && _.has(newValue, ['type'])) {
                const currentRef = _.get(existingItemInDal, path)
                if (currentRef) {
                    refs.add(currentRef.replace(/^#/, ''))
                }
            }
            return (serializedItemId: string) => {
                if (refs.has(serializedItemId)) {
                    refs.delete(serializedItemId)
                    return serializedItemId
                }
            }
        }
        const addItemInternal = (
            item: DalValue,
            namespace: string,
            pageId: string,
            customId?: string,
            languageCode?: string,
            globalResolver?: Function,
            isSerialized = true,
            oldToNewIdMap?: {[key: string]: string},
            options?: AddItemOptions
        ): AddedItemInfo => {
            const itemToUpdatePointer = pointers.data.getItem(namespace, item.id, pageId)
            const id =
                customId ??
                (item.id && !localDal.has(itemToUpdatePointer)
                    ? item.id
                    : generateUniqueIdByType(namespace, pageId, dal, pointers))
            const itemInDALPointerBeforeTranslation = pointers.data.getItem(namespace, id, pageId)
            const itemInDALPointer = translateIfNeeded(dal, pointers, itemInDALPointerBeforeTranslation, languageCode)
            const itemInDAL = localDal.get(itemInDALPointer)
            let itemDalOriginalLangBecauseWeAreCreatingANewTranslation
            if (
                isUpdatingTranslatedItemThatDoesntHaveTranslation(
                    itemInDALPointer,
                    itemInDALPointerBeforeTranslation,
                    itemInDAL
                )
            ) {
                itemDalOriginalLangBecauseWeAreCreatingANewTranslation = localDal.get(itemInDALPointerBeforeTranslation)
            }

            let newItem: DalValue = {
                ...item,
                type: item.type ?? itemInDAL?.type ?? itemDalOriginalLangBecauseWeAreCreatingANewTranslation?.type
            }

            if (!newItem.type) {
                throw Error(
                    `item ${id} from namespace ${namespace} does not have type the item passed is ${JSON.stringify(
                        item
                    )}`
                )
            }
            const {schema} = dal
            const deepCloneNewItemIfNeeded = (() => {
                let didFullyCloneNewItem = false
                return (pathWeAreSettingTo: readonly string[]) => {
                    if (pathWeAreSettingTo.length > 1 && !didFullyCloneNewItem) {
                        // this is to prevent modifying deep properties in our input.
                        // we prefer to only shallow clone normally and this is not always needed, so we only deep clone if needed
                        newItem = deepClone(newItem)
                        didFullyCloneNewItem = true
                    }
                }
            })()
            const addItemWithoutAlteringPermanents = (
                itemNamespace: string,
                currentDataItemId: string | undefined,
                newItemVal: any,
                newItemId?: string
            ): string => {
                const currentDataItemInDal =
                    currentDataItemId && dal.get(pointers.data.getItem(itemNamespace, currentDataItemId))
                if (
                    currentDataItemInDal &&
                    schema.isPermanentDataType(itemNamespace, currentDataItemInDal.type) &&
                    newItemVal.type !== currentDataItemInDal.type
                ) {
                    newItemId = undefined
                }
                if (
                    schema.isPermanentDataType(itemNamespace, newItemVal.type) &&
                    newItemVal.id &&
                    dal.has(pointers.data.getItem(itemNamespace, newItemVal.id))
                ) {
                    return newItemVal.id
                }
                const addedItemInfo = addItemInternal(
                    newItemVal,
                    itemNamespace,
                    pageId,
                    newItemId,
                    languageCode,
                    globalResolver,
                    undefined,
                    undefined,
                    options
                )
                return addedItemInfo.id
            }
            if (isSerialized) {
                const schemaRefFieldsInfo = schema
                    .extractReferenceFieldsInfo(namespace, newItem.type)
                    ?.filter(schemaInfo => schemaInfo.isRefOwner)
                _.forEach(schemaRefFieldsInfo, ({path, referencedMap}) => {
                    const val = _.get(item, path)
                    const getDataItemId = getRefResolver(item, itemInDAL, path, globalResolver)

                    if (_.isArray(val)) {
                        const refArr: string[] = []
                        _.forEach(val, itemInArr => {
                            const reusedId = getDataItemId(itemInArr.id)
                            const referenceToSet = addItemWithoutAlteringPermanents(
                                referencedMap,
                                itemInArr.id,
                                itemInArr,
                                reusedId
                            )
                            refArr.push(`#${referenceToSet}`)
                        })
                        deepCloneNewItemIfNeeded(path)
                        _.setWith(newItem, path, refArr, Object)
                    } else if (_.isPlainObject(val) && _.has(val, ['type'])) {
                        const currentDataItemId = _.get(itemInDAL, path, '').replace(/^#/, '')
                        const reusedId = getDataItemId(currentDataItemId)
                        const referenceToSet = addItemWithoutAlteringPermanents(
                            referencedMap,
                            currentDataItemId,
                            val,
                            reusedId
                        )
                        deepCloneNewItemIfNeeded(path)
                        _.setWith(newItem, path, `#${referenceToSet}`, Object)
                    }
                })
            }

            const existingMetaData = itemInDAL?.metaData ?? {}
            const metaDataObj = {id, metaData: {...existingMetaData, ...item.metaData, pageId}}
            newItem = {...newItem, ...metaDataObj}
            if (!options?.skipOriginalNodeMerge) {
                if (itemDalOriginalLangBecauseWeAreCreatingANewTranslation) {
                    newItem = _.assign(deepClone(itemDalOriginalLangBecauseWeAreCreatingANewTranslation), newItem)
                } else if (shouldMergeDataItems(itemInDAL, newItem)) {
                    newItem = _.assign(deepClone(itemInDAL), newItem)
                }
            }

            addDefaultMetaData(newItem, pageId, namespace)
            newItem = _.omitBy(newItem, _.isNil)
            const itemPointerBeforeTranslation = pointers.data.getItem(namespace, id, pageId)
            const itemPointer = translateIfNeeded(dal, pointers, itemPointerBeforeTranslation, languageCode)
            schema.addDefaultsAndValidate(newItem.type, newItem, namespace)
            newItem = removeVariablesConnections(dal, newItem, namespace, newItem.id, oldToNewIdMap)
            localDal.set(itemPointer, newItem)
            return {pointer: itemPointer, id: newItem.id}
        }
        const addItem: DataModelAPI['addItem'] = (
            item: DalValue,
            namespace: string,
            pageId: string,
            customId?: string,
            languageCode?: string,
            oldToNewIdMap?: any
        ) => {
            const addedItemInfo = addItemInternal(
                item,
                namespace,
                pageId,
                customId,
                languageCode,
                undefined,
                true,
                oldToNewIdMap
            )
            return addedItemInfo.pointer
        }

        const addDeserializedItem: DataModelAPI['addDeserializedItem'] = (
            item: DalValue,
            namespace: string,
            pageId: string,
            customId?: string,
            languageCode?: string
        ) => {
            const addedItemInfo = addItemInternal(item, namespace, pageId, customId, languageCode, undefined, false)
            return addedItemInfo.pointer
        }

        const collectRefs = (pointer: Pointer, refs: Set<string>, languageCode?: string) => {
            relationships.getOwnedReferredPointers(pointer).forEach(p => {
                refs.add(p.id)
                const next = translateIfNeededWithFallback(dal, pointers, p, languageCode)
                collectRefs(next, refs, languageCode)
            })
        }
        const addItemWithRefReuse: DataModelAPI['addItemWithRefReuse'] = (
            item: DalValue,
            namespace: string,
            pageId: string,
            customId?: string,
            languageCode?: string,
            options?: AddItemOptions
        ) => {
            const refs = new Set<string>([])
            const rootId = customId ?? item.id
            const itemToUpdatePointer = translateIfNeededWithFallback(
                dal,
                pointers,
                pointers.data.getItem(namespace, rootId, pageId),
                languageCode
            )
            collectRefs(itemToUpdatePointer, refs, languageCode)

            const getIdToUse = (serializedItemId: string) => {
                if (refs.has(serializedItemId)) {
                    refs.delete(serializedItemId)
                    return serializedItemId
                }
            }
            const addedItemInfo = addItemInternal(
                item,
                namespace,
                pageId,
                rootId,
                languageCode,
                getIdToUse,
                undefined,
                undefined,
                options
            )
            return addedItemInfo.pointer
        }

        const getItemIdForUpdate = (
            compPointer: Pointer,
            refPointer: Pointer,
            namespace: string
        ): undefined | string => {
            if (isRepeatedComponent(compPointer.id)) {
                return getRepeatedItemQuery(dal, compPointer, namespace)
            }
            return localDal.get(refPointer)
        }

        const updateComponentItem: DataModelAPI['components']['addItem'] = (
            compPointer: Pointer,
            namespace: string,
            item: DalValue,
            languageCode?: string,
            options?: AddItemOptions
        ) => {
            const templatePointer = getRepeatedItemPointerIfNeeded(compPointer)
            if (!localDal.has(templatePointer)) {
                //Mainly to prevent developer problems when trying to use this through ds-impl before setting the component
                throw new ReportableError({
                    message: 'component being updated does not exist',
                    errorType: 'dataModel.components.addItem'
                })
            }

            const componentTypePointer = getInnerPointer(templatePointer, 'componentType')
            const componentType = localDal.get(componentTypePointer)
            if (item.type) {
                const validCompNamespaceType = validateCompNamespaceType(dal, componentType, item.type, namespace)
                if (!validCompNamespaceType.isValid) {
                    throw new ReportableError({
                        message: validCompNamespaceType.message ?? 'validateCompNamespaceType',
                        errorType: 'invalidComponent'
                    })
                }
            }

            const namespaceKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[namespace]
            const itemIdPointer = getInnerPointer(templatePointer, namespaceKey)
            const itemId = getItemIdForUpdate(compPointer, itemIdPointer, namespace)

            const idBeforeUpdate = _.isString(itemId) ? relationships.getIdFromRef(itemId) : itemId
            const isPagePointer = pointers.structure.isPage(compPointer)
            const isUpdatingPageData = isPagePointer && namespace === DATA_TYPES.data
            const pageId = _.get(pointers.structure.getPageOfComponent(compPointer), ['id'])
            const idToSet = idBeforeUpdate ?? (isPagePointer ? compPointer.id : undefined)
            const itemPointer = addItemWithRefReuse(item, namespace, pageId, idToSet, languageCode, options)
            if (isUpdatingPageData) {
                //move only the root page data item that was updated to the masterPage
                const pageDataAfterUpdate = dal.get(itemPointer)
                dal.set(itemPointer, {
                    ...pageDataAfterUpdate,
                    metaData: {...pageDataAfterUpdate.metaData, pageId: 'masterPage'}
                })
            }
            if (!itemId) {
                const idAfterUpdate = _.get(localDal.get(itemPointer), ['id'])
                const newItemId = DATA_TYPES_VALUES_WITH_HASH[namespace] ? `#${idAfterUpdate}` : idAfterUpdate
                localDal.set(itemIdPointer, newItemId)
            }

            return itemPointer
        }

        const getItemInternal = (
            id: string,
            namespace: string,
            pageId: string,
            languageCode?: string,
            useOriginalLanguageFallback: boolean = false,
            serialized = true
        ) => {
            const itemPointerBeforeTranslate = pointers.data.getItem(namespace, id, pageId)
            const itemOrNull = deepClone(
                localDal.get(translateIfNeeded(dal, pointers, itemPointerBeforeTranslate, languageCode))
            )
            const item =
                !itemOrNull && useOriginalLanguageFallback
                    ? deepClone(localDal.get(itemPointerBeforeTranslate))
                    : itemOrNull
            const {schema} = dal
            if (item && serialized) {
                const references = schema.getOwnedReferences(namespace, item)
                _.forEach(references, reference => {
                    const referredItem = getItemInternal(
                        reference.id,
                        reference.referencedMap,
                        pageId,
                        languageCode,
                        true
                    )
                    const oldReferred = _.get(item, reference.refInfo.path)
                    if (_.isString(oldReferred)) {
                        _.set(item, reference.refInfo.path, referredItem)
                    } else if (_.isArray(oldReferred)) {
                        _.remove(oldReferred, _.isString)
                        oldReferred.push(referredItem)
                    }
                })
            }

            return item?.metaData ? _.omit(item, ['metaData']) : item
        }

        const getItem: DataModelAPI['getItem'] = (
            id: string,
            namespace: string,
            pageId: string,
            languageCode?: string,
            useOriginalLanguageFallback: boolean = false
        ) => {
            const item = getItemInternal(id, namespace, pageId, languageCode)
            return !item && useOriginalLanguageFallback && languageCode ? getItemInternal(id, namespace, pageId) : item
        }

        const getDeserializedItem: DataModelAPI['getItem'] = (
            id: string,
            namespace: string,
            pageId: string,
            languageCode?: string,
            useOriginalLanguageFallback: boolean = false
        ) => {
            const item = getItemInternal(id, namespace, pageId, languageCode, false, false)
            return !item && useOriginalLanguageFallback && languageCode
                ? getItemInternal(id, namespace, pageId, undefined, false, false)
                : item
        }

        const doesItemTypeSupportsRepeatedItem = (itemType: string): boolean =>
            !!schemaAPI().getNamespaceConfig(itemType)?.supportsRepeaterItem

        const shouldRunRepeatedHooks = (namespace: string, compPointer: Pointer): boolean =>
            doesItemTypeSupportsRepeatedItem(namespace) && isRepeatedComponent(compPointer.id)

        const getItemPointerForNonRepeatedItem = (compPointer: Pointer, namespace: string) => {
            const namespaceKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[namespace]
            const refPointer = getInnerPointer(compPointer, namespaceKey)
            const ref = localDal.get(refPointer)

            if (!ref) {
                return undefined
            }

            const id = relationships.getIdFromRef(ref)
            return getPointer(id, namespace)
        }

        const getItemPointerForRepeatedItem = (
            repeatedItemPointer: Pointer,
            namespace: string
        ): undefined | Pointer => {
            const ref = getRepeatedItemQuery(dal, repeatedItemPointer, namespace)
            if (!ref) {
                return undefined
            }
            return getPointer(ref, namespace)
        }

        const getComponentItemPointer = (compPointer: Pointer, namespace: string): undefined | Pointer => {
            const actualCompPointer = shouldRunRepeatedHooks(namespace, compPointer)
                ? hooksApi().executeHookAndUpdateValue(
                      DATA_MODEL_HOOKS.GET_REPEATED_ITEM_POINTER.BEFORE.createEvent({}),
                      compPointer
                  )
                : compPointer

            if (isRepeatedComponent(actualCompPointer.id)) {
                return getItemPointerForRepeatedItem(actualCompPointer, namespace)
            }

            return getItemPointerForNonRepeatedItem(actualCompPointer, namespace)
        }

        const getComponentItem: DataModelAPI['components']['getItem'] = (
            compPointer: Pointer,
            namespace: string,
            languageCode?: string,
            useOriginalLanguageFallback: boolean = false
        ) => {
            const itemPointer = getComponentItemPointer(compPointer, namespace)

            if (!itemPointer) {
                return undefined
            }

            const pageId = _.get(pointers.structure.getPageOfComponent(compPointer), ['id'])
            return getItem(itemPointer.id, namespace, pageId, languageCode, useOriginalLanguageFallback)
        }

        const getOwnedRefFieldSchemasForItemRemoval = (itemNamespace: string, itemType: string): RefInfo[] => {
            const refFields = dal.schema.extractReferenceFieldsInfo(itemNamespace, itemType)
            const ownedFields = refFields?.filter(schemaInfo => schemaInfo.isRefOwner)
            return (
                ownedFields?.filter(
                    schemaInfo => !IGNORED_ITEM_NAMESPACES_FOR_RECURSIVE_REMOVAL[schemaInfo.referencedMap]
                ) ?? []
            )
        }

        const removeItemRecursivelyInternal = (
            itemPointer: Pointer,
            {shouldRemovePermanentDataNodes}: RemoveItemRecursivelyOptions
        ) => {
            const item = localDal.get(itemPointer)

            if (!item) {
                return
            }

            if (!shouldRemovePermanentDataNodes && dal.schema.isPermanentDataType(itemPointer.type, item.type)) {
                return
            }

            const ownedRefFields = getOwnedRefFieldSchemasForItemRemoval(itemPointer.type, item.type)
            for (const ownedRefField of ownedRefFields) {
                const queryPath = _.flatMap(ownedRefField.path)
                const itemQuery = _.get(item, queryPath)

                if (typeof itemQuery === 'string' && itemQuery.length) {
                    const subItemPointer = pointerUtils.getPointer(
                        _.trimStart(itemQuery, '#'),
                        ownedRefField.referencedMap
                    )
                    removeItemRecursivelyInternal(subItemPointer, {shouldRemovePermanentDataNodes: false})
                } else if (Array.isArray(itemQuery)) {
                    for (const query of itemQuery) {
                        const subItemPointer = pointerUtils.getPointer(
                            _.trimStart(query, '#'),
                            ownedRefField.referencedMap
                        )
                        removeItemRecursivelyInternal(subItemPointer, {shouldRemovePermanentDataNodes: false})
                    }
                }
            }

            localDal.remove(itemPointer)
        }

        const removeItemRecursively: DataModelAPI['removeItemRecursively'] = (itemPointer: Pointer) =>
            removeItemRecursivelyInternal(itemPointer, {shouldRemovePermanentDataNodes: false})

        const removeItemRecursivelyIncludingPermanentNodes: DataModelAPI['removeItemRecursively'] = (
            itemPointer: Pointer
        ) => removeItemRecursivelyInternal(itemPointer, {shouldRemovePermanentDataNodes: true})

        const removeItem: DataModelAPI['components']['removeItem'] = (compPointer: Pointer, namespace: string) => {
            const item = getComponentItem(compPointer, namespace)
            if (item) {
                const queryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[namespace]
                const refPointer = getInnerPointer(compPointer, queryKey)
                localDal.remove(refPointer)

                removeItemRecursively(pointerUtils.getPointer(item.id, namespace))
            }
        }

        const removeItemForDesktopAndMobile = (compId: string, namespace: string): void => {
            const queryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[namespace]
            const mobilePointer = getInnerPointer(getPointer(compId, 'MOBILE'), queryKey)
            localDal.remove(mobilePointer)
            removeItem(getPointer(compId, VIEW_MODES.DESKTOP), namespace)
        }

        const linkDataToItem = (ownerPointer: Pointer, dataItemId: string, itemQuery: string | string[]) => {
            const innerPointer = getInnerPointer(ownerPointer, itemQuery)
            localDal.set(innerPointer, dataItemId)
        }

        const linkComponentToItemByTypeDesktopAndMobile: DataModelAPI['components']['linkComponentToItemByTypeDesktopAndMobile'] =
            (componentPointer: Pointer, itemId: string, itemType: string, itemQuery?: string) => {
                const compPointer = getRepeatedItemPointerIfNeeded(componentPointer)
                const itemIdWithHashIfNeeded = DATA_TYPES_VALUES_WITH_HASH[itemType] ? `#${itemId}` : itemId
                const itemQueryToUse = itemQuery || COMP_DATA_QUERY_KEYS_WITH_STYLE[itemType]
                _.forEach(
                    [
                        pointers.structure.getDesktopPointer(compPointer),
                        pointers.structure.getMobilePointer(compPointer)
                    ],
                    ptr => {
                        if (localDal.has(ptr)) {
                            linkDataToItem(ptr, itemIdWithHashIfNeeded, itemQueryToUse)
                        }
                    }
                )
            }

        const linkDataToItemByType = (ownerPointer: Pointer, itemId: string, namespace: string, itemQuery?: string) => {
            if (_.includes(VIEW_MODES, ownerPointer.type)) {
                linkComponentToItemByTypeDesktopAndMobile(ownerPointer, itemId, namespace, itemQuery)
                return
            }
            const itemIdWithHashIfNeeded = DATA_TYPES_VALUES_WITH_HASH[namespace] || itemQuery ? `#${itemId}` : itemId
            const itemPath = schemaAPI().getReferencePath(ownerPointer, namespace, itemQuery) as string[] | undefined
            if (itemPath) {
                linkDataToItem(ownerPointer, itemIdWithHashIfNeeded, itemPath)
            }
        }

        const getAllRepeaterOverridesInNamespaceForComponent = (
            pointer: Pointer,
            namespace: string,
            langCode?: string
        ): Pointer[] => {
            if (!doesItemTypeSupportsRepeatedItem(namespace)) {
                return []
            }

            const itemPointer = getComponentItemPointer(pointer, namespace)
            if (!itemPointer) {
                return []
            }

            const repeaterPointer = pointers.structure.getAncestorByPredicate(pointer, (ancestorPointer: Pointer) =>
                isRepeater(dal, ancestorPointer)
            )
            const repeaterDataPointer = repeaterPointer && getComponentItemPointer(repeaterPointer, DATA_TYPES.data)
            const repeaterData = repeaterDataPointer && localDal.get(repeaterDataPointer)

            if (!repeaterData) {
                return []
            }

            return _.reduce(
                repeaterData.items,
                (res: Pointer[], itemId) => {
                    const overridePointer = pointers.data.getItem(
                        namespace,
                        getUniqueDisplayedId(itemPointer.id, itemId),
                        repeaterData.metaData.pageId
                    )
                    const translatedPointer = translateIfNeeded(dal, pointers, overridePointer, langCode)
                    if (localDal.has(translatedPointer)) {
                        res.push(overridePointer)
                    }
                    return res
                },
                []
            )
        }

        const getAllRepeaterOverridesForComponent: DataModelAPI['components']['getAllRepeaterOverridesForComponent'] = (
            pointer: Pointer,
            langCode?: string
        ): Pointer[] =>
            _.flatMap(COMP_DATA_QUERY_KEYS_WITH_STYLE, (query: string, namespace: string) =>
                getAllRepeaterOverridesInNamespaceForComponent(pointer, namespace, langCode)
            )

        const getAllRepeaterDataOverridesForComponent: DataModelAPI['components']['getAllRepeaterDataOverridesForComponent'] =
            (pointer: Pointer, langCode?: string) =>
                getAllRepeaterOverridesInNamespaceForComponent(pointer, DATA_TYPES.data, langCode)

        const getComponentItemsPointers: DataModelAPI['components']['getItemsPointers'] = (
            componentPointer: Pointer,
            namespace: string
        ): Pointer[] => {
            const refArrayOrValuePointer = getComponentItemPointer(componentPointer, namespace)

            if (!refArrayOrValuePointer) {
                return []
            }

            const pageId = pointers.structure.getPageOfComponent(componentPointer)?.id
            const {data: dataApi} = extensionAPI as DataExtensionAPI

            return dataApi.refArray.getDataPointersFromRefArray(refArrayOrValuePointer, pageId)
        }

        function getComponentDataItemId(componentPointer: Pointer, namespace: string): string | undefined {
            const namespaceKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[namespace]
            const dataQueryPointer = pointers.getInnerPointer(componentPointer, namespaceKey)
            const dataQuery = localDal.get(dataQueryPointer)
            if (dataQuery) {
                return stripHashIfExists(dataQuery)
            }

            const idPointer = pointers.getInnerPointer(componentPointer, 'id')
            return hooksApi().executeHookAndUpdateValue(
                DATA_MODEL_HOOKS.NAMESPACE_ITEM.GET_QUERY_ID.createEvent({
                    compPointer: componentPointer,
                    propName: namespaceKey,
                    compId: localDal.get(idPointer)
                }),
                dataQuery
            )
        }

        const createDataItemByType = <T = Record<string, any>>(dataType: string, overrides?: any): T =>
            schemaAPI().createItemAccordingToSchema(dataType, DATA_TYPES.data, overrides) as T

        const createStyleItemByType = (styleType: string): StyleRef =>
            schemaAPI().createItemAccordingToSchema(styleType, DATA_TYPES.theme)

        const createBehaviorsItem = (behaviors: string): ObsoleteBehaviorsList =>
            schemaAPI().createItemAccordingToSchema('ObsoleteBehaviorsList', DATA_TYPES.behaviors, {items: behaviors})

        const createConnectionsItem = (connections: IConnectionItem[]): ConnectionList =>
            schemaAPI().createItemAccordingToSchema('ConnectionList', DATA_TYPES.connections, {items: connections})

        const createMobileHintsItem = (mobileHints?: MobileHints): MobileHints =>
            schemaAPI().createItemAccordingToSchema('MobileHints', DATA_TYPES.mobileHints, mobileHints)

        const createDesignItemByType = (schemaName: string): any => {
            const schema = schemaAPI()
            if (schema.hasSchemaForDataType(DATA_TYPES.design, schemaName)) {
                return schema.createItemAccordingToSchema(schemaName, DATA_TYPES.design)
            }
        }

        const createPropertiesItemByType = (propertiesType: string): any => {
            return schemaAPI().createItemAccordingToSchema(propertiesType, DATA_TYPES.prop)
        }

        const duplicate = (id: string, namespace: string, pageId: string, toPageId?: string): Pointer => {
            const item = getItem(id, namespace, pageId)
            return addItem(item, namespace, toPageId ?? pageId)
        }

        return {
            addItem,
            addDeserializedItem,
            linkDataToItemByType,
            addItemWithRefReuse,
            getItem,
            getDeserializedItem,
            generateItemIdWithPrefix,
            generateUniqueIdByType,
            createDataItemByType,
            createStyleItemByType,
            createBehaviorsItem,
            createConnectionsItem,
            createMobileHintsItem,
            createDesignItemByType,
            createPropertiesItemByType,
            components: {
                linkComponentToItemByTypeDesktopAndMobile,
                addItem: updateComponentItem,
                getItem: getComponentItem,
                getItemPointer: getComponentItemPointer,
                removeItemForDesktopAndMobile,
                removeItem,
                getAllRepeaterOverridesForComponent,
                getAllRepeaterDataOverridesForComponent,
                getItemsPointers: getComponentItemsPointers,
                getComponentDataItemId
            },
            removeItemRecursively,
            removeItemRecursivelyIncludingPermanentNodes,
            duplicate
        }
    }

    return {
        dataModel: {
            ...createExtensionApiForLimitedDal(dataAccessApi()),
            withSuper: createExtensionApiForLimitedDal(dataAccessApi().withSuper)
        }
    }
}

const createExtension = (): Extension => ({
    name: 'dataModel',
    dependencies: new Set(['data', 'variants', 'dataAccess']),
    createExtensionAPI
})

export {createExtension}
