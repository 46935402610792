import * as documentManagerUtils from '@wix/document-manager-utils'
import type {
    Callback,
    Callback1,
    Callback2,
    CompRef,
    Pointer,
    PS,
    BlocksAppStudioModel,
    WidgetPluginDescriptor,
    PagesStructure
} from '@wix/document-services-types'
import {guidUtils} from '@wix/santa-core-utils'
import {warmupUtils, utils} from '@wix/santa-ds-libs'
import _ from 'lodash'
import experiment from 'experiment-amd'
import appStudioWidgets from '../appStudioWidgets/appStudioWidgets'
import component from '../component/component'
import componentCode from '../component/componentCode'
import nicknameContextRegistrar from '../component/nicknameContextRegistrar'
import componentDetectorAPI from '../componentDetectorAPI/componentDetectorAPI'
import metaDataUtils from '../componentsMetaData/metaDataUtils'
import constants from '../constants/constants'
import dataModel from '../dataModel/dataModel'
import documentMode from '../documentMode/documentMode'
import environment from '../environment/environment'
import features from '../features/features'
import hooks from '../hooks/hooks'
import blankPageStructure from '../page/blankPageStructure'
import page from '../page/page'
import pageData from '../page/pageData'
import platform from '../platform/platform'
import provision from '../platform/provision'
import refComponentUtils from '../refComponent/refComponentUtils'
import dsUtils from '../utils/utils'
import codeAppInfoUtils from '../wixCode/utils/codeAppInfo'
import appBuilderPlatformApp from './appBuilderPlatformApp'
import appMetadata from './appMetadata'
import appStudioAppDataUtils from './appStudioAppDataUtils'
import appStudioAppDescription from './appStudioAppDescription'
import appStudioAppName from './appStudioAppName'
import appStudioSSRCache from './appStudioSSRCache'
import appStudioBobApp from './appStudioBobApp'
import appStudioCodePackages from './appStudioCodePackages'
import appStudioDataModel, {WidgetInfo} from './appStudioDataModel'
import appStudioDependencies from './appStudioDependencies'
import appStudioPanels from './appStudioPanels'
import appStudioPlugins from './appStudioPlugins'
import appStudioPresets from './appStudioPresets'
import appStudioPresetsHooks from './appStudioPresetsHooks'
import appStudioSchemaHelpers from './appStudioSchemaHelpers'
import appStudioStructureFixer from './appStudioStructureFixer'
import appType from './appType'
import blocksLifecycle, {VersionType} from './blocksLifecycle'
import appStudioConstants from './constants'
import nameGenerator from './nameGenerator'
import appStudioNickname from './nickname'
import controllerConfig from './controllerConfig'
import widgetConfigs from './widgetConfigs/widgetConfigs'
import widgetDescriptors from './widgetDescriptors'
import blocksDashboards from './blocksDashboards'

const APP_STUDIO_DATA_TYPE = 'AppStudioData'
const WIDGET_TYPE = 'WidgetDescriptor'
const VARIATION_TYPE = 'VariationDescriptor'
const NEW_WIDGET_PREFIX = 'Widget'
const NEW_VARIATION_PREFIX = 'Variation'
const APP_WIDGET_TYPE = 'platform.components.AppWidget'
const APP_WIDGET_NEW_TYPE_PREFIX = 'platform.builder'

const {fetchJson} = warmupUtils.requestsUtil
const {ReportableError} = documentManagerUtils
const {cookieUtils} = utils

function getCompsFromVariationPages(ps: PS, compRef: CompRef) {
    const pageContainer = component.getPage(ps, compRef)
    if (
        !pageContainer ||
        !appStudioDataModel.isWidgetPage(ps, pageContainer.id) ||
        appStudioDataModel.isVariationPage(ps, pageContainer.id)
    ) {
        return
    }

    const widgetPointer = appStudioDataModel.getWidgetByRootCompId(ps, pageContainer.id)

    const compNickName = appStudioNickname.get(ps, compRef)

    if (!compNickName) {
        return
    }
    const variations = getWidgetVariations(ps, widgetPointer)

    return _(variations)
        .map(variation => {
            const pageId = appStudioDataModel.getRootCompIdByPointer(ps, variation.pointer)
            const allComps = componentDetectorAPI.getAllComponentsFromFull(ps, pageId)
            return _.filter(allComps, comp => appStudioNickname.get(ps, comp) === compNickName)
        })
        .flatten()
        .value()
}

function removeFromAllVariations(ps: PS, compRef: CompRef) {
    const isDesktop = ps.pointers.components.getViewMode(compRef) === constants.VIEW_MODES.DESKTOP
    if (isDesktop) {
        const compsToDelete = getCompsFromVariationPages(ps, compRef)
        _.forEach(compsToDelete, comp => component.remove(ps, comp))
    }
}

function setNicknameInAllVariations(ps: PS, compRef: CompRef, newNickName: string) {
    const compsInVariations = getCompsFromVariationPages(ps, compRef)
    _.forEach(compsInVariations, comp => componentCode.setNickname(ps, comp, newNickName))
}

const propertiesToSync = ['isCollapsed', 'isHidden', 'isDisabled']

function setPropertyInAllVariations(ps: PS, compRef: CompRef, propertiesItem) {
    const compsInVariations = getCompsFromVariationPages(ps, compRef)
    const propertiesToUpdate = _.pick(propertiesItem, propertiesToSync)
    if (!_.isEmpty(propertiesToUpdate)) {
        _.forEach(compsInVariations, comp => dataModel.updatePropertiesItem(ps, comp, propertiesToUpdate))
    }
}

const getGhostControllerData = (ps: PS, widgetContext: Pointer, compRef: CompRef, nickname: string) => {
    const compData = component.data.get(ps, widgetContext)
    compData.settings = JSON.parse(compData.settings)
    const connections = appStudioWidgets.getPrimaryConnectionItems(ps, compRef)
    const controllerId = _.get(connections, '0.controllerId')
    const compId = `${_.uniqueId('ghost-')}_${nickname}`

    return {
        nickname,
        controllerData: compData,
        compId,
        connections,
        controllerBehaviors: compData.settings.behaviors,
        dependencies: [controllerId]
    }
}

const getGhostStructureByWidgetPointer = (ps: PS, widgetPointer: Pointer) => {
    const widgetContext = appStudioDataModel.getAppWidgetRefFromPointer(ps, widgetPointer)
    const pageStructure = component.serialize(ps, widgetContext)
    return buildGhostStructure(ps, pageStructure)
}

const getGhostControllersInfoFromGhostStructure = rootIdToGhostStructure => {
    let controllersInfo = _.reduce(
        rootIdToGhostStructure,
        (result, value) => {
            const controller = _.mapValues(value.controllers, controllerInfo => {
                const {type} = controllerInfo.controllerData.settings
                const connections = _.map(rootIdToGhostStructure[type].components, compRef =>
                    _.get(compRef, ['connections', 'items', 0])
                )
                controllerInfo.connections = connections
                return controllerInfo
            })
            _.assign(result, controller)
            return result
        },
        {}
    )

    // @ts-expect-error
    controllersInfo = _.mapKeys(controllersInfo, value => value.compId)
    return controllersInfo
}

const setGhostableData = (ps: PS) => {
    const focusedWidgetPointer = appStudioDataModel.getWidgetByRootCompId(ps, ps.siteAPI.getFocusedRootId())

    if (!focusedWidgetPointer) {
        return
    }

    const containedRefComponents = appStudioDataModel.getContainedWidgets(ps, focusedWidgetPointer, {})
    const innerWidgetsPointers = _.map(containedRefComponents, ps.pointers.data.getDataItemFromMaster)

    const widgetsInPage = _.concat(focusedWidgetPointer, innerWidgetsPointers)

    const rootIdToGhostStructure = _(widgetsInPage)
        .keyBy(widgetPointer => appStudioDataModel.getRootCompIdByPointer(ps, widgetPointer))
        .mapValues(widgetPointer => getGhostStructureByWidgetPointer(ps, widgetPointer))
        .value()

    const ghostStructure = {
        [appStudioConstants.APP_BUILDER_PREVIEW_APP_ID]: _.mapValues(rootIdToGhostStructure, value => value.components)
    }
    const controllers = getGhostControllersInfoFromGhostStructure(rootIdToGhostStructure)
    const ghostControllers = {
        [appStudioConstants.APP_BUILDER_PREVIEW_APP_ID]: controllers
    }

    platform.setGhostStructure(ps, ghostStructure)
    platform.setGhostControllers(ps, ghostControllers)
}

const updateBlocksPreviewData = (ps: PS, dataToUpdate) => {
    const currentData = ps.dal.get(ps.pointers.blocks.getBlocksPreviewData())
    const updatedData = _.assign(currentData, dataToUpdate)
    ps.dal.set(ps.pointers.blocks.getBlocksPreviewData(), updatedData)
}

function handleViewModeChange(ps: PS, viewMode: string) {
    if (viewMode === 'preview') {
        setGhostableData(ps)
        updateBlocksPreviewData(ps, {widgetDescriptorsMap: widgetDescriptors.createPublicDescriptorsMap(ps)})
    }
}

function displayFirstWidget(ps: PS) {
    const mainPageId = page.homePage.get(ps)
    const firstWidgetOnLoad = appStudioDataModel.getWidgetByRootCompId(ps, mainPageId)
    displayFirstPreset(ps, firstWidgetOnLoad)
}

function validateAndUpdateRootContainerHeight(ps: PS, newLayout, compPointer: CompRef) {
    const pageRef = component.getPage(ps, compPointer)
    if (appStudioDataModel.getEntityByPage(ps, pageRef) !== 'WIDGET') {
        return newLayout
    }

    const parentPointer = ps.pointers.components.getParent(compPointer)
    const parentType = parentPointer && metaDataUtils.getComponentType(ps, parentPointer)
    if (parentType === APP_WIDGET_TYPE) {
        if (newLayout.componentLayout.height?.type && newLayout.componentLayout.height?.type !== 'auto') {
            ps.extensionAPI.logger.captureError(
                new ReportableError({
                    message: 'Attempted to set height to root container',
                    errorType: 'heightToBlocksRootContainer',
                    tags: {blocks: true},
                    extras: {newLayout: _.cloneDeep(newLayout), compPointer}
                })
            )
            newLayout.componentLayout.height = {type: 'auto'}
        }
        if (newLayout.componentLayout.minHeight?.value && newLayout.componentLayout.minHeight?.value !== 0) {
            ps.extensionAPI.logger.captureError(
                new ReportableError({
                    message: 'Attempted to set min-height to root container',
                    errorType: 'minHeightToBlocksRootContainer',
                    tags: {blocks: true},
                    extras: {newLayout: _.cloneDeep(newLayout), compPointer}
                })
            )
            newLayout.componentLayout.minHeight.value = 0
        }
    }

    return newLayout
}

function setHooks() {
    hooks.registerHook(hooks.HOOKS.REMOVE.BEFORE, removeFromAllVariations)
    hooks.registerHook(
        hooks.HOOKS.REMOVE.AFTER,
        updateContainedWidgetsIfNeeded,
        'wysiwyg.viewer.components.RefComponent'
    )
    hooks.registerHook(hooks.HOOKS.WIX_CODE.SET_NICKNAME_BEFORE, setNicknameInAllVariations)
    hooks.registerHook(hooks.HOOKS.PROPERTIES.UPDATE_AFTER, setPropertyInAllVariations)
    hooks.registerHook(hooks.HOOKS.CHANGE_PARENT.AFTER, removeFromAllVariations)
    hooks.registerHook(hooks.HOOKS.CHANGE_COMPONENT_VIEW_MODE.BEFORE, handleViewModeChange)
    hooks.registerHook(
        hooks.HOOKS.METADATA.MAXIMUM_CHILDREN_NUMBER,
        shouldPageHaveOneChild,
        'mobile.core.components.Page'
    )
    hooks.registerHook(
        hooks.HOOKS.ADD.AFTER,
        appStudioPresetsHooks.createPresetsForDuplicatedWidget,
        'mobile.core.components.Page'
    )
    hooks.registerHook(
        hooks.HOOKS.ADD.AFTER,
        appStudioPresetsHooks.setScopedPresetsForInnerWidget,
        'wysiwyg.viewer.components.RefComponent'
    )
    hooks.registerHook(
        hooks.HOOKS.RESPONSIVE_LAYOUT.BEFORE_UPDATE,
        validateAndUpdateRootContainerHeight,
        'mobile.core.components.Container'
    )
}

function initialize(ps: PS) {
    ps.dal.set(ps.pointers.general.getMobileConversionHeuristicStrategy(), 'appbuilder')
    ps.dal.set(ps.pointers.general.getRenderFlag('isWixBlocks'), true)
    documentMode.setShouldKeepTPAComps(ps, false)
    displayFirstWidget(ps)
    setHooks()
    if (experiment.isOpen('dm_moveNicknameContextProviderExt')) {
        ps.extensionAPI.nicknameContext.setContextProvider(ps.extensionAPI.blocks.getComponentNicknameContext)
    } else {
        nicknameContextRegistrar.setContextProvider(getComponentNicknameContext)
    }
    appBuilderPlatformApp.updateApp(ps)
}

function displayFirstPreset(ps: PS, widgetPointer: Pointer) {
    const [firstPreset] = appStudioPresets.getWidgetPresets(ps, widgetPointer)

    if (firstPreset) {
        appStudioPresets.displayPreset(ps, firstPreset.pointer, widgetPointer)
    }
}

function shouldPageHaveOneChild(ps: PS, metaDataValue, pagePtr: CompRef) {
    if (isConfigurationPage(ps, pagePtr) || appStudioDataModel.isWidgetPage(ps, pagePtr.id)) {
        return 1
    }
    return metaDataValue
}

function isConfigurationPage(ps: PS, pageRef: CompRef) {
    const intentData = features.getFeatureData(ps, pageRef, 'intent')
    return !!intentData && intentData.intent === 'configurationPage'
}

function updateContainedWidgetsIfNeeded(
    ps: PS,
    compRef: CompRef,
    deletingParent,
    removeArgs,
    deletedParentFromFull,
    copyDataItem,
    parentPointer: CompRef
) {
    const pageContainer = component.getPage(ps, parentPointer)
    if (pageContainer && appStudioDataModel.isWidgetPage(ps, pageContainer.id)) {
        appStudioDataModel.updateWidgetContainedWidgets(ps)
    }
}

function getComponentNicknameContext(ps: PS, compPointer: CompRef): null | CompRef {
    if (compPointer) {
        const pagePointer = component.isPageComponent(ps, compPointer)
            ? compPointer
            : component.getPage(ps, compPointer)

        if (!pagePointer) {
            return null
        }

        if (ps.pointers.components.isMasterPage(pagePointer)) {
            return null
        }

        if (pagePointer) {
            return appStudioDataModel.getRootAppWidgetByPage(ps, pagePointer)
        }
    }

    return null
}

function createBlankAppStudioData(ps: PS, appStudioData) {
    const appStudioDefaults = ps.extensionAPI.dataModel.createDataItemByType(APP_STUDIO_DATA_TYPE)
    appStudioDefaults.id = appStudioDataModel.getNewDataId()
    const siteName = ps.dal.get(ps.pointers.documentServicesModel.getSiteName()) || 'MyProject1' //TODO: key from Uval
    appStudioDefaults.name = _.replace(siteName, 'mysite', 'MyProject')

    return _.defaults(appStudioDefaults, appStudioData)
}

function updateWidgetsOnMasterPage(ps: PS, newWidgetsList) {
    const appStudioData = appStudioDataModel.getAppStudioData(ps)
    appStudioData.widgets = newWidgetsList
    appStudioDataModel.updateAppStudioOnMasterPage(ps, appStudioData)
}

function createAppStudioIfNeeded(ps: PS, appStudioData?) {
    let appStudio = appStudioDataModel.getAppStudioData(ps)
    if (_.isUndefined(appStudio)) {
        appStudio = createBlankAppStudioData(ps, appStudioData)
        appStudioDataModel.updateAppStudioOnMasterPage(ps, appStudio)
    }
}

function provisionWixBlocks(ps: PS) {
    return provision.provision(ps, appStudioConstants.APP_BUILDER_PREVIEW_APP_ID)
}

function getDevSiteAppDefId(ps: PS) {
    const devSiteAppDefIdPointer = ps.pointers.general.getDevSiteAppDefIdPointer()
    return ps.dal.get(devSiteAppDefIdPointer)
}

function setDraftAppName(ps: PS, name: string) {
    const draftAppNamePointer = ps.pointers.general.getDraftAppNamePointer()
    ps.dal.set(draftAppNamePointer, name)
}

function setAppStudioModel(ps: PS, appStudioModel: BlocksAppStudioModel) {
    const appStudioModelPointer = ps.pointers.general.getAppStudioModelPointer()
    ps.dal.set(appStudioModelPointer, appStudioModel)
}

function addWidgetToAppStudio(ps: PS, newWidget) {
    const newWidgetsList = appStudioDataModel.getAppStudioData(ps).widgets
    newWidgetsList.push(newWidget)
    updateWidgetsOnMasterPage(ps, newWidgetsList)
    return newWidgetsList
}

export interface WidgetData {
    id: string
    name: string
    kind: string
    rootCompId: string
    widgetApi: string
    defaultSize: string
    plugin?: WidgetPluginDescriptor
    compType?: string
    structureRef?: {
        pageId: string
        rootCompId: string
    }
}

function createBlankWidgetData(ps: PS, widgetID: string, widgetName: string, widgetKind: string, newPageRef: CompRef) {
    const pageId = newPageRef.id
    const newWidget: WidgetData = ps.extensionAPI.dataModel.createDataItemByType(WIDGET_TYPE) as any
    newWidget.id = widgetID
    newWidget.name = widgetName
    newWidget.kind = widgetKind
    newWidget.rootCompId = `#${pageId}`
    if (experiment.isOpen('dm_builderDataInAppDescriptor')) {
        newWidget.compType = guidUtils.getUniqueId(`${APP_WIDGET_NEW_TYPE_PREFIX}.`, undefined)
    }
    return newWidget
}

function createDuplicatedWidgetData(
    ps: PS,
    widgetToDuplicate: WidgetData,
    widgetID: string,
    widgetName: string,
    newPageRef
) {
    const newWidgetData = createBlankWidgetData(ps, widgetID, widgetName, widgetToDuplicate.kind, newPageRef)
    newWidgetData.widgetApi = _.cloneDeep(widgetToDuplicate.widgetApi)
    newWidgetData.defaultSize = widgetToDuplicate.defaultSize
    newWidgetData.plugin = widgetToDuplicate.plugin
    return newWidgetData
}

function updateWidgetStructureRefData(ps: PS, widgetPointer: Pointer, pageRef: CompRef) {
    if (!experiment.isOpen('dm_builderDataInAppDescriptor')) {
        return
    }
    const widgetRoot = appStudioDataModel.getRootAppWidgetByPage(ps, pageRef)
    if (!widgetRoot) {
        throw new ReportableError({
            message: `widget root not found in page ${pageRef.id}`,
            errorType: 'widgetRootNotFound',
            tags: {blocks: true},
            extras: {pageId: pageRef.id}
        })
    }
    const widgetData = appStudioDataModel.getData(ps, widgetPointer)
    widgetData.structureRef = {
        pageId: pageRef.id,
        rootCompId: widgetRoot.id
    }
    appStudioDataModel.setWidgetData(ps, widgetPointer, widgetData)
}

function createBlankVariationData(ps: PS, variationPointer: Pointer, variationName: string, newPageRef) {
    const variationDataItem = ps.extensionAPI.dataModel.createDataItemByType(VARIATION_TYPE)
    variationDataItem.id = variationPointer.id
    variationDataItem.name = variationName
    variationDataItem.rootCompId = `#${_.get(newPageRef, 'id')}`

    ps.dal.set(variationPointer, variationDataItem)

    return variationDataItem
}

function validateWidget(ps: PS, name: string) {
    if (_.isEmpty(name)) {
        throw new Error('appStudio.widgets: name is required')
    }
}

function validateVariationName(ps: PS, variationName: string, widgetPointer: Pointer) {
    if (_.isEmpty(variationName)) {
        throw new Error('appStudio.variations: name is required')
    }

    if (_.includes(_.map(getWidgetVariations(ps, widgetPointer), 'name'), variationName)) {
        throw new Error('appStudio.variations: variation name already exists')
    }
}

function addRootStructure(ps: PS, rootStructure, pageRef: CompRef, controllerType: string, newWidgetConfig) {
    const rootRef = component.getComponentToAddRef(ps, pageRef)
    const structureToAdd = _.defaultsDeep({components: [rootStructure]}, newWidgetConfig.appWidgetStructure)
    component.add(ps, rootRef, pageRef, structureToAdd)
    appStudioWidgets.setInitialAppWidgetData(ps, rootRef, controllerType || pageRef.id)
}

function addRootStructureWrappedWithStageContainer(
    ps: PS,
    rootStructure,
    pageRef: CompRef,
    controllerType: string,
    newWidgetConfig
) {
    const stageContainerRef = component.getComponentToAddRef(ps, pageRef)
    const rootWidgetStructure = _.defaultsDeep({components: [rootStructure]}, newWidgetConfig.appWidgetStructure)
    const structureToAdd = _.defaultsDeep({components: [rootWidgetStructure]}, newWidgetConfig.stageContainerStructure)
    component.add(ps, stageContainerRef, pageRef, structureToAdd)

    const rootWidgetRef = appStudioDataModel.getRootAppWidgetByPage(ps, pageRef)
    appStudioWidgets.setInitialAppWidgetData(ps, rootWidgetRef, controllerType || pageRef.id)
}

function fixRootAppWidgetData(ps: PS, pageRef: CompRef) {
    const widgetRoot = appStudioDataModel.getRootAppWidgetByPage(ps, pageRef)

    const widgetRootData = component.data.get(ps, widgetRoot)
    const parsedSettings = JSON.parse(widgetRootData.settings)
    parsedSettings.type = pageRef.id
    delete parsedSettings.devCenterWidgetId

    component.data.update(ps, widgetRoot, {controllerType: pageRef.id, settings: JSON.stringify(parsedSettings)})
}

function createWidgetPageWithPageStructure(
    ps: PS,
    widgetName: string,
    newPageRef: CompRef,
    widgetPageStructure,
    newWidgetPointer?: Pointer,
    presetDescriptors?
) {
    const oldIdsToNewIdsMap = {}
    page.addPageInternal(ps, newPageRef, widgetName, widgetPageStructure, false, oldIdsToNewIdsMap)

    fixRootAppWidgetData(ps, newPageRef)

    appStudioPresets.addPresetByPresetsDescriptors(ps, presetDescriptors, newWidgetPointer, oldIdsToNewIdsMap)
}

function createWidgetPageWithStructure(
    ps: PS,
    widgetName: string,
    newPageRef: CompRef,
    controllerType: string,
    initialWidgetRootStructure?,
    newWidgetPointer?: Pointer,
    pluginDescriptor?,
    initialPageStructure?: Partial<PagesStructure>
) {
    const isResponsive = environment.isResponsiveDocument(ps)
    const newWidgetConfig = isResponsive
        ? widgetConfigs.createNewResponsiveWidgetConfig()
        : widgetConfigs.createNewWidgetConfig()
    page.add(ps, newPageRef, widgetName, initialPageStructure || newWidgetConfig.getPageStructure(ps))

    features.updateFeatureData(ps, newPageRef, 'intent', {intent: 'widgetPage', type: 'Intent'})

    createFirstPreset(ps, newWidgetPointer)

    if (pluginDescriptor) {
        appStudioPlugins.createWidgetPlugin(ps, newWidgetPointer, pluginDescriptor)
    }

    if (initialWidgetRootStructure) {
        if (isResponsive) {
            addRootStructureWrappedWithStageContainer(
                ps,
                initialWidgetRootStructure,
                newPageRef,
                controllerType,
                newWidgetConfig
            )
            updateWidgetStructureRefData(ps, newWidgetPointer, newPageRef)
        } else {
            addRootStructure(ps, initialWidgetRootStructure, newPageRef, controllerType, newWidgetConfig)
        }
    }
}

function createWidget(
    ps: PS,
    widgetDataId: string,
    widgetNameOrOptions?,
    initialWidgetRootStructureOrCallback?,
    callback?
) {
    let options

    if (typeof widgetNameOrOptions === 'object' && widgetNameOrOptions !== null) {
        options = widgetNameOrOptions
        callback = initialWidgetRootStructureOrCallback
    } else {
        options = {
            widgetName: widgetNameOrOptions,
            initialWidgetRootStructure: initialWidgetRootStructureOrCallback
        }
    }

    return _createWidget(ps, widgetDataId, options, callback)
}

function _createWidget(ps: PS, widgetDataId: string, options: any = {}, callback?) {
    const {
        widgetKind,
        initialWidgetRootStructure,
        presetDescriptors,
        pluginDescriptor,
        widgetPageStructure,
        initialPageStructure
    } = options

    const widgetName = generateNewWidgetName(ps, options.widgetName || NEW_WIDGET_PREFIX, !!options.widgetName)
    createAppStudioIfNeeded(ps)
    provisionWixBlocks(ps)
    validateWidget(ps, widgetName)

    const newPageRef = page.getPageIdToAdd(ps)
    const newWidget = createBlankWidgetData(ps, widgetDataId, widgetName, widgetKind, newPageRef)
    const newWidgets = addWidgetToAppStudio(ps, newWidget)

    const newWidgetPointer = ps.pointers.data.getDataItemFromMaster(newWidget.id)
    if (widgetPageStructure) {
        createWidgetPageWithPageStructure(
            ps,
            widgetName,
            newPageRef,
            widgetPageStructure,
            newWidgetPointer,
            presetDescriptors
        )
    } else {
        createWidgetPageWithStructure(
            ps,
            widgetName,
            newPageRef,
            newPageRef.id,
            initialWidgetRootStructure,
            newWidgetPointer,
            pluginDescriptor,
            initialPageStructure
        )
    }

    if (_.isFunction(callback)) {
        callback(newWidgetPointer)
    }

    setNewHomepageIfNeeded(ps, newWidgets)

    return newWidgetPointer
}

function createFirstPreset(ps: PS, widgetPointer: Pointer) {
    const isResponsive = environment.isResponsiveDocument(ps)
    if (isResponsive) {
        const presetPointer = appStudioDataModel.getNewDataItemPointer(ps)
        appStudioPresets.createPreset(ps, presetPointer, widgetPointer)
    }
}

function createVariation(
    ps: PS,
    variationPointer: Pointer,
    widgetPointer: Pointer,
    initialRootStructure?,
    duplicatePageId?: string,
    newVariationName?
) {
    const isFirstVariation = _.isEmpty(getWidgetVariations(ps, widgetPointer))

    if (widgetPointer) {
        const variationName = newVariationName || generateNewVariationName(ps, NEW_VARIATION_PREFIX, widgetPointer)
        validateVariationName(ps, variationName, widgetPointer)

        const newPageRef = page.getPageIdToAdd(ps)
        const variationData = createBlankVariationData(ps, variationPointer, variationName, newPageRef)
        addVariationToWidgetDataItem(ps, variationData, widgetPointer)

        if (ps.pointers.page.isExists(duplicatePageId)) {
            page.duplicate(ps, newPageRef, duplicatePageId, undefined, false)
            setPageTitle(ps, newPageRef.id, variationName)
        } else {
            const widgetPageId = appStudioDataModel.getRootCompIdByPointer(ps, widgetPointer)
            createWidgetPageWithStructure(ps, variationName, newPageRef, widgetPageId, initialRootStructure)
        }
        if (isFirstVariation) {
            updateAllInstancesToPointToVariation(ps, widgetPointer, duplicatePageId, variationPointer)
        }
    }
}

function updateAllInstancesToPointToVariation(
    ps: PS,
    currentVariationPointer: Pointer,
    currentVariationPageId: string,
    newVariationPointer: Pointer
) {
    const allWidgets = appStudioDataModel.getAllWidgets(ps)
    const innerWidgetsForVariationChange = getWidgetInstances(
        ps,
        currentVariationPointer,
        currentVariationPageId,
        allWidgets
    )
    const newVariationRootCompId = appStudioDataModel.getRootCompIdByPointer(ps, newVariationPointer)

    _.forEach(innerWidgetsForVariationChange, widgetRef =>
        appStudioWidgets.changeVariation(ps, widgetRef, newVariationRootCompId, undefined, undefined, {
            keepOverrides: true
        })
    )
}

function getWidgetInstances(ps: PS, widgetPointer: Pointer, widgetPageId: string, possibleContainingWidgets) {
    return _.flatMap(possibleContainingWidgets, containingWidget => {
        const instancesInWidgets = getFirstInnerWidgetsLayerWithRootCompId(ps, containingWidget.pointer, widgetPageId)

        const variations = getWidgetVariations(ps, containingWidget.pointer)
        const instancesInVariations = getWidgetInstances(ps, widgetPointer, widgetPageId, variations)
        return _.concat(instancesInWidgets, instancesInVariations)
    })
}

const getFirstInnerWidgetsLayerWithRootCompId = (ps: PS, containingWidgetPointer: Pointer, rootCompId: string) => {
    const innerWidgets = appStudioDataModel.getFirstLevelRefChildren(ps, containingWidgetPointer)
    return _.filter(innerWidgets, refComp => {
        const {pageId} = dataModel.getDataItem(ps, refComp)
        return pageId === rootCompId
    })
}

function addVariationToWidgetDataItem(ps: PS, newVariation, widgetPointer: Pointer) {
    const widgetData = appStudioDataModel.getData(ps, widgetPointer)
    if (widgetData) {
        widgetData.variations.push(`#${newVariation.id}`)
        appStudioDataModel.setWidgetData(ps, widgetPointer, widgetData)
    }
}

function validateNames(newData) {
    const allFunctionsHasName = _.every(newData, 'name')
    if (!allFunctionsHasName) {
        throw new Error('appStudio.widgets: can not set widget API without a name')
    }

    const allNamesAreUniq = _.uniqBy(newData, 'name').length === newData.length
    if (!allNamesAreUniq) {
        throw new Error('appStudio.widgets: API name is already in use for this widget')
    }
}

function validateAndFixParams(newData) {
    const fixParamsIfNeeded = param => _.mapValues(param, value => (_.isNil(value) ? '' : value))
    return _.forEach(newData, data => {
        const {params} = data
        if (params) {
            _.set(
                data,
                'params',
                _.map(params, param => fixParamsIfNeeded(param))
            )
        }
    })
}

function validateSchemasNames(newData) {
    const allNamesAreUniq =
        _.uniqBy(newData, item => appStudioSchemaHelpers.getSchemaStructureKey(item)).length === newData.length
    if (!allNamesAreUniq) {
        throw new Error('appStudio.widgets: property name is already in use for this widget')
    }
}

function removeFunctionFromObject(object) {
    return JSON.parse(JSON.stringify(object))
}

function notifyPropertiesUpdateToWorker(ps: PS, widgetPointer: Pointer, oldProps, newProps) {
    if (!_.isEqual(oldProps, newProps)) {
        const pageRef = ps.pointers.components.getPage(
            dsUtils.stripHashIfExists(ps.siteAPI.getFocusedRootId()),
            constants.VIEW_MODES.DESKTOP
        )
        const widgetRoot = appStudioDataModel.getRootAppWidgetByPage(ps, pageRef)
        ps.siteAPI.triggerAppStudioWidgetOnPropsChanged(pageRef.id, widgetRoot.id, removeFunctionFromObject(newProps))
    }
}

function getWidgetFunctions(ps: PS, widgetPointer: Pointer) {
    const functionsPointer = ps.pointers.getInnerPointer(widgetPointer, 'widgetApi.functions')
    return ps.dal.get(functionsPointer)
}

function getWidgetPropertiesSchema(ps: PS, widgetPointer: Pointer) {
    const propertiesPointer = ps.pointers.getInnerPointer(widgetPointer, 'widgetApi.propertiesSchemas')
    return _.map(ps.dal.get(propertiesPointer), prop => _.assign(prop.structure, _.omit(prop, ['structure', 'type'])))
}

function getWidgetEvents(ps: PS, widgetPointer: Pointer) {
    const eventsPointer = ps.pointers.getInnerPointer(widgetPointer, 'widgetApi.events')
    return ps.dal.get(eventsPointer)
}

function setWidgetApiPart(ps: PS, apiPartName: string, widgetPointer: Pointer, newData) {
    if (apiPartName === 'propertiesSchemas') {
        validateSchemasNames(newData)

        const appStudioData = appStudioDataModel.getAppStudioData(ps)
        const customDefinitions = appStudioData.customDefinitions || []
        appStudioSchemaHelpers.validatePropertyDefaultValue(customDefinitions, newData)

        newData = _.map(newData, item => ({structure: item}))
    } else {
        validateNames(newData)
        newData = validateAndFixParams(newData)
    }
    const widgetData = appStudioDataModel.getData(ps, widgetPointer)
    appStudioDataModel.updateWidgetApi(ps, widgetPointer, widgetData, apiPartName, newData)
    appBuilderPlatformApp.updateApp(ps)
}

function generateNewWidgetName(ps: PS, prefix: string, dontShowIndexForFirstName?: boolean) {
    return nameGenerator.generateName(
        appStudioDataModel.getAllWidgets(ps).map(w => w.name),
        {
            prefix,
            dontShowIndexForFirstName
        }
    )
}

function generateNewVariationName(ps: PS, prefix: string, widgetPointer: Pointer) {
    const widgetVariations = getWidgetVariations(ps, widgetPointer)
    return nameGenerator.generateName(
        widgetVariations.map(v => v.name),
        {prefix}
    )
}

function getWidgetVariations(ps: PS, widgetPointer: Pointer): WidgetInfo[] {
    if (widgetPointer) {
        const widgetData = appStudioDataModel.getData(ps, widgetPointer) || {}

        return _.map(widgetData.variations, variationId => {
            const pointer = ps.pointers.data.getDataItemFromMaster(_.replace(variationId, '#', ''))
            const data = ps.dal.get(pointer)

            return {
                pointer,
                name: data?.name
            }
        })
    }

    return []
}

function setPageTitle(ps: PS, pageId: string, pageTitle: string) {
    const widgetPageData = pageData.getPageData(ps, pageId, true)
    widgetPageData.title = pageTitle
    pageData.setPageData(ps, pageId, widgetPageData, true)
}

function syncWidgetNameWithPage(ps: PS, widgetPointer: Pointer, widgetName: string) {
    const pageId = appStudioDataModel.getRootCompIdByPointer(ps, widgetPointer)
    setPageTitle(ps, pageId, widgetName)
}

const getVariationData = (ps: PS, variationDataId: string) =>
    ps.dal.get(ps.pointers.data.getDataItemFromMaster(dsUtils.stripHashIfExists(variationDataId)))

function copyVariations(ps: PS, widgetToCopy, newWidgetPointer: Pointer) {
    _.forEach(widgetToCopy.variations, variationDataId => {
        const variationData = getVariationData(ps, variationDataId)
        const newVariationPointer = appStudioDataModel.getNewDataItemPointer(ps)
        createVariation(
            ps,
            newVariationPointer,
            newWidgetPointer,
            null,
            dsUtils.stripHashIfExists(variationData.rootCompId),
            variationData.name
        )
    })
}

const updateWidgetControllerData = (ps: PS, widgetPointer: Pointer) => {
    const widget = appStudioDataModel.getData(ps, widgetPointer)

    const widgetPageId = dsUtils.stripHashIfExists(widget.rootCompId)

    _(widget.variations)
        .map(variationDataId => getVariationData(ps, variationDataId))
        .map('rootCompId')
        .concat([widget.rootCompId])
        .map(pageId => ps.pointers.components.getPage(dsUtils.stripHashIfExists(pageId)!, constants.VIEW_MODES.DESKTOP))
        .forEach(variationPagePointer => {
            const widgetRoot = appStudioDataModel.getRootAppWidgetByPage(ps, variationPagePointer)
            if (widgetRoot) {
                appStudioWidgets.setInitialAppWidgetData(ps, widgetRoot, widgetPageId)
            }
        })
}

function duplicatePanels(ps: PS, widgetToCopy, newWidgetPointer: Pointer) {
    const duplicatedPanelsCompIdMap = new Map<string, string>()
    _.forEach(widgetToCopy.panels || [], panelId => {
        const panelPointer = ps.pointers.data.getDataItemFromMaster(dsUtils.stripHashIfExists(panelId)!)
        const newPanelPointer = appStudioDataModel.getNewDataItemPointer(ps)

        appStudioPanels.duplicatePanel(ps, newPanelPointer, panelPointer, newWidgetPointer)
        duplicatedPanelsCompIdMap.set(
            appStudioDataModel.getRootCompIdByPointer(ps, newPanelPointer),
            appStudioDataModel.getRootCompIdByPointer(ps, panelPointer)
        )
    })

    return duplicatedPanelsCompIdMap
}

function duplicateWidget(
    ps: PS,
    widgetPointer: Pointer,
    callback?: Callback2<Pointer, Map<String, String>>,
    {widgetName = ''}: {widgetName?: string} = {}
) {
    const widgetToCopy = appStudioDataModel.getData(ps, widgetPointer)
    if (widgetToCopy) {
        const newWidgetName = generateNewWidgetName(ps, widgetName || widgetToCopy.name, !!widgetName)
        const newID = appStudioDataModel.getNewDataId()
        const newPagePointer = page.getPageIdToAdd(ps)
        const newWidgetData = createDuplicatedWidgetData(ps, widgetToCopy, newID, newWidgetName, newPagePointer)
        addWidgetToAppStudio(ps, newWidgetData)

        const newWidgetPointer = ps.pointers.data.getDataItemFromMaster(newWidgetData.id)
        page.duplicate(ps, newPagePointer, dsUtils.stripHashIfExists(widgetToCopy.rootCompId))
        syncWidgetNameWithPage(ps, newWidgetPointer, newWidgetName)

        copyVariations(ps, widgetToCopy, newWidgetPointer)
        const duplicatedPanelsCompIdMap = duplicatePanels(ps, widgetToCopy, newWidgetPointer)
        updateWidgetControllerData(ps, newWidgetPointer)
        updateWidgetStructureRefData(ps, newWidgetPointer, newPagePointer)

        if (_.isFunction(callback)) {
            callback(newWidgetPointer, duplicatedPanelsCompIdMap)
        }

        return newWidgetPointer
    }
}

function updateNameData(ps: PS, dataPointer: Pointer, newName: string) {
    const widgetData = appStudioDataModel.getData(ps, dataPointer)
    widgetData.name = newName
    appStudioDataModel.setWidgetData(ps, dataPointer, widgetData)
}

function setWidgetName(ps: PS, widgetPointer: Pointer, newName: string) {
    validateWidget(ps, newName)
    updateNameData(ps, widgetPointer, newName)
    syncWidgetNameWithPage(ps, widgetPointer, newName)
    appBuilderPlatformApp.updateApp(ps)
}

function setVariationName(ps: PS, variationPointer: Pointer, widgetPointer: Pointer, newName: string) {
    validateVariationName(ps, newName, widgetPointer)
    updateNameData(ps, variationPointer, newName)
    syncWidgetNameWithPage(ps, variationPointer, newName)
}

function getWidgetName(ps: PS, widgetPointer: Pointer) {
    const widgetData = appStudioDataModel.getData(ps, widgetPointer)
    return _.get(widgetData, 'name')
}

function getVariationName(ps: PS, variationPointer: Pointer) {
    if (_.isNil(variationPointer)) {
        return
    }
    const variationData = ps.dal.get(variationPointer)
    return _.get(variationData, 'name')
}

function displayWidget(ps: PS, widgetPointer: Pointer, callback?, presetPointer?: Pointer) {
    const pageId = widgetPointer
        ? appStudioDataModel.getRootCompIdByPointer(ps, widgetPointer)
        : getOrCreateDefaultPage(ps)

    if (pageId) {
        page.navigateTo(ps, pageId, callback)
        if (presetPointer) {
            appStudioPresets.displayPreset(ps, presetPointer, widgetPointer)
        } else if (widgetPointer) {
            displayFirstPreset(ps, widgetPointer)
        }
    }
    appBuilderPlatformApp.updateApp(ps)
}

function displayGettingStartedView(ps: PS, callback?: Callback) {
    const pageId = getOrCreateDefaultPage(ps)
    page.navigateTo(ps, pageId, callback)
}

function getNewHomepage(ps: PS, widgetsList) {
    if (widgetsList.length === 0) {
        return getOrCreateDefaultPage(ps)
    }

    const homePage = page.homePage.get(ps)

    const currentHomepageWidget = widgetsList.find(widget => dsUtils.stripHashIfExists(widget.rootCompId) === homePage)
    const newHomepageWidget = currentHomepageWidget ?? _.head(widgetsList)

    return dsUtils.stripHashIfExists(newHomepageWidget.rootCompId)
}

function findDefaultPage(ps: PS) {
    return page.getPageIdList(ps).find(pageId => appStudioDataModel.isDefaultPage(ps, page.getPage(ps, pageId)))
}

function getOrCreateDefaultPage(ps: PS) {
    const defaultPageId = findDefaultPage(ps)
    if (defaultPageId) {
        return defaultPageId
    }

    const newPageRef = page.getPageIdToAdd(ps)
    const newPageStructure = blankPageStructure.getBlankPageStructure({ps, pageId: newPageRef.id})
    page.add(ps, newPageRef, 'default', newPageStructure, false)
    features.updateFeatureData(ps, newPageRef, 'intent', {intent: 'defaultPage', type: 'Intent'})

    return newPageRef.id
}

function setNewHomepageIfNeeded(ps: PS, widgetsList) {
    const newHomepage = getNewHomepage(ps, widgetsList)

    const currentHomepage = page.homePage.get(ps)
    if (currentHomepage !== newHomepage) {
        page.homePage.set(ps, newHomepage)
    }
}

function removeWidget(ps: PS, widgetPointer: Pointer, callback?: Callback1<PS>) {
    const widgetData = appStudioDataModel.getData(ps, widgetPointer)
    if (widgetData?.rootCompId) {
        removeWidgetFromContainingWidgets(ps, widgetPointer)
        const newWidgetsList = appStudioDataModel.getAppStudioData(ps).widgets
        _.pullAt(newWidgetsList, _.findIndex(newWidgetsList, {id: widgetPointer.id}))
        removeVariationPages(ps, widgetPointer)
        removePanels(ps, widgetPointer)
        updateWidgetsOnMasterPage(ps, newWidgetsList)
        setNewHomepageIfNeeded(ps, newWidgetsList)
        page.remove(ps, widgetData.rootCompId, callback)
    }
}

function removePanels(ps: PS, widgetPointer: Pointer) {
    const panels = appStudioDataModel.getWidgetPanelsPointers(ps, widgetPointer)

    _.forEach(panels, panel => {
        appStudioPanels.removePanel(ps, panel, widgetPointer)
    })
}

function removeVariationPages(ps: PS, widgetPointer: Pointer) {
    const variations = getWidgetVariations(ps, widgetPointer)
    _.forEach(variations, variation => {
        page.remove(ps, appStudioDataModel.getRootCompIdByPointer(ps, variation.pointer))
    })
}

function removeVariation(ps: PS, variationPointer: Pointer, widgetPointer: Pointer) {
    const widgetData = appStudioDataModel.getData(ps, widgetPointer)
    if (widgetData) {
        const variationsArr = widgetData.variations
        _.remove(variationsArr, item => item === `#${variationPointer.id}`)
        widgetData.variations = variationsArr
        appStudioDataModel.setWidgetData(ps, widgetPointer, widgetData)
        const rootCompId = appStudioDataModel.getRootCompIdByPointer(ps, variationPointer)

        const firstVariationId = widgetData.variations[0]
        const firstVariationPointer = ps.pointers.data.getDataItemFromMaster(_.replace(firstVariationId, '#', ''))
        updateAllInstancesToPointToVariation(ps, variationPointer, rootCompId, firstVariationPointer)

        page.remove(ps, rootCompId)
    }
}

function removeWidgetFromContainingWidgets(ps: PS, widgetToBeRemovePointer: Pointer) {
    const widgets = appStudioDataModel.getAllWidgets(ps)
    _.forEach(widgets, widget => {
        if (widgetToBeRemovePointer.id === widget.pointer.id) {
            return
        }
        removeWidgetFromWidgetIfExists(ps, widget.pointer, widgetToBeRemovePointer)
    })
}

function removeWidgetFromWidgetIfExists(ps: PS, containingWidgetPointer: Pointer, widgetToBeRemovePointer: Pointer) {
    const refChildren = appStudioDataModel.getFirstLevelRefChildren(ps, containingWidgetPointer)
    _.forEach(refChildren, refComp => {
        const childWidgetPointer = appStudioDataModel.getWidgetPointerByRefComp(ps, refComp)
        if (_.get(childWidgetPointer, ['id']) === widgetToBeRemovePointer.id) {
            component.remove(ps, refComp)
        }
    })
}

function getVariationByRootCompId(ps: PS, rootCompId: string) {
    const {variationId} = appStudioDataModel.findVariationByPageId(ps, rootCompId)

    return variationId && ps.pointers.data.getDataItemFromMaster(_.replace(variationId, '#', ''))
}

function setCustomDefinition(ps: PS, definitionPointer: Pointer, newDefinitionData, callback?: Callback) {
    const newDefinitionName = appStudioSchemaHelpers.getSchemaStructureKey(newDefinitionData)

    const appStudioData = appStudioDataModel.getAppStudioData(ps)
    const appStudioDataDefinitions = appStudioData.customDefinitions || []

    appStudioSchemaHelpers.validateDefinitionHasProperties(newDefinitionData)

    const currentDefinitionData = definitionPointer && ps.dal.get(definitionPointer)
    if (currentDefinitionData) {
        const definitionIndex = _.findIndex(appStudioDataDefinitions, ['id', definitionPointer.id])
        const currentDefinitionName = appStudioSchemaHelpers.getSchemaStructureKey(
            appStudioDataDefinitions[definitionIndex].structure
        )
        if (currentDefinitionName !== newDefinitionName) {
            appStudioSchemaHelpers.validateNewDefinitionName(appStudioDataDefinitions, newDefinitionName)
        }
        appStudioDataDefinitions[definitionIndex] = appStudioSchemaHelpers.mergeExistingDefinitionWithDefinitionData(
            currentDefinitionData,
            newDefinitionData
        )
        appStudioDataDefinitions[definitionIndex].structure = appStudioSchemaHelpers.setPropertiesOrder(
            appStudioDataDefinitions[definitionIndex].structure
        )
    } else {
        appStudioSchemaHelpers.validateNewDefinitionName(appStudioDataDefinitions, newDefinitionName)
        newDefinitionData = appStudioSchemaHelpers.setPropertiesOrder(newDefinitionData)
        const newDefinition = appStudioSchemaHelpers.createNewDefinitionWithData(ps, dataModel, newDefinitionData)
        appStudioDataDefinitions.push(newDefinition)
    }

    appStudioSchemaHelpers.validateDefinitionDefaultValue(appStudioDataDefinitions, newDefinitionData)

    appStudioData.customDefinitions = appStudioDataDefinitions
    appStudioDataModel.updateAppStudioMetaData(ps, appStudioData)

    if (currentDefinitionData) {
        updateDefinitionUsages(ps, definitionPointer, currentDefinitionData.structure, newDefinitionData)
    }

    if (_.isFunction(callback)) {
        callback()
    }
}

function removeCustomDefinition(ps: PS, definitionPointer: Pointer) {
    const definition = ps.dal.get(definitionPointer)
    if (!definition) {
        throw new Error('appStudio.definitions: cannot remove non existing definition')
    }
    validateDefinitionHasNoUsages(ps, definitionPointer)

    const appStudioData = appStudioDataModel.getAppStudioData(ps)
    _.pullAt(appStudioData.customDefinitions, _.findIndex(appStudioData.customDefinitions, {id: definitionPointer.id}))
    appStudioDataModel.updateAppStudioMetaData(ps, appStudioData)
}

function updateDefinitionUsages(ps: PS, definitionPointer: Pointer, oldDefinitionData, updatedDefinitionData) {
    const difference = appStudioSchemaHelpers.getDefinitionPropertiesDifference(
        oldDefinitionData,
        updatedDefinitionData
    )

    if (
        !_.isEmpty(difference.addedProperties) ||
        !_.isEmpty(difference.removedProperties) ||
        !_.isEmpty(difference.restrictedProperties)
    ) {
        const usages = getAllDefinitionUsages(ps, definitionPointer)
        _.forEach(usages.definitions, usingDefinition => {
            setCustomDefinition(
                ps,
                usingDefinition.definition.pointer,
                appStudioSchemaHelpers.updateDefinitionDefault(ps, usingDefinition, difference)
            )
        })

        _.forEach(usages.widgets, usingWidget => {
            setWidgetApiPart(
                ps,
                'propertiesSchemas',
                usingWidget.widget.pointer,
                appStudioSchemaHelpers.updateWidgetPropertiesDefault(
                    ps,
                    usingWidget,
                    difference,
                    getWidgetPropertiesSchema
                )
            )
        })
    }
}

function getAllDefinitionUsages(ps: PS, definitionPointer: Pointer) {
    const definitionData = ps.dal.get(definitionPointer)
    const definitionName = appStudioSchemaHelpers.getSchemaStructureKey(definitionData.structure)
    const definitionRefId = _.get(definitionData, ['structure', definitionName, '$id'])
    return {
        widgets: appStudioSchemaHelpers.getDefinitionUsagesInWidgets(ps, getWidgetPropertiesSchema, definitionRefId),
        definitions: appStudioSchemaHelpers.getDefinitionUsagesInDefinitions(ps, definitionRefId)
    }
}

function validateDefinitionHasNoUsages(ps: PS, definitionPointer: Pointer) {
    const definitionUsages = getAllDefinitionUsages(ps, definitionPointer)
    if (definitionUsages.widgets.length > 0 || definitionUsages.definitions.length > 0) {
        throw new Error('appStudio.definitions: definition is in use, cannot remove')
    }
}

const report = (ps: PS, e: Response, url: string) => {
    ps.extensionAPI.logger.captureError(
        new ReportableError({
            message: 'fetchJson failed',
            errorType: 'HttpError',
            tags: {method: 'fetchJson'},
            extras: {
                status: e.status,
                statusText: e.statusText,
                url,
                ok: e.ok
            }
        })
    )
}

async function getTokenFromServer(ps: PS, appMarketingName: string) {
    const codeAppInfo = codeAppInfoUtils.getCodeAppInfoFromPS(ps)
    const url = 'https://www.wix.com/_api/cloud-data-packaging/v1/package-data'
    try {
        const res = await fetchJson(url, {
            mode: 'cors',
            method: 'POST',
            credentials: 'include',
            headers: new Headers({Authorization: codeAppInfo.signedInstance}),
            body: JSON.stringify({
                signedInstance: codeAppInfo.signedInstance,
                gridAppId: codeAppInfo.appId,
                appMarketingName,
                skipDataFor: []
            })
        })
        if (!res.ok) {
            report(ps, res, url)
        }
        // @ts-expect-error
        return res?.packageToken
    } catch (e: any) {
        report(ps, e, url)
        throw e
    }
}

function setTokenInMockServer(appDefId: string, appVersion: string, token: string, appMarketingName: string) {
    fetchJson('https://apps.wix.com/app-studio-server/setToken', {
        mode: 'cors',
        method: 'POST',
        credentials: 'include',
        headers: new Headers({
            'content-type': 'application/json; charset=utf-8',
            'X-XSRF-TOKEN': cookieUtils.getCookie('XSRF-TOKEN')
        }),
        body: JSON.stringify({
            token,
            appVersion,
            appDefId,
            appMarketingName
        })
    })
}

async function setWixDataTokenAsync(ps: PS, appVersion: string, appDefId: string, appMarketingName: string) {
    try {
        const token = await getTokenFromServer(ps, appMarketingName)
        return setTokenInMockServer(appDefId, appVersion, token, appMarketingName)
    } catch (errRes: any) {
        const e = await errRes.json()
        if (!_.get(e, 'message', '').contains('No collections to package')) {
            throw e
        }
    }
}

async function setWixDataToken(
    ps: PS,
    appVersion: string,
    appDefId: string,
    appMarketingName: string,
    onSuccess: Callback,
    onError: Callback1<any>
) {
    setWixDataTokenAsync(ps, appVersion, appDefId, appMarketingName).then(onSuccess, onError) // eslint-disable-line promise/prefer-await-to-then
}

function buildGhostStructure(ps: PS, pageStructure) {
    const components = {}
    const controllers = {}

    const compsToTransform = [..._.get(pageStructure, ['components'], [])]
    while (compsToTransform.length > 0) {
        let comp = compsToTransform.shift()
        let widgetContext

        const compRef = componentDetectorAPI.getComponentById(ps, comp.id)
        const nickname = appStudioNickname.get(ps, compRef)

        if (refComponentUtils.isInternalRef(ps, compRef as CompRef)) {
            const compData = component.data.get(ps, compRef)
            const pagePointer = page.getPage(ps, compData.pageId)
            widgetContext = appStudioDataModel.getRootAppWidgetByPage(ps, pagePointer)
            const {excludeFromComponents} = comp
            comp = component.serialize(ps, widgetContext)
            comp.excludeFromComponents = excludeFromComponents
        }

        if (nickname) {
            if (!comp.excludeFromComponents) {
                components[nickname] = comp
                if (!components[nickname].connections) {
                    components[nickname].connections = {items: appStudioWidgets.getPrimaryConnectionItems(ps, compRef)}
                }
                components[nickname].id = _.uniqueId('ghost-')
            }

            //children of inner app should not be included in the components.
            //we keep iterating them so we would get them as controllers
            if (comp.excludeFromComponents || comp.componentType === APP_WIDGET_TYPE) {
                _.forEach(comp.components, childComp => {
                    childComp.excludeFromComponents = true
                })
            }
            compsToTransform.push(...(comp.components || []))
            if (comp.components && !comp.excludeFromComponents) {
                components[nickname].children = _(comp.components)
                    .map(child => componentCode.getNickname(ps, componentDetectorAPI.getComponentById(ps, child)))
                    .compact()
                    .value()
                delete components[nickname].components
            }

            if (comp.componentType === APP_WIDGET_TYPE) {
                controllers[nickname] = getGhostControllerData(ps, widgetContext, compRef, nickname)
            }
        }
    }

    return {components, controllers}
}

const getDefaultVariationId = (ps: PS, widgetPageId: string) => {
    const widgetPointer = appStudioDataModel.getWidgetByRootCompId(ps, widgetPageId)
    const [firstVariation] = getWidgetVariations(ps, widgetPointer) || []

    return firstVariation && appStudioDataModel.getRootCompIdByPointer(ps, firstVariation.pointer)
}

const getSerializedWidgetStructure = (ps: PS, widgetPageId: string, options?) => {
    const {appDefinitionId, devCenterWidgetId, variationId = getDefaultVariationId(ps, widgetPageId)} = options || {}
    const pageIdToSerialize = variationId || widgetPageId
    const serializedWidgetPage = page.serializePage(ps, pageIdToSerialize, true)

    return appStudioStructureFixer.getFixedAppWidgetStructure(ps, serializedWidgetPage, {
        appDefinitionId,
        devCenterWidgetId,
        variationId
    })
}

const getLastBuildId = (ps: PS) => {
    const pointer = ps.pointers.appBuilder.getLastBuildIdPointer()
    return ps.dal.get(pointer)
}

const getAppWidgetRefFromPointer = (ps: PS, widgetPointer: Pointer) => {
    return appStudioDataModel.getAppWidgetRefFromPointer(ps, widgetPointer)
}

const preBuildAsync = (ps: PS, appDefId: string) =>
    new Promise((resolve, reject) =>
        blocksLifecycle.preBuild(ps, appDefId, {
            onSuccess: resolve,
            onError: reject
        })
    )

const buildAsync = (
    ps: PS,
    versionType: VersionType,
    appMarketingName?: string,
    blocksVersion?: string,
    appDefId?: string,
    isAsyncBuild?: boolean,
    releaseNotes?,
    publishRc?: boolean
) =>
    new Promise((resolve, reject) => {
        blocksLifecycle.build(
            ps,
            resolve,
            reject,
            versionType,
            appMarketingName,
            blocksVersion,
            appDefId,
            isAsyncBuild,
            releaseNotes,
            publishRc
        )
    })

const moveWidget = (ps: PS, fromIndex: number, toIndex: number) => {
    const appStudioData = appStudioDataModel.getAppStudioData(ps)
    const newWidgets = dsUtils.moveItemInArray([...appStudioData.widgets], fromIndex, toIndex)

    appStudioDataModel.updateAppStudioOnMasterPage(ps, {...appStudioData, widgets: newWidgets})

    setFirstWidgetAsHomePage(ps, newWidgets)
}

const setFirstWidgetAsHomePage = (ps: PS, widgetsList: WidgetData[]) => {
    const [firstWidget] = widgetsList
    const newHomepage = dsUtils.stripHashIfExists(firstWidget.rootCompId)

    page.homePage.set(ps, newHomepage)
}

export default {
    initialize,
    getEntityByPage: appStudioDataModel.getEntityByPage,
    displayGettingStartedView,
    getNewDataId: appStudioDataModel.getNewDataId,
    updateBlocksPreviewData,
    getNewDataItemPointer: appStudioDataModel.getNewDataItemPointer,
    getAppStudioMetaData: appStudioDataModel.getAppStudioMetaData,
    updateAppStudioMetaData: appStudioDataModel.updateAppStudioMetaData,
    saveAppMetadata: appMetadata.saveMetadata,
    preBuild: blocksLifecycle.preBuild,
    preBuildAsync,
    build: blocksLifecycle.build,
    buildAsync,
    buildWithOptions: blocksLifecycle.buildWithOptions,
    getBuildStatusById: blocksLifecycle.getBuildStatusById,
    getDevSiteAppDefId,
    setDraftAppName,
    setAppStudioModel,
    setWixDataToken,
    appName: appStudioAppName,
    ssrCache: appStudioSSRCache,
    appDescription: appStudioAppDescription,
    isBobApp: appStudioBobApp.isBobApp,
    getUpdatedWidgetPropertiesDefaults: appStudioSchemaHelpers.getUpdatedWidgetPropertiesDefaults,
    fetchAppData: appStudioAppDataUtils.fetchAppData,
    definitions: {
        getAllCustomDefinitions: appStudioDataModel.getAllCustomDefinitions,
        getAllSerializedCustomDefinitions: appStudioDataModel.getAllSerializedCustomDefinitions,
        getSerializedCustomDefinition: appStudioDataModel.getSerializedCustomDefinition,
        setCustomDefinition,
        removeCustomDefinition,
        getAllDefinitionUsages,
        validateDefinitionHasNoUsages,
        validateNewDefinitionName: appStudioSchemaHelpers.validateNewDefinitionName
    },
    panels: {
        createPanel: appStudioPanels.createPanel,
        generateNewPanelName: appStudioPanels.generateNewPanelName,
        displayPanel: appStudioPanels.displayPanel,
        removePanel: appStudioPanels.removePanel,
        duplicatePanel: appStudioPanels.duplicatePanel,
        renamePanel: appStudioPanels.renamePanel,
        getAllPanels: appStudioDataModel.getAllPanels,
        getPanelPointerByRootCompId: appStudioPanels.getPanelPointerByRootCompId,
        getRootCompIdByPanelPointer: appStudioPanels.getRootCompIdByPanelPointer,
        setHelpId: appStudioPanels.setHelpId,
        setTitle: appStudioPanels.setTitle,
        setHeight: appStudioPanels.setHeight,
        validateTitle: appStudioPanels.validateTitle,
        validateHelpId: appStudioPanels.validateHelpId,
        validatePanelName: appStudioPanels.validatePanelName,
        getCount: appStudioDataModel.getPanelsCount,
        movePanel: appStudioPanels.movePanel
    },
    dependencies: {
        hasDependency: appStudioDependencies.hasDependency,
        addDependency: appStudioDependencies.addDependency,
        removeDependency: appStudioDependencies.removeDependency,
        setDependencies: appStudioDependencies.setDependencies,
        getAllDependencies: appStudioDependencies.getAllDependencies
    },
    dashboards: {
        createDashboard: blocksDashboards.createDashboard,
        removeDashboard: blocksDashboards.removeDashboard,
        displayDashboard: blocksDashboards.displayDashboard,
        getAllDashboards: blocksDashboards.getAllDashboards,
        isDashboardPage: appStudioDataModel.isDashboardPage,
        getDashboardByRootCompId: blocksDashboards.getDashboardByRootCompId
    },
    getRootAppWidgetByPage: appStudioDataModel.getRootAppWidgetByPage,
    isBlocksComponentPage: appStudioDataModel.isBlocksComponentPage,
    widgets: {
        updateConfig: controllerConfig.update,
        getContainingWidgetsMap: appStudioDataModel.getContainingWidgetsMap,
        getWidgetInstances,
        getFirstLevelRefChildren: appStudioDataModel.getFirstLevelRefChildren,
        createWidget,
        getAllWidgets: appStudioDataModel.getAllWidgets,
        getAllSerializedWidgets: appStudioDataModel.getAllSerializedWidgets,
        getWidgetFunctions,
        getWidgetPropertiesSchema,
        getWidgetEvents,
        getWidgetPlugin: appStudioPlugins.getWidgetPlugin,
        updateWidgetPlugin: appStudioPlugins.updateWidgetPlugin,
        getSerializedWidgetStructure,
        setWidgetApiPart,
        duplicateWidget,
        setWidgetName,
        getWidgetName,
        displayWidget,
        removeWidget,
        getCount: appStudioDataModel.getWidgetsCount,
        getWidgetPanels: appStudioDataModel.getWidgetPanelsPointers,
        setDefaultSize: appStudioDataModel.setDefaultSize,
        getDefaultSize: appStudioDataModel.getDefaultSize,
        isWidgetPage: appStudioDataModel.isWidgetPage,
        getWidgetByRootCompId: appStudioDataModel.getWidgetByRootCompId,
        getRootCompIdByPointer: appStudioDataModel.getRootCompIdByPointer,
        getWidgetById: appStudioDataModel.getDescriptorPointerById,
        notifyPropertiesUpdateToWorker,
        moveWidget,
        getPanelsCount: appStudioDataModel.getWidgetPanelsCount,
        variations: {
            createVariation,
            getVariationName,
            setVariationName,
            removeVariation,
            isVariationPage: appStudioDataModel.isVariationPage,
            getWidgetVariations,
            getVariationByRootCompId
        },
        presets: {
            createPreset: appStudioPresets.createPreset,
            removePreset: appStudioPresets.removePreset,
            getPresetVariantId: appStudioPresets.getPresetVariantId,
            getPresetVariantPointer: appStudioPresets.getPresetVariantPointer,
            displayPreset: appStudioPresets.displayPreset,
            setPresetName: appStudioPresets.setPresetName,
            getPresetName: appStudioPresets.getPresetName,
            duplicatePreset: appStudioPresets.duplicatePreset,
            getWidgetPresets: appStudioPresets.getWidgetPresets,
            setPresetDefaultSize: appStudioPresets.setPresetDefaultSize,
            getPresetDefaultSize: appStudioPresets.getPresetDefaultSize,
            movePreset: appStudioPresets.movePreset
        }
    },
    codePackages: appStudioCodePackages,
    getLastBuildId,
    getAppType: appType.getAppType,
    getAppWidgetRefFromPointer,
    updateWidgetsOnMasterPage
}
