export const promptHubDefaultPromptId = 'add4bd0e-c548-4390-96d3-7b53e463148b'
export const promptHubPageWithBusinessNamePromptId = '158d2cfc-6ff4-4315-91db-9c1b3fd3d0e8'
export const promptHubTonePromptId = '05c03968-bc79-44cd-bfae-46985edc72f6'

export const experimentPromptHubDefaultPromptId = '75cc3b17-9ed5-4ecf-9528-72a989350fa4'
export const experimentPromptHubPageWithBusinessNamePromptId = 'de1d612e-69ed-48ef-aea7-d7dd7f8dfbf8'
export const experimentPromptHubTonePromptId = '839c46e5-ed10-42a8-bfd4-86a24faefd49'

export const promptHubBusinessNameShorterPromptId = 'aaf4e2f5-a3a6-45b7-9d88-e763e3164d57'
export const promptHubStructurePromptId = '69f7ce91-4309-40ca-b09e-322a495adca2'
export const promptHubNewOutlineStructurePromptId = 'cbd2813b-a271-4c95-8e6c-9ec1c63e926c'
export const promptHubFlatStructurePromptId = '5654e211-0a21-4994-a47d-cb373d1ead73'

export const nonApplicableParamsVersion = 'N/A'

export const aiGatewayUrl = '/_serverless/ai-gateway/ai-prompt'
export const promptHubAppId = 'c4de5ff8-cb71-476c-9208-5dbfca312c15'

export const aiContentOutlineResultErrorType = 'invalidAIContentOutline'
export const aiContentOutlineResultErrorMessage = 'AI Outline result is invalid or does not match the page structure'
export const aiContentOutlineResultConsecutiveErrorType = 'InvalidAIContentOutlineConsecutive'
export const aiContentOutlineResultConsecutiveErrorMessage =
    'AI Outline result is invalid or does not match the page structure also after a retry'
export const aiRequestErrorType = 'AIOutlineContentRequestError'
export const aiRequestErrorMessage = 'AI Content request failed'
export const aiContentTooLongErrorType = 'AIOutlineContentTooLongError'
export const aiContentTooLongErrorMessage = 'Some of the returned content is too long relative to the original content'
export const aiContentBadJsonErrorType = 'AIOutlineContentBadJsonError'
export const aiContentBadJsonErrorMessage = 'AI Content returned unparsable JSON'
export const aiContentUnexpectedError = 'AIOutlineContentUnexpectedError'
export const aiContentUnexpectedErrorMessage = 'An unexpected error occurred while generating AI content'

export const aiContentMainPageFailedErrorType = 'AIContentMainPageFailedError'
export const aiContentMainPageFailedErrorMessage = 'AI Content failed to generate content for main page'
export const aiContentAllPagesFailedErrorType = 'AIContentAllPagesFailedError'

export const aiContentMissingOnOutlineErrorType = 'AIContentMissingOnOutlineError'
export const aiContentMissingOnOutlineMessage = 'Ai content failed for specific field'
export const aiContentAllPagesFailedErrorMessage =
    'AI Content site suggestion failed for all pages and main page was ignored'
export const aiBusinessNameFieldIdMissingType = 'BusinessNameFieldIdMissing'
export const aiBusinessNameFieldIdMissingMessage = 'BusinessNameFieldId is Missing'

export const aiBusinessNamePathMissingOnObjectType = 'BusinessNamePathIsWrong'
export const aiBusinessNamePathMissingOnObjectMessage = 'BusinessName path is Wrong'
export const aiShorterBusinessNameRequestErrorType = 'AiShorterBusinessNameRequestError'
export const aiShorterBusinessNameRequestErrorMessage = 'AI Content request for shorter business name failed'
export const aiPageSuggestionInteraction = 'ai-content-page-suggestion'
export const aiSendMessageRequestErrorType = 'AiSendMessageRequestError'
export const aiSendMessageRequestErrorMessage = 'AI Conversation request for send message failed'

export const blacklistedContentPhrases = [
    "i'm a paragraph",
    'im a paragraph',
    'click here to add your own text and edit me',
    "i'm a great place for you to tell a story and let your users know a little more about you",
    'a great place for you to tell a story and let your users know a little more about you'
].map(phrase => phrase.toLowerCase())

export const blacklistedPageTitleKeywords = ['policy']

export const aiRequestErrorOutlineValidationFailedTag = 'outline-fields-mismatch'
export const aiRequestErrorBlacklistValidationFailedTag = 'blacklist-threshold-exceeded'

export const shortenBusinessNameInteraction = 'shorten-business-name-interaction'

export const aiStructureMissingOutlineMessage = 'AI Content section suggestion failed - empty outline response'
