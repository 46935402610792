import type {CompStructure, Pointer} from '@wix/document-services-types'
import {createHookDefinition} from '../../utils/hooksUtils'
import type {HookEventData} from '../hooks/hooks'

export interface ComponentAfterAddData {
    componentType: string
    compToAddPointer: Pointer
    componentDefinition: CompStructure
    optionalCustomId?: string
    mappers: any
    containerPointer: Pointer
}
export interface ComponentAfterAddDataEvent extends ComponentAfterAddData, HookEventData<'component_after_add'> {}
export interface RefComponentAfterAddDataEvent
    extends ComponentAfterAddData,
        HookEventData<'ref_component_after_add'> {}

export const COMPONENTS_HOOKS = {
    ADD_COMPONENT: {
        AFTER: createHookDefinition<'component_after_add', ComponentAfterAddData>('component_after_add'),
        REF_COMPONENT_AFTER: createHookDefinition<'ref_component_after_add', RefComponentAfterAddDataEvent>(
            'ref_component_after_add'
        )
    }
}
