'use strict'
const coreUtils = require('@wix/santa-core-utils')
const {isReferredId} = coreUtils.displayedOnlyStructureUtil

const exec = (pageJson, pageIdsArray, magicObject) => {
    if (!magicObject.isExperimentOpen('dm_deleteLayoutOverridesForRefComponents')) return

    const {layout_data} = pageJson.data
    if (!layout_data) return

    const layoutDataIds = Object.keys(layout_data)
    layoutDataIds.forEach(id => {
        if (isReferredId(id)) {
            delete layout_data[id]
        }
    })
}

module.exports = {
    name: 'deleteLayoutOverridesForRefComponents',
    version: 0,
    experimentalVersions: [{version: 1, experiment: 'dm_deleteLayoutOverridesForRefComponents'}],
    exec
}
