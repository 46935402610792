import type {
    ComponentStageData,
    MobileAlgoContext,
    Stage,
    StageHandler,
    MobileConversionDal,
    ComponentConversionData,
    MobileAlgoConfig
} from '../types'
import type {Pointer} from '@wix/document-services-types'

export const createComponentStage = (): Stage<ComponentStageData> => {
    const handlers: StageHandler<ComponentStageData>[] = []
    const run = async (
        ctx: MobileAlgoContext,
        mobileConversionDal: MobileConversionDal,
        pageId: string,
        variants: Pointer[],
        config: MobileAlgoConfig
    ) => {
        if (handlers.length === 0) {
            return
        }

        await mobileConversionDal.forEachComponentAsync(async (component: ComponentConversionData) => {
            const componentId = component.id!
            if (component.type === 'Component' || component.type === 'Container') {
                for (const handler of handlers) {
                    await handler({globalContext: ctx, mobileConversionDal, componentId, pageId, config})
                }
            }
        })
    }

    return {
        register: (handler: StageHandler<ComponentStageData>) => {
            handlers.push(handler)
        },
        run
    }
}
