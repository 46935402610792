import type {DocumentManager} from '@wix/document-manager-core'
import type {MasterPageMigrator} from '../dataMigrationRunner'
import * as constants from '../../../../constants/constants'

const {DATA_TYPES} = constants
const TPAS0_ID = 'tpas0'
const TPAS0_SKIN = 'wysiwyg.viewer.skins.TPASectionSkin'

const migrateMasterPage = ({dal, pointers}: DocumentManager) => {
    const tpas0Pointer = pointers.getPointer(TPAS0_ID, DATA_TYPES.theme)
    const tpas0 = dal.get(tpas0Pointer)
    if (tpas0 === undefined || tpas0.skin !== undefined) {
        return
    }

    const tpas0SkinPointer = pointers.getInnerPointer(tpas0Pointer, 'skin')
    dal.set(tpas0SkinPointer, TPAS0_SKIN)
}

const name = 'fixTpas0SystemStyleMissingSkin'
const version = 1

export const fixTpas0SystemStyleMissingSkin: MasterPageMigrator = {
    migrateMasterPage,
    name,
    version
}
