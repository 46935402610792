import {type CreateExtArgs, pointerUtils} from '@wix/document-manager-core'
import type {Pointer, ManifestFeature, Builder} from '@wix/document-services-types'
// eslint-disable-next-line wix-editor/no-internal-import
import {ComponentManifestBuilder} from '@wix/component-protocol/builder'
// eslint-disable-next-line wix-editor/no-internal-import
import {STYLE} from '@wix/component-protocol/schema'
import type {VariablesExtensionAPI} from '../variables/variables'
import {findWidgetByPageId, getRootAppWidgetByPage} from './blocksDataModel'
import {VIEW_MODES} from '../../constants/constants'
import {getWidgetComponentsMetadata, getPresetMetadata} from './blocksMetadata'
import type {FeaturesExtensionAPI} from '../features/features'
import {MANIFEST_FEATURE_NAME} from './manifestUtils'

const {getPointer} = pointerUtils

export interface BuilderManifestOptions {
    typeMapper?(type: string, compPointer: Pointer): string | undefined
}

const COMP_STYLE_TYPE_TO_WIX_UI_STYLE_TYPE_MAP: Record<string, Builder.StyleType> = {
    UnitSizeVar: STYLE.SHAPE_OF.length
}
const DEFAULT_EDITING_BEHAVIORS: Builder.EditingBehaviors = {
    selectable: true,
    removable: true
}

const BUILDER_COMPONENT_MAP: Record<string, string> = {
    'wixui.StylableButton': 'wixui.StylableButton',
    'wixui.Breadcrumbs': 'wixui.Breadcrumbs',
    'wysiwyg.viewer.components.inputs.TextInput': 'wixui.TextInput',
    'wixui.CollapsibleText': 'wixui.CollapsibleText',
    'wysiwyg.viewer.components.FiveGridLine': 'wixui.FiveGridLine',
    'wixui.ProGallery': 'wixui.ProGallery',
    'wysiwyg.viewer.components.inputs.TextAreaInput': 'wixui.TextAreaInput',
    'wysiwyg.viewer.components.inputs.ComboBoxInput': 'wixui.ComboBoxInput',
    'wysiwyg.viewer.components.VerticalLine': 'wixui.VerticalLine',
    'wixui.SelectableContainer': 'wixui.SelectableContainer',
    'wixui.SelectableContainerInput': 'wixui.SelectableContainerInput',
    'wixui.MultiStateBox': 'wixui.MultiStateBox',
    'wixui.ImageX': 'wixui.ImageX',
    'wysiwyg.viewer.components.Repeater': 'wixui.Repeater',
    'wysiwyg.viewer.components.SiteButton': 'wixui.SiteButton',
    'mobile.core.components.Container': 'wixui.Container',
    'wysiwyg.viewer.components.WRichText': 'wixui.WRichText'
}

const toBuilderComponentType = (compType: string): string => BUILDER_COMPONENT_MAP[compType] ?? compType

const toWixStyleType = (styleType: string): Builder.StyleType => COMP_STYLE_TYPE_TO_WIX_UI_STYLE_TYPE_MAP[styleType]

const getDesignVariables = (createExtArgs: CreateExtArgs, rootWidget: Pointer) => {
    const {extensionAPI, dal} = createExtArgs
    const variables = (extensionAPI as VariablesExtensionAPI).variables.list(rootWidget)

    return variables.map(variablePtr => dal.get(variablePtr))
}

const getManifestStyle = (createExtArgs: CreateExtArgs, rootWidget: Pointer): Builder.Style => {
    const variables = getDesignVariables(createExtArgs, rootWidget)

    return variables.reduce((styleObject: NonNullable<Builder.Style>, variable) => {
        styleObject[variable.id] = {
            shapeOf: toWixStyleType(variable.type),
            editableStates: [STYLE.EDITABLE_STATES.regular]
        }

        return styleObject
    }, {})
}

export const getCompManifestData = (createExtArgs: CreateExtArgs, compId: string) => {
    const {features} = createExtArgs.extensionAPI as FeaturesExtensionAPI
    return features.component.get(getPointer(compId, VIEW_MODES.DESKTOP), MANIFEST_FEATURE_NAME)
}

const toEditingBehaviors = (compManifest?: ManifestFeature): Builder.EditingBehaviors => {
    if (!compManifest) {
        return DEFAULT_EDITING_BEHAVIORS
    }

    return {
        removable: !compManifest.behavior?.preventHide,
        selectable: compManifest.behavior?.closed?.selectable ?? true
    }
}

const getManifestElements = (
    createExtArgs: CreateExtArgs,
    rootWidget: Pointer,
    typeMapper?: BuilderManifestOptions['typeMapper']
): Record<string, Builder.Manifest> => {
    const {components} = getWidgetComponentsMetadata(createExtArgs, rootWidget, false)

    return Object.entries(components).reduce((acc: Record<string, Builder.Manifest>, [compId, compData]) => {
        const compManifestData = getCompManifestData(createExtArgs, compId)

        const builder = new ComponentManifestBuilder()

        const componentType =
            typeMapper?.(compData.componentType, getPointer(compId, VIEW_MODES.DESKTOP)) || compData.componentType

        acc[compData.role!] = builder
            .setDisplayName(compManifestData?.displayName?.value ?? compData.role!)
            .setReferredType(toBuilderComponentType(componentType))
            .setSelector(`[id$="_r_${compId}"]`)
            .setEditingBehaviors(toEditingBehaviors(compManifestData))
            .build()

        return acc
    }, {})
}

const toBuilderManifestPresets = (
    createExtArgs: CreateExtArgs,
    presetDescriptorIds: string[]
): Record<string, Builder.Preset> => {
    const presets = presetDescriptorIds.map(presetDescriptorId => getPresetMetadata(createExtArgs, presetDescriptorId))
    return presets.reduce((acc: Record<string, Builder.Preset>, {name, id}) => {
        acc[id] = {displayName: name}
        return acc
    }, {})
}

export const generateBuilderManifest = (
    createExtArgs: CreateExtArgs,
    widgetPageId: string,
    options?: BuilderManifestOptions
): Builder.Manifest => {
    const {pointers} = createExtArgs

    const pagePointer = pointers.structure.getPage(widgetPageId, VIEW_MODES.DESKTOP)
    const rootWidget = getRootAppWidgetByPage(createExtArgs, pagePointer)
    const widgetDescriptor = findWidgetByPageId(createExtArgs, widgetPageId)
    if (!rootWidget || !widgetDescriptor) {
        throw new Error(`missing data for widgetPageId: ${widgetPageId}`)
    }

    const manifestBuilder = new ComponentManifestBuilder()
    return manifestBuilder
        .setDisplayName(widgetDescriptor.name)
        .setStyle(getManifestStyle(createExtArgs, rootWidget))
        .setElements(getManifestElements(createExtArgs, rootWidget, options?.typeMapper))
        .setPresets(toBuilderManifestPresets(createExtArgs, widgetDescriptor.presets ?? []))
        .build()
}
