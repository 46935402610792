import {addIdToMap, getCustomId} from '../utils'
import {DATA_TYPES} from '../../../../constants/constants'
import {addNamespaceToStructure} from '../componentDeserialization'
import type {DeserializeComponentParams} from '../types'

export const updateComponentPropsStructure = (deserializationParams: DeserializeComponentParams) => {
    const {compStructure, customId, mappers} = deserializationParams
    const {oldToNewIdMap} = mappers ?? {oldToNewIdMap: {}}
    deserializationParams.customId = getCustomId(oldToNewIdMap, compStructure.props, customId)

    const propertyQuery = addNamespaceToStructure(
        deserializationParams,
        compStructure.props,
        DATA_TYPES.prop,
        oldToNewIdMap
    )
    if (propertyQuery) {
        compStructure.propertyQuery = propertyQuery
        addIdToMap(compStructure.props, propertyQuery, oldToNewIdMap)
    }
}
