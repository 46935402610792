import type {CreateExtArgs} from '@wix/document-manager-core'
import type {ComponentAfterAddDataEvent} from '../hooks'
import {addPlatformAppToPageIfFirstController, setControllerStateFromSerializedStructure} from './utils/platformAppComp'
import {getControllerAppDefinitionId} from '../../appControllerData/appControllerUtils'

export const handleAppWidgetAddition = (createExtArgs: CreateExtArgs, data: ComponentAfterAddDataEvent) => {
    const {compToAddPointer, componentDefinition} = data
    setControllerStateFromSerializedStructure(createExtArgs, compToAddPointer, componentDefinition)
    addPlatformAppToPageIfFirstController(
        createExtArgs,
        compToAddPointer,
        getControllerAppDefinitionId(componentDefinition.data)!
    )
}
