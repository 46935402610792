import type {
    CreateExtArgs,
    CreateExtensionArgument,
    DeepFunctionMap,
    DmApis,
    Extension,
    ExtensionAPI
} from '@wix/document-manager-core'
import type {CompRef, VariantPointer} from '@wix/document-services-types'
import type {
    BaseStageData,
    ComponentStageData,
    MobileAlgoContext,
    Stage,
    StageHandler,
    StructureStageData,
    MobileAlgoPluginFactory,
    MobileAlgoPluginWithContext,
    MobileAlgoPluginInitializationArgs,
    MobileConversionArgs,
    ReadOnlyExtensionAPI,
    ComponentConversionDataMap,
    ComponentConversionData,
    MobileAlgoConfig
} from './types'
import type {DataModelExtensionAPI} from '../dataModel/dataModel'
import _ from 'lodash'
import * as loadablePlugins from './plugins/plugins'
import {createStructureStage} from './stages/structureStage'
import {createComponentStage} from './stages/componentStage'
import {createPluginContextHelper} from './pluginLifeCycle/pluginContextHelper'
import {createPluginHeuristicsRegistry} from './pluginLifeCycle/pluginHeuristicsRegistry'
import {createPluginContext} from './pluginLifeCycle/pluginContext'
import {createMobileConversionDal} from './mobileConversionDal'
import {createReadOnlyExtensionApi} from './readOnlyExtensionApi'
import type {VariantsExtensionAPI} from '../variants/variants'
import type {GroupApi} from './plugins/grouping/types'
import {DOCUMENT_STAGE_WIDTH, sendRequest} from './plugins/grouping/request'
import {DATA_TYPES, VIEW_MODES} from '../../constants/constants'
import {conversionDataBuilders} from './preprocess'
import {conversionDataTransformers} from './postprocess'
import {mobileConversionValidation} from './validation'

const pluginsNames = _.keys(loadablePlugins)

export const MOBILE_VARIANT = {type: 'variants', id: 'MOBILE-VARIANT'}

export interface MobileAlgoApi extends ExtensionAPI {
    locking: {
        lockComponent(compRef: CompRef): void
        isComponentLocked(compRef: CompRef): boolean
        unlockComponent(compRef: CompRef): void
    }
    context: {
        enable(ctx: MobileAlgoContext, plugins?: string[]): void
        disable(ctx: MobileAlgoContext, plugins?: string[]): void
        create(enabledPlugins?: string[]): MobileAlgoContext
    }
    plugins: {
        register(pluginFactory: MobileAlgoPluginFactory): void
        getApis(ctx: MobileAlgoContext): DeepFunctionMap
    }
    heuristics: HeuristicRegistry
    writeConversionResult(
        convertedComponents: Record<string, ComponentConversionData>,
        variants: VariantPointer[]
    ): void
    runWithContext(
        algoContainerPointer: CompRef,
        ctx: MobileAlgoContext,
        variants: VariantPointer[],
        config?: MobileAlgoConfig
    ): Promise<Record<string, ComponentConversionData>>
    run(algoContainerPointer: CompRef, variants: VariantPointer[]): Promise<void>
}

export interface HeuristicRegistry extends ExtensionAPI {
    registerHeuristic<T extends BaseStageData>(stage: Stage<T>, handler: StageHandler<T>): void
    getStages(): Stages
}
export interface MobileAlgoExtensionApi extends ExtensionAPI {
    mobileAlgo: MobileAlgoApi
}

export interface Stages {
    ANALYZE: Stage<StructureStageData>
    SCALE: Stage<ComponentStageData>
    POSITION: Stage<StructureStageData>
    ADJUST: Stage<ComponentStageData>
}

const createExtension = ({environmentContext, experimentInstance}: CreateExtensionArgument): Extension => {
    const bootPlugins: MobileAlgoPluginFactory[] = [
        loadablePlugins.grouping,
        loadablePlugins.order,
        loadablePlugins.textScale,
        loadablePlugins.horizontalScaleAndSize,
        loadablePlugins.verticalScaleAndSize
    ]

    const createExtensionAPI = ({pointers, dal, extensionAPI}: CreateExtArgs): MobileAlgoExtensionApi => {
        const dataModelApi = () => extensionAPI as DataModelExtensionAPI
        const variantsApi = () => (extensionAPI as VariantsExtensionAPI).variants

        const readOnlyExtensionAPI: ReadOnlyExtensionAPI = createReadOnlyExtensionApi(extensionAPI)

        const stages: Stages = {
            ANALYZE: createStructureStage(),
            //hide
            SCALE: createComponentStage(),
            POSITION: createStructureStage(),
            ADJUST: createComponentStage()
        }

        const pluginRegistry = new Map<string, MobileAlgoPluginWithContext>()

        const getStages = (): Stages => stages

        const filterPlugins = (plugins?: string[]): MobileAlgoPluginWithContext[] => {
            if (!plugins) {
                return Object.values(pluginRegistry)
            }

            return Object.values(pluginRegistry).filter(plugin => plugins.includes(plugin.name))
        }

        const disablePluginsForContext = (ctx: MobileAlgoContext, plugins?: string[]): void => {
            filterPlugins(plugins).forEach(plugin => {
                plugin.contextHelper.disable(ctx)
            })
        }
        const enablePluginsForContext = (ctx: MobileAlgoContext, plugins?: string[]) => {
            filterPlugins(plugins).forEach(plugin => {
                plugin.contextHelper.enable(ctx)
                if (plugin.dependencies) {
                    enablePluginsForContext(ctx, plugin.dependencies)
                }
            })
        }

        const getPluginApis = (ctx: MobileAlgoContext): DeepFunctionMap => {
            const result: DeepFunctionMap = {}
            Object.values(pluginRegistry).forEach((plugin: MobileAlgoPluginWithContext) => {
                if (plugin.createApi) {
                    const pluginContext = createPluginContext(plugin.contextHelper, ctx)
                    result[plugin.name] = plugin.createApi(pluginContext)
                }
            })
            return result
        }

        const registerRequest = (ctx: MobileAlgoContext) => {
            const apis = getPluginApis(ctx)
            const {grouping} = apis as GroupApi
            grouping.registerServer(sendRequest)
        }

        const createContext = (enabledPlugins?: string[]): MobileAlgoContext => {
            const ctx = {}
            if (enabledPlugins) {
                disablePluginsForContext(ctx)
                enablePluginsForContext(ctx, enabledPlugins)
            } else {
                enablePluginsForContext(ctx)
            }
            registerRequest(ctx)
            return ctx
        }

        const registerPlugin = (pluginFactory: MobileAlgoPluginFactory) => {
            const algoApi = (extensionAPI as MobileAlgoExtensionApi).mobileAlgo
            const initializeArgs: MobileAlgoPluginInitializationArgs = {
                experimentInstance,
                readOnlyExtensionAPI,
                stages,
                environmentContext
            }
            const plugin = pluginFactory.createPlugin(initializeArgs)
            const contextHelper = createPluginContextHelper(plugin.name)
            const pluginWithContext = {...plugin, contextHelper}
            const heuristicsRegistry = createPluginHeuristicsRegistry(algoApi.heuristics, contextHelper)
            pluginRegistry[plugin.name] = pluginWithContext
            plugin.register(heuristicsRegistry)
        }

        const registerHeuristic = <T extends BaseStageData>(stage: Stage<T>, handler: StageHandler<T>) => {
            stage.register(handler)
        }

        interface IdAndDepth {
            id: string
            depth: number
        }

        const prepareConversionData = (containerPointer: CompRef): MobileConversionArgs => {
            const queue: IdAndDepth[] = [{id: containerPointer.id, depth: 0}]
            const componentsMap: ComponentConversionDataMap = {}
            const shouldValidateComponentLayout = experimentInstance.isOpen(
                'dm_shouldValidateComponentLayoutMobileAlgo'
            )

            while (queue.length > 0) {
                const {id, depth} = queue.shift()!
                const compPointer = pointers.structure.getComponentById(id, VIEW_MODES.DESKTOP)
                const component = dal.get(compPointer)
                const parentComponent = dal.get({id: component.parent, type: 'DESKTOP'})
                if (shouldValidateComponentLayout) {
                    mobileConversionValidation(component, parentComponent)
                }
                componentsMap[id] = Object.assign(
                    conversionDataBuilders.getConversionData(dal, extensionAPI, compPointer),
                    {depth}
                ) as ComponentConversionData

                for (const childId of component.components ?? []) {
                    queue.push({id: childId, depth: depth + 1})
                }
            }

            return {
                componentsMap,
                rootId: containerPointer.id
            }
        }

        const writeConversionResult = (
            convertedComponents: Record<string, ComponentConversionData>,
            variants: VariantPointer[]
        ): void => {
            const updateLayout = (compPointer: CompRef, component: ComponentConversionData) => {
                //need to change for convertedMeasurements
                if (!component.convertedLayout) {
                    return
                }

                variantsApi().updateComponentDataConsideringVariants(
                    compPointer,
                    component.convertedLayout,
                    DATA_TYPES.layout
                )
            }
            const updateStyle = (compPointer: CompRef, component: ComponentConversionData) => {
                if (!component.style) {
                    return
                }

                const styleTransformer = conversionDataTransformers.getStyleTransformer(
                    extensionAPI,
                    compPointer,
                    component
                )

                if (!styleTransformer) {
                    return
                }

                const styleItem = styleTransformer()

                variantsApi().updateComponentDataConsideringVariants(compPointer, styleItem, DATA_TYPES.theme)
            }

            const updateAbsoluteStyle = (compPointer: CompRef, component: ComponentConversionData) => {
                if (!component.convertedMeasurements) {
                    return
                }
                const comp = dal.get(compPointer)
                dal.set(compPointer, {
                    ...comp,
                    layout: {
                        ...comp.layout,
                        x: component.convertedMeasurements.x,
                        y: component.convertedMeasurements.y,
                        width: component.convertedMeasurements.width,
                        height: component.convertedMeasurements.height
                    }
                })
            }

            const writersToExecute = _.over([updateLayout, updateStyle, updateAbsoluteStyle])

            _.forEach(convertedComponents, (component: ComponentConversionData) => {
                const compPointerWithVariants = pointers.getPointer(component.id!, VIEW_MODES.DESKTOP, {variants})

                writersToExecute(compPointerWithVariants, component)
            })
        }

        const runWithContext = async (
            algoContainerPointer: CompRef,
            ctx: MobileAlgoContext,
            variants: VariantPointer[],
            config: MobileAlgoConfig = {stageWidth: 980}
        ) => {
            const conversionData: MobileConversionArgs = prepareConversionData(algoContainerPointer)
            const mobileConversionDal = createMobileConversionDal(conversionData)
            const pageId = pointers.structure.getPageOfComponent(algoContainerPointer).id
            for (const stage of Object.values(stages)) {
                const {run} = stage
                await run(ctx, mobileConversionDal, pageId, variants, config)
            }

            const convertedComponents: Record<string, ComponentConversionData> = {}
            mobileConversionDal.forEachComponent((component: ComponentConversionData) => {
                convertedComponents[component.id] = _.cloneDeep(component)
            })

            // TODO - Consider maybe the results should be written in the context
            return convertedComponents
        }

        const run = async (algoContainerPointer: CompRef, variants: VariantPointer[] = [MOBILE_VARIANT]) => {
            const ctx = createContext(pluginsNames)
            const {siteWidth} = dal.get({id: 'masterPage', type: 'DESKTOP', innerPath: ['renderModifiers']})
            const config: MobileAlgoConfig = {
                stageWidth: siteWidth ?? DOCUMENT_STAGE_WIDTH
            }
            registerRequest(ctx)
            const convertedComponents = await runWithContext(algoContainerPointer, ctx, variants, config)
            writeConversionResult(convertedComponents, variants)
        }

        const lockComponent = (compRef: CompRef): void => {
            const mobileHints = dataModelApi().dataModel.components.getItem(compRef, 'mobileHints') ?? {
                type: 'MobileHints'
            }
            mobileHints.isLocked = true
            dataModelApi().dataModel.components.addItem(compRef, 'mobileHints', mobileHints)
        }

        const unlockComponent = (compRef: CompRef): void => {
            const mobileHints = dataModelApi().dataModel.components.getItem(compRef, 'mobileHints') ?? {}
            mobileHints.isLocked = false
            dataModelApi().dataModel.components.addItem(compRef, 'mobileHints', mobileHints)
        }

        const isComponentLocked = (compRef: CompRef): boolean => {
            return !!dataModelApi().dataModel.components.getItem(compRef, 'mobileHints')?.isLocked
        }

        return {
            mobileAlgo: {
                locking: {
                    lockComponent,
                    unlockComponent,
                    isComponentLocked
                },
                plugins: {
                    register: registerPlugin,
                    getApis: getPluginApis
                },
                context: {
                    create: createContext,
                    enable: enablePluginsForContext,
                    disable: disablePluginsForContext
                },
                heuristics: {
                    getStages,
                    registerHeuristic
                },
                runWithContext,
                writeConversionResult,
                run
            }
        }
    }

    return {
        name: 'mobileAlgo',
        initialize: async ({extensionAPI}: DmApis) => {
            const mobileAlgoApi = extensionAPI as MobileAlgoExtensionApi
            bootPlugins.forEach(pluginFactory => {
                mobileAlgoApi.mobileAlgo.plugins.register(pluginFactory)
            })
        },
        createExtensionAPI
    }
}

export {createExtension}
