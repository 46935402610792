import type {
    MobileAlgoContext,
    MobileAlgoPlugin,
    MobileAlgoPluginInitializationArgs,
    MobileConversionDal,
    PluginContext,
    PluginHeuristicsRegistry
} from '../../types'
import type {ComponentToChildrenOrderMap, OrderApiPlugin} from './types'
import {CTX_ORDERING_RESULT} from './constants'
import {orderingGroupMap, removeVirtualGroup} from './orderMap'
import type {GroupApi} from '../grouping/types'
import _ from 'lodash'

export const createPlugin = (pluginInitVals: MobileAlgoPluginInitializationArgs): MobileAlgoPlugin => {
    const {stages, readOnlyExtensionAPI} = pluginInitVals

    const createApi = (context: PluginContext): OrderApiPlugin => {
        return {
            getOrderingResult(): ComponentToChildrenOrderMap {
                return context.get(CTX_ORDERING_RESULT)
            }
        }
    }

    const serOrderComponentInConversionDal = (
        mobileConversionDal: MobileConversionDal,
        componentOrderingMap: ComponentToChildrenOrderMap
    ) => {
        const componentMap = _.cloneDeep(componentOrderingMap)
        mobileConversionDal.forEachComponent(({id}) => {
            const realChildrenOrdered: string[] = []
            removeVirtualGroup(componentMap, componentMap[id], realChildrenOrdered)
            mobileConversionDal.setComponents(id, realChildrenOrdered)
        })
    }

    const createOrderMap = (
        {
            globalContext,
            mobileConversionDal
        }: {globalContext: MobileAlgoContext; mobileConversionDal: MobileConversionDal},
        contextHelper: PluginContext
    ) => {
        const apis = readOnlyExtensionAPI.getPluginsApi(globalContext)
        const {grouping} = apis as GroupApi
        const componentToChildrenGroupMap = grouping.getGroupingResult()
        const componentOrderingMap = orderingGroupMap(componentToChildrenGroupMap)
        serOrderComponentInConversionDal(mobileConversionDal, componentOrderingMap)
        contextHelper.set(CTX_ORDERING_RESULT, componentOrderingMap)
    }

    const register = (registry: PluginHeuristicsRegistry) => {
        registry.register(stages.ANALYZE, createOrderMap)
    }

    return {register, createApi, name: 'order', dependencies: []}
}
