import type {
    ComponentSchemasDefinition,
    CoreSchemaConfig,
    SchemaService,
    SchemaConfig,
    SchemaFile,
    NamespaceConfig
} from '@wix/document-services-types'
import _ from 'lodash'
import common from '../dist/schemas/common.json'
import cssSchemas from '../dist/schemas/cssSchemas.json'
import theSchemasFormerlyKnownAsCommon from '../dist/schemas/commonSchemas.json'
import {extendDataNodeSchemas} from './schemas/schemaUtils'
import {constants} from '@wix/santa-core-utils'
import {CannotFindSchemaError, SchemaValidationError, SchemaValidationErrorDetails} from './schemas/errors'
import {createSchemaCore, ValidationFunction} from './schemas/schemaCore'
import {getUnifiedSchemasConfig} from './configs/schemaConfigs'
import * as whitelistCleanup from './schemas/whitelistCleanup'
// eslint-disable-next-line import/no-unresolved
import {coreUtils} from '@wix/santa-ds-libs/basic'

const {isValidFontFamily} = coreUtils.fontUtils.createFontUtils()

const {DATA_TYPES} = constants

const notActuallySchemas = [
    'definition',
    'componentsDefinitionsMap',
    'allComponentsDefinitionsMap',
    'componentTypeAliases',
    'skinsByComponentType',
    'containers'
]

function isExcludedFromValidation(type: string): boolean {
    return ['AppVars'].includes(type)
}

const getSchemasFromConfig = (schemasAndDefinitionsArrangedByDataType: any) => ({
    ..._.omit(schemasAndDefinitionsArrangedByDataType, notActuallySchemas),
    cssSchemas,
    theSchemasFormerlyKnownAsCommon,
    common
})

function createService(schemaConfig: SchemaConfig): SchemaService {
    const coreSchemaConfig = {
        namespaces: getSchemasFromConfig(schemaConfig.schemas),
        permanentDataTypes: schemaConfig.permanentDataTypes,
        formats: {
            'font-family': isValidFontFamily
        }
    } as CoreSchemaConfig
    if (schemaConfig.restrictedSchemas) {
        coreSchemaConfig.restrictedSchemas = getSchemasFromConfig(schemaConfig.restrictedSchemas)
    }
    const compDefs = {...schemaConfig.schemas.definition}
    const systemStyleIds = new Set<string>()

    _.forOwn(compDefs, definition => {
        if (definition.styles) {
            Object.keys(definition.styles).forEach(styleId => systemStyleIds.add(styleId))
        }
    })

    const aliasToCompKeyMap = new Map<string, string>()
    function getDefinition(componentType: string) {
        return compDefs[componentType] ?? compDefs[aliasToCompKeyMap.get(componentType)!]
    }

    const getSchema = (namespace: string, schemaName: string): any => {
        return _.get(schemaConfig.restrictedSchemas, [namespace, schemaName])
    }
    const getComponentType = (compKey: string) => getDefinition(compKey)?.type

    const updateAliasMap = (compKey: string) => {
        const compDef = compDefs[compKey]
        if (compDef?.aliases) {
            compDef.aliases.forEach((alias: string) => {
                aliasToCompKeyMap.set(alias, compKey)
            })
        }
    }

    const updateSystemStyles = (componentDefinition: Record<string, any>) => {
        if (componentDefinition.styles) {
            Object.keys(componentDefinition.styles).forEach((styleId: string) => {
                systemStyleIds.add(styleId)
            })
        }
    }

    Object.keys(compDefs).forEach((compKey: string) => {
        updateAliasMap(compKey)
    })

    const core = createSchemaCore(coreSchemaConfig, schemaConfig.references)

    function getDefinitionByPredicate(lodashPredicate: any) {
        return _.find(compDefs, lodashPredicate)
    }

    /** Return a boolean representing whether or not the given `id` is the id of a system style
     *
     * A system style is any style that exists in a component definitions's `styles` property.
     * Note that while most components are supplied at service initialization, some
     * may be registered at runtime, which might change the results of this function on certain inputs.
     *
     * @param {string} id The id of the style
     * @returns {boolean} true if `id` is the id of a system style, false otherwise
     */
    function isSystemStyle(id: string): boolean {
        return systemStyleIds.has(id)
    }

    const createValidator =
        (coreValidator: ValidationFunction) => (dataTypeName: string, data: any, namespace: string) => {
            const designDt = DATA_TYPES.design
            const dataDt = DATA_TYPES.data

            if (isExcludedFromValidation(data?.type)) {
                return
            }

            // To preserve the legacy API, data schemas override design schemas when there's a naming conflict
            if (
                namespace === designDt &&
                core.hasSchemaForDataType(designDt, dataTypeName) &&
                core.hasSchemaForDataType(dataDt, dataTypeName)
            ) {
                coreValidator(dataDt, dataTypeName, data)
                return
            }

            const namespacesToTry = [namespace, 'mobileHints', 'theSchemasFormerlyKnownAsCommon']
            const ns = _.find(namespacesToTry, possibleNamespace =>
                core.hasSchemaForDataType(possibleNamespace, dataTypeName)
            )
            if (!ns) {
                throw new CannotFindSchemaError(namespace, dataTypeName)
            }
            coreValidator(ns, dataTypeName, data)
        }

    const validate = createValidator(core.addDefaultsAndValidate)
    const validateNoDefaults = createValidator(core.validateNoDefaults)
    const validateStrict = createValidator(core.validateStrict)

    function isValid(dataTypeName: string, data: any, namespace: string): boolean {
        try {
            validate(dataTypeName, data, namespace)
            return true
        } catch (e) {
            return false
        }
    }

    const createItemAccordingToSchema = (schemaName: string, namespace: string, overrides = {}) => {
        if (core.hasSchemaForDataType(namespace, schemaName)) {
            const item = {...overrides, type: schemaName}
            try {
                validate(schemaName, item, namespace)
            } catch (e) {
                /*
                 Do not throw on purpose when creating item according to schema.
                 It is possible we will have a schema with required fields, but no defaults - and these will be added in later
                 This is backwards compatible behavior
                 */
            }
            return item
        }
    }

    function registerComponentDefinitionAndSchemas(
        compType: string,
        {componentDefinition, dataSchemas, propertiesSchemas, otherSchemas}: ComponentSchemasDefinition,
        allowOverrides = false
    ) {
        const schemaExists = !!compDefs[compType]
        if (schemaExists && !allowOverrides) {
            return
        }
        const newCompDef = componentDefinition[compType]
        if (newCompDef.type !== 'Container') {
            newCompDef.type = 'Component'
        }
        compDefs[compType] = newCompDef
        updateAliasMap(compType)
        updateSystemStyles(newCompDef)

        core.addDataTypesToExistingNamespace(
            DATA_TYPES.data,
            extendDataNodeSchemas(dataSchemas, DATA_TYPES.data),
            schemaExists
        )
        core.addDataTypesToExistingNamespace(
            DATA_TYPES.prop,
            extendDataNodeSchemas(propertiesSchemas, DATA_TYPES.prop),
            schemaExists
        )
        const moreSchemas = otherSchemas ?? {}
        Object.keys(moreSchemas).forEach(namespace => {
            if (core.hasNamespace(namespace)) {
                core.addDataTypesToExistingNamespace(namespace, moreSchemas[namespace], schemaExists)
            } else {
                throw new Error(
                    `Namespace ${namespace} does not exist. Creating new namespaces via registerComponentDefinitionAndSchemas is not supported.`
                )
            }
        })
    }

    function registerDataTypeSchema(schemasToRegister: SchemaFile, namespace: string) {
        if (core.hasNamespace(namespace)) {
            core.addDataTypesToExistingNamespace(namespace, extendDataNodeSchemas(schemasToRegister, namespace), true)
        } else {
            core.registerNamespace(namespace, schemasToRegister)
        }
    }

    function removeAdditionalProperties(namespace: string, dataTypeName: string, data: any): void {
        if (isExcludedFromValidation(data?.type)) {
            return
        }

        core.removeAdditionalProperties(namespace, dataTypeName, data)
    }

    const containerTypesSet = new Set(['Page', 'Container', 'Document', 'RepeaterContainer', 'RefComponent'])

    const isContainer = (compKey: string) => containerTypesSet.has(getComponentType(compKey))
    const isPage = (compKey: string) => getComponentType(compKey) === 'Page'
    const isRepeater = (compKey: string) => getComponentType(compKey) === 'RepeaterContainer'
    const isRefComponent = (compKey: string) => getComponentType(compKey) === 'RefComponent'

    const getNamespaceConfig = (namespace: string): NamespaceConfig => {
        return schemaConfig.namespaceConfigs[namespace]
    }

    return {
        getDefinitionByPredicate,
        isSystemStyle,
        registerComponentDefinitionAndSchemas,
        registerDataTypeSchema,
        getDefinition,
        getSchema,
        isDraftDataSchema: core.isDraftDataSchema,
        isDraftItem: core.isDraftItem,
        extractReferences: core.extractReferences,
        extractReferenceFieldsInfo: core.extractReferenceFieldsInfo,
        extractOwnedReferenceFieldsInfo: core.extractOwnedReferenceFieldsInfo,
        hasNamespace: core.hasNamespace,
        hasSchemaForDataType: core.hasSchemaForDataType,
        isPermanentDataType: core.isPermanentDataType,
        removeAdditionalProperties,
        validate,
        // @ts-ignore
        addDefaultsAndValidate: validate,
        validateStrict,
        validateNoDefaults,
        createItemAccordingToSchema,
        isValid, // TODO: Adjust API to include errors like in old service
        isContainer,
        isPage,
        isRepeater,
        isRefComponent,
        getComponentType,
        getNamespaceConfig,
        whitelistCleanup
    }
}

const staticInstance = createService(getUnifiedSchemasConfig())

export {SchemaValidationErrorDetails, SchemaValidationError, createSchemaCore, createService, staticInstance, common}
