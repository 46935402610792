import _ from 'lodash'
import * as jsonSchemas from '@wix/document-services-json-schemas'
import {DATA_TYPES} from '../../constants/constants'

export const ERRORS = {
    COMPONENT_DOES_NOT_EXIST: 'component param does not exist',
    COMPONENT_DOES_NOT_HAVE_TYPE: 'component does not have type',
    INVALID_COMPONENT_STRUCTURE: 'invalid component structure',
    UNKNOWN_COMPONENT_TYPE: 'unknown component type',
    INVALID_COMPONENT_PROPERTIES: 'invalid component properties',
    INVALID_COMPONENT_DATA: 'invalid component data',
    COMPONENT_MISSING_STYLE_OR_SKIN: 'component missing style or skin',
    INVALID_MOBILE_HINTS: 'invalid mobile hints',
    INVALID_CONTAINER_STRUCTURE: 'invalid container structure',
    INVALID_CONTAINER_POINTER: 'invalid container pointer',
    INVALID_COMPONENT_INDEX: 'invalid component index',
    MAXIMUM_CHILDREN_NUMBER_REACHED: 'maximum number of child components reached',
    CANNOT_ADD_COMPONENT_TO_MOBILE_PATH: 'cannot add component to mobile path',
    CANNOT_DELETE_MASTER_PAGE: 'cannot delete master page',
    SITE_MUST_HAVE_AT_LEAST_ONE_PAGE: 'site must have at least one page',
    CANNOT_DELETE_MOBILE_COMPONENT: 'cannot delete mobile component',
    CUSTOM_ID_MUST_BE_STRING: 'customId must be a string',
    COMPONENT_IS_NOT_CONTAINER: 'component is not a container',
    LAYOUT_PARAM_IS_INVALID: 'layout param is invalid',
    LAYOUT_PARAM_IS_NOT_ALLOWED: 'layout param is not allowed',
    LAYOUT_PARAM_MUST_BE_NUMERIC: 'layout param must be numeric',
    LAYOUT_PARAM_MUST_BE_BOOLEAN: 'layout param must be boolean',
    LAYOUT_PARAM_ROTATATION_INVALID_RANGE: 'rotationInDegrees must be a valid range (0-360)',
    LAYOUT_PARAM_CANNOT_BE_NEGATIVE: 'layout param cannot be a negative value',
    CANNOT_DELETE_HEADER_COMPONENT: 'cannot delete a header component',
    CANNOT_DELETE_FOOTER_COMPONENT: 'cannot delete a footer component',
    CANNOT_DELETE_NON_EXISTING_COMPONENT: 'cannot delete a non existing component',
    CANNOT_DELETE_NON_DISPLAYED_COMPONENT: 'cannot delete a non displayed component',
    SKIN_PARAM_MUST_BE_STRING: 'skin name param must be a string',
    CANNOT_SET_BOTH_SKIN_AND_STYLE: 'skin cannot be set if style already exists',
    STYLE_ID_PARAM_MUST_BE_STRING: 'style id param must be a string',
    STYLE_ID_PARAM_DOES_NOT_EXIST: 'style id param does not exist and cannot be set',
    STYLE_ID_PARAM_ALREADY_EXISTS: 'style id param already exists and cannot be overridden with custom style',
    STYLE_PROPERTIES_PARAM_MUST_BE_OBJECT: 'style properties param must be an object',
    COMPONENT_IS_DEPRECATED: 'cannot add because component was deprecated',
    INVALID_COMPONENT_CONNECTION_ROLE: 'invalid connection role - cannot be *',
    CANNOT_ADD_COMPONENT_WITH_VARIANT: 'cannot add component with variants',
    CANNOT_DELETE_COMPONENT_WITH_VARIANT: 'cannot delete component with variants',
    UNKNOWN_SYSTEM_STYLE: 'Adding unknown system style',
    INVALID_DESKTOP_POINTER: 'invalid desktop pointer',
    SITE_CANNOT_USE_EFFECTS: 'The site does not yet use effects so a component with effects data cannot be added'
}

export const ALLOWED_LAYOUT_PARAMS = [
    'x',
    'y',
    'width',
    'height',
    'scale',
    'rotationInDegrees',
    'fixedPosition',
    'docked',
    'aspectRatio'
]

export const SERIALIZED_DATA_KEYS = _.omit(
    {
        ..._.mapKeys(DATA_TYPES, _.identity),
        anchors: 'anchor',
        layout: 'layouts'
    },
    [DATA_TYPES.variants]
)

export const {
    namespaceMapping: {SERIALIZED_SCOPED_DATA_KEYS}
} = jsonSchemas

export const defaultLayout = {
    x: 0,
    y: 0,
    fixedPosition: false,
    width: 160,
    height: 90,
    scale: 1.0,
    rotationInDegrees: 0.0
}

export const SERIALIZED_PROPERTIES_TO_OMIT = [
    'custom',
    'activeModes',
    'layoutResponsive',
    'variants',
    'overrideOwner',
    'templateId',
    'translations',
    ..._.values(SERIALIZED_DATA_KEYS),
    ..._.values(SERIALIZED_SCOPED_DATA_KEYS)
]
