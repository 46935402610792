import _ from 'lodash'
import type {CreateExtArgs} from '@wix/document-manager-core'
import type {CompRef} from '@wix/document-services-types'
import {guidUtils, displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {DataModelExtensionAPI} from '../../dataModel/dataModel'
import {DATA_TYPES} from '../../../constants/constants'

const updateComponentData = (
    {extensionAPI}: CreateExtArgs,
    compPointer: CompRef,
    fixedText: string,
    newLinks: string[]
) => {
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const compData = dataModel.components.getItem(compPointer, DATA_TYPES.data)
    compData.text = fixedText
    compData.linkList = newLinks
    dataModel.components.addItem(compPointer, DATA_TYPES.data, compData)
}

const createTextLinkReferences = (
    createExtArgs: CreateExtArgs,
    compPointer: CompRef,
    newLinkList: any[],
    text: string
) => {
    let textToFix: string = _.clone(text)
    // eslint-disable-next-line lodash/prefer-filter
    _.forEach(newLinkList, linkData => {
        if (linkData.id) {
            const newId = guidUtils.getUniqueId('link', '-', {bucket: 'links'})
            textToFix = textToFix.replace(`#${linkData.id}`, `#${newId}`)
            linkData.id = newId
        }
    })

    updateComponentData(createExtArgs, compPointer, textToFix, newLinkList)
}
export const duplicateLinksDataItems = (createExtArgs: CreateExtArgs, data: any) => {
    const {compToAddPointer, componentDefinition} = data
    const {pointers} = createExtArgs
    if (!componentDefinition.custom?.linkList) {
        return {success: true, description: 'Duplicated text links data items'}
    }

    const repeatedComponents = pointers.structure.getAllDisplayedOnlyComponents(compToAddPointer)
    _.forEach(repeatedComponents, (componentPointer: CompRef) => {
        const repeatedItemId = displayedOnlyStructureUtil.getRepeaterItemId(componentPointer.id)
        const linkListKey = repeatedItemId ? repeatedItemId : componentDefinition.custom.originalCompId
        const repeatedData = repeatedItemId
            ? componentDefinition.data.overrides[repeatedItemId]
            : componentDefinition.data
        const newLinks = deepClone(componentDefinition.custom.linkList[linkListKey])
        createTextLinkReferences(createExtArgs, componentPointer, newLinks, repeatedData.text)
    })

    return {success: true, description: 'Duplicated text links data items'}
}
