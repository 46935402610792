import _ from 'lodash'
import type {DocumentServicesObject} from '@wix/document-services-types'
import type {OverlayManager} from '../overlayManager/overlayManager'
import type {ComponentToChildrenMap} from '../types'
import type {DAL} from '@wix/document-manager-core'
import type {MobileAlgoApi} from '@wix/document-manager-extensions/src/extensions/mobileAlgo/mobileAlgo'
import {NEW_ALGO} from '../constants'
import type {OrderApi} from '@wix/document-manager-extensions/src/extensions/mobileAlgo/plugins/order/types'
import type {GroupApi} from '@wix/document-manager-extensions/src/extensions/mobileAlgo/plugins/grouping/types'
import {getSections} from './utils/getSections'

const getPluginApi = (mobileAlgo: MobileAlgoApi) => {
    const ctx = mobileAlgo.context.create(['grouping', 'order'])
    const apis = mobileAlgo.plugins.getApis(ctx)
    const {grouping} = apis as GroupApi
    const {order} = apis as OrderApi
    return {ctx, order, grouping}
}

const displayOrderIndicationsNew = (overlayManager: OverlayManager, orderMap: ComponentToChildrenMap) => {
    _.forEach(orderMap, data => {
        if (data.components && data.components.length > 0) {
            _.forEach(data.components, (child, index) => {
                overlayManager.markComponent([child], NEW_ALGO, `new-algo:${index}`, 'yellow', '0', '')
            })
        }
    })
}

const displayOrder = async (mobileAlgo: MobileAlgoApi, sections: string[], overlayManager: OverlayManager) => {
    for (const id of sections) {
        const {ctx, order} = getPluginApi(mobileAlgo)
        await mobileAlgo.runWithContext({id, type: 'DESKTOP'}, ctx, [])
        const result = order.getOrderingResult()
        displayOrderIndicationsNew(overlayManager, result)
    }
}

export const displayOrderSingleSection = async (
    mobileAlgo: MobileAlgoApi,
    sectionId: string,
    overlayManager: OverlayManager
) => {
    await displayOrder(mobileAlgo, [sectionId], overlayManager)
}

export const displayOrderAllSections = async (
    documentServices: DocumentServicesObject,
    mobileAlgo: MobileAlgoApi,
    overlayManager: OverlayManager,
    dal: DAL
) => {
    const currentPageSections = getSections(dal, documentServices.pages.getCurrentPageId())
    await displayOrder(mobileAlgo, currentPageSections, overlayManager)
}
