import {type CreateExtArgs, type DAL, ExtensionAPI, pointerUtils} from '@wix/document-manager-core'
import type {
    CompStructure,
    DeserializationMappers,
    Pointer,
    PossibleViewModes,
    SerializedCompStructure
} from '@wix/document-services-types'
import {deserializeComponentData} from './componentDeserialization/componentDeserialization'
import {generateItemIdWithPrefix} from '../../utils/dataUtils'
import _ from 'lodash'
import type {AddComponentOptionals} from './types'
import {SERIALIZED_PROPERTIES_TO_OMIT} from './constants'
import type {ComponentsMetadataAPI} from '../componentsMetadata/componentsMetadata'

const enrichCompStructure = (extensionAPI: ExtensionAPI, compStructure: CompStructure, compPointer: Pointer) => {
    const componentMetaData = () => (extensionAPI as ComponentsMetadataAPI).componentsMetadata
    compStructure.id = compPointer.id
    if (componentMetaData().isContainer(compStructure.componentType)) {
        compStructure.components = []
    }
}

const omitPropertiesFromSerializedComp = (compStructure: CompStructure) => {
    return _.omit(compStructure, SERIALIZED_PROPERTIES_TO_OMIT)
}

export const addComponentToParent = (dal: DAL, parentPointer: Pointer, componentPointer: Pointer) => {
    const componentsPtr = pointerUtils.getInnerPointer(parentPointer, 'components')
    const components = _.cloneDeep(dal.get(componentsPtr))
    components.push(componentPointer.id)
    dal.set(componentsPtr, components)
    dal.set({...componentPointer, innerPath: ['parent']}, parentPointer.id)
}

const buildDalComponent = (
    createExtArgs: CreateExtArgs,
    compStructure: SerializedCompStructure,
    pageId: string,
    compPointer: Pointer,
    mappers?: DeserializationMappers,
    isPage?: boolean
) => {
    const dalComp = _.cloneDeep(compStructure)
    enrichCompStructure(createExtArgs.extensionAPI, dalComp, compPointer)
    deserializeComponentData({
        createExtArgs,
        compStructure: dalComp,
        pageId,
        customId: compPointer?.id,
        mappers,
        isPage
    })
    return omitPropertiesFromSerializedComp(dalComp)
}

const setComponentV2 = (
    createExtArgs: CreateExtArgs,
    parentPointer: Pointer,
    compViewMode: PossibleViewModes,
    compStructure: SerializedCompStructure,
    pageId: string,
    optionals: AddComponentOptionals = {}
) => {
    const {dal} = createExtArgs
    const {compPointerOverride, isPage, mappers} = optionals

    const compPointerToAdd = compPointerOverride
        ? compPointerOverride
        : pointerUtils.getPointer(generateItemIdWithPrefix('comp'), compViewMode)

    const dalComponent = buildDalComponent(createExtArgs, compStructure, pageId, compPointerToAdd, mappers, isPage)
    dal.set(compPointerToAdd, dalComponent)

    if (!isPage) {
        addComponentToParent(dal, parentPointer, compPointerToAdd)
    }

    const children = compStructure.components
    _.forEach(children, child => {
        setComponentV2(createExtArgs, compPointerToAdd, compViewMode, child as SerializedCompStructure, pageId, {
            isPage
        })
    })

    return compPointerToAdd
}

export const addComponentV2Internal = (
    createExtArgs: CreateExtArgs,
    parentPointer: Pointer,
    compViewMode: PossibleViewModes,
    compStructure: SerializedCompStructure,
    compPointerOverride?: Pointer,
    isPage = false
) => {
    const {pointers} = createExtArgs
    const pagePointer = pointers.structure.getPageOfComponent(parentPointer)
    const pageId = pagePointer.id
    return setComponentV2(createExtArgs, parentPointer, compViewMode, compStructure, pageId, {
        compPointerOverride,
        isPage
    })
}
