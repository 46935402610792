import type {Pointer} from '@wix/document-services-types'
import type {DalItem} from '@wix/document-manager-core'
import * as constants from '../../../constants/constants'
import {updateComponentPropsStructure} from './namespaces/props'
import {updateComponentMobileHintsStructure} from './namespaces/mobileHints'
import {updateComponentDataStructure} from './namespaces/data'
import {updateComponentAnchorStructure} from './namespaces/anchors'
import {updateComponentConnectionsStructure} from './namespaces/connections'
import {updateComponentPatternsStructure} from './namespaces/patterns'

import type {DataModelAPI, DataModelExtensionAPI} from '../../dataModel/dataModel'
import _ from 'lodash'

import type {DeserializeComponentParams} from './types'
import {addStyleToStructure, updateComponentStylesStructure} from './namespaces/style'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import {updateComponentLayoutStructure} from './namespaces/layout'
import {updateComponentDesignStructure} from './namespaces/design'
import {updateComponentReactionsStructure} from './namespaces/reactions'
import {updateComponentTransitionsStructure} from './namespaces/transitions'
import {updateComponentTransformationsStructure} from './namespaces/transformations'

const {DATA_TYPES} = constants

const namespaces = [
    DATA_TYPES.data,
    DATA_TYPES.prop,
    DATA_TYPES.mobileHints,
    DATA_TYPES.anchors,
    DATA_TYPES.connections
]
type DeserializeComponentImplementation = (deserializationParams: DeserializeComponentParams) => void

const namespaceMapping: Map<string, DeserializeComponentImplementation> = new Map([
    [DATA_TYPES.data, updateComponentDataStructure],
    [DATA_TYPES.prop, updateComponentPropsStructure],
    [DATA_TYPES.mobileHints, updateComponentMobileHintsStructure],
    [DATA_TYPES.anchors, updateComponentAnchorStructure],
    [DATA_TYPES.connections, updateComponentConnectionsStructure],
    [DATA_TYPES.patterns, updateComponentPatternsStructure],
    [DATA_TYPES.theme, updateComponentStylesStructure],
    [DATA_TYPES.layout, updateComponentLayoutStructure],
    [DATA_TYPES.design, updateComponentDesignStructure],
    [DATA_TYPES.transitions, updateComponentTransitionsStructure],
    [DATA_TYPES.reactions, updateComponentReactionsStructure],
    [DATA_TYPES.transformations, updateComponentTransformationsStructure]
])

const createItemByType = (dataModel: DataModelAPI, item: string, itemType: string) => {
    switch (itemType) {
        case DATA_TYPES.data:
            return dataModel.createDataItemByType(item)
        case DATA_TYPES.prop:
            return dataModel.createPropertiesItemByType(item)
        case DATA_TYPES.behaviors:
            return dataModel.createBehaviorsItem(item)
        default:
            return null
    }
}
const addRepeatedDataToStructure = (
    dataModel: DataModelAPI,
    item: DalItem,
    itemType: string,
    pageId: string,
    customId?: string
) => {
    const itemQueryPointer = dataModel.addItem(item.original, itemType, pageId, customId)

    _.forEach(item.overrides, function (overriddenItem, itemId) {
        const uniqueId = displayedOnlyStructureUtil.getUniqueDisplayedId(itemQueryPointer?.id, itemId)
        dataModel.addItem(overriddenItem, itemType, pageId, uniqueId)
    })
    return itemQueryPointer
}

export const addNamespaceToStructure = (
    deserializationParams: DeserializeComponentParams,
    item: DalItem | string | undefined,
    itemType: string,
    oldToNewIdMap: {[key: string]: string},
    stylesPerPage?: boolean
): string | null => {
    const {createExtArgs, compStructure, pageId, customId} = deserializationParams
    const {extensionAPI, coreConfig} = createExtArgs

    const {dataModel} = extensionAPI as DataModelExtensionAPI
    let itemQueryPointer: Pointer | null = null
    if (item) {
        if (itemType === DATA_TYPES.theme) {
            itemQueryPointer = addStyleToStructure(
                extensionAPI,
                coreConfig,
                item,
                pageId,
                compStructure.componentType,
                customId,
                stylesPerPage
            )
        } else if (_.isObject(item)) {
            if (item.type === 'RepeatedData') {
                itemQueryPointer = addRepeatedDataToStructure(dataModel, item, itemType, pageId, customId)
            } else {
                itemQueryPointer = dataModel.addItem(item, itemType, pageId, customId, '', oldToNewIdMap)
            }
        } else if (_.isString(item)) {
            const defaultDataItem = createItemByType(dataModel, item, itemType)
            if (defaultDataItem) {
                itemQueryPointer = dataModel.addItem(defaultDataItem, itemType, pageId, customId)
            }
        }
    }
    return itemQueryPointer?.id ?? null
}

export const deserializeComponentData = (deserializationParams: DeserializeComponentParams) => {
    namespaces.forEach((namespace: string) => {
        const updateNamespaceImpl = namespaceMapping.get(namespace)
        if (updateNamespaceImpl) {
            updateNamespaceImpl(deserializationParams)
        }
    })
}
