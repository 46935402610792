'use strict'

const _ = require('lodash')
const constants = require('../helpers/constants')
const {stripHashIfExists} = require('../helpers/dataUtils')
const {createPageManager} = require('../helpers/pageManager')
const {
    DATA_TYPES,
    NAMESPACE_MAPPING,
    DATA_TYPE_BY_COMP_DATA_QUERY,
    COMP_DATA_QUERY_KEYS_WITH_STYLE
} = require('@wix/document-services-json-schemas/dist/constants.json')

const HOVER = 'HOVER'
const DEFAULT = 'DEFAULT'
const MODE_IN = 'modeIn'
const MODE_OUT = 'modeOut'
const MODE_CHANGE = 'modeChange'
const CONTAINER_DEFAULT_STYLE = 'c1'
const ZERO_PX = {value: 0, unit: 'px'}
const ZERO_RGBA = {red: 0, green: 0, blue: 0, alpha: 0}
const HOVER_BOX_COMP_TYPE = 'wysiwyg.viewer.components.HoverBox'
const MEDIA_CONTAINER_DEFAULT_CSS_STYLE = {
    cssBorderRadius: {
        topLeft: ZERO_PX,
        topRight: ZERO_PX,
        bottomLeft: ZERO_PX,
        bottomRight: ZERO_PX
    },
    cssBorder: {
        width: {
            top: ZERO_PX,
            right: ZERO_PX,
            bottom: ZERO_PX,
            left: ZERO_PX
        },
        style: {
            top: 'solid',
            right: 'solid',
            left: 'solid',
            bottom: 'solid'
        },
        color: {
            top: ZERO_RGBA,
            right: ZERO_RGBA,
            left: ZERO_RGBA,
            bottom: ZERO_RGBA
        }
    }
}
const MEDIA_CONTAINER_CUSTOM_STYLE = {
    styleType: 'custom',
    componentClassName: 'wysiwyg.viewer.components.MediaContainer',
    style: {
        groups: {},
        properties: {},
        propertiesSource: {}
    },
    type: 'ComponentStyle',
    skin: 'wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer'
}
const DEFAULT_MODE_IN_ANIMATIONS_SETTINGS = {
    FadeIn: {name: 'FadeIn', delay: 0, duration: 1.2},
    FloatIn: {
        name: 'FloatIn',
        delay: 0,
        duration: 1.2,
        params: {direction: 'right'}
    },
    ExpandIn: {
        name: 'ExpandIn',
        delay: 0,
        duration: 1.2,
        params: {direction: 'right'}
    },
    SpinIn: {
        name: 'SpinIn',
        delay: 0,
        duration: 1.2,
        params: {cycles: 2, direction: 'cw'}
    },
    FlyIn: {
        name: 'FlyIn',
        delay: 0,
        duration: 1.2,
        params: {direction: 'right'}
    },
    TurnIn: {
        name: 'TurnIn',
        delay: 0,
        duration: 1.2,
        params: {direction: 'right'}
    },
    ArcIn: {
        name: 'ArcIn',
        delay: 0,
        duration: 1.2,
        params: {direction: 'right'}
    },
    DropIn: {name: 'DropIn', delay: 0, duration: 1.2},
    FlipIn: {
        name: 'FlipIn',
        delay: 0,
        duration: 1.2,
        params: {direction: 'left'}
    },
    FoldIn: {
        name: 'FoldIn',
        delay: 0,
        duration: 1.2,
        params: {direction: 'left'}
    },
    Reveal: {
        name: 'Reveal',
        delay: 0,
        duration: 1.2,
        params: {direction: 'left'}
    },
    SlideIn: {
        name: 'SlideIn',
        delay: 0,
        duration: 1.2,
        params: {direction: 'left'}
    }
}
const NON_SPECIAL_COMP_QUERIES = _.omit(DATA_TYPE_BY_COMP_DATA_QUERY, ['connectionQuery', 'styleId'])
const TEXT_COMP_TYPE = 'wysiwyg.viewer.components.WRichText'

const migratePage = pageManager => {
    pageManager.data.verifyDataMapExists([
        NAMESPACE_MAPPING.effects,
        NAMESPACE_MAPPING.variants,
        NAMESPACE_MAPPING.transitions,
        NAMESPACE_MAPPING.transformations,
        NAMESPACE_MAPPING.triggers
    ])
    const {desktopComponents, mobileComponents} = pageManager.structure.getAllComponentsBasedOnType(HOVER_BOX_COMP_TYPE)

    const getId = prefix => pageManager.generateId(prefix)

    const removeQueriesFromCompStructure = (compStructure, queriesToRemove) => {
        queriesToRemove.forEach(query => delete compStructure[query])
    }

    const duplicateItem = (idToDuplicate, namespace, type, overrides) => {
        const duplicatedItem = _.cloneDeep(pageManager.data.getItem(namespace, idToDuplicate))
        return pageManager.data.addItem(namespace, type, {...duplicatedItem, ...overrides})
    }

    const linkQueryToComponent = (type, id, compStructure) => {
        compStructure[COMP_DATA_QUERY_KEYS_WITH_STYLE[type]] = constants.DATA_TYPES_WITH_HASH.includes(type)
            ? `#${id}`
            : id
    }

    const duplicateItemAndLinkToComponent = (compStructure, namespace, type, itemToDuplicateId, overrides) => {
        const newId = duplicateItem(stripHashIfExists(itemToDuplicateId), namespace, type, overrides)
        linkQueryToComponent(type, newId, compStructure)
        return newId
    }

    const addConnectionData = (comp, connectionIdToDuplicate) => {
        if (connectionIdToDuplicate || comp[constants.CONNECTION_QUERY]) {
            const newId = duplicateItem(
                connectionIdToDuplicate ?? comp[constants.CONNECTION_QUERY],
                NAMESPACE_MAPPING.connections,
                DATA_TYPES.connections
            )
            linkQueryToComponent(DATA_TYPES.connections, newId, comp)
            _.find(pageManager.data.getItem(NAMESPACE_MAPPING.connections, newId).items, item => {
                if (item.type === 'WixCodeConnectionItem') {
                    item.role = getId(item.role)
                    return true
                }
            })
        }
    }

    const addTransformationOnMediaContainer = (compStructure, variantId, modeType) => {
        const defaultTransformId = pageManager.data.addItem(
            NAMESPACE_MAPPING.transformations,
            DATA_TYPES.transformations,
            {hidden: modeType === HOVER, type: constants.TRANSFORM_DATA_TYPE}
        )
        const transformInVariantId = pageManager.data.addItem(
            NAMESPACE_MAPPING.transformations,
            DATA_TYPES.transformations,
            {hidden: modeType === DEFAULT, type: constants.TRANSFORM_DATA_TYPE}
        )
        const variantRelationId = pageManager.data.addVariantRelation(
            NAMESPACE_MAPPING.transformations,
            DATA_TYPES.transformations,
            [variantId],
            transformInVariantId,
            compStructure.id
        )
        const refArrayId = pageManager.data.addRefArrayItem(
            NAMESPACE_MAPPING.transformations,
            DATA_TYPES.transformations,
            [defaultTransformId, variantRelationId]
        )
        pageManager.component.data.linkQueryToComponent(compStructure, DATA_TYPES.transformations, refArrayId)
    }

    const addTransformation = (compStructure, {transformationData, hoverVariantId}) => {
        const transformDataItemId = pageManager.data.addItem(
            NAMESPACE_MAPPING.transformations,
            DATA_TYPES.transformations,
            {
                ...transformationData,
                origin: {
                    x: {
                        value: 50,
                        type: 'percentage'
                    },
                    y: {
                        value: 50,
                        type: 'percentage'
                    }
                },
                type: constants.TRANSFORM_DATA_TYPE
            }
        )
        const variantRelationId = pageManager.data.addVariantRelation(
            NAMESPACE_MAPPING.transformations,
            DATA_TYPES.transformations,
            [hoverVariantId],
            transformDataItemId,
            compStructure.id
        )
        const refArrayId = pageManager.data.addRefArrayItem(
            NAMESPACE_MAPPING.transformations,
            DATA_TYPES.transformations,
            [variantRelationId]
        )
        pageManager.component.data.linkQueryToComponent(compStructure, DATA_TYPES.transformations, refArrayId)
    }

    const addTransition = (compStructure, transitionData) => {
        const transitionDataItemId = pageManager.data.addItem(NAMESPACE_MAPPING.transitions, DATA_TYPES.transitions, {
            ...transitionData,
            type: constants.TRANSITION_DATA_TYPE
        })
        const refArrayId = pageManager.data.addRefArrayItem(NAMESPACE_MAPPING.transitions, DATA_TYPES.transitions, [
            transitionDataItemId
        ])
        pageManager.component.data.linkQueryToComponent(compStructure, DATA_TYPES.transitions, refArrayId)
    }

    const addMobileHints = (compStructure, value) => {
        duplicateItemAndLinkToComponent(
            compStructure,
            NAMESPACE_MAPPING.mobileHints,
            DATA_TYPES.mobileHints,
            compStructure[constants.MOBILE_HINTS_QUERY],
            {...value, type: 'MobileHints'}
        )
    }

    const migrateModeChangeAndRunCodeBehaviors = (compStructure, context, modeId) => {
        const behaviorId = compStructure[constants.BEHAVIOR_QUERY]
        if (behaviorId) {
            const behaviorItem = pageManager.data.getItem(NAMESPACE_MAPPING.behaviors, behaviorId)
            const filteredParsedItems = JSON.parse(behaviorItem.items).filter(
                item =>
                    !(item.viewMode === 'MOBILE' && [MODE_IN, MODE_OUT].includes(item.action)) ||
                    !(item.action === 'screenIn' && item.params.modeIds)
            )
            const isCompHiddenByModes =
                context.originalComponentModes?.isHiddenByModes ||
                context.originalComponentModes?.overrides?.find(({isHiddenByModes}) => !!isHiddenByModes)

            const parsedItemsToKeep = []
            if (filteredParsedItems.length === 0) {
                removeQueriesFromCompStructure(compStructure, [constants.BEHAVIOR_QUERY])
            } else {
                filteredParsedItems.forEach(item => {
                    if (item.action && item.behavior) {
                        item.action.sourceId = compStructure.id
                        item.behavior.params.compId = compStructure.id
                        parsedItemsToKeep.push(item)
                    }
                    if (item.action === MODE_CHANGE && context.transformationData) {
                        addTransformation(compStructure, context)
                        addTransition(compStructure, {
                            delay: item.params.delay ?? item.delay ?? 0,
                            duration: item.params.duration ?? item.duration ?? 0.5,
                            'timing-function': 'ease-in-out'
                        })
                    }
                    if ([MODE_IN, MODE_OUT].includes(item.action) && isCompHiddenByModes) {
                        _.defaultsDeep(
                            item,
                            {
                                params: {
                                    direction: item.direction
                                }
                            },
                            DEFAULT_MODE_IN_ANIMATIONS_SETTINGS[item.name]
                        )

                        if (item.params?.modeIds?.includes(modeId) || !item.params?.modeIds?.length) {
                            item.triggerVariantId = context.triggerHoverVariantId
                            item.reversed = context.isDefault
                            parsedItemsToKeep.push(item)
                        }
                    }
                })
            }
            if (parsedItemsToKeep.length === 0) {
                removeQueriesFromCompStructure(compStructure, [constants.BEHAVIOR_QUERY])
            } else {
                behaviorItem.items = JSON.stringify(parsedItemsToKeep)
            }
        }
    }

    const prepareModeChangeBehavior = (hoverBoxComp, defaultModeId, hoverModeId) => {
        hoverBoxComp.components.forEach(comp => {
            const behaviorId = comp[constants.BEHAVIOR_QUERY]
            if (behaviorId) {
                const behaviorItem = pageManager.data.getItem(NAMESPACE_MAPPING.behaviors, behaviorId)
                const parsedItems = JSON.parse(behaviorItem.items)

                const doGivenItemsIncludeGivenAction = (givenAction, givenItems = parsedItems) =>
                    givenItems.some(({action}) => action === givenAction)

                const hasModeChange = doGivenItemsIncludeGivenAction(MODE_CHANGE)

                if (hasModeChange) {
                    let parsedItemsToKeep = []
                    const {modes} = comp
                    const transformationData = {}

                    const shouldRemoveDueToEqualLayout = () => {
                        if (modes) {
                            const layoutInDefaultMode =
                                modes.overrides.find(({modeIds}) => modeIds[0] === defaultModeId)?.layout ?? comp.layout

                            const layoutInHoverMode = modes.overrides.find(
                                ({modeIds}) => modeIds[0] === hoverModeId
                            )?.layout

                            if (layoutInDefaultMode && layoutInHoverMode) {
                                if (layoutInDefaultMode.x !== layoutInHoverMode.x) {
                                    const widthDiff = layoutInHoverMode.width - layoutInDefaultMode.width
                                    _.set(transformationData, 'translate.x', {
                                        value: layoutInHoverMode.x - layoutInDefaultMode.x + widthDiff * 0.5,
                                        type: 'px'
                                    })
                                }

                                if (layoutInDefaultMode.y !== layoutInHoverMode.y) {
                                    const heightDiff = layoutInHoverMode.height - layoutInDefaultMode.height
                                    _.set(transformationData, 'translate.y', {
                                        value: layoutInHoverMode.y - layoutInDefaultMode.y + heightDiff * 0.5,
                                        type: 'px'
                                    })
                                }

                                if (
                                    layoutInDefaultMode.height !== layoutInHoverMode.height &&
                                    comp.componentType !== TEXT_COMP_TYPE
                                ) {
                                    _.set(
                                        transformationData,
                                        'scale.y',
                                        layoutInHoverMode.height / layoutInDefaultMode.height
                                    )
                                }

                                if (
                                    layoutInDefaultMode.width !== layoutInHoverMode.width &&
                                    comp.componentType !== TEXT_COMP_TYPE
                                ) {
                                    _.set(
                                        transformationData,
                                        'scale.x',
                                        layoutInHoverMode.width / layoutInDefaultMode.width
                                    )
                                }

                                if (layoutInDefaultMode.rotationInDegrees !== layoutInHoverMode.rotationInDegrees) {
                                    transformationData.rotate =
                                        layoutInHoverMode.rotationInDegrees || layoutInDefaultMode.rotationInDegrees
                                }

                                return Object.keys(transformationData).length === 0
                            }
                        }
                        return true
                    }

                    if (shouldRemoveDueToEqualLayout()) {
                        parsedItems.forEach(item => {
                            if (item.action !== MODE_CHANGE) {
                                parsedItemsToKeep.push(item)
                            }
                        })
                    } else {
                        parsedItemsToKeep = parsedItems
                    }

                    if (Object.keys(transformationData).length) {
                        comp.layout.transformationData = transformationData
                    }

                    if (parsedItemsToKeep.length === 0) {
                        removeQueriesFromCompStructure(comp, [constants.BEHAVIOR_QUERY])
                    } else {
                        behaviorItem.items = JSON.stringify(parsedItemsToKeep)
                    }
                }
            }
        })
    }

    const handleBehaviorForMasterContainer = compStructure => {
        const behaviorId = compStructure[constants.BEHAVIOR_QUERY]
        if (behaviorId) {
            const behaviorItem = pageManager.data.getItem(NAMESPACE_MAPPING.behaviors, behaviorId)
            const parsedItemsToKeep = JSON.parse(behaviorItem.items).filter(
                ({action}) => ![MODE_IN, MODE_OUT, MODE_CHANGE].includes(action)
            )
            if (parsedItemsToKeep.length === 0) {
                removeQueriesFromCompStructure(compStructure, [constants.BEHAVIOR_QUERY])
                pageManager.data.removeItem(NAMESPACE_MAPPING.behaviors, behaviorId)
                return
            }
            behaviorItem.items = JSON.stringify(parsedItemsToKeep)
        }
    }

    const cleanAndUpdateMasterContainerStructure = compStructure => {
        removeQueriesFromCompStructure(compStructure, ['modes', constants.PROPS_QUERY, constants.DESIGN_QUERY])
        compStructure[constants.STYLE_QUERY] = CONTAINER_DEFAULT_STYLE
        compStructure.componentType = constants.CONTAINER_COMP_TYPE
        compStructure.components = []
    }

    const createDesktopMasterContainer = compStructure => {
        cleanAndUpdateMasterContainerStructure(compStructure)
        addMobileHints(compStructure, {type: 'MobileHints', modifiedByUser: true})
        if (compStructure[constants.BEHAVIOR_QUERY]) {
            handleBehaviorForMasterContainer(compStructure)
        }

        const triggerHoverVariantId = pageManager.data.addItem(NAMESPACE_MAPPING.variants, DATA_TYPES.variants, {
            type: 'Trigger',
            trigger: 'hover',
            componentId: compStructure.id
        })
        const triggersListId = pageManager.data.addItem(NAMESPACE_MAPPING.triggers, DATA_TYPES.triggers, {
            type: 'Triggers',
            values: [`#${triggerHoverVariantId}`]
        })
        pageManager.component.data.linkQueryToComponent(compStructure, DATA_TYPES.triggers, triggersListId)
        const hoverVariantId = pageManager.data.addItem(NAMESPACE_MAPPING.variants, DATA_TYPES.variants, {
            type: 'Hover',
            componentId: compStructure.id
        })

        return {
            triggerHoverVariantId,
            hoverVariantId
        }
    }

    const createMobileMasterContainer = (mobileHoverBox, desktopMasterContainer) => {
        cleanAndUpdateMasterContainerStructure(mobileHoverBox)
        mobileHoverBox[constants.MOBILE_HINTS_QUERY] = desktopMasterContainer[constants.MOBILE_HINTS_QUERY]
        if (!desktopMasterContainer[constants.BEHAVIOR_QUERY] && mobileHoverBox[constants.BEHAVIOR_QUERY]) {
            delete mobileHoverBox[constants.BEHAVIOR_QUERY]
        }
    }

    const createMediaContainerDesignData = designId => {
        const oldDesignItem = pageManager.data.getItem(NAMESPACE_MAPPING.design, designId)
        const overrides = _.isEmpty(oldDesignItem.cssStyle) ? {cssStyle: MEDIA_CONTAINER_DEFAULT_CSS_STYLE} : undefined
        const newDesignQuery = duplicateItem(designId, NAMESPACE_MAPPING.design, DATA_TYPES.design, overrides)
        const newBackgroundId = duplicateItem(
            stripHashIfExists(pageManager.data.getItem(NAMESPACE_MAPPING.design, designId).background),
            NAMESPACE_MAPPING.design,
            DATA_TYPES.design
        )
        pageManager.data.updateItem(newDesignQuery, NAMESPACE_MAPPING.design, {background: `#${newBackgroundId}`})
        const oldMediaRefId = stripHashIfExists(
            pageManager.data.getItem(NAMESPACE_MAPPING.design, newBackgroundId).mediaRef
        )
        if (oldMediaRefId) {
            const newMediaRefId = duplicateItem(oldMediaRefId, NAMESPACE_MAPPING.design, DATA_TYPES.design)
            pageManager.data.updateItem(newBackgroundId, NAMESPACE_MAPPING.design, {mediaRef: `#${newMediaRefId}`})
        }
        return newDesignQuery
    }

    const createContainerPerMode = (
        oldStructure,
        parentRef,
        isMobile,
        modeIdToAdd,
        {hoverVariantId} = {hoverVariantId: undefined}
    ) => {
        const {modes, designQuery} = oldStructure
        const {definitions, overrides} = modes
        const designIdToDuplicate = _.isEmpty(overrides)
            ? stripHashIfExists(designQuery)
            : stripHashIfExists(_.find(overrides, ({modeIds}) => _.some(modeIds, id => id === modeIdToAdd)).designQuery)
        const newDesignId = createMediaContainerDesignData(designIdToDuplicate)
        const styleId = pageManager.data.addItem(
            NAMESPACE_MAPPING.style,
            DATA_TYPES.theme,
            MEDIA_CONTAINER_CUSTOM_STYLE
        )
        const mediaContainerStructure = pageManager.component.addComponent(parentRef, {
            styleId,
            type: constants.CONTAINER_TYPE,
            designQuery: `#${newDesignId}`,
            componentType: constants.MEDIA_CONTAINER_COMP_TYPE,
            layout: {x: 0, y: 0, height: _.clone(parentRef.layout.height), width: _.clone(parentRef.layout.width)}
        })
        addMobileHints(mediaContainerStructure, {modifiedByUser: true, hidden: !isMobile})
        addConnectionData(mediaContainerStructure, oldStructure.connectionQuery)
        if (!isMobile) {
            addTransformationOnMediaContainer(
                mediaContainerStructure,
                hoverVariantId,
                _.find(definitions, ({modeId}) => modeId === modeIdToAdd).type
            )
        }
        return mediaContainerStructure
    }

    const prepare = (desktopHoverBox, mobileHoverBox) => {
        const {definitions} = desktopHoverBox.modes
        const defaultModeId = _.find(definitions, ({type}) => type === DEFAULT).modeId
        const hoverModeId = _.find(definitions, ({type}) => type === HOVER).modeId
        prepareModeChangeBehavior(desktopHoverBox, defaultModeId, hoverModeId)
        const desktopHoverBoxClone = _.cloneDeep(desktopHoverBox)
        const variants = createDesktopMasterContainer(desktopHoverBox)
        const defaultMediaContainer = createContainerPerMode(
            desktopHoverBoxClone,
            desktopHoverBox,
            false,
            defaultModeId,
            variants
        )
        const hoverMediaContainer = createContainerPerMode(
            desktopHoverBoxClone,
            desktopHoverBox,
            false,
            hoverModeId,
            variants
        )

        let mobileDescriptor
        if (mobileHoverBox) {
            const mobileHoverBoxClone = _.cloneDeep(mobileHoverBox)
            createMobileMasterContainer(mobileHoverBox, desktopHoverBox)
            const mobileMediaContainer = createContainerPerMode(mobileHoverBoxClone, mobileHoverBox, true)
            mobileDescriptor = {
                parentRef: mobileMediaContainer,
                oldStructure: mobileHoverBoxClone,
                context: {
                    isHiddenByMobileHints: false
                }
            }
        }

        return [
            {
                parentRef: defaultMediaContainer,
                oldStructure: desktopHoverBoxClone,
                modeId: defaultModeId,
                context: {
                    ...variants,
                    isDefault: true,
                    isHiddenByMobileHints: true,
                    hoverModeId
                }
            },
            {
                parentRef: hoverMediaContainer,
                oldStructure: _.cloneDeep(desktopHoverBoxClone),
                modeId: hoverModeId,
                context: {
                    ...variants,
                    isDefault: false,
                    isHiddenByMobileHints: true,
                    defaultModeId
                }
            },
            mobileDescriptor
        ]
    }

    const applyOverrides = (comp, modeId, context) => {
        if (comp.modes && modeId) {
            const overridesInModeId = comp.modes.overrides.find(({modeIds}) => modeIds.includes(modeId))
            if (!_.isEmpty(overridesInModeId)) {
                const apply = query => {
                    if (comp[query]) {
                        if (query === constants.ABSOLUTE_LAYOUT && context.transformationData && !context.isDefault) {
                            comp[query] =
                                comp.modes.overrides.find(({modeIds}) => modeIds.includes(context.defaultModeId))?.[
                                    query
                                ] ?? comp[query]

                            if (context.transformationData.hasOwnProperty('rotate')) {
                                comp[query].rotationInDegrees = 0
                            }
                            //TODO check msid 0029619f-5495-42cf-a992-83dc80a3b588 comp-kpbxktog
                            return
                        }

                        comp[query] = overridesInModeId[query] ?? comp[query]
                    }
                }

                ;[
                    constants.STYLE_QUERY,
                    constants.PROPS_QUERY,
                    constants.DESIGN_QUERY,
                    constants.ABSOLUTE_LAYOUT
                ].forEach(compQuery => apply(compQuery))

                comp.modes.isHiddenByModes = overridesInModeId.hasOwnProperty('isHiddenByModes')
                    ? overridesInModeId.isHiddenByModes
                    : comp.modes.isHiddenByModes
            }
            delete comp.layout.transformationData
        }
    }

    const duplicateReferences = comp => {
        _.forEach(_.keys(comp), queryId => {
            if (NON_SPECIAL_COMP_QUERIES[queryId]) {
                const queryIdWithoutHash = stripHashIfExists(comp[queryId])
                const type = DATA_TYPE_BY_COMP_DATA_QUERY[queryId]
                const namespace = NAMESPACE_MAPPING[type]
                duplicateItemAndLinkToComponent(comp, namespace, type, queryIdWithoutHash)
            }
        })

        if (comp[constants.CONNECTION_QUERY]) {
            addConnectionData(comp)
        }

        const {styleId} = comp
        const styleItem = pageManager.data.getItem(NAMESPACE_MAPPING.style, styleId)
        if (styleItem && styleItem.styleType === 'custom') {
            duplicateItemAndLinkToComponent(comp, NAMESPACE_MAPPING.style, DATA_TYPES.theme, styleId)
        }
    }

    const migrateChildren = containerDescriptor => {
        if (containerDescriptor) {
            const {parentRef, oldStructure, modeId, context} = containerDescriptor
            const childrenStack = [oldStructure]
            while (childrenStack.length > 0) {
                const comp = childrenStack.pop()
                delete comp.modes
                if (comp.components) {
                    comp.components.forEach(child => {
                        delete context.transformationData
                        delete context.originalComponentModes

                        if (child.layout.transformationData) {
                            context.transformationData = _.cloneDeep(child.layout.transformationData)
                        }

                        if (child.modes) {
                            context.originalComponentModes = _.cloneDeep(child.modes)
                        }

                        applyOverrides(child, modeId, context)
                        duplicateReferences(child)
                        child.id = getId(constants.COMP_PREFIX)
                        addMobileHints(child, {modifiedByUser: true, hidden: context.isHiddenByMobileHints})
                        migrateModeChangeAndRunCodeBehaviors(child, context, modeId)
                        delete child.modes?.overrides
                    })
                    comp.components = comp.components.filter(({modes}) => !modes || !modes.isHiddenByModes)
                    childrenStack.push(...comp.components)
                }
            }
            parentRef.components = oldStructure.components
        }
    }

    _.forEach(desktopComponents, hoverBoxStructure => {
        const mobileHoverBoxStructure = mobileComponents[hoverBoxStructure.id]
        const containersDescriptors = prepare(hoverBoxStructure, mobileHoverBoxStructure)
        containersDescriptors.forEach(migrateChildren)
    })
}

module.exports = {
    name: 'migrateOldHoverBoxToNewFixer',
    version: 0,
    experimentalVersions: [{version: 1, experiment: 'dm_migrateOldHoverBoxToNewFixer'}],
    exec(pageJson, pageIdsArray, magicObject) {
        const {uniqueIdGenerator} = magicObject.dataFixerUtils

        if (!magicObject.isExperimentOpen('dm_migrateOldHoverBoxToNewFixer')) {
            return
        }

        const pageManager = createPageManager(pageJson, uniqueIdGenerator)
        migratePage(pageManager)
    }
}
