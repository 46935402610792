import _ from 'lodash'
import type {CompRef, Pointer, PossibleViewModes, Rect} from '@wix/document-services-types'
import {
    CreateExtArgs,
    Extension,
    HistoryItem,
    Transaction,
    ExtensionAPI,
    DeepFunctionMap,
    DmApis,
    PointerMethods,
    pointerUtils,
    PDAL
} from '@wix/document-manager-core'
import type {CreateViewerExtensionArgument} from '../../types'
import {VIEWER_PAGE_DATA_TYPES, VIEW_MODES, MULTILINGUAL_TYPES} from '../../constants/constants'
import type {PageAPI} from '../page'
import {ReportableError, getReportableFromError} from '@wix/document-manager-utils'
import {viewerTransformSet} from './viewerTransformers'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import type {ComponentWithInnerElement} from '@wix/viewer-manager-interface'

const VIEWER_NAMES = {
    BOLT: 'bolt',
    THUNDERBOLT: 'tb',
    MOCK: 'mock'
}

const documentToViewerTypes = _.assign({}, VIEWER_PAGE_DATA_TYPES, VIEW_MODES, MULTILINGUAL_TYPES, {
    rendererModel: 'rendererModel',
    platform: 'platform',
    wixCode: 'wixCode',
    documentServicesModel: 'documentServicesModel',
    pagesPlatformApplications: 'pagesPlatformApplications'
})

const NAVIGATION_ERROR_IDENTIFIER = 'viewer_extension_navigation_error_identifier'

const viewerManagerTypes = _.keyBy([
    'activeVariants',
    'activeModes',
    'renderFlags',
    'displayedOnlyComponents',
    'customElementsPointer',
    'renderRealTimeConfigPointer',
    'runtime',
    'svgShapes',
    'multilingual',
    'ghostStructure',
    'ghostControllers',
    'blocksPreviewData'
])

export interface InnerViewerExtensionAPI extends DeepFunctionMap {
    getPrimaryPageId(): string
    navigateToPage(pageId: string): Promise<void>
    getCurrentViewMode(): string
    syncViewer(pagesToSync?: PagesToSync, syncOnlyItemsWithoutPage?: boolean): void
    syncViewerFromOpenTransaction(): void
    syncPointers(pointers: Pointer[]): void
    syncPagesToViewer(pageIds: string[], viewMode?: PossibleViewModes): void
    getViewerName(): string
    getViewerVersion(): string
    convertIdToScopedPointer(id: string, enableRepeatersInScopes?: boolean): Pointer
    getRepeaterItemsIndexesById(id: string): number[] | undefined
    getUnderXYIncludingInnerElements(
        x: number,
        y: number
    ): {visibleElements: ComponentWithInnerElement[]; hiddenByBlockingLayer: ComponentWithInnerElement[]}
    getComponentMeasurements(compRef: CompRef): Rect
    getElementComputedStyle(path: string[], propKeys: string[]): Record<string, string>
}

type ViewerSiteAPI = Record<string, Function>

export interface ViewerExtensionAPI extends ExtensionAPI {
    viewer: InnerViewerExtensionAPI
    siteAPI: ViewerSiteAPI
}

interface PagesToSync {
    [pageId: string]: boolean
}

const createExtension = ({viewerManager}: CreateViewerExtensionArgument): Extension => {
    const {dal: viewerManagerDal, pointers, viewerSiteAPI, actions} = viewerManager
    let pages: PageAPI | null = null

    const pagesApi = () => {
        if (!pages) {
            throw new Error('pagesApi accessed before it was initialized')
        }
        return pages
    }

    const getViewerActions = (
        documentTransaction: Transaction,
        pagesToSync: PagesToSync = {},
        syncOnlyItemsWithoutPage: boolean = false
    ): HistoryItem[] =>
        documentTransaction.items.filter(action => {
            const pageId = _.get(action, ['value', 'metaData', 'pageId'])
            if (syncOnlyItemsWithoutPage) {
                return !pageId
            }
            return (
                !pageId ||
                pageId === 'masterPage' ||
                (!_.isEmpty(pagesToSync) ? pagesToSync[pageId] : viewerSiteAPI.getViewerLoadedPagesIds()[pageId])
            )
        })

    const updateViewerManager = (dmApis: DmApis, viewerActions: HistoryItem[], viewMode?: PossibleViewModes) => {
        const {coreConfig} = dmApis
        const {logger} = coreConfig
        // Excluding non current view modes
        const currentViewMode = viewerManager.viewerSiteAPI.getViewMode()
        const viewModeToFilter = viewMode ?? currentViewMode
        const ignoredViewModes = _(VIEW_MODES)
            .values()
            .filter(_viewMode => _viewMode !== viewModeToFilter)
            .value()

        try {
            // TODO: {@see DM-3109} runInBatch impl in viewer-manager-adapter
            actions.runInBatch(() => {
                viewerActions.forEach(action => {
                    // Only sending specific types and relevant types
                    const {key, value} = action
                    const updateDocumentToViewerType =
                        documentToViewerTypes[key.type] && !ignoredViewModes.includes(key.type as PossibleViewModes)
                    const updateViewerOnlyType = viewerManagerTypes[key.type]
                    if (updateDocumentToViewerType || updateViewerOnlyType) {
                        if (!_.isNil(value)) {
                            viewerTransformSet(dmApis, viewerManagerDal, key, value)
                        } else {
                            viewerManagerDal.remove(key)
                        }
                    }
                })
            })
        } catch (err) {
            const reportableError = getReportableFromError(err, {
                message: (err as Error).message,
                errorType: 'viewerBatchFailed'
            })
            logger.captureError(reportableError)
            throw reportableError
        }
    }

    const getMainPageId = (): string => {
        if (!pages) {
            throw new ReportableError({
                errorType: 'PAGES_API_NOT_AVAILABLE',
                message: "The pages api isn't available yet, so we can't get the id of the main page"
            })
        }
        return pagesApi().getMainPageId()
    }

    const getCurrentPageId = (): string => viewerSiteAPI.getPrimaryPageId()

    const getFocusId = (): string => viewerSiteAPI.getFocusedRootId()

    const isFocusedOnPopup = (): boolean => getFocusId() === getCurrentPageId()

    const isFocusBeingDeleted = (viewerActions: HistoryItem[]): boolean =>
        _.some(viewerActions, {
            key: {
                id: getFocusId(),
                type: viewerManager.viewerSiteAPI.getViewMode()
            },
            value: undefined
        })

    const getCurrentViewMode = (): string => viewerSiteAPI.getViewMode()

    const navigateToPage = (pageId: string): Promise<void> => {
        const navInfoPointer = pointers.runtime.getWantedNavInfo()

        return new Promise<void>((resolve, reject) => {
            viewerManagerDal.set(navInfoPointer, {pageId})
            viewerSiteAPI.registerNavigationComplete(resolve)
            viewerSiteAPI.registerNavigationError(NAVIGATION_ERROR_IDENTIFIER, reject)
        }).finally(() => {
            viewerSiteAPI.unregisterNavigationError(NAVIGATION_ERROR_IDENTIFIER)
        })
    }

    const chooseAutoNavigationDestination = (viewerActions: HistoryItem[]): string | null => {
        if (isFocusBeingDeleted(viewerActions)) {
            return isFocusedOnPopup() ? getMainPageId() : getCurrentPageId()
        }
        return null
    }

    const createPostTransactionOperations = (dmApis: DmApis) => ({
        updateViewer: (documentTransaction: Transaction) => {
            const viewerActions = getViewerActions(documentTransaction, {}, false)
            const autoNavigationDestination = chooseAutoNavigationDestination(viewerActions)
            if (autoNavigationDestination) {
                return async () => {
                    await navigateToPage(autoNavigationDestination)
                    updateViewerManager(dmApis, viewerActions)
                }
            }
            updateViewerManager(dmApis, viewerActions)
        }
    })

    const getViewerActionsAndUpdateViewerManager = (
        dmApi: DmApis,
        documentTransaction: Transaction,
        pagesToSync: PagesToSync = {},
        syncOnlyItemsWithoutPage: boolean = false
    ) => {
        const viewerActions = getViewerActions(documentTransaction, pagesToSync, syncOnlyItemsWithoutPage)
        updateViewerManager(dmApi, viewerActions)
    }
    // @ts-expect-error
    const createPointersMethods = (/*dmApis: DmApis*/): PointerMethods => pointers

    const createExtensionAPI = (createExtArgs: CreateExtArgs): ViewerExtensionAPI => {
        const {
            extensionAPI,
            dal,
            coreConfig: {logger}
        } = createExtArgs
        pages = extensionAPI.page as PageAPI
        const siteAPI = viewerSiteAPI as unknown as ViewerSiteAPI

        const syncViewer = (pagesToSync?: PagesToSync, syncOnlyItemsWithoutPage?: boolean) =>
            getViewerActionsAndUpdateViewerManager(
                createExtArgs,
                dal.getTentativeAndAcceptedAsTransaction(),
                pagesToSync,
                syncOnlyItemsWithoutPage
            )

        /* The clone is needed here since bolt is sending these data through window.postMessage, which cannot serialize proxies.
           See ticket https://jira.wixpress.com/browse/DM-5124.
           This can and should be removed when thunderbolt is open to all.
           If the performance impact is too severe, we can relax the restriction in the dal that always exports proxies,
           since this is the only flow that uses `dal.getCurrentOpenTransaction`
         */
        const syncViewerFromOpenTransaction = () =>
            getViewerActionsAndUpdateViewerManager(createExtArgs, _.cloneDeep(dal.getCurrentOpenTransaction()))

        const syncPointers = (ptrs: Pointer[]) => {
            const viewerActions = getViewerActions({
                id: 'syncPointers',
                items: ptrs.map(pointer => ({key: pointer, value: dal.get(pointer)}))
            })
            updateViewerManager(createExtArgs, viewerActions)
        }

        const syncPagesToViewer = (pageIds: string[], viewMode?: PossibleViewModes) => {
            const values = _.flatMap(pageIds, pageId => {
                const pageCompFilter = pagesApi().getPageIndexId(pageId)
                const pageCompInStoreIndex = dal.getIndexed(pageCompFilter)
                return _.flatMap(pageCompInStoreIndex, (namespaceResults, type: string) =>
                    _.map(namespaceResults, (value, id) => ({key: {id, type}, value}))
                )
            })
            updateViewerManager(createExtArgs, values, viewMode)
        }

        const getViewerName = () => viewerManager.viewerConfig.viewerName

        const isBolt = () => getViewerName() === VIEWER_NAMES.BOLT

        const getViewerVersion = () => viewerManager.viewerConfig.viewerVersion

        const getRepeaterItemsIndexesById = (id: string): number[] | undefined => {
            return viewerManager.viewerSiteAPI.getRepeaterItemsIndexes
                ? viewerManager.viewerSiteAPI.getRepeaterItemsIndexes(id)
                : undefined
        }
        const getRepeatedComponents = (pointer: Pointer): Pointer[] | null => {
            if (!viewerManager.viewerSiteAPI?.getRepeatedComponents) {
                return null
            }

            const templatePointer = {...pointer, id: displayedOnlyStructureUtil.getRepeaterTemplateId(pointer.id)}
            return viewerManager.viewerSiteAPI.getRepeatedComponents(templatePointer)
        }

        const convertIdToScopedPointer = (id: string, enableRepeatersInScopes = true) => {
            if (isBolt()) {
                const reportableError = new ReportableError({
                    message: 'Scopes are not available in bolt',
                    errorType: 'useScopesInBolt'
                })
                logger.captureError(reportableError)

                return pointerUtils.getPointer(id, getCurrentViewMode())
            }

            return viewerManager.viewerSiteAPI.convertIdToScopedPointer(id, enableRepeatersInScopes)
        }

        const getUnderXYIncludingInnerElements = (
            x: number,
            y: number
        ): {visibleElements: ComponentWithInnerElement[]; hiddenByBlockingLayer: ComponentWithInnerElement[]} => {
            return viewerManager.viewerSiteAPI.getInnerElementsUnderXY(x, y)
        }

        const getComponentMeasurements = (compRef: CompRef): Rect =>
            viewerManager.viewerSiteAPI.getBasicMeasureForComp(compRef)

        const getElementComputedStyle = (path: string[], propKeys: string[]): Record<string, string> =>
            viewerManager.viewerSiteAPI.getElementComputedStyle(path, propKeys)

        const getContentArea = (compRef: CompRef): {left: number; width: number} =>
            viewerManager.viewerSiteAPI.getContentArea(compRef)

        return {
            viewer: {
                convertIdToScopedPointer,
                getPrimaryPageId: getCurrentPageId,
                navigateToPage,
                getCurrentViewMode,
                syncViewer,
                syncPointers,
                syncViewerFromOpenTransaction,
                syncPagesToViewer,
                getViewerName,
                getViewerVersion,
                getRepeaterItemsIndexesById,
                getRepeatedComponents,
                getUnderXYIncludingInnerElements,
                getComponentMeasurements,
                getElementComputedStyle,
                getContentArea
            },
            siteAPI
        }
    }

    const getter = (dal: PDAL, pointer: Pointer) => {
        if (pointer.noRefFallbacks) {
            return dal.get(pointer)
        }

        return viewerManagerDal.get(pointer, false) //purposely not cloned, as the clone will happen in the GeneralSuperDAL
    }
    const setter = ({}, pointer: Pointer, value: any) => viewerManagerDal.set(pointer, value)
    setter.avoidInConflictDetection = true

    const createGetters = () =>
        _(viewerManagerTypes)
            .keyBy(val => val)
            .mapValues(() => getter)
            .value()

    return {
        name: 'viewerExtension',
        createPointersMethods,
        createPostTransactionOperations,
        createExtensionAPI,
        createGetters
    }
}

export {createExtension, documentToViewerTypes, viewerManagerTypes}
