import type {
    ComponentToChildrenGroupMap,
    MobileAlgoConfig,
    MobileAlgoPlugin,
    MobileAlgoPluginInitializationArgs,
    MobileConversionDal,
    PluginContext,
    PluginHeuristicsRegistry
} from '../../types'
import {createVirtualGroupMap, groupingAIResult} from './groupingMap'
import type {GroupingApiPlugin, GroupingResult, SendRequest} from './types'
import {CTX_AI_GROUPING_RESULT, CTX_SERVER, CTX_VIRTUAL_GROUPING_RESULT} from './constants'

export const createPlugin = (pluginInitVals: MobileAlgoPluginInitializationArgs): MobileAlgoPlugin => {
    const {stages} = pluginInitVals

    const createApi = (context: PluginContext): GroupingApiPlugin => {
        return {
            registerServer: (server: SendRequest) => {
                context.set(CTX_SERVER, server)
            },
            getGroupingResult: (): ComponentToChildrenGroupMap => {
                return context.get(CTX_VIRTUAL_GROUPING_RESULT)
            },
            getAiGroupTree: (): GroupingResult => {
                return context.get(CTX_AI_GROUPING_RESULT)
            }
        }
    }

    const createGroupingMap = async (
        {
            mobileConversionDal,
            pageId,
            config
        }: {
            mobileConversionDal: MobileConversionDal
            pageId: string
            config: MobileAlgoConfig
        },
        contextHepler: PluginContext
    ) => {
        const serverResponse = await groupingAIResult(
            mobileConversionDal,
            pageId,
            pluginInitVals,
            contextHepler,
            config
        )
        contextHepler.set(CTX_AI_GROUPING_RESULT, serverResponse)
        const componentToChildrenGroupMap = createVirtualGroupMap(serverResponse, mobileConversionDal)
        contextHepler.set(CTX_VIRTUAL_GROUPING_RESULT, componentToChildrenGroupMap)
    }

    const register = (registry: PluginHeuristicsRegistry) => {
        registry.register(stages.ANALYZE, createGroupingMap)
    }

    return {register, createApi, name: 'grouping', dependencies: []}
}
