import type {CompStructure, DeserializationMappers, SerializedCompStructure} from '@wix/document-services-types'
import type {CreateExtArgs, ExtensionAPI} from '@wix/document-manager-core'
import {addIdToMap, getCustomId, getIdentifierForQuery} from '../utils'
import {COMP_DATA_QUERY_KEYS_WITH_STYLE, DATA_TYPES, DATA_TYPES_VALUES_WITH_HASH} from '../../../../constants/constants'
import * as _ from 'lodash'
import {SERIALIZED_DATA_KEYS, SERIALIZED_SCOPED_DATA_KEYS} from '../../constants'
import {replaceVariantsOnSerialized} from '../../../variants/variantsReplacement'
import type {DataModelExtensionAPI} from '../../../dataModel/dataModel'
import type {DataExtensionAPI} from '../../../data'
import {addNamespaceToStructure} from '../componentDeserialization'

const setCompQueryValue = (compStructure: CompStructure, id: string, itemType: string) => {
    const compDataQueryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[itemType]
    compStructure[compDataQueryKey] = DATA_TYPES_VALUES_WITH_HASH[itemType] ? `#${id}` : id
}

const createAndAddRefArray = (extensionAPI: ExtensionAPI, refValues: string[], itemType: string, pageId: string) => {
    const {data: dataApi} = extensionAPI as DataExtensionAPI
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const refArray = dataApi.refArray.create(refValues)
    return dataModel.addDeserializedItem(refArray, itemType, pageId)
}

export const linkRefArrayOrNonScopedValue = (
    extensionAPI: ExtensionAPI,
    compStructure: CompStructure,
    itemType: string,
    pageId: string,
    mappers: DeserializationMappers,
    refValues: string[]
) => {
    const {oldToNewIdMap} = mappers ?? {}
    const isThereRefArrayOrStyleObjectToLink = !_.isEmpty(refValues)
    if (isThereRefArrayOrStyleObjectToLink) {
        const scopedSerializeKey = SERIALIZED_SCOPED_DATA_KEYS[itemType]
        const shouldLinkNonScopedValue =
            !compStructure[scopedSerializeKey] && [DATA_TYPES.theme, DATA_TYPES.design].includes(itemType)

        const dataQuery = shouldLinkNonScopedValue
            ? refValues[0]
            : createAndAddRefArray(extensionAPI, refValues, itemType, pageId).id

        if (dataQuery) {
            addIdToMap({id: getIdentifierForQuery(extensionAPI, compStructure, itemType)}, dataQuery, oldToNewIdMap)
            setCompQueryValue(compStructure, dataQuery, itemType)
        }
    }
}

const updateVariantRelation = (
    createExtArgs: CreateExtArgs,
    compStructure: SerializedCompStructure,
    itemType: string,
    pageId: string,
    mappers: DeserializationMappers,
    scopedValue: any,
    variants: string,
    stylesPerPage?: boolean
) => {
    const {extensionAPI} = createExtArgs

    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const {data} = extensionAPI as DataExtensionAPI
    const {oldToNewIdMap} = mappers ?? {}
    const updatedVariants = variants.split(',').map(oldVariant => oldToNewIdMap[oldVariant])
    const newVariants = updatedVariants.filter(Boolean)

    if (newVariants.length !== updatedVariants.length) {
        return
    }

    const scopedValueId = addNamespaceToStructure(
        {createExtArgs, compStructure, pageId},
        scopedValue,
        itemType,
        oldToNewIdMap,
        stylesPerPage
    )
    if (compStructure.id && scopedValueId) {
        const variantRelation = data.variantRelation.create(newVariants, compStructure.id!, scopedValueId!)
        return dataModel.addItem(variantRelation, itemType, pageId).id
    }
}

export const updateScopedValues = (
    createExtArgs: CreateExtArgs,
    compStructure: SerializedCompStructure,
    itemType: string,
    pageId: string,
    mappers: DeserializationMappers,
    refValues: string[] = [],
    stylesPerPage?: boolean
) => {
    const scopedValues = _.get(compStructure, SERIALIZED_SCOPED_DATA_KEYS[itemType])
    if (scopedValues) {
        const relationsIds = _.map(scopedValues, (scopedValue, variant: string) => {
            return updateVariantRelation(
                createExtArgs,
                compStructure,
                itemType,
                pageId,
                mappers,
                scopedValue,
                variant,
                stylesPerPage
            )
        })

        refValues.splice(refValues.length, 0, ..._.compact(relationsIds))
    }
}

export const updateNonScopedValue = (
    createExtArgs: CreateExtArgs,
    compStructure: SerializedCompStructure,
    itemType: string,
    pageId: string,
    mappers: DeserializationMappers,
    refValues: string[] = [],
    stylesPerPage?: boolean
) => {
    const nonScopedValue = _.get(compStructure, SERIALIZED_DATA_KEYS[itemType])
    const {oldToNewIdMap} = mappers ?? {}
    if (nonScopedValue) {
        const nonScopedId = addNamespaceToStructure(
            {
                createExtArgs,
                compStructure,
                pageId
            },
            nonScopedValue,
            itemType,
            oldToNewIdMap,
            stylesPerPage
        )
        if (nonScopedId) {
            refValues.unshift(nonScopedId)
        }
    }
}

export const fixRefArrayValues = (
    compStructure: CompStructure,
    itemType: string,
    mappers: DeserializationMappers = {oldToNewIdMap: {}}
) => {
    const {variantMapper} = mappers
    const scopedPath = SERIALIZED_SCOPED_DATA_KEYS[itemType]
    const nonScopedPath = SERIALIZED_DATA_KEYS[itemType]

    if (!Array.isArray(variantMapper) || variantMapper.length === 0) {
        return
    }

    const refArrayValues = {
        ..._.get(compStructure, scopedPath),
        '': _.get(compStructure, nonScopedPath)
    }

    const {defaultValue, scopedValues} = replaceVariantsOnSerialized(refArrayValues, variantMapper)

    if (Object.keys(scopedValues).length > 0) {
        _.set(compStructure, scopedPath, scopedValues)
    } else {
        _.unset(compStructure, scopedPath)
    }

    if (defaultValue) {
        _.set(compStructure, nonScopedPath, defaultValue)
    }
}

export const updateComponentScopedValues = (
    createExtArgs: CreateExtArgs,
    compStructure: SerializedCompStructure,
    itemType: string,
    pageId: string,
    mappers: DeserializationMappers = {oldToNewIdMap: {}},
    stylesPerPage?: boolean
) => {
    const {extensionAPI} = createExtArgs

    const refValues: string[] = []
    const {oldToNewIdMap} = mappers ?? {}
    const dataQueryId = getCustomId(oldToNewIdMap, {
        id: getIdentifierForQuery(extensionAPI, compStructure, itemType)
    })

    if (dataQueryId) {
        setCompQueryValue(compStructure, dataQueryId, itemType)
        return
    }
    fixRefArrayValues(compStructure, itemType, mappers)
    updateNonScopedValue(createExtArgs, compStructure, itemType, pageId, mappers, refValues, stylesPerPage)
    updateScopedValues(createExtArgs, compStructure, itemType, pageId, mappers, refValues, stylesPerPage)
    linkRefArrayOrNonScopedValue(extensionAPI, compStructure, itemType, pageId, mappers, refValues)
}
