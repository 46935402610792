import {addIdToMap, getCustomId} from '../utils'
import {DATA_TYPES} from '../../../../constants/constants'
import {addNamespaceToStructure} from '../componentDeserialization'
import type {DeserializeComponentParams} from '../types'
import type {SerializedCompStructure} from '@wix/document-services-types'
import type {ExtensionAPI} from '@wix/document-manager-core'
import type {LoggerExtAPI} from '../../../logger'
import {ReportableWarning} from '@wix/document-manager-utils'

const DATA_TYPES_WITH_APP_DEF_ID = ['WidgetRef', 'TPAWidget', 'TPAGlobalData', 'TPA', 'TPAMultiSection']

const reportOnMissingAppDefId = (compStructure: SerializedCompStructure, extensionAPI: ExtensionAPI) => {
    const {id, type, data} = compStructure
    const {appDefinitionId, type: dataType} = data || {}
    if (DATA_TYPES_WITH_APP_DEF_ID.includes(dataType) && !appDefinitionId) {
        const {logger} = extensionAPI as LoggerExtAPI
        logger.captureError(
            new ReportableWarning({
                errorType: 'MissingAppDefIdInComponentData',
                message: 'Component data does not have appDefinitionId',
                extras: {
                    id,
                    type,
                    data: JSON.stringify(data).substring(0, 200)
                }
            })
        )
    }
}

export const updateComponentDataStructure = (deserializeComponentParams: DeserializeComponentParams) => {
    const {compStructure, createExtArgs, pageId, customId, mappers, isPage} = deserializeComponentParams
    reportOnMissingAppDefId(compStructure, createExtArgs.extensionAPI)
    if (isPage) {
        compStructure.dataQuery = `#${pageId}`
    } else {
        const {oldToNewIdMap} = mappers ?? {oldToNewIdMap: {}}
        deserializeComponentParams.customId = getCustomId(oldToNewIdMap, compStructure.data, customId)
        const dataQuery = addNamespaceToStructure(
            deserializeComponentParams,
            compStructure.data,
            DATA_TYPES.data,
            oldToNewIdMap
        )
        if (dataQuery) {
            compStructure.dataQuery = `#${dataQuery}`
            addIdToMap(compStructure.data, dataQuery, oldToNewIdMap)
        }
    }
}
