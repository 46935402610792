import type {Pointer, PS} from '@wix/document-services-types'
import experiment from 'experiment-amd'
import _ from 'lodash'
import dataModel from '../../dataModel/dataModel'
import clientSpecMapService from '../../tpa/services/clientSpecMapService'
import component from '../../component/component'
import scopes from '../../scopes/scopes'

function appsOfComp(ps: PS, dataItem, componentPointer: Pointer) {
    let apps: string[] = []
    if (dataItem && dataItem.type === 'AppController') {
        apps = appsOfAppController(ps, dataItem, componentPointer)
    } else if (dataItem && dataItem.type === 'TPAWidget') {
        apps = appsOfTPAWidgetDataItem(ps, dataItem, componentPointer)
    } else if (dataItem && dataItem.type === 'ConnectionList') {
        apps = appsOfConnectionItem(ps, dataItem)
    } else if (!dataItem && componentPointer) {
        apps = appsOfControllerDataItem(dataModel.getDataItem(ps, componentPointer))
    } else if (experiment.isOpen('dm_refreshBlocksWidgetWhenReparentingPages') && dataItem?.type === 'WidgetRef') {
        apps = appsOfAppController(ps, dataItem, scopes.getRootComponent(ps, componentPointer))
    }
    if (_.isEmpty(apps) && componentPointer) {
        apps = _([...component.getAncestorsFromFull(ps, componentPointer), componentPointer])
            .flatMap(pointer => dataModel.getConnectionsItem(ps, pointer))
            .compact()
            .reject(x => _.isEqual(x.type, 'WixCodeConnectionItem'))
            .flatMap(controllerObj =>
                appsOfControllerDataItem(dataModel.getDataItem(ps, _.get(controllerObj, 'controllerRef')))
            )
            .uniq()
            .value()
    }
    return apps
}

function appsOfAppController(ps: PS, dataItem, componentPointer: Pointer) {
    if (
        _.has(dataItem, 'applicationId') ||
        (experiment.isOpen('dm_livePreviewRefreshWithAppDefId') && _.has(dataItem, 'appDefinitionId'))
    ) {
        return appsOfControllerDataItem(dataItem)
    }
    return appsOfControllerDataItem(dataModel.getDataItem(ps, componentPointer))
}

function appsOfControllerDataItem(dataItem) {
    if (experiment.isOpen('dm_livePreviewRefreshWithAppDefId')) {
        return _.compact([_.get(dataItem, 'appDefinitionId') ?? _.get(dataItem, 'applicationId')])
    }
    return _.compact([_.get(dataItem, 'applicationId')])
}

function appsOfConnections(ps: PS, componentPointer: Pointer) {
    const connectionItemPointer = dataModel.getConnectionsItemPointer(ps, componentPointer)
    if (connectionItemPointer) {
        const connectionItem = ps.dal.get(connectionItemPointer)
        const appsOfConnectionItems = appsOfConnectionItem(ps, connectionItem)
        if (!_.isEmpty(appsOfConnectionItems)) {
            const pagePointer = ps.pointers.components.getPageOfComponent(componentPointer)
            const appsOfControllers = _(_.get(connectionItem, 'items'))
                .reject(x => _.isEqual(x.type, 'WixCodeConnectionItem'))
                .map('controllerId')
                .compact()
                .map(controllerId => dataModel.getControllerRefFromId(ps, controllerId, pagePointer))
                .map(controllerRef => appsOfConnections(ps, controllerRef))
                .compact()
                .value()
            if (appsOfControllers.length) {
                appsOfConnectionItems.push(...appsOfControllers)
            }
        }
        return appsOfConnectionItems || []
    }
    return []
}

function appsOfTPAWidgetDataItem(ps: PS, dataItem, componentPointer: Pointer) {
    const widgetData = clientSpecMapService.getWidgetData(ps, dataItem.appDefinitionId, dataItem.widgetId)
    const apps = _.has(widgetData, 'componentFields.componentUrl') ? [dataItem.appDefinitionId] : []
    return apps.length ? apps.concat(appsOfConnections(ps, componentPointer)) : apps
}

function appsOfControllerStateMap(ps: PS, controllerStateMap) {
    return _(_.get(controllerStateMap, 'configured'))
        .flatMap(controllerRef => appsOfControllerDataItem(dataModel.getDataItem(ps, controllerRef)))
        .compact()
        .sortedUniq()
        .value()
}

function appsOfConnectionItem(ps: PS, connectionItem) {
    return _(_.get(connectionItem, 'items'))
        .reject(x => _.isEqual(x.type, 'WixCodeConnectionItem'))
        .map('controllerId')
        .compact()
        .flatMap(controllerId => appsOfControllerDataItem(dataModel.getDataItemById(ps, controllerId)))
        .value()
}

function getAppOfController(ps: PS, controllerRef: Pointer) {
    const controllerData = component.data.get(ps, controllerRef)
    if (experiment.isOpen('dm_livePreviewRefreshWithAppDefId')) {
        return _.get(controllerData, 'appDefinitionId') ?? _.get(controllerData, 'applicationId')
    }
    return _.get(controllerData, 'applicationId')
}

export default {
    appsOfComp,
    appsOfControllerStateMap,
    appsOfConnectionItem,
    getAppOfController
}
