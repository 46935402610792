import {COMPONENTS_HOOKS} from '../hooks'
import {COMP_TYPES, PAGE_COME_TYPES} from '../../../constants/constants'
import type {HooksExtensionApi} from '../../hooks/hooks'
import type {CreateExtArgs} from '@wix/document-manager-core'

import {handleAppWidgetAddition} from './appWidgetHooks'
import {handleControllerAddition} from './appControllerHooks'
import {duplicateLinksDataItems} from './wRichTextHooks'
import {setPlayerIdAfterAddHook} from './mediaPlayerHooks'
import {handleDataOverridesAfterAdd} from './referredComponentsHooks'
import {mapHiddenAnchorsForAnchorsMenu} from './pageHooks'

export const registerComponentHooks = (createExtArgs: CreateExtArgs) => {
    const {extensionAPI} = createExtArgs
    const {hooks} = extensionAPI as HooksExtensionApi
    hooks.registerHookWithExtensionArgs(
        COMPONENTS_HOOKS.ADD_COMPONENT.AFTER.id,
        handleControllerAddition,
        COMP_TYPES.CONTROLLER_TYPE
    )
    hooks.registerHookWithExtensionArgs(
        COMPONENTS_HOOKS.ADD_COMPONENT.AFTER.id,
        handleAppWidgetAddition,
        COMP_TYPES.APP_WIDGET_TYPE
    )

    hooks.registerHookWithExtensionArgs(
        COMPONENTS_HOOKS.ADD_COMPONENT.AFTER.id,
        setPlayerIdAfterAddHook,
        COMP_TYPES.MEDIA_OVERLAY_CONTROLS
    )
    hooks.registerHookWithExtensionArgs(
        COMPONENTS_HOOKS.ADD_COMPONENT.AFTER.id,
        setPlayerIdAfterAddHook,
        COMP_TYPES.MEDIA_CONTROLS
    )
    hooks.registerHookWithExtensionArgs(
        COMPONENTS_HOOKS.ADD_COMPONENT.AFTER.id,
        duplicateLinksDataItems,
        COMP_TYPES.WRICHTEXT
    )

    hooks.registerHookWithExtensionArgs(
        COMPONENTS_HOOKS.ADD_COMPONENT.AFTER.id,
        handleDataOverridesAfterAdd,
        COMP_TYPES.REF_TYPE
    )
    PAGE_COME_TYPES.forEach((pageCompType: string) => {
        hooks.registerHookWithExtensionArgs(
            COMPONENTS_HOOKS.ADD_COMPONENT.AFTER.id,
            mapHiddenAnchorsForAnchorsMenu,
            pageCompType
        )
    })
}
