'use strict'

const _ = require('lodash')
const galleriesMobileTypeFixer = require('../plugins/galleriesMobileTypeFixer')
const galleriesMissingComponentType = require('../plugins/galleriesMissingComponentType')
const pageTopFixer = require('../plugins/pageTopFixer')
const masterPageFixer = require('../plugins/masterPageFixer')
const menuDataOnPageFixer = require('../plugins/menuDataOnPageFixer')
const customSiteMenuFixer = require('../plugins/customSiteMenuFixer')
const multipleMenusFixer = require('../plugins/multipleMenusFixer')
const skinFixer = require('../plugins/skinFixer')
const stylesFixer = require('../plugins/stylesFixer')
const bgImageStripDataFixer = require('../plugins/bgImageStripDataFixer')
const compFixer = require('../plugins/compFixer')
const galleryFixer = require('../plugins/galleryFixer')
const behaviorsFixer = require('../plugins/behaviorsFixer')
const connectionsDataFixer = require('../plugins/connectionsDataFixer')
const fiveGridLineLayoutFixer = require('../plugins/fiveGridLineLayoutFixer')
const moveRotationToTransformation = require('../plugins/moveRotationToTransformationFixer')
const googleMapsFixer = require('../plugins/googleMapsFixer')
const landingPageFixer = require('../plugins/landingPageFixer')
const toPageAnchorsFixer = require('../plugins/toPageAnchorsFixer')
const wrongAnchorsFixer = require('../plugins/wrongAnchorsFixer')
const addMissingAnchorsOfMasterPage = require('../plugins/addMissingAnchorsOfMasterPage')
const sitePagesFixer = require('../plugins/sitePagesFixer')
const linkRefDataFixer = require('../plugins/linkRefDataFixer')
const fromDocumentToThemeData = require('../plugins/fromDocumentToThemeData')
const tpaGluedWidgetDataFixer = require('../plugins/tpa/tpaGluedWidgetDataFixer')
const tpaAddChatAppDataFixer = require('../plugins/tpa/tpaAddChatAppDataFixer')
const tpaAddDataToDataItemFixer = require('../plugins/tpa/tpaAddDataToDataItemFixer')
const addAppDefinitionIdToTpaPages = require('../plugins/addAppDefinitionIdToTpaPages')
const tpaProGalleryBackgroundDataFixer = require('../plugins/tpa/tpaProGalleryBackgroundDataFixer')
const tpaCartPageResponsiveLayoutFixer = require('../plugins/tpa/tpaCartPageResponsiveLayoutFixer')
const compsWithImagesDataFixer = require('../plugins/compsWithImagesDataFixer')
const appPartCustomizationsFixer = require('../plugins/appPartCustomizationsFixer')
const backgroundMediaConverter = require('../plugins/backgroundMediaConverter')
const backgroundMediaUndefinedFixer = require('../plugins/backgroundMediaUndefinedFixer')
const stripContainerBgEffectFixer = require('../plugins/stripContainerBgEffectFixer')
const stripContainerSlideShowSlideLayoutFixer = require('../plugins/stripContainerSlideShowSlideLayoutFixer')
const documentMediaFixer = require('../plugins/documentMediaFixer')
const pinterestFollowFixer = require('../plugins/pinterestFollowFixer')
const blogPageMenuFixer = require('../plugins/blogPageMenuFixer')
const appPartMediaInnerCustomizationFormatFixer = require('../plugins/appPartMediaInnerCustomizationFormatFixer')
const appPartReadMoreValueCustomizationFormatFixer = require('../plugins/appPartReadMoreValueCustomizationFormatFixer')
const appPartTagsValueCustomizationFormatFixer = require('../plugins/appPartTagsValueCustomizationFormatFixer')
const appPartDuplicateCustomizationFixer = require('../plugins/appPartDuplicateCustomizationFixer')
const blogCustomFeedPostsPerPageCustomizationFixer = require('../plugins/blogCustomFeedPostsPerPageCustomizationFixer')
const blogDateAndAuthorHiddenCustomizationFixer = require('../plugins/blogDateAndAuthorHiddenCustomizationFixer')
const blogDateAlignmentCustomizationFixer = require('../plugins/blogDateAlignmentCustomizationFixer')
const appPartBrokenButtonStyleFixer = require('../plugins/appPartBrokenButtonStyleFixer')
const appPartBrokenAppInnerIdFixer = require('../plugins/appPartBrokenAppInnerIdFixer')
const blogDateFormatCustomizationFormatFixer = require('../plugins/blogDateFormatCustomizationFormatFixer')
const contactFormMigrationDataFixer = require('../plugins/contactFormMigrationDataFixer')
const pageDataFixer = require('../plugins/pageDataFixer')
const nestedPageDataFixer = require('../plugins/nestedPageDataFixer')
const groupFixer = require('../plugins/groupFixer')
const textSecurityFixer = require('../plugins/textSecurityFixer')
const pageUriSeoFixer = require('../plugins/pageUriSeoFixer')
const migrateStripToColumnsContainer = require('../plugins/migrateStripToColumnsContainer')
const tinyMenuSkinBackgroundFixer = require('../plugins/tinyMenuSkinBackgroundFixer')
const tinyMenuLayoutFixer = require('../plugins/tinyMenuLayoutFixer')
const designDataFixer = require('../plugins/designDataFixer')
const displayCountMenuDataItemFixer = require('../plugins/displayCountMenuDataItemFixer')
const homePageLoginDataFixer = require('../plugins/homePageLoginDataFixer')
const characterSetsFixer = require('../plugins/characterSetsFixer')
const popupPropsTempDataFixer = require('../plugins/popupPropsTempDataFixer')
const posterImageRefFixer = require('../plugins/posterImageRefFixer')
const blogSelectionSharerCustomizationsFixer = require('../plugins/blogSelectionSharerCustomizationsFixer')
const quickActionBarDataFixer = require('../plugins/quickActionBarDataFixer')
const appPartMobileFeedMediaDateHiddenFixer = require('../plugins/appPartMobileFeedMediaDateHiddenFixer')
const originalImageDataRefFixer = require('../plugins/originalImageDataRefFixer')
const filterEffectPropertyFixer = require('../plugins/filterEffectPropertyFixer')
const tinyMenuSkinFixer = require('../plugins/tinyMenuSkinFixer')
const svgShapeToVectorImageFixer = require('../plugins/svgShapeToVectorImageFixer')
const artAndShapeToVectorImageFixer = require('../plugins/artAndShapeToVectorImageFixer')
const migrateGridDataSource = require('../plugins/migrateGridDataSource')
const unforkDesignData = require('../plugins/unforkDesignData')
const stripColumnsContainerNoDesignFixer = require('../plugins/stripColumnsContainerNoDesignFixer')
const richTextSkinDataFixer = require('../plugins/richTextSkinDataFixer')
const hoverBoxPropertyDataFixer = require('../plugins/hoverBoxPropertyDataFixer')
const tpaFullScreenPageDataFixer = require('../plugins/tpa/tpaFullScreenPageDataFixer')
const tpaCheckoutPageDataFixer = require('../plugins/tpa/tpaCheckoutPageDataFixer')
const animationDelayFixer = require('../plugins/animationDelayFixer')
const inputsPlaceholderDataFixer = require('../plugins/inputsPlaceholderDataFixer')
const fixedComponentParentFixer = require('../plugins/fixedComponentParentFixer')
const componentTypeMigrator = require('../plugins/componentTypeMigrator')
const tinyMenuLoginSocialBarRefFixer = require('../plugins/tinyMenuLoginSocialBarRefFixer')
const comboBoxPlaceholderStyleFixer = require('../plugins/comboBoxPlaceholderStyleFixer')
const stylableButtonFixer = require('../plugins/stylableButtonFixer')
const vectorImagePropsFixer = require('../plugins/vectorImagePropsFixer')
const missingResponsiveRefsFixer = require('../plugins/missingResponsiveRefsFixer')
const migrateResponsiveLayoutToSingleLayoutData = require('../plugins/migrateResponsiveLayoutToSingleLayoutData')
const migrateResponsiveToVariantsModels = require('../plugins/migrateResponsiveToVariantsModels')
const globalImageQualityMissingIdFixer = require('../plugins/globalImageQualityMissingIdFixer')
const childlessComponentDataFixer = require('../plugins/childlessComponentDataFixer')
const duplicatedBreakpointsIdsFixer = require('../plugins/duplicatedBreakpointsIdsFixer')
const matrixGalleryFixer = require('../plugins/matrixGalleryFixer')
const backgroundBehaviorsFixer = require('../plugins/backgroundBehaviorsFixer')
const sospSkinDataFixer = require('../plugins/sospSkinDataFixer')
const publicDataFilterFixer = require('../plugins/viewerData/publicDataFilterFixer.js')
const queryDataFilterFixer = require('../plugins/viewerData/queryDataFilterFixer.js')
const removePopupBehaviorForNonExistingPopupFixer = require('../plugins/removePopupBehaviorForNonExistingPopupFixer.js')
const a11yDefaultSettings = require('../plugins/a11yDefaultSettings')
const textThemesDataFixer = require('../plugins/textThemesDataFixer')
const variantsNamespacesMobileSplitFixer = require('../plugins/variantsNamespacesMobileSplitFixer')
const removeResponsiveDataFromClassicEditorFixer = require('../plugins/removeResponsiveDataFromClassicEditorFixer.js')
const invalidLayoutSizeFixer = require('../plugins/invalidLayoutSizeFixer')
const addMobileVariantOnce = require('../plugins/addMobileVariantOnce')
const deleteLayoutOverridesForRefComponents = require('../plugins/deleteLayoutOverridesForRefComponents')
const deleteTpaChildrenWithoutCorrespondingSlots = require('../plugins/tpa/deleteTpaChildrenWithoutCorrespondingSlots')
const meshDataFixer = require('../plugins/meshDataFixer/meshDataFixer')
const migrateScreenInBehaviorsToEntranceEffects = require('../plugins/migrateScreenInBehaviorsToEntranceEffects')
const migrateResponsiveSectionStyleItemToDesign = require('../plugins/migrateResponsiveSectionStyleItemToDesign')
const collapsibleTextStylesFixer = require('../plugins/collapsibleTextToNonStylable.js')
const migrateOldHoverBoxToNewFixer = require('../plugins/migrateOldHoverBoxToNewFixer')
const breakpointMinFixer = require('../plugins/breakpointMinFixer')
const stripColumnsContainerScreenInBehaviorsFixer = require('../plugins/stripColumnsContainerScreenInBehaviorsFixer')
const anchorUrlFragmentFixer = require('../plugins/anchorUrlFragmentFixer')

const defaultPlugins = _.compact([
    componentTypeMigrator,
    fixedComponentParentFixer,
    tpaFullScreenPageDataFixer,
    tpaCheckoutPageDataFixer,
    galleriesMobileTypeFixer,
    galleriesMissingComponentType,
    masterPageFixer,
    menuDataOnPageFixer,
    backgroundMediaConverter,
    backgroundMediaUndefinedFixer,
    pageUriSeoFixer,
    pageDataFixer,
    nestedPageDataFixer,
    customSiteMenuFixer,
    multipleMenusFixer,
    skinFixer,
    stylesFixer,
    bgImageStripDataFixer,
    compFixer,
    galleryFixer,
    behaviorsFixer,
    animationDelayFixer,
    connectionsDataFixer,
    quickActionBarDataFixer,
    fiveGridLineLayoutFixer,
    googleMapsFixer,
    landingPageFixer,
    toPageAnchorsFixer,
    wrongAnchorsFixer,
    addMissingAnchorsOfMasterPage,
    sitePagesFixer,
    linkRefDataFixer,
    fromDocumentToThemeData,
    pageTopFixer,
    tpaGluedWidgetDataFixer,
    tpaAddChatAppDataFixer,
    tpaAddDataToDataItemFixer,
    addAppDefinitionIdToTpaPages,
    tpaProGalleryBackgroundDataFixer,
    tpaCartPageResponsiveLayoutFixer,
    compsWithImagesDataFixer,
    appPartCustomizationsFixer,
    designDataFixer,
    displayCountMenuDataItemFixer,
    stripContainerBgEffectFixer,
    stripContainerSlideShowSlideLayoutFixer,
    documentMediaFixer,
    pinterestFollowFixer,
    blogPageMenuFixer,
    appPartMediaInnerCustomizationFormatFixer,
    appPartReadMoreValueCustomizationFormatFixer,
    appPartTagsValueCustomizationFormatFixer,
    blogCustomFeedPostsPerPageCustomizationFixer,
    blogDateAndAuthorHiddenCustomizationFixer,
    blogDateAlignmentCustomizationFixer,
    appPartDuplicateCustomizationFixer,
    appPartBrokenButtonStyleFixer,
    blogDateFormatCustomizationFormatFixer,
    contactFormMigrationDataFixer,
    groupFixer,
    textSecurityFixer,
    migrateStripToColumnsContainer,
    tinyMenuSkinBackgroundFixer,
    tinyMenuLayoutFixer,
    homePageLoginDataFixer,
    popupPropsTempDataFixer,
    posterImageRefFixer,
    characterSetsFixer,
    blogSelectionSharerCustomizationsFixer,
    appPartMobileFeedMediaDateHiddenFixer,
    appPartBrokenAppInnerIdFixer,
    originalImageDataRefFixer,
    filterEffectPropertyFixer,
    tinyMenuSkinFixer,
    svgShapeToVectorImageFixer,
    artAndShapeToVectorImageFixer,
    richTextSkinDataFixer,
    migrateGridDataSource,
    unforkDesignData,
    stripColumnsContainerNoDesignFixer,
    hoverBoxPropertyDataFixer,
    tinyMenuLoginSocialBarRefFixer,
    inputsPlaceholderDataFixer,
    comboBoxPlaceholderStyleFixer,
    stylableButtonFixer,
    vectorImagePropsFixer,
    globalImageQualityMissingIdFixer,
    childlessComponentDataFixer,
    duplicatedBreakpointsIdsFixer,
    missingResponsiveRefsFixer,
    migrateResponsiveLayoutToSingleLayoutData,
    migrateResponsiveToVariantsModels,
    moveRotationToTransformation,
    matrixGalleryFixer,
    backgroundBehaviorsFixer,
    sospSkinDataFixer,
    publicDataFilterFixer,
    queryDataFilterFixer,
    removePopupBehaviorForNonExistingPopupFixer,
    a11yDefaultSettings,
    textThemesDataFixer,
    variantsNamespacesMobileSplitFixer,
    removeResponsiveDataFromClassicEditorFixer,
    invalidLayoutSizeFixer,
    addMobileVariantOnce,
    migrateOldHoverBoxToNewFixer,
    stripColumnsContainerScreenInBehaviorsFixer,
    migrateScreenInBehaviorsToEntranceEffects,
    migrateResponsiveSectionStyleItemToDesign,
    collapsibleTextStylesFixer,
    deleteLayoutOverridesForRefComponents,
    deleteTpaChildrenWithoutCorrespondingSlots,
    meshDataFixer,
    breakpointMinFixer,
    anchorUrlFragmentFixer
])

module.exports = () => {
    const additionalPlugins = []
    return {
        registerPlugin: plugin => additionalPlugins.push(plugin),
        getPlugins: () =>
            [...defaultPlugins, ...additionalPlugins].map(plugin => (_.isFunction(plugin) ? plugin() : plugin))
    }
}
