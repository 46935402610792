import _ from 'lodash'
import type {
    CompRef,
    Pointer,
    Pointers,
    ExternalsBuilderMap,
    RawComponentExportStructure,
    ExternalsMap,
    SchemaService,
    RawImportExportOptions,
    Namespace,
    DalValue,
    ComponentFeature,
    DataItemsMap,
    ResolvedDataItem
} from '@wix/document-services-types'
import type {DAL, ExtensionAPI} from '@wix/document-manager-core'
import {getResolvedDataItemsByNamespaceMap, updateDataItemsByNamespacesDataMap} from './dataItems'
import type {DataModelExtensionAPI} from '../../../../dataModel/dataModel'
import type {ComponentDefinitionExtensionAPI} from '../../../../componentDefinition'
import {getComponentFeaturesByType, getFeaturesInfoByComponentFeatures} from '../../utils'
import {
    getCompIdWithRepeatersNesting,
    isReferredId,
    replaceRefComponentIdInOverride
} from '../../../../../utils/inflationUtils'
import {COMPONENT_FEATURES} from '../../constants'
import {stripHashIfExists} from '../../../../../utils/refArrayUtils'
import {getRepeaterItemId} from '../../../../../utils/repeaterUtils'

const getOverrides = (
    component: RawComponentExportStructure,
    componentPointer: CompRef,
    namespaces: Set<Namespace>,
    pointers: Pointers,
    extensionAPI: ExtensionAPI
): Pointer[] => {
    const {dataModel, componentDefinition} = extensionAPI as DataModelExtensionAPI & ComponentDefinitionExtensionAPI
    const overridesPointers = dataModel.components.getAllRepeaterOverridesForComponent(componentPointer)

    if (componentDefinition.isRefComponent(component.structure.componentType)) {
        overridesPointers.push(...pointers.full.referredStructure.getAllOverrides(componentPointer))
    }

    return overridesPointers.filter(pointer => namespaces.has(pointer.type))
}

const mapOverridesIds = (
    overrides: RawComponentExportStructure['overrides'],
    iteratee: (id: string, namespace: Namespace) => string
): RawComponentExportStructure['overrides'] => {
    if (overrides === undefined) {
        return
    }

    return _.mapValues(overrides, (featureOverrides: DataItemsMap, namespace: Namespace) =>
        _.mapKeys(featureOverrides, (override: ResolvedDataItem, overrideId: string) => iteratee(overrideId, namespace))
    )
}

const stripRepeaterOverridesIds = (
    overrides: RawComponentExportStructure['overrides']
): RawComponentExportStructure['overrides'] =>
    mapOverridesIds(overrides, (overrideId: string) => {
        if (isReferredId(overrideId)) {
            return overrideId
        }

        return getRepeaterItemId(overrideId)
    })

export const resolveOverrides = (
    component: RawComponentExportStructure,
    componentPointer: CompRef,
    pagePointer: CompRef,
    {componentFeatures}: RawImportExportOptions,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    schemaService: SchemaService,
    externals?: ExternalsBuilderMap
) => {
    const relevantFeatures = getComponentFeaturesByType(component.structure.componentType, componentFeatures)
    const {namespaces} = getFeaturesInfoByComponentFeatures(relevantFeatures)
    const overridesPointers = getOverrides(component, componentPointer, namespaces, pointers, extensionAPI)

    const overrides = getResolvedDataItemsByNamespaceMap(
        overridesPointers,
        pagePointer,
        pointers,
        extensionAPI,
        schemaService,
        externals
    )
    const fixedOverrides = stripRepeaterOverridesIds(overrides)

    if (overrides !== undefined) {
        component.overrides = fixedOverrides
    }
}

const buildRepeaterOverrideId = (componentPointer: CompRef, itemId: string, namespace: Namespace, dal: DAL): string => {
    const {query} = COMPONENT_FEATURES[namespace]
    const dataId = stripHashIfExists(dal.get(componentPointer)[query])
    return getCompIdWithRepeatersNesting(dataId, [itemId])
}

const getRelevantOverrides = (
    {overrides}: RawComponentExportStructure,
    aliases: Set<ComponentFeature['alias']>
): RawComponentExportStructure['overrides'] =>
    _.pickBy(overrides, (overridesItems: Record<string, DalValue>, alias: string) => aliases.has(alias))

const fixOverridesIds = (
    overrides: RawComponentExportStructure['overrides'],
    componentPointer: CompRef,
    dal: DAL
): RawComponentExportStructure['overrides'] =>
    mapOverridesIds(overrides, (overrideId: string, namespace: Namespace) => {
        if (isReferredId(overrideId)) {
            return replaceRefComponentIdInOverride(overrideId, componentPointer.id)
        }

        return buildRepeaterOverrideId(componentPointer, overrideId, namespace, dal)
    })

export const updateOverrides = (
    componentPointer: CompRef,
    component: RawComponentExportStructure,
    externals: ExternalsMap,
    {componentFeatures}: RawImportExportOptions,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    schemaService: SchemaService
) => {
    const relevantFeatures = getComponentFeaturesByType(component.structure.componentType, componentFeatures)
    const {aliases, namespaces} = getFeaturesInfoByComponentFeatures(relevantFeatures)
    const origOverridesPointers = getOverrides(component, componentPointer, namespaces, pointers, extensionAPI)
    const pagePointer = pointers.structure.getPageOfComponent(componentPointer) as CompRef

    const relevantOverrides = getRelevantOverrides(component, aliases)
    const fixedOverrides = fixOverridesIds(relevantOverrides, componentPointer, dal)

    updateDataItemsByNamespacesDataMap(
        origOverridesPointers,
        fixedOverrides,
        pagePointer,
        externals,
        dal,
        pointers,
        extensionAPI,
        schemaService
    )
}
