import type {
    CompRef,
    CssOverrides,
    Layout,
    Pointer,
    CompCssOverrides,
    PS,
    MeshData,
    Rect
} from '@wix/document-services-types'
import structure, {shouldEnforceKeepChildrenInPlace} from '../structure/structure'
import constants from '../constants/constants'
import variantsUtils from '../variants/variantsUtils'
import {absoluteToMesh, getMeasurements, remeasureContainer, remeasureContainerIfNeeded} from './mesh'
import {getResponsiveLayout, updateResponsiveLayout} from './getAndUpdate'
import * as experiment from 'experiment'
import _ from 'lodash'
import componentsMetaData from '../componentsMetaData/componentsMetaData'

const remeasureAndUpdateResponsiveLayout = (ps: PS, compRef: CompRef, newLayout) => {
    const containerRef = ps.pointers.components.getParent(compRef)
    const containerRefWithVariants = ps.pointers.components.getComponentVariantsPointer(containerRef, compRef.variants)
    remeasureContainerIfNeeded(ps, containerRefWithVariants as CompRef)
    updateResponsiveLayout(ps, compRef, newLayout)
}

const update = (ps: PS, compRef: CompRef, newLayout) => {
    if (experiment.isOpen('dm_meshLayout')) {
        remeasureAndUpdateResponsiveLayout(ps, compRef, newLayout)
    } else {
        updateResponsiveLayout(ps, compRef, newLayout)
    }
}

const didResizeFromLeft = (oldRect: Rect, newRect: Rect) => {
    return newRect.width !== oldRect.width && newRect.x !== oldRect.x
}
const didResizeFromTop = (oldRect: Rect, newRect: Rect) => {
    return newRect.height !== oldRect.height && newRect.y !== oldRect.y
}
const shouldKeepChildrenInPlace = (ps: PS, containerPointer: Pointer, oldRect: Rect, newRect: Rect) => {
    if (
        !componentsMetaData.getShouldKeepChildrenInPlace(ps, containerPointer) ||
        !shouldEnforceKeepChildrenInPlace(ps)
    ) {
        return false
    }
    if (!didResizeFromLeft(oldRect, newRect) && !didResizeFromTop(oldRect, newRect)) return false

    return true
}

const moveChildrenToKeepThemInPlace = (ps: PS, containerPointer: Pointer, oldRect: Rect, newRect: Rect) => {
    if (!experiment.isOpen('dm_meshLayout')) return
    if (!shouldKeepChildrenInPlace(ps, containerPointer, oldRect, newRect)) return

    const childrenPointers = ps.pointers.components.getChildren(containerPointer)
    const diffX = (newRect.x || 0) - (oldRect.x || 0)
    const diffY = (newRect.y || 0) - (oldRect.y || 0)
    _.forEach(childrenPointers, function (childPointer) {
        const childResponsiveLayout = getResponsiveLayout(ps, childPointer)
        childResponsiveLayout.itemLayout.meshData.x -= diffX
        childResponsiveLayout.itemLayout.meshData.y -= diffY
        updateResponsiveLayout(ps, childPointer, childResponsiveLayout)
    })
}

const pinComponent = (ps: PS, compPointer: CompRef, newLayout: Layout) => {
    structure.reparentComponentToPage(ps, compPointer, false)
    return update(ps, compPointer as CompRef, newLayout)
}

const unpinComponent = (ps: PS, compPointer: CompRef, newContainerPointer: CompRef, newLayout) => {
    structure.setContainer(ps, null, compPointer, newContainerPointer)
    return update(ps, compPointer as CompRef, newLayout)
}

const removeScopedLayout = (ps: PS, compPointer: Pointer) => {
    const isWithVariants = ps.pointers.components.isWithVariants(compPointer)
    if (!isWithVariants) {
        throw new Error('cannot remove non scoped layout')
    }
    variantsUtils.removeComponentDataConsideringVariants(ps, compPointer, constants.DATA_TYPES.layout)
}

const getRelativeToContainerBoundingBox = (ps: PS, compPointer: CompRef): MeshData =>
    ps.siteAPI.getBasicMeasureForComp(compPointer)

export default {
    get: getResponsiveLayout,
    update,
    updateWithoutRemeasuring: updateResponsiveLayout,
    moveChildrenToKeepThemInPlace,
    pin: pinComponent,
    unpin: unpinComponent,
    isNewLayoutMigration: () => true,
    removeScopedLayout,
    remeasureContainer,
    remeasureContainerIfNeeded,
    absoluteToMesh,
    getMeasurements,
    // runtime
    measure: (ps: PS, compPointer: Pointer) => ({
        boundingBox: ps.siteAPI.getComponentBoundingBox(compPointer),
        containerMeasures: ps.siteAPI.responsive.getGridMeasures(compPointer)
    }),
    getRelativeToContainerBoundingBox,
    getBoundingBox: (ps: PS, compPointer: Pointer) => ps.siteAPI.getComponentBoundingBox(compPointer),
    getNonRotatedBoundingBox: (ps: PS, compPointer: Pointer) => ps.siteAPI.getOriginalComponentBoundingBox(compPointer),
    getRelativeToViewportBoundingBox: (ps: PS, compPointer: Pointer) =>
        ps.siteAPI.getRelativeToViewportBoundingBox(compPointer),
    getPadding: (ps: PS, compPointer: Pointer) => ps.siteAPI.getPadding(compPointer),
    getScrollHeight: (ps: PS, compPointer: Pointer) => ps.siteAPI.responsive.getScrollHeight(compPointer),
    getClientHeight: (ps: PS, compPointer: Pointer) => ps.siteAPI.responsive.getClientHeight(compPointer),
    getScrollWidth: (ps: PS, compPointer: Pointer) => ps.siteAPI.responsive.getScrollWidth(compPointer),
    getClientWidth: (ps: PS, compPointer: Pointer) => ps.siteAPI.responsive.getClientWidth(compPointer),
    getGridMeasures: (ps: PS, compPointer: Pointer) => ps.siteAPI.responsive.getGridMeasures(compPointer),
    detachLayout: (ps: PS, compPointer: Pointer) => ps.siteAPI.responsive.detach(compPointer),
    detachComponents: (ps: PS, componentsPointers) => ps.siteAPI.responsive.detachMulti(componentsPointers),
    detachContainer: (
        ps: PS,
        containerPointer: Pointer,
        containerOverrides: CssOverrides,
        childrenOverrides: CompCssOverrides
    ) => ps.siteAPI.responsive.detachContainer(containerPointer, containerOverrides, childrenOverrides),
    updateDetachedLayout: (ps: PS, detachedCompPointer: Pointer, boundingBox) =>
        ps.siteAPI.responsive.updateDetached(detachedCompPointer, boundingBox),
    updateDetachedStyles: (ps: PS, detachedCompPointer: Pointer, styles) =>
        ps.siteAPI.responsive.updateDetachedStyles(detachedCompPointer, styles),
    updateDetachedRotation: (ps: PS, detachedCompPointer: Pointer, rotationsInDegrees) =>
        ps.siteAPI.responsive.updateDetachedRotation(detachedCompPointer, rotationsInDegrees),
    updateDetachedTransformation: (ps: PS, detachedCompPointer: Pointer, transformationOverrides) =>
        ps.siteAPI.responsive.updateDetachedTransformation(detachedCompPointer, transformationOverrides),
    reattachLayout: (ps: PS, detachedCompPointer: Pointer) => {
        ps.siteAPI.responsive.clearDetached(detachedCompPointer.id)
    },
    reattachComponents: (ps: PS) => {
        ps.siteAPI.responsive.clearDetached()
    },
    detachGridItem: (ps: PS, compPointer: Pointer) => {
        const parentPointer = ps.pointers.components.getParent(compPointer)
        ps.siteAPI.responsive.detachGridItem(compPointer.id, parentPointer.id)
    },
    updateDetachedGridItem: (ps: PS, detachedCompPointer: Pointer, boundingBox) =>
        ps.siteAPI.responsive.updateDetachedGridItem(detachedCompPointer.id, boundingBox),
    reattachGridItem: (ps: PS, detachedCompPointer: Pointer) => {
        ps.siteAPI.responsive.clearDetachedGridItem(detachedCompPointer.id)
    },
    detachStackItem: (ps: PS, compPointer: Pointer) => {
        const parentPointer = ps.pointers.components.getParent(compPointer)
        ps.siteAPI.responsive.detachStackItem(compPointer.id, parentPointer.id)
    },
    updateDetachedStackItem: (ps: PS, detachedCompPointer: Pointer, boundingBox) =>
        ps.siteAPI.responsive.updateDetachedStackItem(detachedCompPointer.id, boundingBox),
    updateDetachedStackItemOrder: (ps: PS, detachedCompPointer: Pointer, order) =>
        // @ts-expect-error
        ps.siteAPI.responsive.updateDetachedStackItemOrder(detachedCompPointer.id, order),
    reattachStackItem: (ps: PS, detachedCompPointer: Pointer) => {
        ps.siteAPI.responsive.clearDetachedStackItem(detachedCompPointer.id)
    },
    getRuntimeProperty: (ps, pointer, properties) => ps.siteAPI.getRuntimeProperty(pointer, properties)
}
