import {
    CreateExtArgs,
    type CreateExtensionArgument,
    DAL,
    DalValue,
    DmApis,
    Extension,
    ExtensionAPI,
    pointerUtils,
    ValidateValue
} from '@wix/document-manager-core'
import type {
    Pointer,
    ScopeMetaDataTemplate,
    WidgetSlot,
    CompRef,
    Component,
    PossibleViewModes
} from '@wix/document-services-types'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import _ from 'lodash'
import {COMP_DATA_QUERY_KEYS, DATA_TYPES, TPA_COMP_TYPES, VIEW_MODES} from '../constants/constants'
import type {ComponentsAPI} from './components/components'
import type {DataModelExtensionAPI} from './dataModel/dataModel'
import type {RelationshipsAPI} from './relationships'
import type {SchemaExtensionAPI} from './schema/schema'
import type {RemoteStructureMetaDataAPI} from './remoteStructureMetaData'
import {getUniqueRefId, removeSharedPartsPrefix, splitReferredId} from '../utils/inflationUtils'
import {ReportableError} from '@wix/document-manager-utils'
import {deepClone} from '@wix/wix-immutable-proxy'

const {getPointer, getOverrideComponentPointer} = pointerUtils
const {DESKTOP, MOBILE} = VIEW_MODES
const NO_MATCH: string[] = []

export const EVENTS = {
    SLOTS: {
        AFTER_POPULATE: 'SLOTS_AFTER_POPULATE'
    }
}

export type SlotDataType = 'PlaceholderSlot' | 'DynamicSlots'
interface SlotScope {
    componentId: string
    scope: {
        componentIds: string[]
    }
}
export interface SlotsExtensionAPI extends ExtensionAPI {
    slots: {
        getPluginParent(childPointer: CompRef): CompRef | null
        populate(
            slottedComponent: CompRef,
            slotName: string,
            componentDefinition: any,
            newCompPointerOverride?: CompRef,
            slotDataTypeOverride?: SlotDataType
        ): Pointer
        getPopulatedSlotNames(componentPointer: CompRef): string[]
        getSlotsData(componentPointer: CompRef): Record<string, CompRef>
        remove(
            slottedComponent: CompRef,
            slotName: string,
            dontDeleteComponent?: boolean
        ): {appDefinitionId?: string; widgetId?: string}
        isChildOfSlottedComp(componentId: string): boolean
        verifySlotName(slottedComponent: CompRef, slotName: string): void
        removeFromQuery(slottedComponent: CompRef, slotName: string): void
        getWidgetSlots(widgetRef: Pointer): WidgetSlot[]
        getSlotScope(componentId: string): SlotScope
        getCompDefinition(componentPointer: CompRef): any
    }
}

const createExtension = ({experimentInstance}: CreateExtensionArgument): Extension => {
    const createFilters = () => ({
        getSlotsFilter: (namespace: string, value: any): string[] => {
            if (namespace !== DATA_TYPES.slots || !value) {
                return NO_MATCH
            }
            if (value.slots) {
                return _.values(value.slots)
            }
            return NO_MATCH
        }
    })

    const createExtensionAPI = ({
        dal,
        extensionAPI,
        eventEmitter,
        pointers,
        coreConfig
    }: CreateExtArgs): SlotsExtensionAPI => {
        const {components} = extensionAPI as {components: ComponentsAPI}
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        const {schemaAPI} = extensionAPI as SchemaExtensionAPI

        const getPluginParent = (compPointer: CompRef): CompRef | null => {
            if (!compPointer?.id) {
                return null
            }

            const slotsDataItems = dal.queryKeys(
                DATA_TYPES.slots,
                dal.queryFilterGetters.getSlotsFilter(compPointer.id)
            )
            if (slotsDataItems.length === 0) {
                return null
            }

            const slotDataPointer = slotsDataItems[0]
            if (!displayedOnlyStructureUtil.isReferredId(slotDataPointer)) {
                return null
            }

            return getOverrideComponentPointer(slotDataPointer, compPointer.type, 'slotsQuery')
        }

        const getOwnerOfComponentInSlot = (slottedComponent: CompRef): CompRef => {
            if (displayedOnlyStructureUtil.isRefPointer(slottedComponent)) {
                const rootRefHostCompId = displayedOnlyStructureUtil.getRootRefHostCompId(slottedComponent.id)
                return pointerUtils.getCompPointer(rootRefHostCompId, VIEW_MODES.DESKTOP)
            }

            return slottedComponent
        }
        const getCompDefinition = (compPointer: Pointer) => {
            const compStructure = dal.get(compPointer)
            // there is no way to read referred components from extensions, we skip validating these components intentionally because of this
            if (displayedOnlyStructureUtil.isReferredId(compPointer.id) && !compStructure) {
                const {remoteStructureMetaData} = extensionAPI as RemoteStructureMetaDataAPI
                const compType = remoteStructureMetaData.getComponentTypeFromRemoteStructure(compPointer)
                if (compType) {
                    return schemaAPI.getDefinition(compType)
                }
                throw new Error('failed to get slot type')
            }
            return schemaAPI.getDefinition(compStructure.componentType)
        }

        const getSlotProperties = (slottedComponentPointer: CompRef) => {
            const {slotsDataType} = getCompDefinition(slottedComponentPointer)
            const schema = schemaAPI.getSchema(DATA_TYPES.slots, slotsDataType)
            return _.get(schema, ['properties', 'slots', 'properties'])
        }

        const isDynamic = (compPointer: Pointer) => getCompDefinition(compPointer).slotsDataType === 'DynamicSlots'

        const verifySlotName = (slottedComponent: CompRef, slotName: string) => {
            const isDynamicSlots = isDynamic(slottedComponent)
            if (!isDynamicSlots) {
                const slotProperties = getSlotProperties(slottedComponent)
                const allowedSlotNames = _.keys(slotProperties)

                if (!allowedSlotNames.includes(slotName)) {
                    throw new Error(`Slot "${slotName}" is not a valid slot name`)
                }
            }
        }

        const getSlotsQueryRefOverrideId = (slottedComponent: CompRef) => {
            return `${slottedComponent.id}-${COMP_DATA_QUERY_KEYS.slots}`
        }

        const getSlotsDataItem = (slottedComponent: CompRef) => {
            slottedComponent = pointerUtils.getCompPointer(
                removeSharedPartsPrefix(slottedComponent.id),
                slottedComponent.type
            )

            if (displayedOnlyStructureUtil.isReferredId(slottedComponent.id)) {
                const ownerComp = getOwnerOfComponentInSlot(slottedComponent)
                const pageId = pointers.structure.getPageOfComponent(ownerComp).id
                return dataModel.getItem(getSlotsQueryRefOverrideId(slottedComponent), DATA_TYPES.slots, pageId)
            }
            return dataModel.components.getItem(slottedComponent, DATA_TYPES.slots)
        }

        const updateSlotsDataItem = (slottedComponent: CompRef, slotsData: any) => {
            if (displayedOnlyStructureUtil.isReferredId(slottedComponent.id)) {
                const ownerComp = getOwnerOfComponentInSlot(slottedComponent)
                const pageId = pointers.structure.getPageOfComponent(ownerComp).id

                dataModel.addItem(slotsData, DATA_TYPES.slots, pageId, getSlotsQueryRefOverrideId(slottedComponent))
            } else {
                dataModel.components.addItem(slottedComponent, DATA_TYPES.slots, slotsData)
            }
        }

        const getSlotsData = (slottedComponentPointer: CompRef): Record<string, CompRef> => {
            const slotsData = getSlotsDataItem(slottedComponentPointer)
            return _.mapValues(
                slotsData?.slots,
                compId => getPointer(_.trimStart(compId, '#'), slottedComponentPointer.type) as CompRef
            )
        }

        const getPopulatedSlotNames = (componentPointer: CompRef) => _.keys(getSlotsData(componentPointer))

        const verifyCanPopulate = (slotName: string, slottedComponent: CompRef) => {
            verifySlotName(slottedComponent, slotName)

            const currentSlots = getPopulatedSlotNames(slottedComponent)
            if (currentSlots.includes(slotName)) {
                coreConfig.logger.interactionStarted('slot already exist', {
                    extras: {currentSlots, slotName, slottedComponent}
                })
                throw new Error(`Slot "${slotName}" already exists`)
            }
        }

        const getSlotsDataType = (slottedComponent: CompRef, slotDataTypeOverride?: SlotDataType) => {
            if (slotDataTypeOverride) {
                return slotDataTypeOverride
            }

            const {slotsDataType} = getCompDefinition(slottedComponent)
            return slotsDataType
        }

        const updateSlot = (
            slottedComponent: CompRef,
            slotName: string,
            compToAddPointer: Pointer,
            slotDataTypeOverride?: SlotDataType
        ) => {
            verifyCanPopulate(slotName, slottedComponent)
            const slotsDataType = getSlotsDataType(slottedComponent, slotDataTypeOverride)
            const updatedData = getSlotsDataItem(slottedComponent)

            if (updatedData?.slots) {
                updatedData.slots[slotName] = compToAddPointer.id
                updateSlotsDataItem(slottedComponent, updatedData)
            } else {
                updateSlotsDataItem(slottedComponent, {
                    type: slotsDataType,
                    slots: {[slotName]: compToAddPointer.id}
                })
            }
        }

        const populate = (
            slottedComponent: CompRef,
            slotName: string,
            componentDefinition: any,
            newCompPointerOverride?: Pointer,
            slotDataTypeOverride?: SlotDataType
        ): Pointer => {
            const ownerOfComponentInSlot = getOwnerOfComponentInSlot(slottedComponent)
            const addedComponentPointer = components.addComponent(
                ownerOfComponentInSlot,
                componentDefinition,
                newCompPointerOverride,
                {
                    addDefaultResponsiveLayout: true
                }
            )
            updateSlot(slottedComponent, slotName, addedComponentPointer, slotDataTypeOverride)

            eventEmitter.emit(
                EVENTS.SLOTS.AFTER_POPULATE,
                slottedComponent,
                ownerOfComponentInSlot,
                addedComponentPointer
            )

            return addedComponentPointer
        }

        const removeFromQuery = (slottedComponent: CompRef, slotName: string) => {
            const slotsData = getSlotsDataItem(slottedComponent)
            delete slotsData.slots[slotName]
            dal.set(getPointer(slotsData.id, DATA_TYPES.slots, {innerPath: ['slots']}), slotsData.slots)
        }

        const remove = (
            slottedComponent: CompRef,
            slotName: string,
            dontDeleteComponent?: boolean
        ): {appDefinitionId?: string; widgetId?: string} => {
            verifySlotName(slottedComponent, slotName)
            const compPointer = _.get(getSlotsData(slottedComponent), slotName)
            const owningCompPointer = getOwnerOfComponentInSlot(slottedComponent)
            const data = dataModel.components.getItem(owningCompPointer, DATA_TYPES.data) || {}
            if (!compPointer) {
                return {}
            }

            if (dal.has(compPointer) && !dontDeleteComponent) {
                components.removeComponent(compPointer)
            }

            removeFromQuery(slottedComponent, slotName)

            return {appDefinitionId: data.appDefinitionId, widgetId: data.widgetId}
        }

        const isChildOfSlottedComp = (componentId: string): boolean => {
            const slotsIds = dal.queryKeys(DATA_TYPES.slots, dal.queryFilterGetters.getSlotsFilter(componentId))
            return !_.isEmpty(slotsIds)
        }

        const getSlotDataFromContext = (
            metaDataForWidget: ScopeMetaDataTemplate,
            refIdContext: string
        ): WidgetSlot[] => {
            const slots: WidgetSlot[] = []
            _.forEach(metaDataForWidget.slots, slotCompObj => {
                const {compId, interfaces} = slotCompObj
                let pluginInfo = null
                const comp = _.get(metaDataForWidget.components, [compId])

                if (!comp) {
                    throw new ReportableError({
                        errorType: 'COMPONENT_FOR_SLOT_DOES_NOT_EXIST',
                        message: `comp not retrieved by '${compId}' for '${JSON.stringify(
                            slotCompObj
                        )}' during 'getSlotDataFromContext'`
                    })
                }

                const {role, componentType} = comp

                const populatedSlotCompId = getUniqueRefId(refIdContext, compId)
                const slotData = getSlotsData(pointers.getPointer(populatedSlotCompId, VIEW_MODES.DESKTOP) as CompRef)
                if (slotData) {
                    const slottedCompId = _.get(slotData, ['slot', 'id'])
                    if (slottedCompId) {
                        const compData = dataModel.components.getItem(
                            pointers.getPointer(slottedCompId, VIEW_MODES.DESKTOP),
                            DATA_TYPES.data
                        )
                        if (compData?.appDefinitionId && compData?.widgetId) {
                            const clientSpecMapPtr = pointers.general.getClientSpecMapEntryByAppDefId(
                                compData.appDefinitionId
                            )
                            const csmEntry = dal.get(clientSpecMapPtr)
                            const info = _.find(csmEntry.components, component => {
                                return component?.data?.referenceComponentId === compData.widgetId
                            })
                            const marketData = deepClone(_.get(info, ['data', 'marketData'], {}))
                            pluginInfo = {
                                ...marketData,
                                widgetId: compData.widgetId,
                                appDefinitionId: compData.appDefinitionId
                            }
                        }
                    }
                }
                const slotInfo: WidgetSlot = {
                    compRef: pointers.getPointer(populatedSlotCompId, VIEW_MODES.DESKTOP),
                    interfaces,
                    role,
                    componentType
                }
                if (pluginInfo) {
                    slotInfo.pluginInfo = pluginInfo
                }
                slots.push(slotInfo)
            })
            return slots
        }
        const getWidgetSlotsInner = (
            appDefinitionId: string,
            compWidgetId: string,
            possibleSlots: WidgetSlot[],
            refIdContext: string
        ) => {
            const remoteWidgetMetaDataPointer = pointers.remoteStructureMetaData.getRemoteStructureWidgetMetaData(
                appDefinitionId,
                compWidgetId
            )
            const metaDataForWidget = deepClone(dal.get(remoteWidgetMetaDataPointer))
            if (!metaDataForWidget) {
                throw new ReportableError({
                    errorType: 'REMOTE_META_DATA_WAS_NOT_LOADED',
                    message: `remote meta data for ${appDefinitionId} and widget ${compWidgetId} was not loaded`
                })
            }

            const directSlots = getSlotDataFromContext(metaDataForWidget, refIdContext)
            possibleSlots.push(...directSlots)
            _.forEach(metaDataForWidget.innerWidgets, innerWidgetData => {
                const {widgetId, compId} = innerWidgetData
                const innerContext = getUniqueRefId(refIdContext, compId)
                getWidgetSlotsInner(appDefinitionId, widgetId, possibleSlots, innerContext)
            })
        }
        const getWidgetSlots = (widgetCompRef: Pointer) => {
            const widgetData = dataModel.components.getItem(widgetCompRef, DATA_TYPES.data)
            if (!widgetData) {
                throw new ReportableError({
                    errorType: 'WIDGET_NOT_FOUND',
                    message: 'comp not found or does not have data'
                })
            }
            const {appDefinitionId, widgetId} = widgetData
            const possibleSlots: WidgetSlot[] = []
            getWidgetSlotsInner(appDefinitionId, widgetId, possibleSlots, widgetCompRef.id)
            return possibleSlots
        }

        const getSlotScope = (componentId: string): SlotScope => {
            const splitIdsArr: string[] = splitReferredId(componentId)
            return {
                componentId: splitIdsArr.pop() as string,
                scope: {
                    componentIds: splitIdsArr
                }
            }
        }

        return {
            slots: {
                getPluginParent,
                populate,
                getPopulatedSlotNames,
                getSlotsData,
                remove,
                isChildOfSlottedComp,
                verifySlotName,
                removeFromQuery,
                getWidgetSlots,
                getSlotScope,
                getCompDefinition
            }
        }
    }

    const getReferringSlot = (dal: DAL, refCompChildId: string) => {
        return dal.getIndexPointers(dal.queryFilterGetters.getSlotsFilter(refCompChildId), DATA_TYPES.slots)
    }

    const belongsToOverride = (dal: DAL, refCompChildId: string, refHostId: string): boolean => {
        const slotsOverrideId = getReferringSlot(dal, refCompChildId)
        if (slotsOverrideId.length === 1) {
            return displayedOnlyStructureUtil.getRootRefHostCompId(slotsOverrideId[0].id) === refHostId
        }
        return false
    }

    const createValidator = ({dal, extensionAPI, coreConfig}: DmApis): Record<string, ValidateValue> => {
        const getSlotOwners = (slotsQueryPointer: Pointer): Pointer[] => {
            const {relationships} = extensionAPI as RelationshipsAPI
            if (displayedOnlyStructureUtil.isRefPointer(slotsQueryPointer)) {
                const refComponentId = displayedOnlyStructureUtil.getRootRefHostCompId(slotsQueryPointer.id)

                return _.filter(
                    [getPointer(refComponentId, DESKTOP), getPointer(refComponentId, MOBILE)],
                    refCompPointer => {
                        const plugin = getPointer(slotsQueryPointer.id, refCompPointer.type)
                        return dal.has(refCompPointer) && dal.has(plugin)
                    }
                )
            }

            return relationships.getOwningReferencesToPointer(slotsQueryPointer)
        }

        const getInvalidRefComponentChildren = (refComponent: Component | undefined): string[] => {
            if (!refComponent?.components?.length) {
                return []
            }

            const refComponentChildren = refComponent.components.filter((compId: string) => !compId.startsWith('dead-'))
            return _.reject(refComponentChildren, child => belongsToOverride(dal, child, refComponent.id))
        }

        const getInvalidChildrenOfTpa = (pointer: CompRef): string[] => {
            const tpa: Component | undefined = dal.get(pointer)
            if (tpa?.componentType !== TPA_COMP_TYPES.TPA_WIDGET || !tpa.components?.length) {
                return []
            }

            const {slotsQuery} = tpa
            if (!slotsQuery) {
                return tpa.components
            }

            const slots = dal.get({id: slotsQuery, type: DATA_TYPES.slots})?.slots
            if (!slots) {
                return tpa.components
            }

            const slotsIds = new Set(Object.values(slots))
            return tpa.components.filter(child => !slotsIds.has(child))
        }

        return {
            invalidRefComponentChildren: (pointer: Pointer, value: DalValue) => {
                if (!_.includes(Object.values(VIEW_MODES), pointer.type)) {
                    return
                }

                const component: Component | undefined = dal.get(pointer)

                if (component?.type !== 'RefComponent') return

                const invalidRefCompChildren = getInvalidRefComponentChildren(component)

                if (invalidRefCompChildren.length === 0) return

                coreConfig.logger.captureError(
                    new ReportableError({
                        message: 'invalidRefCompChildren',
                        errorType: 'invalidRefCompChildren',
                        extras: {
                            pointer,
                            value
                        }
                    })
                )
                return [
                    {
                        shouldFail: true,
                        type: 'invalidRefComponentChildren',
                        message: 'Invalid components inside refComponent',
                        extras: {
                            invalidRefCompChildren
                        }
                    }
                ]
            },
            invalidSlotQuery: (pointer: Pointer) => {
                if (pointer.type === DATA_TYPES.slots && displayedOnlyStructureUtil.isRefPointer(pointer)) {
                    const refCompId = displayedOnlyStructureUtil.getRefHostCompId(pointer.id)

                    const invalidRefCompChildren = _.uniq(
                        Object.values(VIEW_MODES).flatMap((viewMode: PossibleViewModes) =>
                            getInvalidRefComponentChildren(dal.get(pointerUtils.getCompPointer(refCompId, viewMode)))
                        )
                    )

                    if (invalidRefCompChildren.length === 0) return

                    return [
                        {
                            shouldFail: coreConfig.experimentInstance.isOpen('dm_shouldFailInvalidSlotQuery'),
                            type: 'invalidSlotQuery',
                            message:
                                'Reference to plugin component was removed from slots data item, but was not removed from the RefComponent children',
                            extras: {
                                invalidRefCompChildren
                            }
                        }
                    ]
                }
            },
            invalidChildrenOfTpa: (pointer: Pointer) => {
                if (!experimentInstance.isOpen('dm_validateTpaChildren')) return

                const invalidChildrenOfTpa = getInvalidChildrenOfTpa(pointer as CompRef)
                if (invalidChildrenOfTpa.length) {
                    return [
                        {
                            shouldFail: true,
                            type: 'invalidChildrenOfTpa',
                            message: 'Invalid components inside of the TPA widget',
                            extras: {
                                invalidChildrenOfTpa
                            }
                        }
                    ]
                }
            },
            invalidSlotsReference: (pointer: Pointer, value: DalValue) => {
                if (pointer.type === DATA_TYPES.slots && !_.isEmpty(value?.slots)) {
                    const referencedCompsIds = value.slots as Record<string, string>
                    const slotOwners = getSlotOwners(pointer)
                    const ownersFromDal = _.map(slotOwners, ownerPtr => dal.get(ownerPtr))
                    const missingCompsIds = _.pickBy(referencedCompsIds, refId =>
                        _.some(ownersFromDal, owner => {
                            return !_.includes(owner.components, refId)
                        })
                    )
                    if (!_.isEmpty(missingCompsIds)) {
                        return [
                            {
                                shouldFail: true,
                                type: 'invalidSlotsReference',
                                message: 'Invalid slots reference to one or more of its components',
                                extras: {
                                    missingCompsIds
                                }
                            }
                        ]
                    }
                }
            }
        }
    }

    return {
        name: 'slots',
        createFilters,
        createValidator,
        createExtensionAPI
    }
}
export {createExtension}
