import type {PagesData} from '@wix/document-services-types'
import {siteConstants} from '@wix/santa-core-utils'
const {URL_FORMATS} = siteConstants

const COMPONENT_TYPES = {
    ANCHOR: 'wysiwyg.common.components.anchor.viewer.Anchor',
    APP_CONTROLLER: 'platform.components.AppController',
    BACK_TO_TOP_BUTTON: 'wysiwyg.viewer.components.BackToTopButton',
    BOX_SLIDE_SHOW: 'wysiwyg.viewer.components.BoxSlideShow',
    BOX_SLIDE_SHOW_SLIDE: 'wysiwyg.viewer.components.BoxSlideShowSlide',
    COLUMN: 'wysiwyg.viewer.components.Column',
    CONTACT_FORM: 'wysiwyg.viewer.components.ContactForm',
    CONTAINER: 'mobile.core.components.Container',
    DROP_DOWN_MENU: 'wysiwyg.viewer.components.menus.DropDownMenu',
    VERTICAL_MENU: 'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu',
    EXPANDABLE_MENU: 'wysiwyg.viewer.components.ExpandableMenu',
    STYLABLE_HORIZONTAL_MENU: 'wixui.StylableHorizontalMenu',
    BREADCRUMBS: 'wixui.Breadcrumbs',
    HEADER_CONTAINER: 'wysiwyg.viewer.components.HeaderContainer',
    FOOTER_CONTAINER: 'wysiwyg.viewer.components.FooterContainer',
    MASTER_PAGE: 'mobile.core.components.MasterPage',
    MEDIA_CONTAINER: 'wysiwyg.viewer.components.MediaContainer',
    LOGIN_BUTTON: 'wysiwyg.viewer.components.LoginButton',
    MEDIA_PLAYER: 'wysiwyg.viewer.components.MediaPlayer',
    MEDIA_CONTROLS: 'wysiwyg.viewer.components.MediaControls',
    MEDIA_OVERLAY_CONTROLS: 'wysiwyg.viewer.components.MediaOverlayControls',
    PAGE: 'mobile.core.components.Page',
    PAGES_CONTAINER: 'wysiwyg.viewer.components.PagesContainer',
    GOOGLE_MAP: 'wysiwyg.viewer.components.GoogleMap',
    PINTEREST_PIN_IT_WIDGET: 'wysiwyg.common.components.pinterestpinit.viewer.PinterestPinIt',
    POPUP_CLOSE_ICON_BUTTON: 'wysiwyg.viewer.components.PopupCloseIconButton',
    QUICK_ACTION_BAR_ITEM: 'wysiwyg.viewer.components.QuickActionBarItem',
    QUICK_ACTION_BAR: 'wysiwyg.viewer.components.QuickActionBar',
    REPEATER: 'wysiwyg.viewer.components.Repeater',
    FILE_UPLOADER: 'wysiwyg.viewer.components.inputs.FileUploader',
    INLINE_POPUP: 'wysiwyg.viewer.components.InlinePopup',
    SITE_BUTTON: 'wysiwyg.viewer.components.SiteButton',
    STYLABLE_BUTTON: 'wixui.StylableButton',
    LOGIN_SOCIAL_BAR: 'wysiwyg.viewer.components.LoginSocialBar',
    SINGLE_AUDIO_PLAYER: 'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer',
    STRIP_CONTAINER_SLIDE_SHOW: 'wysiwyg.viewer.components.StripContainerSlideShow',
    STRIP_CONTAINER_SLIDE_SHOW_SLIDE: 'wysiwyg.viewer.components.StripContainerSlideShowSlide',
    STRIP_COLUMNS_CONTAINER: 'wysiwyg.viewer.components.StripColumnsContainer',
    SVG_SHAPE: 'wysiwyg.viewer.components.svgshape.SvgShape',
    TPA_GLUED_WIDGET: 'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
    TPA_MULTI_SECTION: 'wysiwyg.viewer.components.tpapps.TPAMultiSection',
    TPA_MODAL: 'wysiwyg.viewer.components.tpapps.TPAModal',
    TPA_SECTION: 'wysiwyg.viewer.components.tpapps.TPASection',
    TPA_WIDGET: 'wysiwyg.viewer.components.tpapps.TPAWidget',
    VECTOR_IMAGE: 'wysiwyg.viewer.components.VectorImage',
    VK_SHARE_BUTTON: 'wysiwyg.viewer.components.VKShareButton',
    HTML_COMPONENT: 'wysiwyg.viewer.components.HtmlComponent',
    WPHOTO: 'wysiwyg.viewer.components.WPhoto',
    IMAGE_BUTTON: 'wysiwyg.common.components.imagebutton.viewer.ImageButton',
    IMAGEX: 'wixui.ImageX',
    APP_PART: 'wixapps.integration.components.AppPart',
    APP_PART2: 'wixapps.integration.components.AppPart2',
    APP_PAGE: 'wixapps.integration.components.AppPage',
    WRICHTEXT: 'wysiwyg.viewer.components.WRichText',
    TWITTER_TWEET: 'wysiwyg.viewer.components.WTwitterTweet',
    POPUP_CONTAINER: 'wysiwyg.viewer.components.PopupContainer',
    MATRIX_GALLERY: 'wysiwyg.viewer.components.MatrixGallery',
    TINY_MENU: 'wysiwyg.viewer.components.mobile.TinyMenu',
    VERTICAL_ANCHORS_MENU: 'wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu',
    TPA_3DGALLERY: 'wysiwyg.viewer.components.tpapps.TPA3DGallery',
    SLIDER_GALLERY: 'wysiwyg.viewer.components.SliderGallery',
    SITE_BACKGROUND: 'wysiwyg.viewer.components.SiteBackground',
    PAGINATED_GRID_GALLERY: 'wysiwyg.viewer.components.PaginatedGridGallery',
    POPOVER: 'wysiwyg.viewer.components.Popover',
    GROUP: 'wysiwyg.viewer.components.Group',
    REF_COMPONENT: 'wysiwyg.viewer.components.RefComponent',
    CHECK_BOX_GROUP: 'wysiwyg.viewer.components.inputs.CheckboxGroup',
    APP_WIDGET: 'platform.components.AppWidget',
    SLOT_PLACEHOLDER: 'wixui.SlotsPlaceholder',
    RESPONSIVE_SECTION: 'responsive.components.Section',
    CLASSIC_SECTION: 'wysiwyg.viewer.components.ClassicSection',
    COLLAPSIBLE_TEXT: 'wixui.CollapsibleText'
}

const PAGE_DATA_TYPES = {
    page: 'Page',
    appPage: 'AppPage'
}

const DEFAULT_PAGE_DATA: Partial<PagesData> = {
    type: 'Page',
    hideTitle: true,
    icon: '',
    descriptionSEO: '',
    metaKeywordsSEO: '',
    pageTitleSEO: 'page-title-seo',
    hidePage: false,
    underConstruction: false,
    tpaApplicationId: 0,
    pageSecurity: {
        requireLogin: false
    },
    indexable: true,
    isLandingPage: false,
    isPopup: false,
    pageBackgrounds: {
        desktop: {
            custom: true,
            isPreset: true
        },
        mobile: {
            custom: true,
            isPreset: true
        }
    },
    metaData: {
        pageId: 'masterPage'
    }
}

const DEFAULT_DS_MODEL = {
    originalTemplateId: '111-111-111',
    version: 1,
    revision: 1,
    metaSiteData: {
        externalUriMappings: [] as string[],
        favicon: '',
        indexable: true,
        metaTags: [
            {
                name: 'description',
                value: '',
                isProperty: false
            },
            {
                name: 'fb_admins_meta_tag',
                value: '',
                isProperty: false
            },
            {
                name: 'keywords',
                value: '',
                isProperty: false
            }
        ],
        suppressTrackingCookies: false,
        thumbnail: '',
        title: ''
    },
    editorSessionId: '111-111-111',
    publicUrl: 'https://dsUser.wixsite.com/',
    usedMetaSiteNames: ['website-1', 'mysite-1'],
    userInfo: {
        name: 'dsUser',
        email: 'dsUser@wix.com',
        isStudio: false,
        userId: '111-111-111',
        accountId: '111-111-111'
    },
    siteName: '-start-from-scratch',
    customHeadTags: undefined as string | undefined,
    isPublished: true,
    pendingApps: [] as string[],
    permissionsInfo: {
        isOwner: false,
        permissions: [] as string[]
    },
    isHttpsEnabled: true,
    mediaManagerInfo: {},
    isDraft: false,
    autoSaveInfo: {}
}

const DEFAULT_RENDERER_MODEL = {
    previewMode: false,
    urlFormatModel: {
        format: URL_FORMATS.SLASH
    },
    clientSpecMap: {
        11: {
            type: 'tpa',
            applicationId: 11,
            appDefinitionId: 'e4c4a4fb-673d-493a-9ef1-661fa3823ad9',
            instanceId: '1234',
            demoMode: true
        },
        13: {
            type: 'tpa',
            applicationId: 13,
            appDefinitionId: 'e4c4a4fb-673d-493a-9ef1-661fa3823ad8',
            instanceId: '',
            demoMode: false
        },
        16: {
            type: 'appbuilder',
            applicationId: 16,
            appDefinitionId: '3d590cbc-4907-4cc4-b0b1-ddf2c5edf297',
            instanceId: 'EA8957FF-C900-4339-94ED-FF6B83501955',
            state: 'Template'
        },
        '-666': {
            type: 'metasite',
            metaSiteId: '591148be-f404-41e9-b146-3c374f7f0e22',
            appDefId: '22bef345-3c5b-4c18-b782-74d4085112ff',
            instance: 'stub_instance_of_-666_clientSpec_entry',
            expirationDate: '2021-01-07T21:40:03.442Z',
            appDefinitionId: '22bef345-3c5b-4c18-b782-74d4085112ff',
            applicationId: -666
        }
    },
    siteMetaData: {
        contactInfo: {
            address: '',
            companyName: '',
            fax: '',
            phone: ''
        },
        quickActions: {
            colorScheme: 'dark',
            configuration: {
                addressEnabled: false,
                emailEnabled: false,
                navigationMenuEnabled: true,
                phoneEnabled: false,
                quickActionsMenuEnabled: false,
                socialLinksEnabled: false
            }
        },
        socialLinks: [] as string[],
        adaptiveMobileOn: true
    },
    siteId: 'a2051fe8-e3d3-42b6-a3c0-f72b2fce5732',
    metaSiteId: '8bf9f82c-51ba-4ede-a31c-d84ec021d869',
    siteInfo: {
        applicationType: 'HtmlWeb',
        documentType: 'UGC',
        siteId: 'bac442c8-b7fd-4bd1-ac7e-096fec2fc800',
        siteTitleSEO: 'reactest'
    },
    languageCode: 'en',
    runningExperiments: {
        sv_meshLayout: 'new'
    },
    premiumFeatures: [] as string[],
    passwordProtectedPages: {
        pages: [] as string[]
    },
    pagesPlatformApplications: {},
    userId: '8504c4b3-a1a4-46ec-8da2-cdab0eadcc15',
    pageList: {
        topology: [
            {
                baseUrl: 'https://Base1',
                parts: 'sites/{filename}'
            },
            {
                baseUrl: 'https://Base2',
                parts: 'sites/{filename}'
            }
        ],
        pages: [
            {
                pageId: 'defaultPageId',
                title: 'defaultPageIditle',
                pageUriSEO: 'defaultPageIdtitleUriSeo',
                pageJsonFileName: 'defaultPageId.json'
            }
        ],
        mainPageId: 'defaultPageId'
    },
    sitePropertiesInfo: {
        currency: 'EUR',
        language: 'en',
        locale: 'en-us',
        multilingualInfo: {
            originalLanguage: {
                code: 'en',
                countryCode: 'USA',
                id: 'en',
                languageCode: 'en',
                locale: 'en-us',
                machineTranslationLanguageCode: 'EN',
                name: 'English',
                primary: true
            },
            translationLanguages: [],
            languageCode: 'en'
        },
        siteDisplayName: 'My Site 11',
        timeZone: 'Europe/Dublin'
    }
}

const DEFAULT_MASTER_PAGE_DATA = {
    type: 'Document',
    id: 'masterPage',
    documentType: 'document',
    metaData: {
        isPreset: true,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'masterPage'
    },
    siteName: 'Template Base',
    mainPage: 'defaultPageId',
    mainPageId: 'defaultPageId',
    renderModifiers: {
        pageAutoShrink: true
    },
    characterSets: ['latin', 'arabic', 'hebrew'],
    usedFonts: [] as string[],
    ignoreBottomBottomAnchors: true,
    layoutSettings: {
        useDesktopSectionsLayout: true,
        mechanism: 'mesh'
    }
}

export {
    DEFAULT_MASTER_PAGE_DATA,
    DEFAULT_RENDERER_MODEL,
    DEFAULT_PAGE_DATA,
    COMPONENT_TYPES,
    DEFAULT_DS_MODEL,
    PAGE_DATA_TYPES
}
