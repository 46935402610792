import _ from 'lodash'
import type {
    ComponentExport,
    CompRef,
    ImportExportOptions,
    PS,
    RawComponentExport,
    WMLFiles
} from '@wix/document-services-types'
import hooks from '../hooks/hooks'

type PossibleExportFormats = WMLFiles | ComponentExport

const createConvertApi =
    (convertExtensionApiPath: string): Function =>
    async (ps: PS, componentToReplace: CompRef, componentExport: PossibleExportFormats): Promise<void> => {
        try {
            const rawComponentExport: RawComponentExport = await _.invoke(
                ps.extensionAPI.importExport,
                convertExtensionApiPath,
                componentExport
            )
            ps.setOperationsQueue.asyncPreDataManipulationComplete(rawComponentExport)
        } catch (e) {
            ps.setOperationsQueue.asyncPreDataManipulationComplete(null, e as Error)
        }
    }

export default {
    components: {
        replace: (
            ps: PS,
            componentExport: RawComponentExport,
            componentToReplace: CompRef,
            originalExport: PossibleExportFormats,
            options?: ImportExportOptions
        ): CompRef => {
            const res = ps.extensionAPI.importExport.components.replace(componentToReplace, componentExport, options)
            hooks.executeHook(hooks.HOOKS.IMPORT_EXPORT.AFTER_IMPORT, null!, [ps])
            return res
        }
    },
    pages: {
        replace: (
            ps: PS,
            pageExport: RawComponentExport,
            pageToReplace: CompRef,
            originalExport: PossibleExportFormats,
            options?: ImportExportOptions
        ): CompRef => {
            const res = ps.extensionAPI.importExport.pages.replace(pageToReplace, pageExport, options)
            hooks.executeHook(hooks.HOOKS.IMPORT_EXPORT.AFTER_IMPORT, null!, [ps])
            return res
        }
    },
    wml: {
        convertWmlToRawComponentExport: createConvertApi('wml.convertWmlToRawComponentExport'),
        exportComponent: (ps: PS, componentPointer: CompRef, options?: ImportExportOptions): WMLFiles =>
            ps.extensionAPI.importExport.wml.exportComponent(componentPointer, options),
        exportPage: (ps: PS, pagePointer: CompRef, options?: ImportExportOptions): WMLFiles =>
            ps.extensionAPI.importExport.wml.exportPage(pagePointer, options)
    },
    core: {
        convertCoreToRawComponentExport: createConvertApi('core.convertCoreToRawComponentExport'),
        exportComponent: (ps: PS, componentPointer: CompRef, options?: ImportExportOptions): ComponentExport =>
            ps.extensionAPI.importExport.core.exportComponent(componentPointer, options),
        exportPage: (ps: PS, pagePointer: CompRef, options?: ImportExportOptions): ComponentExport =>
            ps.extensionAPI.importExport.core.exportPage(pagePointer, options)
    }
}
